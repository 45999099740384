import React, { useState } from "react";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import ShareTokenFileRow from "./ShareTokenFileRow";
import { usePrivacy, usePrivacyDispatch } from "../ShareTokenPrivacy/TokenPrivacyContext";
import ShareTokenPropertyRow from "./ShareTokenPropertyRow";
import { documentPathTypes } from "../../../configs/constant";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const ShareOption = (props: any) => {
	const { data } = props;
	const { name, path, items = [] } = data;
	const [expanded, setExpanded] = useState(false);

	const privacySettings: any = usePrivacy();
	const dispatch = usePrivacyDispatch();

	if (documentPathTypes.COVER_IMAGE === path) return null;

	const getKey = (item: any) => {
		return item.filename
			? ['documents', path, item.uuid].join('.')
			: `token.${item.key}`;
	}

	const checkedAll = items.filter((item: any) => {
		const key = getKey(item);
		return !!privacySettings.sharedOptions[key];
	}).length === items.length;

	const selectAllHandle = (selected: boolean) => {
		items.forEach((item: any) => {
			const key = getKey(item);

			dispatch({
				type: 'update_share_option',
				data: {
					key,
					selected
				}
			})
		});
	};

	const _toggleGroup = () => {
		setExpanded(!expanded);
	}

	return (
		<>
			{!data.hideAll && <TableRow className="table__row group">
				<TableCell onClick={_toggleGroup}>{name}</TableCell>
				<TableCell align="center" key={`property-permision-${path}`} padding="checkbox">
					<GridExpandMoreIcon className={`accordion-icon ${expanded ? 'expanded' : ''}`} onClick={_toggleGroup} />
					<Checkbox
						size="small"
						color="secondary"
						checked={checkedAll}
						onChange={(evt, checked) => selectAllHandle(checked)}
					/>
				</TableCell>
			</TableRow>}
			{items.length > 0 &&
				items.map((item: any, idx: number) =>
					!!item.filename ? (
						<ShareTokenFileRow className={!expanded && 'invisible'} path={path} file={item} key={`token-file-${idx}`} />
					) : (
						<ShareTokenPropertyRow
							{...item}
							name={item.name}
							permId={`token.${item.key}`}
							childs={item.childs}
							key={`token-property-${item.key}`}
						/>
					)
				)}
		</>
	);
};

export default ShareOption;
