import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './SeedRevealVerify.scss'
import { t } from 'i18next';
import { connect } from 'react-redux';
import { decryptSeedPhrase } from '../../service/crypto';
import { persistor } from '../../store/rootReducer';
import { logout } from '../../store/slices/authSlice';
import SeedPhraseRevealVerifyConfirm from './SeedPhraseRevealVerifyConfirm';

interface ISeedPhraseRevealVerify {
  open: boolean;
  needVerify: boolean;
  seedPhrase: string;
  password: string;
  address: string;
  onDismiss: () => void;
  userLogout: () => void;
}

const SeedPhraseRevealVerify = ({ open, needVerify, seedPhrase, password, address, onDismiss, userLogout }: ISeedPhraseRevealVerify) => {
  const [phrases, setPhrases] = useState<Array<string>>([])
  const [mode, setMode] = useState('view');

  const _onLogout = () => {
    onDismiss();
    userLogout();
  }

  const _onContinue = () => {
    setMode('verify');
  }

  useEffect(() => {
    if (open && password) {
      let mnemonic = decryptSeedPhrase(seedPhrase, password, address);
      setPhrases(mnemonic.split(' '))
    }
  }, [open, seedPhrase, password, address])

  return (mode === 'verify' ? <SeedPhraseRevealVerifyConfirm open={open} phrases={phrases} onDismiss={onDismiss} /> :
    <Dialog open={open} className="seed-phrase-reveal-verify" onClose={onDismiss}>
      <Box className="seed-phrase-reveal-verify__head">
        <span className='logo'></span>
      </Box>
      <DialogContent>
        <Typography variant="h5" fontWeight="500" sx={{ mb: 1 }}>{t('pages:profile.write_down_seed_phrase')}</Typography>
        <Typography variant="body2" sx={{ mb: 3 }}>{t('pages:profile.seed_phrase_note_write_down')}</Typography>

        <Grid container spacing={2} className='seed-phrase-reveal-verify__phrases'>
          <Grid item xs={6} >
            {phrases.map((phrase, idx) => {
              if (idx >= phrases.length / 2) return null;
              return (
                <Box className="seed-phrase-reveal-verify__phrase">
                  <Typography variant="body2" component="span" color="GrayText">#{idx + 1}</Typography>
                  <Typography variant="body2" component="span" sx={{ ml: 1 }}>{phrase}</Typography>
                </Box>
              )
            })}
          </Grid>
          <Grid item xs={6} >
            {phrases.map((phrase, idx) => {
              if (idx < phrases.length / 2) return null;
              return (
                <Box className="seed-phrase-reveal-verify__phrase">
                  <Typography variant="body2" component="span" color="GrayText">#{idx + 1}</Typography>
                  <Typography variant="body2" component="span" sx={{ ml: 1 }}>{phrase}</Typography>
                </Box>
              )
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} px={2} mb={2}>
          <Grid item xs={12}>
            <Button className="w--100" onClick={needVerify ? _onContinue : onDismiss} color="secondary" variant="contained">
              {needVerify ? t("common:continue") : t("common:close")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button className="w--100" onClick={_onLogout} variant="text">
              {t("common:logout")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog >
  )
}

export default connect((state: any) => {
  const wallet = state?.wallet;
  const auth = state?.auth;
  return {
    isLoggedIn: (!!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime()),
    seedPhrase: wallet?.private?.seedPhrase,
    address: wallet?.address,
  };
}, dispatch => {
  return {
    userLogout: () => {
      persistor.purge();
      dispatch(logout({}));
    }
  }
})(SeedPhraseRevealVerify);
