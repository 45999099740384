import React, { createContext, useContext, useReducer } from "react";

export const SelectTokenContext = createContext(null);
export const SelectTokenDispatchContext = createContext(null);

const initialConfigs = {
	tokens: [],
	lastUpdate: 0
}

const reducer = (settings: any, action: any) => {
	const tokens = action?.tokens;
	let newState;

	try {
		newState = JSON.parse(JSON.stringify(settings));
	} catch (error) {
		newState = { tokens: [] };
	}

	switch (action.type) {
		case "update":
			return {
				...newState,
				tokens: tokens
			};
		case "reset":
			return { ...initialConfigs, lastUpdate: Date.now() };
		default:
			return newState;
	}
}


export function SelectTokenProvider({ children }: any) {
	const [settings, dispatch] = useReducer(reducer, initialConfigs);

	return (
		<SelectTokenContext.Provider value={settings} >
			<SelectTokenDispatchContext.Provider value={dispatch}>
				{children}
			</SelectTokenDispatchContext.Provider>
		</SelectTokenContext.Provider>
	);
}

export function useSelectTokenContext() {
	return useContext(SelectTokenContext);
}

export function useSelectTokenDispatch() {
	return useContext(SelectTokenDispatchContext);
}