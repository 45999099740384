import { useEffect, useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Box,
	Checkbox,
	FormControlLabel,
	Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { t } from "i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createWallet } from "../../store/actions/walletActions";
import { MessageBox } from "../Animation";
import { MuiTelInput } from "mui-tel-input";

import "./SignUp.scss";
import { FormField } from "../Form/FormField";
import { updateError } from "../../store/slices/walletSlice";
import { useLazyGetUsernameByEmailQuery } from "../../service/api/meveoApi";

const SignUp = (props) => {
	const { status, error, createAccount, logginStatus, resetError, isOperator = false } = props;
	const [errors, setErrors] = useState({});
	const [agreement, setAgreement] = useState(false);
	const [getUsernameByEmail] = useLazyGetUsernameByEmailQuery();

	const [formData, setFormData] = useState({
		username: "",
		password: "",
		confirmPassword: "",
		email: "",
		phone: "",
		dialCode: 0,
		operatorPrivateKey: ""
	});

	const validationRules = {
		username: [[/(?=.{6,})/, t("pages:login.signup_username_invalid")]],
		email: [[/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, t("pages:login.signup_email_invalid")]],
		phone: [[/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, t("pages:login.signup_phone_invalid")]],
		password: [
			[
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_\{\}\[\]\=\+])(?=.{8,})/,
				t("pages:login.password_field_valid_message"),
			],
		],
		confirmPassword: [
			[
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_\{\}\[\]\=\+])(?=.{8,})/,
				t("pages:login.password_field_valid_message"),
			],
		],
	};

	const validation = () => {
		let errors = {};
		let isValid = true;
		for (let fieldId in formData) {
			errors[fieldId] = validFormField(fieldId);
			isValid = isValid && errors[fieldId].length <= 0;
		}
		setErrors(errors);
		return isValid;
	};

	const handleChange = (event, value, id) => {
		let fieldId = !!event ? event?.target?.name : id;
		let fieldValue = !!event ? event?.target?.value : value;
		
		if ( !fieldId ) return;

		fieldValue = fieldValue.replace(/\s/g, "");
		let fieldErrors = validFormField(fieldId, fieldValue);
		setErrors({
			...errors,
			[fieldId]: fieldErrors,
		});

		setFormData({
			...formData,
			[fieldId]: fieldValue,
		});
	};

	const submitHandle = async (event) => {
		event.preventDefault();
		if (!agreement) return;
		let isValid = validation();
		if (!isValid) return;

		if ( !!isOperator && (typeof formData.operatorPrivateKey === 'undefined' || formData.operatorPrivateKey.length < 2) ) {
			setErrors({
				...errors,
				operatorPrivateKey: ["You need an operator private key to signup"],
			});
			return;
		}

		const data = await getUsernameByEmail(formData.email).unwrap();
		if (data.status === 'success' && data.result) {
			setErrors(prev => ({
				...prev,
				email: t('pages:login.email_is_taken'),
			}));
			return;
		}
		createAccount(formData);
	};

	const validFormField = (id, value) => {
		let fieldErrors = [];
		
		value = value || formData[id];
		if( id === 'confirmPassword' && value !== formData.password ) {
			fieldErrors.push(t('pages:login.password_confirmation_message'))
		}

		if (typeof formData[id] === "undefined") return fieldErrors;
		const rules = validationRules[id];
		if (!rules || rules.length <= 0) return [];


		for (let idx in rules) {
			let rule = rules[idx];
			if (!!rule[1] && !rule[0].test(value)) {
				fieldErrors.push(rule[1]);
			}
		}

		return fieldErrors;
	};

	useEffect(() => {
		if (status === 'fail' && error && error['wallet/create/rejected']) {
			const err = error['wallet/create/rejected'];

			if (err.includes('same username')) {
				setErrors(prev => ({
					...prev,
					username: t('pages:login.username_is_taken')
				}));
			} else {
				setErrors(prev => ({
					...prev,
					phone: err,
				}));
			}
		}
	}, [status, error]);

	useEffect(() => {
		return () => resetError();
	}, [resetError])

	return (
		<Box
			component="form"
			noValidate={false}
			autoComplete="off"
			className="flex flex--vertical form__signup"
			onSubmit={submitHandle}
			sx={{ p: 2, width: "100%", maxWidth: "600px" }}
		>
			{logginStatus === "loading" && (
				<div className="form--logging-in">
					<CircularProgress className="icon" />
				</div>
			)}
			<Typography
				sx={{ ml: "auto", mr: "auto", mb: 8 }}
				variant="subtitle"
				align="center"
				className="page-anchor"
			>
				<span>
					<FontAwesomeIcon icon={solid("user-check")} />
				</span>{" "}
				{t(isOperator ? "pages:login.signup_operator_user_form_label" : "pages:login.signup_form_label")}
			</Typography>


			{status === "fail" && !!error && Object.values(error).length > 0 && (
				<MessageBox type="error">
					<ul>
						{Object.values(error).map((i, idx) => (
							<li key={`error-${idx}`}>{typeof i === "string" ? i : i.message || ""}</li>
						))}
					</ul>
				</MessageBox>
			)}
			<fieldset className="flex flex--vertical" disabled={status === "loading"}>
				<Box className="form__fields flex flex--vertical" sx={{ mb: 2 }}>
					<FormField
						fieldId="username"
						value={formData.username}
						onChange={handleChange}
						label={t("pages:login.label_username")}
						errors={errors.username}
					/>

					<FormField
						fieldId="email"
						value={formData.email}
						onChange={handleChange}
						label={t("pages:login.label_email")}
						errors={errors.email}
					/>

					<FormField
						fieldId="phone"
						value={formData.phone}
						onChange={handleChange}
						label={t("pages:login.label_phone")}
						errors={errors.phone}
						type="phone"
					/>

					{!!isOperator && (
						<FormField
							fieldId="operatorPrivateKey"
							value={formData.operatorPrivateKey}
							onChange={handleChange}
							label={t("pages:login.label_operator_private_key")}
							errors={errors.operatorPrivateKey}
						/>
					)}

					<Typography
						color={!!errors.password && errors.password.length > 0 ? "error" : ""}
						variant="subtitle2"
						sx={{ mb: 2 }}
					>
						<div dangerouslySetInnerHTML={{ __html: t("pages:login.password_field_valid_message") }}></div>
					</Typography>
					<FormField
						type="password"
						fieldId="password"
						value={formData.password}
						onChange={handleChange}
						label={t("pages:login.label_password")}
						errors={errors.password}
						hideErrors={true}
					/>

					<FormField
						type="password"
						fieldId="confirmPassword"
						value={formData.confirmPassword}
						onChange={handleChange}
						label={t("pages:login.label_confirm_password")}
						errors={errors.confirmPassword}
					/>

					<FormControlLabel
						value="agre"
						control={
							<Checkbox
								onChange={(event) => {
									setAgreement(event.target.checked);
								}}
							/>
						}
						label={
							<Trans
								i18nKey="pages:login.signup_agreement_label"
								components={{
									privacy: <Link to="/privacy-policy" target="_blank" rel="noreferer" />,
									terms: <Link to="/terms-conditions" target="_blank" rel="noreferer" />,
								}}
							></Trans>
						}
						labelPlacement="end"
					/>
				</Box>

				<LoadingButton
					disabled={!agreement}
					loading={status === "loading"}
					sx={{ mb: 2, borderRadius: 6 }}
					variant="contained"
					type="submit"
				>
					{t("pages:login.signup_account_button")}
				</LoadingButton>
			</fieldset>
		</Box>
	);
};

export default connect(
	(state) => {
		const walletState = state?.wallet;
		const authState = state?.auth;
		return {
			status: walletState.status,
			error: walletState.error,
			logginStatus: authState.status,
		};
	},
	(dispatch) => {
		return {
			createAccount: (formData) => {
				dispatch(createWallet(formData));
			},
			resetError: () => {
				dispatch(updateError({}));
			}
		};
	}
)(SignUp);
