import { createSlice } from "@reduxjs/toolkit";
import { getAuthToken, refreshToken } from "../actions/authActions";

const initialState = {
	status: "idle",
	error: null,
	accessToken: null,
	refreshToken: null,
	expiredAt: 0,
	isRefreshingToken: false,
	// oncePwd: "",
	seedPhrase: null,
};

const authSlice = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		logout(state, action) {
			state.accessToken = "";
			state.refreshToken = "";
			state.expiredAt = 0;
			state.status = "idle";
			state.seedPhrase = null;
			state.oncePwd = "";
			state.error = null;
			state.isRefreshingToken = false;
		},
		resetError: (state, action) => {
			state.error = null;
			state.status = "idle";
		},
		// updatePwd: (state, action) => {
		// 	!!action.payload && (state.oncePwd = action.payload);
		// },
		removePwd: (state, action) => {
			state.oncePwd = "";
		},
		updateSeedPhrase: (state, action) => {
			if (typeof action.payload === "string") {
				state.seedPhrase = action.payload;
			}
		},
		manualLogin: (state, action) => {
			const { data } = action.payload

			state.accessToken = data.access_token;
			state.refreshToken = data.refresh_token;
			state.expiredAt = new Date().getTime() + data.expires_in*1000; 
			state.error = null;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAuthToken.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(getAuthToken.fulfilled, (state, action) => {
				const data = action.payload;
				state.status = "success";
				state.accessToken = data.accessToken;
				state.refreshToken = data.refreshToken;
				state.expiredAt = data.expiredAt;
				state.error = null;
			})
			.addCase(getAuthToken.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
				state.accessToken = null;
				state.refreshToken = null;
				state.expiredAt = null;
			});

		builder
			.addCase(refreshToken.pending, (state, action) => {
				state.status = "loading";
				state.isRefreshingToken = true;
			})
			.addCase(refreshToken.fulfilled, (state, action) => {
				const data = action.payload;
				state.status = "success";
				state.accessToken = data.accessToken;
				state.refreshToken = data.refreshToken;
				state.expiredAt = data.expiredAt;
				state.error = null;
				state.isRefreshingToken = false;
			})
			.addCase(refreshToken.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload;
				state.accessToken = null;
				state.refreshToken = null;
				state.expiredAt = null;
				state.isRefreshingToken = false;
			});
	},
});

export const { 
	logout, 
	resetError, 
	removePwd, 
	updateSeedPhrase,
	manualLogin
} = authSlice.actions;
export default authSlice.reducer;
