import { Box } from "@mui/material";
import { useImageCompare } from "./ImageCompareContext";
import { useEffect, useRef, useState } from "react";
import { proxySrcReplace } from "../../Gallery/ImageProxy";

const ImageViewer = ({ source }) => {
	const viewer = useImageCompare();
	const imgRef = useRef();
	const refOverlay = useRef();
	const scale = viewer?.images[source.key]?.scale || 1;
	const rotation = viewer?.images[source.key]?.rotation || 0;
	const translate = viewer?.images[source.key]?.translate || {
		x: -50,
		y: -50,
	};

	const editor = useRef();

	let clippath = `0 ${100 - (viewer?.spacer?.left || 50)}% 0 0`;

	let opacityValue =
		viewer?.images[source.key] && typeof viewer.images[source.key]?.opacity !== "undefined"
			? 1 - viewer.images[source.key].opacity / 100
			: 1;

	useEffect(() => {
		if (refOverlay.current && source.src) {
			refOverlay.current.className = 'img--preload'
		}
	}, [source.src])

	return (
		<Box
			ref={editor}
			sx={{
				opacity: opacityValue,
			}}
			className={`image-compare__image image--${source.key} ${!!source.clipPath && "image--clippath"}`}
		>
			<img
				draggable={false}
				className="source"
				src={proxySrcReplace(source.src, 4000)}
				alt=""
				style={{
					translate: `${translate.x}% ${translate.y}%`,
					scale: `${scale}`,
					rotate: `${rotation}deg`,
				}}
			/>
			<Box
				className="viewport"
				sx={
					source.clipPath
						? {
								clipPath: `inset(${clippath})`,
						  }
						: null
				}
			>
				<img
					draggable={false}
					className="visible"
					src={proxySrcReplace(source.src, 4000)}
					alt=""
					style={{
						translate: `${translate.x}% ${translate.y}%`,
						scale: `${scale}`,
						rotate: `${rotation}deg`,
					}}
					ref={imgRef}
					onLoad={(evt) => {
						refOverlay.current.className = 'img--preload hidden'
					 }}
				/>
				<img
					ref={refOverlay}
					className="img--preload"
					src={proxySrcReplace(source.src, 800)}
					alt="preload"
					style={{
						translate: `${translate.x}% ${translate.y}%`,
						scale: `${scale}`,
						rotate: `${rotation}deg`,
					}}
				/>
			</Box>
			<Box className="handlers">
				<Box className="center"></Box>
			</Box>
		</Box>
	);
};

export default ImageViewer;
