import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import ListOperatorUsers from "./ListOperatorUsers";
import { useSendEmailMutation } from "../../service/api/meveoApi";
import { t } from "i18next";
import { FormField } from "../Form/FormField";
import { isEmail } from "../../utils/helpers";
import { connect } from "react-redux";
import LoadingModal from "../LoadingModal";
import { toast } from "react-toastify";

const ManagedUser = ({ username }: any) => {
	const [inviteModal, toggleInviteModal] = useState(false);
	const [formData, setFormData] = useState<any>({ email: '' });
	const [errors, setErrors] = useState<any>({ email: '' });
	const [sendInvitation, { isLoading, data }] = useSendEmailMutation();

	const onCancel = () => {
		toggleInviteModal(false)
	}

	const onSend = async () => {

		sendInvitation({ email: formData.email, operatorName: username });
	}

	useEffect(() => {
		if (!isLoading && data) {
			if (data?.status === 'success') {
				toggleInviteModal(false);
				toast.success(t('pages:profile.invitation_has_been_sent'));
			} else {
				toast.error(data?.result || t('pages:profile.failed_to_invite_operator_user'));
			}
		}
	}, [isLoading, data]);

	const handleChange = (event: any, value: string, id: string) => {
		let fieldId = !!event ? event?.target?.name : id;
		let fieldValue = !!event ? event?.target?.value : value;
		if (!fieldId) return;

		const fieldErrors = [];
		if (!isEmail(fieldValue)) {
			fieldErrors.push(t('pages:profile.invalid_email'));
		}

		setErrors({
			...errors,
			[fieldId]: fieldErrors,
		});

		setFormData({
			...formData,
			[fieldId]: fieldValue,
		});
	};

	useEffect(() => {
		if (!inviteModal) {
			setFormData({ email: '' })
		};
	}, [inviteModal])

	return (
		<>
			<Card className="profile__settings-group">
				<CardContent>
					<div className="flex flex--justify-between flex--align-center">
						<p>{t('pages:profile.you_can_invite_and_edit_operator_user')}</p>
						<Button
							variant="contained"
							size="medium"
							color="secondary"
							onClick={() => toggleInviteModal(true)}
						>
							{t('pages:profile.invite_operator_user')}
						</Button>
					</div>
					<ListOperatorUsers />
				</CardContent>
			</Card>
			<Dialog
				className="dialog invite_operator-dialog"
				open={inviteModal}
				onClose={() => toggleInviteModal(false)}
			>
				<DialogTitle>
					{t('pages:profile.invite_operator_user')}
					<IconButton aria-label="close" onClick={() => toggleInviteModal(false)}>
						<span className="icon icon--close"></span>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Typography sx={{ pb: 1 }} fontWeight="bold" >{t('common:email_address')}</Typography>
					<fieldset>
						<FormField
							fieldId={'email'}
							value={formData.email}
							onChange={handleChange}
							label={t('pages:profile.enter_email_to_invite_operator_user')}
							errors={errors.email}
							fullWidth={true}
						/>
					</fieldset>
					<Grid container columnSpacing={2} mb={2} mt={1}>
						<Grid item xs={6}>
							<Button className="w--100" onClick={onCancel} color="secondary" variant="outlined">
								{t("common:cancel")}
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button className="w--100" onClick={onSend} disabled={isLoading || !formData.email || errors.email?.length > 0} color="secondary" variant="contained">
								{t("common:send")}
							</Button>
						</Grid>
					</Grid>
					{isLoading && (
						<LoadingModal
							visible={true}
							onClose={() => null}
						/>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
}

export default connect((state: any) => {
	const wallet = state?.wallet;
	return {
		wallet,
		username: wallet?.private?.username,
		walletAddress: wallet?.address,
		currentUserEmail: wallet?.private?.email?.address,
		isOperatorUser: wallet?.isOperatorUser,
	}
})(ManagedUser);