import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import config from '../../configs/config';

const baseQuery = fetchBaseQuery({
  baseUrl: `${config.SERVER_ADDRESS}/token-pdf-generator`,
  credentials: 'same-origin',
});

export const pdfGeneratorApi = createApi({
  reducerPath: 'pdfGeneratorApi',
  baseQuery,
  endpoints: (builder) => ({
    createPdf: builder.mutation({
      query: (args) => ({
        url: 'create-pdf',
        method: 'POST',
        body: JSON.stringify(args),
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const { useCreatePdfMutation } = pdfGeneratorApi;
