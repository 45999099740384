import { Avatar, Card, CardContent, CardMedia, Checkbox, Skeleton, Stack, TableCell, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { documentPrivacyTypes } from "../../../configs/constant";
import { proxySrcReplace } from "../../Gallery/ImageProxy";
import { usePrivacy, usePrivacyDispatch } from "./TokenPrivacyContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const TokenFileRow = (props) => {
	const { file, path, className } = props;
	const privacySettings = usePrivacy();
	const dispatch = usePrivacyDispatch();
	const perm = !!privacySettings['documents'][path] && !!privacySettings['documents'][path][file.uuid] ? (privacySettings['documents'][path][file.uuid] === 'public' ? 'public' : 'private') : 'private';

	const onChangeHandle = (selected: boolean) => {
		const perm = selected ? 'public' : 'private';
		dispatch({
			type: 'update_perm',
			data: [{
				key: ['documents', path, file.uuid].join('.'),
				perm
			}]
		})
	}


	const image =
		file.mimeType.startsWith("text/") &&
			file.filename.endsWith(".obj") &&
			file.texture &&
			file.texture.mimeType.startsWith("image/")
			? file.texture.fileUrl
			: file.mimeType.startsWith("image/")
				? file.fileUrl
				: false;


	return (
		<TableRow className={`table__row table__row-item ${className || ''}`} >
			<TableCell>
				<Card
					className="flex flex--horizontal flex--nowrap flex--align-top"
					sx={{
						display: "flex",
						width: "auto!important",
						maxWidth: "100%",
						textOverflow: "ellipsis",
						borderRaidus: 0,
						border: "none",
						boxShadow: "none",
						mr: 0,
					}}
				>
					{!!image ? <CardMedia
						component="img"
						sx={{ width: 50, height: 50 }}
						image={proxySrcReplace(image, 120, 120)}
						alt={file.name}
					/>
						: <Stack sx={{ display: 'inline-block' }}>
							<Avatar variant="square" sx={{ width: '40px', height: '40px' }} >
								<FontAwesomeIcon icon={solid('file')} />
							</Avatar>
						</Stack>
					}
					<CardContent sx={{ pt: 0, pb: 0, pl: 1, pr: 0 }}>
						<Typography component="div" variant="body">
							{file.name}
						</Typography>
						<Typography sx={{}} variant="body2" color="text.secondary" component="div">
							{file.filename}
						</Typography>
					</CardContent>
				</Card>
			</TableCell>
			<TableCell align="center" key={`property-permision-${file.uuid}`} padding="checkbox">
				<Checkbox
					size="small"
					color="primary"
					checked={perm === 'public'}
					onChange={(evt, selected) => onChangeHandle(selected)}
				/>
			</TableCell>
		</TableRow>
	);
};

export default TokenFileRow;