import { Checkbox, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import TokenFileRow from "./TokenFileRow";
import { usePrivacy, usePrivacyDispatch } from "./TokenPrivacyContext";
import TokenPropertyRow from "./TokenPropertyRow";
import { documentPrivacyTypes } from "../../../configs/constant";
import { documentPathTypes } from "../../../configs/constant";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const TokenFilesGroup = (props) => {
	const { data } = props;
	const { name, path, items = [] } = data;
	const [expanded, setExpanded] = useState(false);

	const privacySettings = usePrivacy();
	const dispatch = usePrivacyDispatch();

	const _toggleGroup = () => {
		setExpanded(!expanded);
	}

	if (documentPathTypes.COVER_IMAGE === path) return null;
	const selectAllHandle = (perm) => {
		let updateData = [];
		if (path) {
			!!privacySettings.documents[path] &&
				Object.keys(privacySettings.documents[path]).forEach((uuid) => {
					updateData.push({
						key: `documents.${path}.${uuid}`,
						perm,
					});
				});
		} else {
			Object.keys(privacySettings.token).forEach((key) => {
				updateData.push({
					key: `token.${key}`,
					perm,
				});
			});
		}
		dispatch({
			type: "update_perm",
			data: updateData,
		});
	};

	let allStatus = true;
	const groupData = path ? privacySettings["documents"][path] : privacySettings["token"];
	Object.values(groupData).forEach((perm) => {
		if (!allStatus) return;
		if (allStatus === true) {
			allStatus = perm;
			return;
		}
		allStatus = perm === allStatus ? perm : false;
	});

	const getPerm = (item) => (Object.values(documentPrivacyTypes).indexOf(allStatus) >= 0 ? allStatus : item.perm);

	return (
		<>
			{!data.hideAll && <TableRow className="table__row group">
				<TableCell onClick={_toggleGroup}>{name}</TableCell>
				<TableCell align="center" key={`property-permision-${path}`} padding="checkbox">
					<GridExpandMoreIcon className={`accordion-icon ${expanded ? 'expanded' : ''}`} onClick={_toggleGroup} />
					<Checkbox
						size="small"
						color="primary"
						checked={allStatus === 'public'}
						onChange={() => selectAllHandle(allStatus === 'public' ? 'private' : 'public')}
					/>
				</TableCell>
			</TableRow>}
			{items.length > 0 &&
				items.map((item, idx) =>
					!!item.filename ? (
						<TokenFileRow className={!expanded && 'invisible'} path={path} perm={getPerm(item)} file={item} key={`token-file-${idx}`} />
					) : (
						<TokenPropertyRow
							{...item}
							name={item.name}
							permId={`token.${item.key}`}
							perm={getPerm(item)}
							childs={item.childs}
							key={`token-property-${item.key}`}
						/>
					)
				)}
		</>
	);
};

export default TokenFilesGroup;
