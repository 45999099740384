import React from 'react'
import './Center.css'

const Center = ({ children }: any) => {
  return (
    <div className='abs-center'>
      {children}
    </div>
  )
}

export default Center
