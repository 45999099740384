import { Box, Button, Card, CardContent, Hidden, Typography } from "@mui/material";

import {
	documentPathTypes,
	tokenStatus as STATUSES,
	TRANSACTION_TYPES,
	unitTypes,
} from "../../../configs/constant";


import EditTokenGallery from "../EditToken/EditTokenGallery";
import TokenDocuments from "../TokenDocuments";
import TokenDetailSkeleton from "../TokenDetailSkeleton";
import ConfirmDialog from "../../ConfirmDialog";

import "./TokenDetailEdit.scss";
import { t } from "i18next";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import TokenTransactions from "../TokenTransactions";
import EditTokenSpecifications from "./EditTokenSpecifications";
import { useSearchParams } from "react-router-dom";
import { getTokenBlockchainExplorerURL, getTokenIdBignumber, transferToUnitDisplay, transferUnitFromDisplayToSavedData } from "../../../utils/helpers";
import BreadcrumbNavi from "../../BreadcrumbNavi";
import { FormField, validFormField } from "../../Form/FormField";
import { useTokenEdit, useTokenEditDispatch } from "./EditTokenContext";
import OperatorActionConfirmation from "../../Operator";
import { useChangeTokenCoverImageMutation, useUpdateTokenMutation } from "../../../service/api/meveoApi";
import { useUploadDocumentsMutation } from "../../../service/api/uploadApi";
import config from "../../../configs/config";
import { toast } from "react-toastify";
import { usePlatformDispatch } from "../../../layout/Platform/PlatformContext";
import LoadingModal from "../../LoadingModal";
import { useAddDocumentHashWithSmartContract } from "../../../hooks/smartContractHooks";
import { standPost } from "../../../pages/Standalone/Standalone";


const TokenDetailEdit = (props: any) => {
	const { isFetching = false, token, mode = 'default', standalone = false, isLoggedin, isOperatorUser, unit, onCancel } = props;
	const [searchParams, setSearchParams] = useSearchParams();
	const [historyCount, setHistoryCount] = useState(0);
	const [confirmDialog, toggleConfirmDialog] = useState(false);
	const [askConfirmCancel, toggleConfirmCancel] = useState(false);
	const [isUpdating, setUpdating] = useState(false);
	const [confirmSaving, setConfirmSaving] = useState(false);
	const [updateToken] = useUpdateTokenMutation();
	const [uploadFiles] = useUploadDocumentsMutation();
	const [updateCoverImage] = useChangeTokenCoverImageMutation();
	const dispatchPlatform = usePlatformDispatch();
	const [addDocumentHash] = useAddDocumentHashWithSmartContract({ token });

	const params = {};
	for (let [key, value] of searchParams.entries()) {
		params[key] = value;
	}

	const dispatch = useTokenEditDispatch();
	const tokenUpdate: any = useTokenEdit();

	const maxLengthDesc = 500;
	const [viewAllDesc, setViewAllDesc] = useState(false);

	const isEditable = !!isLoggedin && (token?.token.status !== STATUSES.IN_TRANSFER || isOperatorUser);

	const coverImage = !!token && !!token.documents ? token.documents.find((document: any) => document.path === documentPathTypes.COVER_IMAGE) : '';

	const saveChanges = () => {
		setConfirmSaving(true);
	}

	const _onClose = (success = false) => {
		if (onCancel) {
			if (success && standalone) {
				// just reset form
				standPost({ action: 'success', data: token });
			} else {
				onCancel();
			}
		}
		dispatchPlatform({
			type: 'reset'
		});
		dispatch({
			type: 'reset_changed'
		})
	}

	const isNumberType = (fieldId: string) => ['value', 'lengthInMeter', 'depthInMeter', 'widthInMeter', 'weightInKilo'].includes(fieldId);

	const formDataField = (fieldId: string) => {
		const fieldValue = tokenUpdate[fieldId];

		const formData: any = {
			[fieldId]: {
				id: fieldId,
				label: '',
				errors: [],
				valid: [],
				value: fieldValue,
				type: isNumberType(fieldId) ? 'number' : 'text',
			}
		};

		return formData;
	}

	const handleCloseConfirm = () => {
		toggleConfirmDialog(false);
	};

	const handleUpdateToken = async () => {
		const isValid = Object.keys(tokenUpdate).every((key) => {
			const fieldId = key;
			let fieldValue: any = `${tokenUpdate[key]}`;

			if (isNumberType(fieldId)) {
				fieldValue = fieldValue.replace(/[^\d\.]/g, '');
				fieldValue = !fieldValue ? 0.0 : parseFloat(fieldValue);
			}

			const formData: any = formDataField(fieldId);
			const fieldErrors = validFormField(fieldId, fieldValue, formData);

			return fieldErrors.length === 0;
		});

		if (!isValid) return;

		const tokenPropsUpdate: any = {
			...token.token,
			...tokenUpdate,
			markReference: tokenUpdate.tokenMarkReference,
		};

		let updateData: any = {
			token: tokenPropsUpdate,
			isUploadDocument: false,
			addedDocuments: [],
			deletedDocuments: [],
			editToken: true,
		}

		if (tokenUpdate.addedDocuments.length > 0) {
			updateData.isUploadDocument = true;
			tokenUpdate.addedDocuments.forEach((item: any) => {
				const url = `${config.IMGPROXY_SERVER_ADDRESS}insecure/raw:1/plain/s3://${config.SCW_BUCKET_NAME}/${item.file.key}`;

				const documentData: any = {};
				documentData.documentId = item.hash;
				documentData.path = item.type.path || documentPathTypes.OTHERS;
				documentData.name = item.file.name;
				documentData.filename = item.file.name;
				documentData.mimeType = item.file.mimetype;
				documentData.size = item.file.size;
				documentData.fileUrl = url;

				updateData.addedDocuments.push(documentData);
			});
		}
		if (tokenUpdate.deletedDocuments.length > 0) {
			tokenUpdate.deletedDocuments.forEach((item: any) => {
				updateData.deletedDocuments.push({
					path: item.path,
					name: item.name,
				});
			});
		}

		delete updateData.token.deferredCoverImage;
		delete updateData.token.addedDocuments;
		delete updateData.token.deletedDocuments;

		toggleConfirmDialog({
			callback: async () => {
				setUpdating(true);
				toggleConfirmDialog(false);
				updateData.token.lengthInMeter = transferUnitFromDisplayToSavedData(unit, updateData.token.lengthInMeter, true)
				updateData.token.widthInMeter = transferUnitFromDisplayToSavedData(unit, updateData.token.widthInMeter, true)
				updateData.token.depthInMeter = transferUnitFromDisplayToSavedData(unit, updateData.token.depthInMeter, true)
				updateData.token.weightInKilo = transferUnitFromDisplayToSavedData(unit, updateData.token.weightInKilo, false)

				if (token.token.mintDate) {
					const transactionInfo = await addDocumentHash({
						...token,
						token: tokenPropsUpdate,
					});
					if (!transactionInfo) {
						setUpdating(false);
						return;
					}
					updateData.transactionInfo = transactionInfo;
				}

				const files = tokenUpdate.addedDocuments.map((item: any) => item.hash);
				uploadFiles({
					data: {
						file: files,
						folder: token.token?.uuid,
					},
					pushCloud: true
				}).unwrap().then(async res => {
					// Files were moved to cloud successfully. Now we can update token
					updateToken(updateData).unwrap()
						.then(resp => {
							if (resp.status === 'fail') {
								setUpdating(false);
								toast.error(resp.result);
							} else if (!tokenUpdate.deferredCoverImage) {
								setUpdating(false);
								toast.success(t("pages:token.document_details.update_token_success_message"));
								_onClose(true);
							} else {
								updateCoverImage({
									tokenID: token.token?.uuid,
									documentId: tokenUpdate.deferredCoverImage
								}).unwrap()
									.then(resp => {
										setUpdating(false);
										toast.success(t("pages:token.document_details.update_token_success_message"));
										_onClose(true);
									}).catch(err => {
										setUpdating(false);
										toast.error(err.message);
									});
							}
						})
						.catch(err => {
							setUpdating(false);
							toast.error(err.message);
						})
				}).catch(err => {
					setUpdating(false);
					toast.error(err.message);
				});
			}
		});
	}

	const handleChange = (event: any, value: string, id: string) => {
		let fieldId = !!event ? event?.target?.name : id;
		let fieldValue = !!event ? event?.target?.value : value;

		if (!fieldId) return;

		if (isNumberType(fieldId)) {
			fieldValue = fieldValue.replace(/[^\d\.]/g, '');
			fieldValue = !fieldValue ? 0.0 : parseFloat(fieldValue);
		}

		dispatch({
			type: 'update_field',
			data: {
				key: fieldId,
				value: fieldValue
			}
		})
	}

	const onNavigationIntercept = () => {
		if (tokenUpdate?.isChanged) {
			toggleConfirmCancel(true);
			return true;
		} else if (standalone) {
			_onClose();
		}
		return false;
	}

	useEffect(() => {
		dispatchPlatform({
			type: 'navigation_intercept',
			data: {
				interceptHandle: onNavigationIntercept
			}
		});

		return () => {
			dispatchPlatform({
				type: 'reset'
			});
		}
	}, [tokenUpdate?.isChanged]);

	useEffect(() => {
		const handleTabClose = (event: any) => {
			event.preventDefault();
			return (event.returnValue = t("pages:token.document_details.confirm_cancel_edit_token"));
		};

		if (tokenUpdate?.isChanged) {
			window.addEventListener('beforeunload', handleTabClose);
		}

		return () => {
			window.removeEventListener('beforeunload', handleTabClose);
		};
	}, [tokenUpdate?.isChanged]);

	useEffect(() => {
		if (token.token) {
			const data = { ...token.token };
			['lengthInMeter', 'depthInMeter', 'widthInMeter', 'weightInKilo'].forEach((fieldId: string) => {
				data[fieldId] = transferToUnitDisplay(unit, data[fieldId], fieldId !== 'weightInKilo', false)
			})
			dispatch({
				type: 'init_token',
				data
			});
		}
	}, [token, dispatch]);

	if (isFetching) return <TokenDetailSkeleton />;

	const renderActions = () => (
		<Box className="token__actions flex flex--horizontal">
			<Button
				color='secondary'
				variant='outlined'
				aria-label='cancel'
				title='cancel changes'
				onClick={() => tokenUpdate?.isChanged ? toggleConfirmCancel(true) : _onClose()}
			>
				<Typography component="span" variant="body2">
					{t("common:cancel")}
				</Typography>
			</Button>
			<Box mr={1} />
			<Button
				color='secondary'
				variant='contained'
				aria-label='save'
				title='save changes'
				onClick={() => saveChanges()}
				disabled={!tokenUpdate?.isChanged}
			>
				<Typography component="span" variant="body2">
					{t("common:save_changes")}
				</Typography>
			</Button>
		</Box>
	)

	return (
		<>
			<Box className="token__information token__edit_form flex flex--horizontal flex--wrap">
				<Box className={`w--100 token__heading ${standalone ? 'token__heading_sticky' : ''} flex flex--horizontal`}>
					{!standalone && (
						<BreadcrumbNavi paths={[
							{ label: t("pages:wallet.title"), to: "/wallet" },
							{ label: token.token.name },
						]} />
					)}

					{!!isEditable && mode === 'default' && (
						<Hidden smDown>
							{renderActions()}
						</Hidden>
					)}
				</Box>

				<Box className="w--100 token__gallery">
					<EditTokenGallery
						token={token?.token}
						coverImage={coverImage}
						documents={token?.documents.filter((item: any) => item.path === documentPathTypes.GALLERY)}
					/>
				</Box>

				<Box className="w--100 token__desc">
					<Card className="description" variant="outlined" sx={{ border: "none" }}>
						<CardContent sx={{ p: 0, mt: 1 }}>
							<FormField
								fieldId={'name'}
								key={`token-information-field-name`}
								value={tokenUpdate?.name}
								label={''}
								className={`token-information-form__inputfield`}
								fullWidth={true}
								onChange={handleChange}
							/>
							<FormField
								fieldId={'description'}
								key={`token-information-field-description`}
								value={tokenUpdate?.description}
								label={''}
								className={`token-information-form__textarea`}
								fullWidth={true}
								type={'multiline'}
								maxLength={maxLengthDesc}
								onChange={handleChange}
							/>
							<Typography variant="caption" component="span">
								{tokenUpdate?.description?.length}/{maxLengthDesc}
							</Typography>
							<Typography variant="h6" component="h6" mt={2}>
								{t("pages:token.blockchain_id")}
							</Typography>
							<FormField
								fieldId={'blockchainId'}
								value={getTokenBlockchainExplorerURL(token.token.uuid)}
								label={''}
								fullWidth={true}
								disabled={true}
							/>
						</CardContent>
					</Card>
				</Box>

				<Box className="w--100 token__content">
					<Box sx={{ width: "100%" }}>
						<Typography variant="h6" component="h3">
							{t("pages:token.specifications")}
						</Typography>
						<EditTokenSpecifications token={token} unit={unit} />

						<Box className="documents-heading" display="flex" justifyContent="space-between" alignItems="center">
							<Typography variant="h6" component="h3">
								{t("pages:token.documents")}
							</Typography>
						</Box>
						<TokenDocuments token={token} editing={true} />

						{!standalone && (
							<>
								<Typography variant="h6" component="h3">
									{t("pages:token.history")}
								</Typography>
								<Box className="history-heading" display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="body2" component="span">
										{t("pages:token.document_details.history_of_activity_found", { count: historyCount })}
									</Typography>
								</Box>
								<TokenTransactions token={token} coverImage={coverImage} onDataChange={(data: any) => !!data && setHistoryCount(data.length)} />
							</>
						)}
					</Box>
				</Box>

				{!!isEditable && mode === 'default' && (
					<Hidden smUp>
						<Box className="token__edit_footer" >
							{renderActions()}
						</Box>
					</Hidden>
				)}
			</Box>

			<LoadingModal
				visible={isUpdating}
				// message={t("common:updating")}
				onClose={() => null}
			/>
			<OperatorActionConfirmation
				offerCode={token?.billingOfferCode}
				defaultPartnerCode={token?.billingOfferCode ? token?.uuid.split('_')[0] : ''}
				action={TRANSACTION_TYPES.UPDATE_TOKEN.key}
				open={confirmDialog}
				toggle={toggleConfirmDialog}
				onClose={() => {
					handleCloseConfirm()
				}}
			/>
			<ConfirmDialog
				visible={askConfirmCancel}
				title={t("pages:token.document_details.confirm_cancel_edit_token")}
				message={t("pages:token.document_details.all_your_changes_will_be_removed")}
				onConfirm={() => {
					toggleConfirmCancel(false);
					_onClose();
				}}
				onDismiss={() => {
					toggleConfirmCancel(false);
				}}
			/>

			<ConfirmDialog
				visible={confirmSaving}
				title={t("common:save_changes")}
				message={t("pages:token.confirm_before_saving")}
				onConfirm={() => {
					setConfirmSaving(false);
					handleUpdateToken();
				}}
				onDismiss={() => {
					setConfirmSaving(false);
				}}
			/>
		</>
	);
};

export default connect((state) => {
	const wallet = state.wallet;
	const auth = state.auth;

	return {
		isLoggedin:
			!!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime() ? auth.accessToken : false,
		unit: wallet?.public?.unit || unitTypes.METRIC.key,
		currentUserEmail: wallet?.private?.email?.address,
		isOperatorUser: wallet.isOperatorUser || wallet.isOperator,
	};
})(TokenDetailEdit);
