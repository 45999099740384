import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { t } from "i18next";
import { DOCUMENT_UPLOAD_FILE_SIZE, documentPathTypes, documentTypes } from "../../../configs/constant";
import { useUploadDocumentsMutation } from "../../../service/api/uploadApi";
import './UploadDocumentItem.scss';
import { useTokenEdit } from "../EditToken/EditTokenContext";

const UploadingImage = (props: any) => {
	const { file, onChange } = props;

	const tokenUpdate: any = useTokenEdit();
	const prevFile = useRef(false);
	const [uploadFiles, { isLoading, data: uploadResult, reset }] = useUploadDocumentsMutation();
	const [progress, setProgress] = useState(0);

	const doUpload = useCallback(() => {
		if (file.size / (1024 * 1024) >= DOCUMENT_UPLOAD_FILE_SIZE) {
			toast.error(`File size must be smaller than ${DOCUMENT_UPLOAD_FILE_SIZE}MB`);
			return;
		}
		// Start uploading
		let file_key = `file-${new Date().getTime()}`;
		let files = new FormData();
		files.append(file_key, file.file);
		files.append('folder', tokenUpdate.uuid);
		uploadFiles({ data: files, delay: true });
	}, [file, uploadFiles]);

	useEffect(() => {
		if (!prevFile.current || (!!prevFile.current && prevFile.current.file.name !== file.file.name)) {
			prevFile.current = file;

			// Do auto upload
			doUpload();
		}
	}, [file, doUpload]);

	// Update file when upload success
	useEffect(() => {
		if (!isLoading && uploadResult && uploadResult.status === 'success' && uploadResult?.result?.files) {
			let updateFile = file;
			updateFile['isReady'] = true;
			// Make sure this upload action do once time
			let result = JSON.parse(JSON.stringify(uploadResult));
			reset();
			if (result.result?.files.length > 0) {
				let uploadedFile = result.result.files[0];
				updateFile['file'] = uploadedFile.file;
				updateFile['hash'] = uploadedFile.hash;
				updateFile.type = documentTypes()[documentPathTypes.GALLERY];
				if (uploadedFile?.file?.storageUrl) {
					updateFile['s3_src'] = `${uploadedFile.file.storageUrl}/${uploadedFile.file.key}`;
				}
			}
			setProgress(100);
			typeof onChange === 'function' && onChange(file.name, updateFile);
		}
	}, [isLoading, uploadResult, file, onChange, reset]);

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress >= 100) {
					clearInterval(timer);
					return 0;
				}
				const diff = Math.random() * 10;
				return Math.min(oldProgress + diff, 100);
			});
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, []);
	if ( !isLoading ) return null;
	return (
		<Box className="uploading-image-item">
			<Box>
				<Typography>
					{Math.floor(progress)}%
					<span className="spinner"></span>
				</Typography>
			</Box>
			<Typography mt={1}>{t('common:uploading')}...</Typography>
		</Box>
	);
};

export default UploadingImage;
