import { Button } from "@mui/material";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import Logo from "../Logo";

import './Header.scss';
import { connect } from "react-redux";
import { logout } from "../../store/slices/authSlice";
import { persistor } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import { reset } from "../../store/slices/cryptoSlice";
import { menus } from "../../configs/routes";

const MenuItem = ({ link, title}) => {
  const navigate = useNavigate();

  const moveHandle = () => {
    return navigate(link)
  }
  return (
    <a href={link} onClick={(event) => {
      event.preventDefault();
      moveHandle();
    }} title={title}>{title}</a>
  )
}

const Header = ({ isLogged, userLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  
  const logoutHandle = () => {
    userLogout();
    return navigate('/');
  }

  const toggleMenu = () => {
    const container = document.querySelector('.site');
    if( container.classList.contains('show-navigation') ) {
      container.classList.remove('show-navigation')
    } else {
      container.classList.add('show-navigation')
    }
  }
  
  return (
    <header className="site__header">
      <div className="container container--fluid flex">
        <div className="brand">
          <Logo className="site__logo logo--short" />
        </div>

        <ul className="menu menu--horizontal site__navigation m--l">
					{Object.values(menus).map(item => {
						return <li className={location.pathname === item.path?'active':''} key={`menu-item-${item.path}`}><MenuItem link={item.path} title={item.label} /></li>
					})}
          {!!isLogged && <li><a href="#logout" className="btn btn-logout" onClick={() => logoutHandle()}>{t('menu:logout')}</a></li>}
        </ul>
        <div className="menu__toggle hamburger" onClick={toggleMenu}>
          <span className="divider"></span>
        </div>
      </div>
    </header>
  )
}

export default connect(state => {
  let data = state?.auth

  return {
    isLogged: !!data.accessToken
  }
}, dispatch => {
  return {
    userLogout: () => {
      persistor.purge();
      dispatch(logout());
    }
  }
})(Header);