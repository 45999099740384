import { createContext, useContext, useReducer } from "react";

export const MarkProofVerificationContext= createContext(null);
export const MarkProofVerificationDispatchContext = createContext(null);

const initialConfigs = {
	fullscreen: false
}

const reducer = (settings, action) => {
	const data = action?.data;
	let newState;
	
	try {
		newState = JSON.parse(JSON.stringify(settings));
	} catch (error) {
		newState = {};
	}

	switch(action.type) {

		case "toggle_fullscreen":
			return {
				...newState,
				fullscreen: data.fullscreen
			}
		case "reset":
			return initialConfigs;
		default: 
			return newState;
	}
}


export function MarkproofVerificationProvider({ children }) {
	const [settings, dispatch] = useReducer(reducer, initialConfigs);

	return (
		<MarkProofVerificationContext.Provider value={settings}>
			<MarkProofVerificationDispatchContext.Provider value={dispatch}>
				{children}
			</MarkProofVerificationDispatchContext.Provider>
		</MarkProofVerificationContext.Provider>
	);
}

export function useMarkproofVerificationContext() {
	return useContext(MarkProofVerificationContext);
}

export function useMarkproofVerificationDispatch() {
	return useContext(MarkProofVerificationDispatchContext);
}