import { Box, IconButton, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import "./DragDropUpload.scss";
import { t } from "i18next";
import { standPost } from "../../../pages/Standalone/Standalone";
import { convertBase64ToFile } from "../../Token/AddToken/AddToken";

const DragDropUpload = (props) => {
	const { text, buttonText, onChange, disabled = false, fileType, multiple = true } = props;
	const [dragActive, setDragActive] = useState();
	const [standalone, setStandalone] = useState(false);
	// ref
	const inputRef = useRef(null);

	// triggers when file is selected with click
  const handleChange = function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };
	const onButtonClick = (evt) => {
		evt.stopPropagation();
		if (!!disabled) return;
		if (standalone) {
			registerFilePicker();
			standPost({action: 'input-file', data: { type: fileType || 'photo', multiple: multiple || false }})
		} else {
			inputRef.current.click();
		}
	};

	const registerFilePicker = () => {
		if (standalone) {
			window.pickerFile = (data) => {
				const base64Images = JSON.parse(data);
				const files = base64Images.map((base64Image) => convertBase64ToFile(base64Image))
				handleFile(files);
			}
		}
	}

	useEffect(() => {
		if (document.getElementById('standaloneWebApp')) {
			setStandalone(true);
		}
	}, []);

	const handleFile = (fileList) => {
		if ( !!disabled ) return;
		if ( !!fileList && fileList.length > 0 ) {
			let newFiles = [];
			for (let i = 0; i < fileList.length; i++) {
				newFiles.push(fileList[i]);
			}
			if (multiple === false) newFiles = [newFiles[newFiles.length - 1]]
			typeof onChange === 'function' && onChange(newFiles);
		}
	};

	// handle drag events
	const handleDrag = function (e) {
		if ( !!disabled ) return;
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	};

	// triggers when file is dropped
	const handleDrop = function (e) {
		if ( !!disabled ) return;
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			handleFile(e.dataTransfer.files);
		}
	};

	useEffect(() => {
		if(inputRef.current) inputRef.current.value = '';
	}, []);

	return (
		<div className={`upload--drag-drop ${!!disabled?'upload--disabled':''}`} onDragEnter={handleDrag}>
			<input 
				onChange={handleChange} 
				ref={inputRef} type="file" id="input-file-upload" 
				accept="image/webp, image/png, image/jpeg, image/gif, application/pdf, text/plain, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/zip, application/octet-stream, model/obj, model/mtl"
				multiple={multiple} 
			/>
			<label id="label-file-upload" htmlFor={standalone ? 'input-file-picker' : "input-file-upload"}>
				<Box className="align--center" p={2} px={3} onClick={onButtonClick}>
					<Box py={2}>
						<IconButton disabled={disabled} variant="contained" size="small" className="upload-button">
							<span className="icon icon--upload"></span>
						</IconButton>
					</Box>
					<p dangerouslySetInnerHTML={{__html: buttonText || t("common:upload_dragdrop_text")}}></p>
					<Typography variant="caption" color="GrayText">{text || t("pages:token.document_details.note_3d_gallery")}</Typography>
				</Box>
			</label>
			{dragActive && (
				<div
					className="drag__file-element"
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				></div>
			)}
		</div>
	);
};

export default DragDropUpload;
