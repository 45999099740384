import * as React from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import {PlatformContextProvider} from "./PlatformContext";

import "./Platform.scss";
import Logo from "../../components/Logo";
import { Avatar, Button, Link, useMediaQuery } from "@mui/material";
import Toolbar from "./Toolbar";
import Sidebar from "./Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Platform = (props) => {
	const { backLink = false, pageTitle = "Page title", className = "", actions = null, hideSidebar = false, standalone=false, onNavigate, logoLink = false } = props;
	const [sidebar, toggleSidebar] = useState(false);
	const theme = useTheme();
	const navigate = useNavigate();
	const md = useMediaQuery(theme.breakpoints.down("lg"));

	// const [open, setOpen] = React.useState(false);
	const open = md ? false : true;

	const goBackHandle = () => {
		navigate(-1);
	};

	return (
		<PlatformContextProvider>
		<Box sx={{ display: "flex" }} className={`platform ${md ? "platform--md" : ""} ${className}`}>
			<AppBar className={`platform__appbar platform__appbar--${open ? "open" : "close"}`} position="fixed">
				<Toolbar pageTitle={pageTitle} onNavigate={onNavigate} toggleSidebar={(active) => toggleSidebar(!sidebar)} standalone={standalone}/>
			</AppBar>
			<Drawer
				className={`platform__sidebar platform__sidebar--${open ? "open" : "close"} ${
					sidebar ? "platform__mobile--open" : ""
				}`}
				variant="permanent"
				PaperProps={hideSidebar ? { sx: { height: "auto" } } : {}}
			>
				<DrawerHeader className="platform__sidebar-header">
					<Logo 
						className={`platform__logo logo--${open ? "" : "short"}`} 
						onClick={(event) => navigate(logoLink || "/", { replace: true }) }
					/>

					<IconButton onClick={() => toggleSidebar(false)} className="arrowdown-button toggle-sidebar">
						<FontAwesomeIcon icon={solid("xmark")} />
					</IconButton>
				</DrawerHeader>
					{!hideSidebar && <Sidebar open={open} onClose={()=>toggleSidebar(false)} />}
			</Drawer>

			<Box
				component="main"
				className={`site--platform site--platform-${open && !hideSidebar ? "open" : "close"}`}
				sx={{ flexGrow: 1 }}
			>
				<Box component="div" className="container page">
					{(!!backLink || !!actions) && (
						<Box className="page__actions flex flex--horizontal flex--align-center">
							{!!backLink && (
								<Link className="btn-go-back" onClick={goBackHandle}>
									<FontAwesomeIcon icon={solid("chevron-left")} /> {t("common:back")}
								</Link>
							)}
							<Box className="m--r">{!!actions && actions}</Box>
						</Box>
					)}
					<Box className="page__content">{props.children}</Box>
				</Box>
			</Box>
			</Box>
			</PlatformContextProvider>
	);
};

export default Platform;
