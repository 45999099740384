import { Box, Typography } from "@mui/material";
import { connect } from "react-redux";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ForgotPasswordSubmit from "./ForgotPasswordSubmit";
import { t } from "i18next";
import { useEffect, useState } from "react";
import ForgotPasswordVerifyOTP from "./ForgotPasswordVerifyOTP";

const ForgotPassword = (props) => {
	const { status, OTPRequest } = props
	const [showVerifyOTP, setShown] = useState(false);
	const [data, setData] = useState({
		type: 'email',
		value: ''
	})
	const onSubmit = (type, value) => {
		setData({ type, value });
	}

	useEffect(() => {
		if ( status === 'success' && (!!OTPRequest.email || !!OTPRequest.phone) ) {
			setShown(true);
		}
	}, [status, OTPRequest])
	return (

		<Box>
			<Typography
				sx={{ ml: "auto", mr: "auto", mb: 8 }}
				variant="subtitle"
				align="center"
				className="page-anchor"
			>
				<span>
					<FontAwesomeIcon icon={solid("user-check")} />
				</span>{" "}
				{t("pages:login.label_account_forgot_password")}
			</Typography>

			{!!showVerifyOTP ? <ForgotPasswordVerifyOTP type={data.type} value={data.value} /> : <ForgotPasswordSubmit onSubmit={onSubmit} />}
		</Box>
	)
}

export default connect(state => {
	const wallet = state.wallet;

	return {
		status: wallet.status,
		OTPRequest: wallet.otpRequests
	}
})(ForgotPassword);
