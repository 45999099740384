import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { PageLayout } from "../../layout/Pages";
import { t } from "i18next";
import { PlatformLayout } from "../../layout";
import { FormField } from "../../components/Form/FormField";
import { useState } from "react";
import * as XLSX from "xlsx";
import Web3, { utils } from 'web3';
import { canonicalize } from 'json-canonicalize';
import { parseWalletAddressFromSignature, signMessage } from "../../service/crypto";
import { LoadingButton } from "@mui/lab";
import { useSearchParams } from "react-router-dom";

const OnboardingLinkGenerator = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [errorMessage, setErrorsMessage] = useState('');
	const [searchParams] = useSearchParams();
	const operator = searchParams.get('operator') || '';
	const onboardSite = searchParams.get('onboard-url') || 'https://unikbase.com/pestel-debord';

	const initData = {
		operator_code: {
			id: "operator_code",
			label: "Operator Code",
			errors: [],
			valid: [
				['required', 'Operator Code is required']
			],
			value: operator || '',
			fullWidth: true,
			disabled: !!operator
		},
		operator_private_key: {
			id: "operator_private_key",
			label: "Operator Private key",
			errors: [],
			valid: [
				['required', 'Operator Private key is required']
			],
			value: "",
			fullWidth: true,
		},
		file: {
			id: "file",
			label: "File",
			type: "file",
			errors: [],
			valid: [
				['required', 'File is required']
			],
			value: "",
			fullWidth: true,
		},
	};
	const [data, setFormData] = useState(initData);

	const validationRules = {
		operator_code: [
				['required', 'Operator Private key is required']
		],
		operator_private_key: [
				['required', 'Operator Private key is required']
		],
		file: [
				['required', 'File is required']
		]
	}
	

	const validation = () => {
		let errors = {};
		let isValid = true;
		for (let fieldId in data) {
			errors[fieldId] = validFormField(fieldId, data[fieldId].value);
			isValid = isValid && errors[fieldId].length <= 0;
		}
		setErrors(errors);
		return isValid;
	};

	const validFormField = (id, value) => {
		let fieldErrors = [];

		value = typeof value === 'undefined' ? data[id] : value;
		if( id === 'confirmPassword' && value !== data.password ) {
			fieldErrors.push(t('pages:login.password_confirmation_message'))
		}
		
		if (typeof data[id] === "undefined") return fieldErrors;
		const rules = validationRules[id];
		if (!rules || rules.length <= 0) return [];


		for (let idx in rules) {
			let rule = rules[idx];
			if ( rule[0] === 'required' ) {
				if ( value.length <= 0 ) {
					fieldErrors.push(rule[1]);
				}
				continue;
			}
			if (!!rule[1] && !rule[0].test(value)) {
				fieldErrors.push(rule[1]);
			}
		}

		return fieldErrors;
	};

	const handleChange = (event, value, id) => {
		let fieldId = !!event ? event?.target?.name : id;
		let fieldValue = !!event ? event?.target?.value : value;
		if (!fieldId) return;

		let updateData = data[fieldId];
		if (!updateData) return;
		updateData.value = fieldValue;

		let fieldErrors = validFormField(fieldId, fieldValue);
		setErrors({
			...errors,
			[fieldId]: fieldErrors,
		});

		setFormData({
			...data,
			[fieldId]: updateData,
		});
	};
	const submitHandle = (event) => {
		event.preventDefault();
		setErrorsMessage('');
		let isValid = validation();

		if (!isValid) return;

		setIsLoading(true);
		if ( data['file'] ) {
			// Get mimetype of file
			let file = data['file'].value;
			let mimetype = file[0].type;
			// Only allow excel file
			let excelMimeTypes = [
					'application/vnd.ms-excel',
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					'application/vnd.ms-excel.sheet.macroenabled.12',
					'application/vnd.ms-excel.sheet.binary.macroenabled.12'
			];
			let operator_code = data['operator_code'].value;
			let operator_private_key = data['operator_private_key'].value;
			
			if ( mimetype && excelMimeTypes.indexOf(mimetype) >= 0 ) {
				// read file
				let fileReader = new FileReader();
				fileReader.onload = function(event) {
					let data = event.target.result;
					try {
						let workbook = XLSX.read(data, {
							type: "binary"
						});
						let newData = [];
						workbook.SheetNames.forEach(sheet => {
							let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
							rowObject.forEach((row, idx) => {
								let params = {
									'operator-code': operator_code,
									'lot-number': '',
									'firstname':'',
									'name': '',
									'email': '',
									'phone': '',
									'width': '',
									'height': '',
									'image': '',
									'description': '',
									'sign': '',
								}

								Object.keys(row).forEach((key) => {
									let value = row[key];
									let column = key.trim().toLowerCase();
									switch ( column ) {
										case 'num':
											params['lot-number'] = value+'';
											break;
										case 'nom':
											let name = value.trim().split(' ');
											params['firstname'] = name[0];
											params['name'] = name.length > 1 ? name.slice(1).join(' ') : '';
											break;
										case 'estimation':
											let dimensions = value ? value.replace(/\s/g,'').split('/') : [0, 0];
											params['width'] = dimensions[0];
											params['height'] = dimensions[1];
											break;
										case 'email':
											params['email'] = value;
											break;
										case 'phone':
											params['phone'] = value;
											break;
										case 'cover image':
											params['image'] = value;
											break;
										case 'description':
											params['description'] = value;
											break;
										default:
											break;
									}
									
								});
								if ( params['lot-number'] ) {
									try {
										let lotNumber = params['lot-number'].replace(/\.0+$/,'').replace(".0", "-").replace(".", "-");
										let message = canonicalize({
											"tpk_id" : `231219_${lotNumber}`,
											"email" :  params['email']
										});
										params['sign'] = signMessage(message, operator_private_key);
									} catch(e) {
										setErrorsMessage(e.message);
										return;
									}
								}
								// Add new column with URL data
								let queryString = Object.keys(params).map((key) => { return key + '=' + encodeURIComponent(params[key]) }).join('&');
								const url = onboardSite + '?' + queryString;
								newData.push({
									...row,
									URL: url
								});
							});
						});
						if ( newData.length > 0 ) {
							let newWorkbook = XLSX.utils.book_new();
							let newWorksheet = XLSX.utils.json_to_sheet(newData);
							XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, 'Sheet1');
							// Generate XLSX file and send to client
							let wbout = XLSX.write(newWorkbook, {bookType:'xlsx', type: 'binary'});
							let buf = new ArrayBuffer(wbout.length);
							let view = new Uint8Array(buf);
							for (let i=0; i<wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
							let blob = new Blob([buf], {type:'application/octet-stream'});

							// Create a link element, hide it, direct 
							// it towards the blob, and then 'click' it programatically
							let a = document.createElement('a');
							a.style = 'display: none';
							document.body.appendChild(a);
							let url = window.URL.createObjectURL(blob);
							a.href = url;
							a.download = 'onboarding-urls.xlsx';
							a.click();
							window.URL.revokeObjectURL(url);
						}
					} catch (e) {
						setErrorsMessage(e.message);
					}
					setIsLoading(false);
				};
				fileReader.readAsBinaryString(file[0]);
			}
		}
	}
	return (
		<PlatformLayout pageTitle={`Onboarding URLs`} className="site site--single pestel-debord">
			<Card sx={{ width: "100%", borderRadius: 0 }}>
				<CardContent sx={{ p: 3 }} className="flex flex--horizontal flex--nowrap cols">
					<Box component="form" className="flex" onSubmit={submitHandle}>
						<Box className="w--50">
							{Object.values(data).map((field) => (
								<FormField
									fieldId={field["id"]}
									key={`contact-form-field-${field["id"]}`}
									value={field["value"]}
									onChange={handleChange}
									label={field.label}
									errors={errors[field["id"]]}
									className={`contact-form__field contact-form__field-${field["id"]} ${
										!field.fullWidth ? "w--50" : ""
									}`}
									fullWidth={field.fullWidth || false}
									type={field.type || "default"}
									options={field.options || false}
									preview={false}
									disabled={field.disabled || false}
								/>
							))}

							<LoadingButton 
								loading={isLoading}
								type="submit" 
								variant="contained" 
								color="secondary"
							>
								{t("common:send")}
							</LoadingButton>
							{errorMessage && <Typography variant="body1" color="error">{errorMessage}</Typography>}
						</Box>
					</Box>
				</CardContent>
			</Card>
		</PlatformLayout>
	)
}
export default OnboardingLinkGenerator;