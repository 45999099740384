import { KeyringController } from "@metamask/eth-keyring-controller";
import { Button, Dialog, DialogActions, DialogContent, IconButton, DialogTitle, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { decryptSeedPhrase } from "../../service/crypto";
import { persistor } from "../../store/rootReducer";
import { logout } from "../../store/slices/authSlice";
import { setVerifiedPassword, unsetRequirePassword, updateKeyringController } from "../../store/slices/cryptoSlice";
import { updateError } from "../../store/slices/walletSlice";
import MessageBox from "../Animation/MessageBox";
import PasswordField from "../Form/PasswordField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { fetchAuthToken } from "../../store/actions/authActions";
import { parseJwt } from "../../utils/helpers";

import "./RequirePassword.scss";

const ErrorAlert = connect(
	(state) => {
		const walletState = state.wallet;
		return {
			error: walletState.error,
		};
	},
	(dispatch) => {
		return {
			removeError: () => {
				dispatch(updateError(null));
			},
		};
	}
)(({ error, removeError }) => {
	error = !!error && typeof error === "object" ? Object.values(error) : [];
	return (
		<>
			{error.length > 0 && (
				<MessageBox type="error">
					<ul>
						{error.map((item, idx) => (
							<li key={`wallet-error-message${idx}`}>
								{typeof item === "string" ? item : item.message || ""}
							</li>
						))}
					</ul>
				</MessageBox>
			)}
		</>
	);
});

const RequirePassword = (props) => {
	const {
		currentState,
		userLogout,
		isOperatorUser,
		setVerifiedPassword,
		unsetRequirePassword,
		passwordHint,
		address,
		encryptedSeed,
		updateKeyringController,
		currentUsername
	} = props;
	const [open, setOpen] = useState(currentState);
	const [password, setPassword] = useState("");
	const [errors, setErrors] = useState([]);
	const [invalidPassword, toggleInvalidPassword] = useState(false);

	const handleClose = () => {
		setOpen(false);
		unsetRequirePassword();
	};

	const handleSubmitPassword = async () => {
		if (isOperatorUser) {
			const response = await fetchAuthToken(currentUsername, password);
			if (!response || !response.access_token || !response.refresh_token) {
				toggleInvalidPassword(true);
			} else {
				setVerifiedPassword(password);
				toggleInvalidPassword(false);
				setPassword('');
				handleClose();
			}
			return;
		}
		try {
			let mnemonic = decryptSeedPhrase(encryptedSeed, password, address);

			if (mnemonic) {
				const keyringController = new KeyringController({});
				await keyringController.createNewVaultAndRestore(password, mnemonic);

				setVerifiedPassword(password);
				updateKeyringController(keyringController);
				toggleInvalidPassword(false);
				setPassword('');
				return true;
			}
		} catch (error) {
			console.error(error);
		}
		toggleInvalidPassword(true);
		setTimeout(() => {
			toggleInvalidPassword(false);
		}, 1500);
	};

	const cancleHandle = () => {
		// userLogout();
		handleClose();
	};

	const onChangeHandle = (id, value, validates) => {
		setPassword(value);
		setErrors(validates);
	};

	useEffect(() => {
		setOpen(currentState);
	}, [currentState]);

	useEffect(() => {
		setVerifiedPassword(null);
	}, [])

	return (
		<Dialog className="require-password-dialog" open={open} onClose={handleClose}>
			<DialogTitle>
				{t("common:enter_password")}
				<IconButton aria-label="close" onClick={handleClose}>
					<FontAwesomeIcon icon={solid("xmark")} size="sm" />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				{invalidPassword && <MessageBox type="error">{t('pages:profile.current_password_invalid')}</MessageBox>}
				{!!passwordHint && <Typography variant="body2" sx={{ mb: 1 }}>{passwordHint}</Typography>}
				<Typography variant="body2" fontWeight="bold" sx={{ mb: 1 }}>{t('common:password')}</Typography>
				<PasswordField
					data={{
						id: "re-enter-password",
						label: t("common:password"),
						errors: errors,
						valid: [["(?=.{8,})", t("pages:profile.current_password_invalid")]],
						value: password,
					}}
					onChange={onChangeHandle}
					autocomplete={currentUsername}
				/>
			</DialogContent>
			<DialogActions>
				<Grid container spacing={2} paddingX={2} paddingBottom={2}>
					<Grid item sm={6}>
						<Button className="w--100" variant="outlined" color="secondary" onClick={cancleHandle}>
							{t("common:cancel")}
						</Button>
					</Grid>
					<Grid item sm={6}>
						<Button className="w--100" variant="contained" color="secondary" onClick={handleSubmitPassword} disabled={password.length === 0}>
							{t("common:continue")}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};

export default connect(
	(state) => {
		const cryptoState = state.crypto;
		const auth = state.auth;
		const wallet = state.wallet;

		let username = '';
		if (auth?.accessToken) {
			const jwt = parseJwt(auth?.accessToken)
			username = jwt?.name || jwt?.email;
		}

		return {
			currentState: cryptoState.requirePassword,
			passwordHint: cryptoState.passwordHint,
			encryptedSeed: wallet?.private?.seedPhrase,
			address: wallet?.address,
			currentUsername: wallet?.private?.username || username,
			isOperatorUser: wallet?.isOperatorUser,
		};
	},
	(dispatch) => {
		return {
			userLogout: () => {
				persistor.purge();
				dispatch(logout());
			},
			setVerifiedPassword: (password) => {
				dispatch(setVerifiedPassword(password));
			},
			unsetRequirePassword: () => {
				dispatch(unsetRequirePassword());
			},
			updateKeyringController: (keyringController) => {
				dispatch(
					updateKeyringController({
						controller: keyringController,
					})
				);
			},
			updateError: (error) => {
				dispatch(updateError(error));
			},
		};
	}
)(RequirePassword);
