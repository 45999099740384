import { meoveoRestApi, useCreateTPKOpertorMutation } from "../../service/api/meveoApi";
import { getAuthToken } from "../actions/authActions";
import { createWallet } from "../actions/walletActions";
import { logout } from "../slices/authSlice";
import { reset } from "../slices/cryptoSlice";
import { resetWallet } from "../slices/walletSlice";

/**
 * Log a warning and show a toast!
 */
export const logoutChecker = (api) => (next) => (action) => {
	// Clear wallet/meveo info when user logged out
	if (action.type === logout.type) {
		api.dispatch(meoveoRestApi.util.resetApiState());
		api.dispatch(resetWallet());
		api.dispatch(reset()); // Reset cryptoslice
	}
	return next(action);
};

/**
 * Log-in when user created
 */
export const userCreated = (api) => (next) => (action) => {
	if (
		action.type.toString() === createWallet.fulfilled.toString() ||
		meoveoRestApi.endpoints.updateWallet.matchFulfilled(action)
	) {
		const { username, password } = action?.payload;
		if (username && password) {
			// Force user to login with new account
			api.dispatch(
				getAuthToken({
					username,
					password,
				})
			);
		}
	}
	return next(action);
};
