import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Typography } from "@mui/material";
import { t } from "i18next";
import './ConfirmDialog.scss'

interface IConfirmDialog {
  visible: boolean;
  title: string;
  message: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm: () => void;
  onDismiss: () => void;
}

const ConfirmDialog = ({ visible, title, message, confirmLabel, cancelLabel, onConfirm, onDismiss }: IConfirmDialog) => {
  const _onConfirm = () => {
    onConfirm && onConfirm();
  }

  const onClose = () => {
    onDismiss && onDismiss();
  }

  return (
    <Dialog className="confirm-dialog" open={visible} onClose={onClose}>
      <DialogContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h5" fontWeight="500">{title || ''}</Typography>
          <IconButton className="confirm-dialog__close" aria-label="close" onClick={onClose}>
            <span className="icon icon--close"></span>
          </IconButton>
        </Box>
        {!!message && <Typography variant="body2" sx={{my: 1}}>{message || ''}</Typography>}
      </DialogContent>
      <DialogActions className="flex flex--align-center flex--justify-center">
        <Grid container spacing={2} pl={2} pr={2} mb={2}>
          <Grid item xs={6}>
            <Button className="w--100" onClick={onClose} color="secondary" variant="outlined">
              {cancelLabel || t("common:no")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button className="w--100" onClick={_onConfirm} color="secondary" variant="contained">
              {confirmLabel || t("common:yes")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
