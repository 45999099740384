import { createContext, useContext, useReducer } from "react";
import { documentPathTypes } from "../../../configs/constant";

export const TokenPrivacyContext = createContext(null);
export const TokenPrivacyDispatchContext = createContext(null);

function privacyReducer(privacySettings, action) {
	const data = action?.data;
	let newState;
	try {
		newState = JSON.parse(JSON.stringify(privacySettings));
	} catch (error) {
		newState = {};
	}

	switch (action.type) {
		case "update_token": //Token origin data
			if (data.token) {
				newState.originToken = data.token;
			}
			return newState;
		case "init_privacy_settings":
			try {
				const settings = JSON.parse(JSON.stringify(data.settings));
				newState = Object.assign({}, newState, settings);

				if (!!newState?.documents) {
					for (let type in newState?.documents) {
						for (let uuid in newState?.documents[type]) {
							const filter = data.documents.filter((item) => item.uuid === uuid);
							if (filter.length <= 0) {
								delete newState.documents[type][uuid];
							}
						}
					}
				}

				newState.isChanged = false;
				return newState;
			} catch (error) {
				console.log(error);
			}
			return privacySettings;
		case "update_perm":
			if (!Array.isArray(data) || data.length <= 0) return;

			try {
				data.forEach((item) => {
					let keys = item.key.split(".");
					let lastKeyIndex = keys.length - 1;
					keys.reduce((acc, curr, currIndex) => {
						currIndex === lastKeyIndex && (acc[curr] = item.perm);
						return acc[curr];
					}, newState);

					// Force update coverImage follow tokenPrivacy
					if (item.key === 'token.tokenPrivacy' && !!newState.documents[documentPathTypes.COVER_IMAGE]) {
						for (let id in newState.documents[documentPathTypes.COVER_IMAGE]) {
							newState.documents[documentPathTypes.COVER_IMAGE][id] = item.perm
						}
					}
				});
			} catch (error) {
				console.log(error);
			}

			newState.isChanged = true;
			return newState;
		case "update_all":
			const perm = data.perm;
			for (let i in newState.token) {
				if(i === 'hashPrefix') continue;
				newState.token[i] = perm;
			}
			for (let i in newState.documents) {
				for (let j in newState.documents[i]) {
					newState.documents[i][j] = perm;
				}
			}

			newState.isChanged = true;
			return newState;
		case "reset_changed":
			newState.isChanged = false;
			return newState;
		case "update_share_option": {
			if (!data || !data.key) return;

			newState.sharedOptions[data.key] = data.selected;
			return newState;
		}
		default: {
			return newState;
		}
	}
}

const initialPrivacy = {
	documents: {
		coverImage: {},
		"2d-gallery": {},
		"3d-gallery": {},
		"doc/certificate/": {},
		invoice: {},
		markProofs: {},
		NFCTag: {},
		inkFingerprints: {},
		others: {},
	},
	token: {
		depthInMeter: "private",
		widthInMeter: "private",
		weightInKilo: "private",
		currency: "private",
		tokenPrivacy: "public",
		blockChainID: "private",
		creationDate: "private",
		lengthInMeter: "private",
		value: "private",
		description: "private",
		ownerUsername: "private",
		ownerEmail: "private",
		ownerWallet: "private",
		certIssueDate: "private",
		history: "private",
	},
	sharedOptions: {}
};

export function TokenPrivacyProvider({ children }) {
	const [privacySettings, dispatch] = useReducer(privacyReducer, initialPrivacy);

	return (
		<TokenPrivacyContext.Provider value={privacySettings}>
			<TokenPrivacyDispatchContext.Provider value={dispatch}>{children}</TokenPrivacyDispatchContext.Provider>
		</TokenPrivacyContext.Provider>
	);
}

export function usePrivacy() {
	return useContext(TokenPrivacyContext);
}

export function usePrivacyDispatch() {
	return useContext(TokenPrivacyDispatchContext);
}
