import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	seedPhraseStored: {},
	sidebar_toggle: true, // off
	wallet_view: 'list' // list - grid
}

const generalSettingsSlice = createSlice({
	name: 'generalSettings',
	initialState: initialState,
	reducers: {
		setUserWalletView: (state, action) => {
			const { type } = action.payload;
			return {
				...state,
				wallet_view: type
			}
		},
		markStoredSeedphrase: (state, action) => {
			const { email } = action.payload
			
			return {
				...state,
				seedPhraseStored: {
					...state.seedPhraseStored,
					[email]: true
				}
			}
		}
	}
});

export const { setUserWalletView, markStoredSeedphrase } = generalSettingsSlice.actions;
export default generalSettingsSlice.reducer;