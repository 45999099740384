import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Typography } from "@mui/material";
import { t } from "i18next";
import './ShareCopy.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormField } from '../Form/FormField';

interface IShareCopy {
  visible: boolean;
  title: string;
  message: string;
  link: string
  onDismiss: () => void;
  onCopied: () => void;
}

const ShareCopy = ({ visible, title, message, link, onDismiss, onCopied }: IShareCopy) => {
  const onClose = () => {
    onDismiss && onDismiss();
  }

  const _onCopy = () => {
    navigator.clipboard.writeText(`${link}`);
    if(onCopied) onCopied();
  }

  return (
    <Dialog className="confirm-dialog" open={visible} onClose={onClose}>
      <DialogContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h5" fontWeight="500">{title || ''}</Typography>
          <IconButton className="confirm-dialog__close" aria-label="close" onClick={onClose}>
            <span className="icon icon--close"></span>
          </IconButton>
        </Box>
        <Typography variant="body2" mt={2} fontWeight="500">{message || ''}</Typography>
      </DialogContent>
      <DialogActions className="flex flex--align-center flex--justify-center">
        <Grid container spacing={2} pl={2} pr={2} mb={2}>
          <Grid item xs={12} sm={9}>
            <FormField
              fieldId={''}
              value={`${link}`}
              label={''}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button className="w--100" onClick={_onCopy} color="secondary" variant="contained" size="small">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                sx={{ mr: 0.5 }}
              >
                <FontAwesomeIcon style={{
                  fontSize: "12px",
                }} icon={solid("copy")} color='white' />
              </IconButton>
              {t("common:copy")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ShareCopy
