import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Alert,
	Box,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Checkbox,
	FormControlLabel,
	LinearProgress,
	Skeleton,
	Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { t, changeLanguage } from "i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { Trans } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { createWallet } from "../../store/actions/walletActions";
import { MessageBox } from "../Animation";
import { MuiTelInput } from "mui-tel-input";

import "./SignUp.scss";
import { FormField } from "../Form/FormField";
import { updateError } from "../../store/slices/walletSlice";
import { fileToBase64, isEmail } from "../../utils/helpers";
import { useTPKSignup } from "../../hooks/userHooks";
import { manualLogin } from "../../store/slices/authSlice";
import config from "../../configs/config";
import { meoveoRestApi, useGetTokenByIdQuery, useGetUsernameByEmailQuery } from "../../service/api/meveoApi";
import { canonicalize } from 'json-canonicalize';
import Logo from "../Logo";
import { UnikbaseSvg } from "../Logo/Logo";

// localhost:3000/pestel-signup?tokenUUID=UNCP_PESTEL_70-1&name=MERCIER&firtname=SUCC.&email=rambudev.tester.12%40gmail.com&phone=84000000234&width=300&height=400&sign=27b69b066a188f88973c6db49b57ffa50f26e1eff769487a1c2a4dbda2a67a853bacc338ca4c722181c32e099852b734dd94737aa2847b5f7dce1bdfecbd410e1c&commercialOfferCode=BASIC

const PestelSignup = (props) => {
	const { login, resetError, isOperator = false, isLoggedIn } = props;
	const [errors, setErrors] = useState({});
	const [ref, setRef] = useState(false);
	const [agreement, setAgreement] = useState(false);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	changeLanguage('fr');

	//prepare signupData, if searchParams is empty, get from cookie
	const signupData = useMemo(() => {
		let ref = searchParams.get('ver');
		let email = searchParams.get('email') || '';
		let tokenUUID = searchParams.get('tokenUUID') || '';
		let operatorData = tokenUUID.split('_');
		let message = canonicalize({
			"tpk_id": operatorData.length > 1 ? operatorData.slice(1).join('_') : '',
			"email": email
		});

		let data = {
			operator: searchParams.get('operator-code') || '',
			email: email,
			phone: searchParams.get('phone') || '',
			name: searchParams.get('name') || '',
			firstname: searchParams.get('firstname') || '',
			tokenUUID: tokenUUID,
			sign: searchParams.get('sign') || '',
			message: message,
			commercialOfferCode: searchParams.get('commercialOfferCode') || '',
			session_id: searchParams.get('session_id') || '',
		};
		if ( !data.email ) {
			if ( ref ) {
				let cookieData = JSON.parse(localStorage.getItem(`-unkb-pestel-data-${ref}`));
				if ( !!cookieData && !!cookieData.email ) {
					data = {
						...data,
						...cookieData
					}
				}
			}
		} else {
			//Store data into cookie
			let reference = new Date().getTime();
			localStorage.setItem(`-unkb-pestel-data-${reference}`, JSON.stringify(data))
			setRef(reference);	;
		}
		return data
	}, [searchParams]);

	const [createTPKUser, { status, step, error: createTPKError }] = useTPKSignup({
		login: login,
		operatorCode: signupData?.operator ? signupData.operator : 'RBDV'
	});

	const {data: tokenData, isFetching: tokenFetching } = useGetTokenByIdQuery({
		id: signupData.tokenUUID,
		mode: 'public'
	}, {
		skip: !signupData.tokenUUID
	});
	const tokenId = useMemo(() => (
		signupData.tokenUUID && signupData.tokenUUID.startsWith(signupData.operator + '_') ? signupData.tokenUUID : `${signupData?.operator}_${signupData.tokenUUID}`
	), [signupData.tokenUUID, signupData.operator]);
	const { data: existingUser, isFetching: checkingExistedUser } = useGetUsernameByEmailQuery(signupData.email);

	const [formData, setFormData] = useState({
		username: signupData.email.replace(/(@.*$)/,''),
		email: signupData.email,
		phone: signupData.phone.trim(),
		password: "",
		confirmPassword: "",
		document: [],
	});

	const validationRules = {
		username: [[/(?=.{6,})/, t("pages:login.signup_username_invalid")]],
		email: [[/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, t("pages:login.signup_email_invalid")]],
		phone: [[/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, t("pages:login.signup_phone_invalid")]],
		password: [
			[
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_\{\}\[\]\=\+])(?=.{8,})/,
				t("pages:login.password_field_valid_message"),
			],
		],
		confirmPassword: [
			[
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_\{\}\[\]\=\+])(?=.{8,})/,
				t("pages:login.password_field_valid_message"),
			],
		],
	};

	const validation = () => {
		let errors = {};
		let isValid = true;
		for (let fieldId in formData) {
			errors[fieldId] = validFormField(fieldId);
			isValid = isValid && errors[fieldId].length <= 0;
		}
		setErrors(errors);
		return isValid;
	};

	const handleChange = (event, value, id) => {
		let fieldId = !!event ? event?.target?.name : id;
		let fieldValue = !!event ? event?.target?.value : value;
		
		if ( !fieldId ) return;


		if ( fieldId === 'document' ) {
			// check file size < 10MB
			if ( !fieldValue || fieldValue.length <= 0 ) {
				setErrors({
					...errors,
					[fieldId]: ['File is required']
				});
			} else {
				let file = fieldValue[0]
				if ( file.size > 10 * 1024 * 1024 ) {
					setErrors({
						...errors,
						[fieldId]: [t('pages:login.file_size_error')]
					});
				}
			}
		} else {
			fieldValue = fieldValue.replace(/\s/g, "");
			let fieldErrors = validFormField(fieldId, fieldValue);
			setErrors({
				...errors,
				[fieldId]: fieldErrors,
			});
		}
		setFormData({
			...formData,
			[fieldId]: fieldValue,
		});
	};

	const submitHandle = async (event) => {
		event.preventDefault();
		if (!agreement) return;
		let isValid = validation();
		
		if (!isValid) return;

		createTPKUser({
			...formData,
			...signupData
		});
	};

	const validFormField = (id, value) => {
		let fieldErrors = [];
		
		value = value || formData[id];
		if( id === 'confirmPassword' && value !== formData.password ) {
			fieldErrors.push(t('pages:login.password_confirmation_message'))
		}
		
		if (typeof formData[id] === "undefined") return fieldErrors;
		const rules = validationRules[id];
		if (!rules || rules.length <= 0) return [];


		for (let idx in rules) {
			let rule = rules[idx];
			if (!!rule[1] && !rule[0].test(value)) {
				fieldErrors.push(rule[1]);
			}
		}

		return fieldErrors;
	};

	useEffect(() => {
		return () => resetError();
	}, [resetError])

	useEffect(() => {
		if ( searchParams.get('email') && ref ) {
			navigate(`/pestel-signup?ver=${ref}`, { replace: true });
		}
	}, [searchParams, ref, navigate]);

	useEffect(() => {
		if (isLoggedIn && tokenId) {
			var button = document.getElementById('gtm-account-created-button-pestel');
			if (button) button.click();
			navigate(`/token/${tokenId}`, { replace: true })
			// navigate(`/wallet`, { replace: true })
		}
	}, [isLoggedIn, tokenId, navigate]);

	const token = useMemo(() => {
		if ( !tokenData || tokenData.status !== 'success' || !tokenData.result ) return;

		let coverImage = '';
		if ( tokenData.result?.documents ) {
			let covers = tokenData.result?.documents.find(doc => doc.path === 'coverImage');
			if ( covers ) {
				coverImage = covers.fileUrl;
			}
		}
		return {
			name: tokenData?.result?.token.name,
			coverImage: coverImage
		}
	}, [tokenData]);

	if( !isEmail(signupData.email) || !signupData.tokenUUID ) {
		return (
			<Box>
				<p>Your signup link is out of date please contact our technical to get support</p>
			</Box>
		)
	}
	const isExistUser = !!existingUser && existingUser.status === 'success' && existingUser.result;
	const isPreparingDocument = !!step && step.startsWith('cover-image');
	
	return (
		<Box
			key="pestel-signup-form"
			component="form"
			noValidate={false}
			autoComplete="off"
			className="flex flex--vertical form__signup form__signup--tpk"
			onSubmit={submitHandle}
			sx={{ p: 2, width: "100%", maxWidth: "600px" }}
		>
			<Typography
				sx={{ ml: "auto", mr: "auto", mb: 2 }}
				variant="subtitle"
				align="center"
				className="page-anchor"
			>
				<span>
					<FontAwesomeIcon icon={solid("user-check")} />
				</span>{" "}
				{t(isOperator ? "pages:login.signup_operator_user_form_label" : "pages:login.signup_form_label")}
			</Typography>
			
			{!!token && (
				<Card 
					sx={{ 
						display: 'flex', 
						backgroundColor: "var(--unikbase-gray)",
						mb: 6
					}}
					className="token__details"
				>
					<CardMedia
						component="div"
						sx={{ 
							width: "150px",
							flexShrink: 0,
							backgroundSize: "contain"
						}}
						image={ token.coverImage }
						alt={token.name}
						className="token__coverImage"
					/>
					<Box className="token__description" sx={{ display: 'flex', flexDirection: 'column' }}>
						<CardContent sx={{ flex: '1 0 auto' }}>
							<Typography variant="h6" component="h3">{token.name}</Typography>
							<Typography variant="body" component="div">
								{token.description}
							</Typography>
						</CardContent>
					</Box>
				</Card>
			)}
			{"loading" !== status && !!createTPKError && (
				<MessageBox timeout={10000} type="error">
					{createTPKError}
				</MessageBox>
			)}

			<fieldset 
				className={`flex flex--vertical ${isExistUser ? 'form--disabled' : ''}`}
				disabled={status === 'loading' || !!isExistUser || !!isPreparingDocument}
				sx={{
					opacity: status === 'loading' ? 0.6: 1
				}}
			>
				<Box className="form__fields flex flex--vertical" sx={{ mb: 2 }}>
				{!!checkingExistedUser ? (
					<>
						<Skeleton width={"100%"} height={70}></Skeleton>
						<Skeleton sx={{ mt: 3 }} width={"100%"} height={70}></Skeleton>
						<Skeleton width={"100%"} height={70}></Skeleton>
					</>
				) : (
					<>
						{!!isExistUser && (
							<Alert severity="error">
								User exists with same email ( {signupData.email} ). Please contact support to get more information.
							</Alert>
						)}
						<FormField
							fieldId="username"
							value={formData.username}
							onChange={handleChange}
							label={t("pages:login.label_username")}
							errors={errors.username}
						/>

						<FormField
							fieldId="email"
							value={formData.email}
							onChange={handleChange}
							label={t("pages:login.label_email")}
							errors={errors.email}
							disabled={true}
						/>
						<FormField
							fieldId="phone"
							value={formData.phone}
							type="phone"
							onChange={handleChange}
							label={t("pages:login.label_phone")}
							errors={errors.phone}
						/>

						<Typography
							color={!!errors.password && errors.password.length > 0 ? "error" : ""}
							variant="subtitle2"
							sx={{ mb: 2 }}
						>
							<div dangerouslySetInnerHTML={{ __html: t("pages:login.password_field_valid_message") }}></div>
						</Typography>
						<FormField
							type="password"
							fieldId="password"
							value={formData.password}
							onChange={handleChange}
							label={t("pages:login.label_password")}
							errors={errors.password}
							hideErrors={true}
						/>

						<FormField
							type="password"
							fieldId="confirmPassword"
							value={formData.confirmPassword}
							onChange={handleChange}
							label={t("pages:login.label_confirm_password")}
							errors={errors.confirmPassword}
						/>
						
						<FormField
							fieldId="document"
							multiple={false}
							value={formData.document}
							onChange={handleChange}
							label=""
							errors={errors.document}
							type="file"
							buttonText="Glissez ou téléchargez le bordereau d'achat de votre objet."
							uploadText="Formats acceptés Word, Jpeg ou PDF."
							preview={false}
						/>

						<FormControlLabel
							value="agre"
							control={
								<Checkbox
									onChange={(event) => {
										setAgreement(event.target.checked);
									}}
								/>
							}
							label={
								<Trans
									i18nKey="pages:login.signup_agreement_label"
									components={{
										privacy: <Link to="/privacy-policy" target="_blank" rel="noreferer" />,
										terms: <Link to="/terms-conditions" target="_blank" rel="noreferer" />,
									}}
								></Trans>
							}
							labelPlacement="end"
						/>
					</>
				)}
				</Box>

				<LoadingButton
					disabled={!agreement || (!!existingUser && existingUser.status === 'success' && existingUser.result)}
					loading={status === 'loading'}
					sx={{ mb: 2, borderRadius: 6 }}
					variant="contained"
					type="submit"
				>
					{t("pages:login.signup_account_button")}
				</LoadingButton>
				<button id="gtm-account-created-button-pestel" type="button" style={{ display: 'none' }}></button>
			</fieldset>

			{!!isPreparingDocument && 
				<Box className="creation-loading" sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<UnikbaseSvg 
						id="loading-logo"
						className="logo logo--short logo--tpk-loading"
						style={{
							backgroundColor: 'var(--unikbase-red)',
						}}
					/>
					<Box sx={{ width: '100%' }}>
						<LinearProgress 
							color="secondary"
							className="loading-progress"
						/>
					</Box>
					<Typography sx={{ mt: 2 }} component="p" variant="body">L'opération est en cours et peut prendre quelques instants, merci de votre patience.</Typography>
				</Box> 
			}
		</Box>
	);
};

export default connect(
	(state) => {
		const walletState = state?.wallet;
		const authState = state?.auth;
		return {
			error: walletState.error,
			logginStatus: authState.status,
			isLoggedIn: !!authState.accessToken && !!authState.expiredAt && authState.expiredAt > new Date().getTime(),
		};
	},
	(dispatch) => {
		return {
			createAccount: (formData) => {
				dispatch(createWallet(formData));
			},
			resetError: () => {
				dispatch(updateError({}));
			},
			login: (authentication, password = false) => {
				dispatch(manualLogin({ 
					data: authentication,
					password
				}))
			}
		};
	}
)(PestelSignup);
