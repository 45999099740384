import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getOTPStatus, requestOTP } from "../../store/actions/walletActions";
import { MessageBox } from "../Animation";
import { FormField } from "../Form/FormField";

const ForgotPasswordSubmit = (props) => {
	const { status, checkOTP, requestOTP, OTP, error, OTPStatus, onSubmit } = props;
	const [type, setType] = useState("email");
	const [errors, setErrors] = useState({});
	const [formData, setFormData] = useState({
		email: "",
		phone: "",
		dialCode: "+33",
	});

	const validationRules = {
		email: [
			[type==='email'?'': 'disabled'],
			[/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, t("pages:login.signup_email_invalid")]
		],
		phone: [
			[type==='phone'?'': 'disabled'],
			[/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, t("pages:login.signup_phone_invalid")]
		],
	};

	const validation = () => {
		let errors = {};
		let isValid = true;
		for (let fieldId in formData) {
			errors[fieldId] = validFormField(fieldId);
			isValid = isValid && errors[fieldId].length <= 0;
		}
		setErrors(errors);
		return isValid;
	};

	const handleChange = (event, value, id) => {
		let fieldId = !!event ? event?.target?.name : id;
		let fieldValue = !!event ? event?.target?.value : value;
		if (!fieldId) return;

		fieldValue = fieldValue.replace(/\s/g, "");
		const fieldErrors = validFormField(fieldId, fieldValue);
		setErrors({
			...errors,
			[fieldId]: fieldErrors,
		});

		setFormData({
			...formData,
			[fieldId]: fieldValue,
		});
	};

	const validFormField = (id, value) => {
		if (typeof formData[id] === "undefined") return [];

		const rules = validationRules[id];
		if (!rules || rules.length <= 0) return [];

		const isDisabled = rules.filter(item => item.indexOf('disabled') >= 0 ).length > 0
		if  ( isDisabled ) return [];

		value = value || formData[id];

		const fieldErrors = [];
		for (let idx in rules) {
			let rule = rules[idx];
			if (!!rule[1] && !rule[0].test(value)) {
				fieldErrors.push(rule[1]);
			}
		}

		return fieldErrors;
	};

	const submitHandle = (event) => {
		event.preventDefault();
		let isValid = validation();

		if (!isValid) return;

		checkOTP(type, formData['email'], formData['phone']);
		typeof onSubmit === 'function' && onSubmit(type, formData[type] || '');
		return;
	};

	useEffect(() => {
		const isTooManyAttemps = !!OTP && OTP.attemps > 5
		if (OTPStatus === "success" && !isTooManyAttemps) {
			requestOTP(type, formData['email'], formData['phone']);
		}
	}, [OTPStatus, OTP, requestOTP, type, formData]);

	return (
		<Box>
			<Box className="align--center">
				<Typography variant="h6">{t("pages:login.title_account_forgot_password")}</Typography>
				<Typography variant="body2">{t("pages:login.subtitle_account_forgot_password")}</Typography>
			</Box>
			<Box
				onSubmit={submitHandle}
				component="form"
				noValidate={false}
				autoComplete="off"
				className="flex flex--vertical"
				sx={{ p: 2, width: "100%", maxWidth: "400px", ml: "auto", mr: "auto" }}
			>
				{type === "email" ? (
					<FormField
						fieldId="email"
						value={formData.email}
						onChange={handleChange}
						label={t("pages:login.label_email")}
						errors={errors.email}
					/>
				) : (
					<FormField
						fieldId="phone"
						value={formData.phone}
						onChange={handleChange}
						label={t("pages:login.label_phone")}
						errors={errors.phone}
						type="phone"
					/>
				)}
				<Typography sx={{ ml: 1, mb: 2 }} component="a" onClick={() => setType(type==='email'?'phone':'email')} variant="caption" color="primary">{type ==='email' ? t("pages:login.reset_password_by_phone") :  t("pages:login.reset_password_by_email") }</Typography>

				<LoadingButton
					loading={status === "loading" || OTPStatus === 'loading'}
					sx={{ mb: 2, borderRadius: 6 }}
					variant="contained"
					type="submit"
				>
					{t("pages:login.forgot_password_button")}
				</LoadingButton>

				{!!error && error.length > 0 && (
					<MessageBox type="error">
						<ul>
							{Object.values(error).map((item, idx) => (
								<li key={`error-message-${idx}`}>{item}</li>
							))}
						</ul>
					</MessageBox>
				)}
			</Box>
		</Box>
	);
};

export default connect(
	(state) => {
		const walletState = state?.wallet;
		return {
			status: walletState.status,
			error: walletState.error,
			OTP: walletState.otp,
			OTPStatus: walletState.otpChecking,
			OTPRequests: walletState.otpRequests,
		};
	},
	(dispatch) => {
		return {
			checkOTP: (type = 'email', email = '', phone = '') => {
				dispatch(getOTPStatus({ type, email, phone }));
			},
			requestOTP: (type = 'email', email = '', phone = '') => {
				dispatch(requestOTP({ type, email, phone }))
			}
		};
	}
)(ForgotPasswordSubmit);
