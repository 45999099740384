import { useEffect, useState } from "react";

const CountDown = ({ availableTill, text, hideHour, hideMinute, onSuccess }) => {
	const [clock, setClock] = useState({
		hour: "00",
		minute: "00",
		second: "00",
	});

	useEffect(() => {
		const hours = 60 * 60 * 1000;
		const minutes = 60 * 1000;
		const seconds = 1000;

		const intervalId = setInterval(() => {
			const current = new Date().getTime();
			const remain = availableTill - current;
			
			if (remain <= 0) {
				typeof onSuccess == 'function' && onSuccess();
				setClock({
					hour: "00",
					minute: "00",
					second: "00",
				});
				clearInterval(intervalId);
				return;
			}
			const hour = Math.floor(remain / hours);
			let remainder = remain % hours;
			const minute = Math.floor(remainder / minutes);
			remainder = remainder % minutes;
			const second = Math.floor(remainder / seconds);

			setClock({
				hour: hour > 9 ? hour : "0" + hour,
				minute: minute > 9 ? minute : "0" + minute,
				second: second > 9 ? second : "0" + second,
			});
		}, 1000);
		return () => {
			clearInterval(intervalId);
		};
	}, [availableTill, onSuccess]);

	if ( clock.hour === '00' && clock.minute === '00' && clock.second === '00' ) {
		return <></>;
	}
	return (
		<>
			{`${text||''} `}
			{!hideHour && `${clock.hour}:`}{!hideMinute && `${clock.minute}:`}{clock.second}{hideHour && hideMinute && ' seconds'}
		</>
	);
};

export default CountDown;
