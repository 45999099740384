import { KeyringController } from '@metamask/eth-keyring-controller';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { default as apiConfigs } from '../../configs/api';
import { default as serverConfigs } from '../../configs/config';

import {
  decryptSeedPhrase,
  encrypeSeedPhrase,
  encryptOperatorKey,
  generateSeedphrase,
  isValidMnemonic,
} from '../../service/crypto';
import { keccak_256, base64, merge } from '../../utils/helpers';
import { updateKeyringController } from '../slices/cryptoSlice';
import Web3, { utils } from 'web3';
import { fromWei } from 'web3-utils';
import { fetchAuthToken, getAuthToken } from './authActions';
import { meoveoRestApi, prepareBasicAuthToken } from '../../service/api/meveoApi';
import { t } from 'i18next';

const BillingContractABIs = require('../../assets/json/unkb.abi.json');

export const EXPORT_SEEDPHRASE = 'wallet/export-seedphrase';

export const conditionCheck = (userId, { getState }) => {
  const cryptoState = getState().crypto;
  const wallet = getState().wallet;
  if (!wallet?.private?.seedPhrase || !cryptoState.keyringController) {
    return false;
  }
};

export const getWallet = createAsyncThunk('wallet/info', async (payload, { getState, rejectWithValue }) => {
  const auth = getState()?.auth;

  if (!auth) {
    return rejectWithValue({
      code: 'get-wallet',
      message: 'Permission denied',
    });
  }

  try {
    const response = await fetch(apiConfigs.wallet.info, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
      },
    }).then((response) => response.json());

    if (!response.status || response.status !== 'success' || !response.result) {
      return rejectWithValue({
        code: 'get-wallet',
        message: 'Permission denied',
      });
    }
    const { publicInfo = {}, privateInfo = {} } = response.result;

    // Wallet data which use to show
    const walletData = {
      public: publicInfo,
      private: {
        phone: {
          number: privateInfo?.phoneNumber.phoneNumber,
          dialCode: privateInfo?.dialCode.dialCode,
          verified: privateInfo?.phoneNumber.verified,
        },
        email: {
          address: privateInfo?.email.emailAddress,
          verified: privateInfo?.email.verified,
        },
        username: privateInfo?.username,
        seedPhrase: privateInfo.seedPhrase,
      },
      address: response.result?.address,
    };

    return {
      wallet: walletData,
    };
  } catch (error) {
    return rejectWithValue({
      code: 'get-wallet',
      message: error.message,
    });
  }
});

export const updateWallet = createAsyncThunk(
  'wallet/update',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const store = getState();
    const auth = store.auth;

    if (!auth || !auth.accessToken) {
      return rejectWithValue({
        code: 'update-wallet',
        message: 'Permission denied',
      });
    }

    try {
      const walletState = store.wallet;
      const keyringController = store.crypto?.keyringController;
      let isChangingPassword = false;

      const tokenPrivacySettings = walletState.public?.tokenPrivacySettings || {};
      let publicInformation = Object.assign({}, walletState.public, {
        tokenPrivacySettings: { ...tokenPrivacySettings },
      });
      if (typeof payload.public !== 'undefined') {
        publicInformation = merge(publicInformation, walletState.public, payload.public);
      }

      let data = JSON.stringify(publicInformation);
      const hash = keccak_256(data);
      const signature = await keyringController.signMessage({
        from: walletState.address,
        data: hash,
      });

      const requestData = [
        walletState.private.username,
        walletState.address,
        signature,
        JSON.stringify(publicInformation || ''),
      ];

      let privateInformation = Object.assign({}, walletState.private);
      // Change password
      if (typeof payload.password !== 'undefined') {
        const { current, newPassword } = payload.password;
        const passwordValidation = await keyringController.verifyPassword(current);
        if (passwordValidation) {
          return rejectWithValue({
            code: 'update-wallet',
            message: 'Current password is not valid',
          });
        }

        const mnemonic = decryptSeedPhrase(walletState.private.seedPhrase, current, walletState.address);
        const updateEncryptedSeedPhase = encrypeSeedPhrase(mnemonic, newPassword, walletState.address);

        const updatePrivateInformation = {
          seedPhrase: updateEncryptedSeedPhase,
          password: newPassword,
          emailAddress: walletState.private?.email?.address,
          username: walletState.private?.username,
          operatorPK: walletState.private?.operatorPK,
        };
        // 4th params for update private information - password
        requestData.push(JSON.stringify(updatePrivateInformation || '{}'));
        privateInformation.seedPhrase = updateEncryptedSeedPhase;
        isChangingPassword = true;
        await keyringController.createNewVaultAndRestore(newPassword, mnemonic);
      } else if (typeof payload.opk !== 'undefined') {
        try {
          const privateKey = await keyringController.exportAccount(walletState.address);
          let operatorPK = await encryptOperatorKey(privateKey, payload.opk, walletState.address);
          if (operatorPK === walletState?.private.operatorPK) {
            // Ignore this when opratorPK same as old one
            return rejectWithValue({
              code: 'updat-wallet-operator-pk',
              message: 'Invalid data',
            });
          }
          const updatePrivateInformation = {
            seedPhrase: walletState.private?.seedPhrase,
            emailAddress: walletState.private?.email?.address,
            username: walletState.private?.username,
            operatorPK: operatorPK,
          };
          requestData.push(JSON.stringify(updatePrivateInformation));
          privateInformation.operatorPK = operatorPK;
        } catch (e) {
          privateInformation.operatorPK = false;
        }
      }

      const response = await fetch(apiConfigs.wallet.update, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.accessToken}`,
        },
        body: JSON.stringify({
          ...apiConfigs.basicMethod,
          method: apiConfigs.methods.walletUpdate,
          params: requestData,
        }),
      }).then((resp) => resp.json());

      if (!!response && response.result && response.result === walletState.private.username) {
        if (isChangingPassword) {
          // Update keyringController with new password
          dispatch(updateKeyringController({ controller: keyringController }));
        }
        return {
          public: publicInformation,
          private: privateInformation,
        };
      }
      return rejectWithValue({
        code: 'update-wallet',
        message: 'Update error',
      });
    } catch (error) {
      console.log(error);
      return rejectWithValue({
        code: 'update-wallet',
        message: error.message,
      });
    }
  },
  {
    condition: conditionCheck,
    dispatchConditionRejection: true,
  }
);

const createCollectorAccount = async (payload, rejectFunc) => {
  const { username, password, email, phone, dialCode, countryCode } = payload;

  let transformUsername = username.toLowerCase();

  const publicInformation = {
    base64Avatar: '',
  };

  try {
    const seedPhrase = generateSeedphrase();
    const keyringController = new KeyringController({});
    await keyringController.createNewVaultAndRestore(password, seedPhrase);
    const accounts = await keyringController.getAccounts();
    const selectedAddress = accounts[0];

    const encryptedSeedPhrase = encrypeSeedPhrase(seedPhrase, password, selectedAddress);

    const hash = keccak_256(transformUsername + selectedAddress);

    const privateInformation = {
      username: transformUsername,
      emailAddress: email,
      phoneNumber: phone,
      dialCode: {
        dialCode: dialCode,
        countryCodes: countryCode,
      },
      seedPhrase: encryptedSeedPhrase,
      password: password,
    };

    let hashPrivate = keccak_256(JSON.stringify(privateInformation));
    const signature = await keyringController.signMessage({
      from: selectedAddress,
      data: hashPrivate,
    });

    const requestData = [
      transformUsername,
      selectedAddress,
      hash,
      signature,
      JSON.stringify(publicInformation),
      JSON.stringify(privateInformation),
    ];

    let newToken = await prepareBasicAuthToken();
    const response = await fetch(apiConfigs.wallet.update, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Basic ${base64(serverConfigs.BASIC_AUTH)}`,
        Authorization: `Bearer ${newToken}`,
      },
      body: JSON.stringify({
        ...apiConfigs.basicMethod,
        method: apiConfigs.methods.walletCreation,
        params: requestData,
      }),
    }).then((resp) => resp.json());

    if (!!response && !!response.error && !!response.error.code) {
      return rejectFunc({
        code: ['wallet-create', response.error.code],
        message: response.error.message || 'Error when create new wallet',
      });
    }

    return {
      address: response.result,
      username: transformUsername,
      password: password,
    };
  } catch (error) {
    return rejectFunc({
      code: 'wallet-create',
      message: error.message,
    });
  }
};

const createOperatorAccount = async (payload, rejectFunc) => {
  const { username, password, email, operatorPrivateKey } = payload;
  let transformUsername = username.toLowerCase();

  const requestData = {
    username: transformUsername,
    emailAddress: email,
    name: transformUsername,
    password: password,
    privateKey: operatorPrivateKey,
  };

  try {
    let newToken = await prepareBasicAuthToken();
    const response = await fetch(apiConfigs.wallet.operator, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //Authorization: `Basic ${base64(serverConfigs.BASIC_AUTH)}`,
        Authorization: `Bearer ${newToken}`,
      },
      body: JSON.stringify(requestData),
    }).then((resp) => resp.json());

    if (!!response && (!!response.error || response.status === 'fail')) {
      return rejectFunc({
        code: ['operator-create', response?.error?.code || 404],
        message:
          response?.error?.message || response?.error || response.result || 'Error when create new operator account',
      });
    }

    return {
      address: response?.result?.wallet,
      username: transformUsername,
      password: password,
    };
  } catch (error) {
    return rejectFunc({
      code: 'operator-create',
      message: error.message,
    });
  }
};

export const createWallet = createAsyncThunk('wallet/create', async (payload, { getState, rejectWithValue }) => {
  const { operatorPrivateKey } = payload;
  let response;
  if (!!operatorPrivateKey) {
    response = await createOperatorAccount(payload, rejectWithValue);
  } else {
    response = await createCollectorAccount(payload, rejectWithValue);
  }

  return response;
});

export const getOTPStatus = createAsyncThunk(
  'wallet/verify/get-otp-status',
  async (payload, { getState, rejectWithValue }) => {
    const wallet = getState().wallet;
    const privateInformation = wallet.private;

    const type = payload?.type || 'email';
    const verifyId =
      !!payload.email || !!payload.phone
        ? type === 'email'
          ? payload.email
          : payload.phone
        : type === 'phone'
        ? privateInformation.phone.number
        : privateInformation.email.address;

    if (!verifyId) {
      return rejectWithValue({
        message: 'Verify data is not valid',
      });
    }

    const response = await fetch(`${apiConfigs.otp.status}/${verifyId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((resp) => resp.json());

    return {
      creationDate: parseInt(response.creationDate || 0),
      lastAttemptDate: response.lastAttemptDate,
      sent: response.otpSent,
      attempts: response.attempts,
    };
  }
);

export const requestOTP = createAsyncThunk(
  'wallet/verify/get-otp',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const type = payload?.type || 'email';

    const wallet = getState().wallet;
    const privateInformation = wallet.private;

    const verifyId =
      !!payload.email || !!payload.phone
        ? type === 'email'
          ? payload.email
          : payload.phone
        : type === 'phone'
        ? privateInformation.phone.number
        : privateInformation.email.address;

    if (!verifyId) {
      return rejectWithValue({
        message: 'Verify data is not valid',
      });
    }

    if ('email' === type && !!privateInformation?.email?.address && !!privateInformation?.email?.verified) {
      return rejectWithValue({
        code: 'wallet-verify-email',
        message: 'Verify email address is not valid',
      });
    }

    const current = new Date().getTime();
    try {
      const url =
        'email' === type ? `${apiConfigs.otp.requestEmail}/${verifyId}` : `${apiConfigs.otp.requestPhone}/${verifyId}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${base64(serverConfigs.BASIC_AUTH)}`,
        },
      }).then((resp) => resp.json());

      if (!!response.status && response.status === 'fail') {
        return rejectWithValue({
          code: `wallet-verify-${type}`,
          message: response.result || 'Request OTP fail',
        });
      }
      switch (response) {
        case 'success':
          return { creationTime: { [type]: current } };
        case 'too_many_requests':
          return { creationTime: { [type]: 1 } };
        default:
          return { creationTime: { [type]: 0 } }; // to enable resend
      }
    } catch (error) {
      return { creationTime: { [type]: 0 } }; // to enable resend
    }
  }
);

export const verifyOTP = createAsyncThunk('wallet/verify/email', async (payload, { getState, rejectWithValue }) => {
  const type = payload?.type || 'email';
  const otp = payload?.otp;

  if (!otp || otp.length <= 0) {
    rejectWithValue({
      message: 'OTP Code is not valid',
    });
  }

  const wallet = getState().wallet;
  const privateInformation = wallet.private;

  const verifyId =
    !!payload.email || !!payload.phone
      ? type === 'email'
        ? payload.email
        : payload.phone
      : type === 'phone'
      ? privateInformation.phone.number
      : privateInformation.email.address;

  const url = `${apiConfigs.otp.verifyEmail}/${verifyId}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${base64(serverConfigs.BASIC_AUTH)}`,
      },
      body: JSON.stringify({ otp }),
    }).then((resp) => {
      const cloneResp = resp.clone();
      return resp.json().catch(() => cloneResp.text());
    });

    switch (response) {
      case 'success':
        return {
          type: type,
          success: true,
        };
      case 'too_many_attempts':
        return rejectWithValue({
          message: 'Too many attempts',
        });
      case 'invalid_request':
      case 'invalid_code':
      default:
        return rejectWithValue({
          message: 'Invalid code',
        });
    }
  } catch (error) {
    console.log(error);
    return rejectWithValue({
      code: verifyOTP.rejected.toString(),
      message: error.message,
    });
  }
});

export const getBalance = createAsyncThunk('wallet/balance', async (payload, { getState, rejectWithValue }) => {
  let { address } = payload;
  try {
    address = address.startsWith('0x') ? address : `0x${getState().wallet.address}`;

    const web3 = new Web3(new Web3.providers.HttpProvider(apiConfigs.token.rpcProvider));
    const contract = new web3.eth.Contract(BillingContractABIs, serverConfigs.BILLING_CONTRACT_ADDRESS, {
      from: address,
    });
    const balance = await contract.methods.balanceOf(address).call();
    return { balance };
  } catch (e) {
    return rejectWithValue({
      code: getBalance.rejected.toString(),
      message: e.message,
    });
  }
});

export const getOperatorWallet = createAsyncThunk('wallet/info', async (payload, { getState, rejectWithValue }) => {
  const auth = getState()?.auth;

  if (!auth) {
    return rejectWithValue({
      code: 'get-operator',
      message: 'Permission denied',
    });
  }

  try {
    const response = await fetch(apiConfigs.operator.details, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
      },
    }).then((response) => response.json());

    if (!response.status || response.status !== 'success' || !response.result) {
      return rejectWithValue({
        code: 'get-operator',
        message: 'Permission denied',
      });
    }

    const data = response.result?.wallet;
    const publicInfo = data?.publicInfo || {};
    const privateInfo = data?.privateInfo || {};

    // Wallet data which use to show
    const walletData = {
      public: publicInfo,
      private: {
        phone: {
          number: privateInfo?.phoneNumber.phoneNumber,
          dialCode: privateInfo?.dialCode.dialCode,
          verified: privateInfo?.phoneNumber.verified,
        },
        email: {
          address: privateInfo?.email.emailAddress,
          verified: privateInfo?.email.verified,
        },
        seedPhrase: privateInfo?.seedPhrase,
        username: privateInfo?.username,
      },
      address: data?.address,
    };

    return {
      wallet: walletData,
      isOperatorUser: true,
    };
  } catch (error) {
    return rejectWithValue({
      code: 'get-operator',
      message: error.message,
    });
  }
});

export const getWalletBySeedphrase = createAsyncThunk(
  'wallet/info-by-seedphrase',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const { seedphrase, password } = payload;

    if (!seedphrase || !isValidMnemonic(seedphrase)) {
      return rejectWithValue({
        code: 'get-wallet-by-seedphrase',
        message: 'Invalid Seedphrase Format',
      });
    }

    try {
      const keyringController = new KeyringController({});
      await keyringController.createNewVaultAndRestore(password, seedphrase);

      const [address] = await keyringController.getAccounts();
      // const privateKey = await keyringController.exportAccount(address);
      // const publicKey = await keyringController.getEncryptionPublicKey(address);
      const message = `walletInfo,${address},${new Date().getTime()}`;

      const hash = keccak_256(message);
      const signature = await keyringController.signMessage({
        from: address,
        data: hash,
      });

      const response = await fetch(apiConfigs.wallet.update, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${base64(serverConfigs.BASIC_AUTH)}`,
        },
        body: JSON.stringify({
          ...apiConfigs.basicMethod,
          method: apiConfigs.methods.walletInfo,
          params: [address, signature, message],
        }),
      }).then((resp) => resp.json());

      if (!response.result || typeof response.result.privateInfo === 'undefined') {
        return rejectWithValue({
          code: 'get-wallet-by-seedphrase',
          message: !!response.error && response.error.message ? response.error.message : 'The seedphrase is not valid',
        });
      }

      const wallet = response.result;
      // Will try to login with password
      try {
        let authResponse = await fetchAuthToken(wallet.name, password);
        // User can logged in with current password without change it
        if (!!authResponse && !!authResponse.access_token) {
          return {
            wallet,
            password,
          };
        }
      } catch (error) {
        return rejectWithValue({
          code: 'get-wallet-by-seedphrase',
          message: error.message || "Error while checking user's credential",
        });
      }
      // If we cannot use new password to login, force to Change password
      let data = JSON.stringify(wallet.publicInfo);
      const hashPublicInfo = keccak_256(data);
      const signUpdateInfo = await keyringController.signMessage({
        from: address,
        data: hashPublicInfo,
      });

      const requestData = [wallet.name, address, signUpdateInfo, JSON.stringify(wallet.publicInfo || '')];

      const updateEncryptedSeedPhase = encrypeSeedPhrase(seedphrase, password, address);

      const updatePrivateInformation = {
        seedPhrase: updateEncryptedSeedPhase,
        password: password,
        emailAddress: wallet?.privateInfo?.email?.emailAddress,
        username: wallet?.name,
      };
      // 4th params for update private information - password
      requestData.push(JSON.stringify(updatePrivateInformation || '{}'));

      const changePasswordResponse = await fetch(apiConfigs.wallet.update, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${base64(serverConfigs.BASIC_AUTH)}`,
        },
        body: JSON.stringify({
          ...apiConfigs.basicMethod,
          method: apiConfigs.methods.walletUpdate,
          params: requestData,
        }),
      }).then((resp) => resp.json());

      if (!!changePasswordResponse && !!changePasswordResponse.error && !!changePasswordResponse.error.code) {
        return rejectWithValue({
          code: ['import-wallet-by-seedphrase', changePasswordResponse.error.code],
          message: changePasswordResponse.error.message || 'Error when import account by seedphrase',
        });
      }

      return {
        wallet: wallet,
        password: password,
      };
    } catch (error) {
      return rejectWithValue({
        code: 'get-wallet-by-seedphrase',
        message: error.message,
      });
    }
  }
);

export const signMessage = createAsyncThunk('wallet/signMessage', async (payload, { dispatch, getState, rejectWithValue }) => {
  try {
    const store = getState();
    const walletState = store.wallet;
    const seedPhrase = walletState.private?.seedPhrase;
    const password = payload.password;
    const address = walletState.address;

    const mnemonic = decryptSeedPhrase(seedPhrase, password, address);

    if (mnemonic) {
      const keyringController = new KeyringController({});
      await keyringController.createNewVaultAndRestore(password, mnemonic);
      dispatch(updateKeyringController({ controller: keyringController }));

      const data = keccak_256(payload.data);
      return await keyringController.signMessage({
        from: address,
        data,
      });
    } else {
      return rejectWithValue({ message: 'Invalid keyring controller!' });
    }
  } catch (error) {
    return rejectWithValue({ message: error });
  }
});

export const getOperatorInfo = createAsyncThunk(
  'wallet/getOperatorInfo',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    if (!payload) {
      return rejectWithValue({});
    }
    try {
      const auth = getState()?.auth;
      const info = await fetch(apiConfigs.operator.info + '/' + payload, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      }).then((response) => response.json());
      const { status, result } = info;
      if (status === 'success' && result) {
        return {
          uuid: result.uuid,
          name: result.name || result.uuid,
          logo: result.logoUrl || '',
          stamp: result.stampUrl || '',
        };
      } else {
        return rejectWithValue({});
      }
    } catch (error) {
      return rejectWithValue({ message: error });
    }
  }
);

export const resetNonceOfCurrentWallet = createAsyncThunk(
  'wallet/resetNonce',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
      const auth = getState()?.auth;
      const info = await fetch(apiConfigs.wallet.resetNonce, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.accessToken}`,
        },
      }).then((response) => response.json());
      const { status, result } = info;
      if (status === 'success' && result) {
        return { success: true };
      } else {
        return rejectWithValue(info);
      }
    } catch (error) {
      return rejectWithValue({ message: error });
    }
  }
);
