import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import store from "../store/rootReducer";
import Web3, { ResponseError } from "web3";
import { default as apiConfigs } from "../configs/api";
import config from "../configs/config";
import { useTraceUpdate } from "../utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import { requirePassword } from "../store/slices/cryptoSlice";
import { decryptOperatorKey } from "../service/crypto";
import { generateJsonHash, generateZipHash } from "@unikbase/react-token-hash";
import { getTokenIdBignumber, isEmail } from "../utils/helpers";
import { meoveoRestApi, useAcceptTransferTokenMutation, useRefuseTransferTokenMutation, useTransferTokenMutation } from "../service/api/meveoApi";
import { requirePropFactory } from "@mui/material";
import { toast } from "react-toastify";
import { t } from "i18next";
import { uuidV4 } from "web3-utils";
import { resetNonceOfCurrentWallet } from "../store/actions/walletActions";

const NftContractABIs = require("../assets/json/unkb.abi.json");
const useNewNonceMechanism = false;

function decodeRevertReason(hexString) {
	try {
		hexString = hexString.trim("\u0000");
		// Remove the '0x' prefix if present
		hexString = hexString.startsWith('0x') ? hexString.slice(2) : hexString;
		// Check if the input hexString is empty
		if (hexString.length === 0) {
			return 'No revert reason provided';
		}

		// Split the hexString into pairs of characters
		const hexPairs = hexString.match(/.{2}/g);

		// Convert hex pairs to ASCII characters
		const decodedString = hexPairs
			.map(hexPair => String.fromCharCode(parseInt(hexPair, 16)))
			.join('');
		return decodedString;
	} catch (error) {
		return hexString
	}
}

// Theses function below were used to do smartContract action, its were write with asyncThunk instead of normal func becuz i want to use midleware to catch the case when KeyringController was not initialized
async function doSmartContractTask(privateCredential, method, transactionParams, address, modifiers = {}) {
	const web3 = new Web3(new Web3.providers.HttpProvider(
		apiConfigs.token.rpcProvider,
		{
			providerOptions: {
				headers: {
					Authorization: `Bearer ${store.getState()?.auth?.accessToken}`
				}
			}
		}
	));
	const contract = new web3.eth.Contract(NftContractABIs, config.NFT_CONTRACT_ADDRESS, { from: address });

	let tx_builder = contract.methods[method](...transactionParams);
	let encoded_tx = tx_builder.encodeABI();

	const gasPrice = await web3.eth.getGasPrice();
	let transactionObject = {
		data: encoded_tx,
		from: address,
		to: config.NFT_CONTRACT_ADDRESS,
		gasPrice: gasPrice,
	};
	try {
		let estimatedGas = await web3.eth.estimateGas(transactionObject);
		transactionObject.gas = Number(estimatedGas.toString());
	} catch (error) {
		transactionObject.gas = Number('5000000');
	}

	const fetchNonce = async () => {
		const res = await store.dispatch(meoveoRestApi.endpoints.getNonce.initiate({ refresh: new Date().getTime() }))
		if (res.isSuccess) {
			const { status, result } = res.data;
			if (status === 'success') {
				transactionObject.nonce = result.nonce;
				return result.nonce;
			} else if (result?.error === 'NONCE_RESERVED') {
				const reservedDate = result.data.reservedDate;
				const timeGap = new Date().getTime() - reservedDate;
				return await new Promise((resolve) => {
					setTimeout(resolve(fetchNonce), timeGap + 500);
				});
			}
		}
		return false;
	}
	const commitNonce = () => {
		return store.dispatch(meoveoRestApi.endpoints.commitNonce.initiate({ refresh: new Date().getTime() }))
	}
	const rollbackNonce = () => {
		return store.dispatch(meoveoRestApi.endpoints.rollbackNonce.initiate({ refresh: new Date().getTime() }))
	}

	if (useNewNonceMechanism) {
		fetchNonce();
	}

	let attempt = 0;
	const submitTransaction = async () => {
		const { rawTransaction } = await web3.eth.accounts.signTransaction(transactionObject, privateCredential);

		if (method === 'addDocumentHash' && !modifiers?.withSending) {
			return rawTransaction;
		}

		try {
			// const transactionResult = await web3.eth.sendSignedTransaction(rawTransaction);// warning: stuck on mobile webview
			const transactionResult = await new Promise((resolve, reject) => {
				web3.currentProvider.request({
					id: uuidV4(),
					jsonrpc: '2.0',
					method: 'eth_sendRawTransaction',
					params: [rawTransaction],
				}).then((result) => {
					const error = result?.error && result.error.message?.toLowerCase()
					if (attempt === 0 && error && (error.includes('nonce is too') || error.includes('nonce too low'))) {
						attempt++;
						store.dispatch(resetNonceOfCurrentWallet())
							.then(async (res) => {
								if (res.payload.success) {
									if (useNewNonceMechanism) await fetchNonce();
									resolve(submitTransaction())
								} else {
									reject(result)
								}
							})
					} else
						if (result?.result?.startsWith('0x')) {
							const txHash = result.result;
							const timeout = 30 * 1000 + new Date().getTime();

							const interval = setInterval(function () {
								web3.eth.getTransactionReceipt(txHash).then(receipt => {
									if (receipt) {
										clearInterval(interval);
										resolve(receipt);
									} else if (new Date().getTime() > timeout) {
										clearInterval(interval);
										resolve({ ...result, transactionHash: txHash })
									}
								});
							}, 1000);
						} else {
							reject(result)
						}
				}).catch((err) => {
					reject(err)
				})
			})

			if (transactionResult && transactionResult?.transactionHash) {
				if (useNewNonceMechanism) await commitNonce();
				return transactionResult?.transactionHash;
			} else {
				if (useNewNonceMechanism) await rollbackNonce();
				return transactionResult;
			}
		} catch (error) {
			if (useNewNonceMechanism) await rollbackNonce();
			if (error instanceof ResponseError) {
				return error
			}
			let message = decodeRevertReason(error.data);
			console.log(message);
			return new Error(message)
		}
	}
	return submitTransaction();
};

const defaultState = {
	status: 'idle',
	error: false,
	data: null,
	payload: null
}

function awaitPasswordPrompt() {
	return new Promise((resolve) => {
		const interval = setInterval(() => {
			const crypto = store.getState()?.crypto || {};
			const { keyringController, requirePassword } = crypto;
			if (!!keyringController) {
				clearInterval(interval);
				resolve(true);
			} else if (!requirePassword) {
				clearInterval(interval);
				resolve(false);
			}
		}, 1000);
	})
}

function useSmartContract(options) {
	const { params, action } = options;
	const currentStatus = useRef('idle');
	const [result, setResult] = useState(Object.assign({}, defaultState))
	const dispatch = useDispatch();
	const keyringController = useSelector(state => state?.crypto?.keyringController);

	const reset = () => {
		setResult(defaultState)
	}

	const getPrivateKey = async (wallet) => {
		const operatorPK = wallet?.private?.operatorPK;
		const creationDate = wallet?.operatorCreationDate;
		const partnerCode = wallet?.partnerCode;

		if (operatorPK && creationDate && partnerCode) {
			const privateKey = await decryptOperatorKey(operatorPK, `${partnerCode}unikbase${creationDate}`, wallet.address);
			return privateKey;
		}
	}
	const execute = useCallback(async (args) => {
		const wallet = store.getState()?.wallet;
		// Start action
		const payload = {
			action: args?.action || action,
			params: args?.params || params,
		}
		if (!wallet.isOperatorUser && !keyringController) {
			dispatch(requirePassword())
			const result = await awaitPasswordPrompt();
			if (!result) {
				setResult(r => ({
					...r,
					status: 'require-password',
					payload: payload
				}));
				return;
			}
		}
		setResult({
			...defaultState,
			status: 'loading',
			payload
		})

		try {
			const _keyringController = store.getState()?.crypto?.keyringController;
			const selectedAddress = `${wallet.address.startsWith("0x") ? "" : "0x"}${wallet.address}`;

			let privateCredential;
			if (wallet.isOperatorUser) {
				privateCredential = await getPrivateKey(wallet);
				if (!privateCredential) {
					privateCredential = await _keyringController.exportAccount(selectedAddress);
				}
			} else {
				privateCredential = await _keyringController.exportAccount(selectedAddress);
			}

			if (!privateCredential) {
				throw new Error('Permission denied');
			}

			const response = await doSmartContractTask(
				privateCredential,
				payload.action,
				payload.params, // inputs
				selectedAddress,
				{ ...(args || {}) }
			);

			let transactionHash = false;
			if (!!response && typeof response === 'string') {
				transactionHash = response
				setResult(r => ({
					...r,
					status: 'success',
					result: { transactionHash },
					error: null,
					payload: null
				}));
				return transactionHash;
			}

			if (response instanceof Error) {
				if (response.message.match(/(?=Unikbase:).+/) || response.message.toLowerCase().indexOf('token already minted') >= 0) {
					setResult(r => ({
						...r,
						status: 'success',
						result: { transactionHash },
						error: null,
						payload: null
					}))
					return response.message; // Ignore some case
				}

				throw response
			}


			if (!!response && response.error) {
				setResult(r => ({
					...r,
					status: 'fail',
					result: null,
					error: response.error,
					payload: null
				}));

				return response.error.message;
			}

		} catch (err) {
			setResult(r => ({
				...r,
				status: 'fail',
				result: null,
				error: err,
				payload: null
			}));
		}

	}, [keyringController, dispatch, action, params])

	useEffect(() => {
		if (currentStatus.current === result.status && !keyringController) {
			// A Pause
			// These lines were use to prevent loop call on single status change
			return;
		}
		currentStatus.current = result.status;

		if (!!keyringController && result.status === 'require-password') {
			execute(result.payload);
		}
	}, [keyringController, result.status, result.payload, execute])

	return [execute, result, reset]
}

function useAddDocumentHashWithSmartContract({ token }) {
	let data = JSON.parse(JSON.stringify(token))

	const tokenId = getTokenIdBignumber(token.token.uuid);

	const zipHash = generateZipHash(data);
	const jsonHash = generateJsonHash(data);

	const [process, result, reset] = useSmartContract({
		params: [tokenId, zipHash, jsonHash],
		action: 'addDocumentHash'
	})

	const execute = (token, withSending = false) => {
		let data = JSON.parse(JSON.stringify(token))

		const zipHash = generateZipHash(data);
		const jsonHash = generateJsonHash(data);

		return process({
			params: [tokenId, zipHash, jsonHash],
			action: 'addDocumentHash',
			withSending
		})
	}

	return [execute, result, reset];
}

function useMintAndPrepareTokenWithSmartContract(options) {
	const { token } = options;
	let data = JSON.parse(JSON.stringify(token))

	const tokenId = getTokenIdBignumber(token.token.uuid);
	const publicLink = `${window.location.origin}/token/public/${token.token.uuid}`;

	const zipHash = generateZipHash(data);
	const jsonHash = generateJsonHash(data);

	const action = token.token.billingOfferCode
		? 'mintOnOfferAndPrepareTransfer'
		: 'mintAndPrepareTransfer';

	const params = [tokenId, publicLink, zipHash, jsonHash];
	if (token.token.billingOfferCode) {
		params.push(token.token.billingOfferCode);
	}

	return useSmartContract({
		params,
		action
	})
}

function useProposeTokenWithSmartContract(options) {
	const { token } = options;

	const [process, result, reset] = useSmartContract({ token });

	const execute = (receiver) => {
		const tokenId = getTokenIdBignumber(token.token.uuid);
		const action = isEmail(receiver) ? "proposeTransferNoWallet" : "proposeTransfer"; // isEmail also means it has no wallet address. because if wallet address were found, it should update receiver to wallet address already
		process({
			params: [tokenId, receiver],
			action
		});
	}

	return [execute, result, reset]
}

function useTransferTokenWithSmartContract(options) {
	const { token } = options;
	const [mintAndPrepare, { status: mintAndPrepareStatus, data: mintAndPrepareResult, error: mintAndPrepareError }, resetMintAndPrepare] = useMintAndPrepareTokenWithSmartContract({ token });
	const [proposeTransfer, { status: proposeStatus, data: proposeResult, error: proposeError }, resetPropose] = useProposeTokenWithSmartContract({ token });
	const [transferData, setTransferData] = useState({});

	const [transferToken, { data: transferResult, isLoading: transferLoading, isError: isTranserError, error: meveoTransferError }] = useTransferTokenMutation();

	const transactionHash = useMemo(() => {
		let hashes = [];
		if (mintAndPrepareStatus === 'success' && typeof mintAndPrepareResult?.transactionHash === 'string') {
			hashes.push(mintAndPrepareResult.transactionHash)
		}
		return hashes
	}, [mintAndPrepareStatus, mintAndPrepareResult]);

	// Process data
	const data = useMemo(() => {
		return {
			isLoading: mintAndPrepareStatus === 'loading' || proposeStatus === 'loading' || transferLoading,
			error: proposeError || mintAndPrepareError || meveoTransferError,
			status: transferResult?.status
		}
	}, [mintAndPrepareStatus, proposeStatus, mintAndPrepareError, proposeError, transferLoading, meveoTransferError, transferResult?.status]);

	const reset = () => {
		resetMintAndPrepare();
	}

	const execute = (transferData) => {
		const address = transferData?.receiver?.wallet || transferData?.receiver?.email;
		setTransferData(transferData);
		if (token?.token?.mintDate) {
			proposeTransfer(address);
		} else {
			mintAndPrepare();
		}
	}


	useEffect(() => {
		if (mintAndPrepareStatus === 'success' && proposeStatus !== 'loading' && !transferLoading) {
			const address = transferData?.receiver?.wallet || transferData?.receiver?.email;
			proposeTransfer(address);
			resetMintAndPrepare();
		}
	}, [mintAndPrepareStatus, proposeStatus, transferLoading, resetMintAndPrepare, transferData, proposeTransfer])

	useEffect(() => {
		if (proposeStatus === 'success' && !transferLoading) {
			let data = Object.assign({}, transferData);
			if (transactionHash && transactionHash.length > 0) {
				data.transactionHash = transactionHash;
			}
			resetPropose(); // Clear
			transferToken(transferData);
		}
	}, [proposeStatus, proposeResult, proposeError, transactionHash, transferData, transferToken, transferLoading, resetPropose]);

	return [execute, data, reset];
}

function useRefuseTransferWithSmartContract(options) {
	const { token } = options
	const [status, setStatus] = useState('idle');
	const [holdAction, setHoldAction] = useState(false);
	const dispatch = useDispatch();
	const keyringController = useSelector(state => state?.crypto?.keyringController);

	const [blockchainCancel, { data: blockchainCancelResult, status: blockchainCancelStatus, error: blockchainCancelError }, resetBlockchainCancel] = useSmartContract({
		action: 'cancelTransfer',
		params: [getTokenIdBignumber(token?.token?.uuid)]
	})
	const [refuseToken, meveoRefuseResponse] = useRefuseTransferTokenMutation();

	// Process data
	const data = useMemo(() => {
		return {
			status: status,
			isLoading: !!meveoRefuseResponse.isLoading || blockchainCancelStatus === 'loading',
			error: blockchainCancelError || meveoRefuseResponse?.error
		}
	}, [status, blockchainCancelStatus, blockchainCancelError, meveoRefuseResponse?.error, meveoRefuseResponse.isLoading]);

	const reset = () => {
		resetBlockchainCancel();
		setStatus('idle');
		meveoRefuseResponse.reset();
	}

	const execute = useCallback((args) => {
		if (!keyringController) {
			dispatch(requirePassword())
			setHoldAction(args)
			return;
		}
		refuseToken(args)
	}, [dispatch, keyringController, refuseToken])

	// Refuse Transfered Token Process
	useEffect(() => {
		if (blockchainCancelStatus === 'success') {
			setStatus('success');
			resetBlockchainCancel();
		} else {
			setStatus('fail');
		}
	}, [blockchainCancelStatus, resetBlockchainCancel]);

	useEffect(() => {
		if (meveoRefuseResponse.isSuccess && blockchainCancelStatus !== 'loading') {
			if (meveoRefuseResponse?.data?.status === 'success') {
				blockchainCancel();
			} else {
				setStatus('fail');
			}
			meveoRefuseResponse.reset();
		}
	}, [
		meveoRefuseResponse,
		blockchainCancelStatus,
		blockchainCancel
	])

	useEffect(() => {
		if (holdAction !== false && !!keyringController) {
			setHoldAction(false);
			execute(holdAction);
		}
	}, [keyringController, execute, holdAction])

	return [execute, data, reset];
}

function useAcceptTransferWithSmartConstract(options) {
	const { token } = options;
	const [status, setStatus] = useState('idle');

	const [blockchainAccept, { data: blockchainAcceptResult, status: blockchainAcceptStatus, error: blockchainAcceptError }, resetBlockchainAccept] = useSmartContract({
		action: 'approveTransfer',
		params: [getTokenIdBignumber(token?.token?.uuid)]
	});

	const [acceptToken, meveoAcceptResponse] = useAcceptTransferTokenMutation();

	// Process data
	const data = useMemo(() => {
		return {
			status: status,
			isLoading: !!meveoAcceptResponse.isLoading || blockchainAcceptStatus === 'loading',
			error: blockchainAcceptError || meveoAcceptResponse?.error
		}
	}, [status, blockchainAcceptStatus, blockchainAcceptError, meveoAcceptResponse?.error, meveoAcceptResponse.isLoading]);

	const reset = () => {
		resetBlockchainAccept();
		setStatus('idle');
		meveoAcceptResponse.reset();
	}


	useEffect(() => {
		if (blockchainAcceptStatus === 'success') {
			toast.success(t("pages:token.receive_token_success"));
			acceptToken({
				tokenID: token?.token?.uuid
			});
			resetBlockchainAccept();
		} else {
			// Not go to next step
			setStatus('fail');
		}
	}, [blockchainAcceptStatus, resetBlockchainAccept, acceptToken, token]);

	useEffect(() => {
		if (meveoAcceptResponse.isSuccess && blockchainAcceptStatus !== 'loading') {
			if (meveoAcceptResponse?.data?.status === 'success') {
				setStatus('success');
			} else {
				setStatus('fail');
			}
			meveoAcceptResponse.reset();
		}
	}, [
		meveoAcceptResponse,
		blockchainAcceptStatus,
		blockchainAccept
	])

	return [blockchainAccept, data, reset];
}

export {
	useAddDocumentHashWithSmartContract,
	useMintAndPrepareTokenWithSmartContract,
	useProposeTokenWithSmartContract,
	useTransferTokenWithSmartContract,
	useRefuseTransferWithSmartContract,
	useAcceptTransferWithSmartConstract
}