import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { MessageBox } from "../../components/Animation";
import CountDown from "../../components/Form/CountDown";
import DigitsField from "../../components/Form/DigitsField";
import Loader from "../../components/Loader";
import {
	getOTPStatus,
	requestOTP,
	verifyEmail,
	verifyOTP as verifyOTPRequest,
} from "../../store/actions/walletActions";
import { persistor } from "../../store/rootReducer";
import { logout } from "../../store/slices/authSlice";
import { hideEmail, useTraceUpdate } from "../../utils/helpers";
import { useGetUserOTP } from "../../hooks/userHooks";
import { LoadingButton } from "@mui/lab";


const VerificationForm = (props) => {
	const { type, verifyOTP, OTP, email, phone } = props;
	const verifyDigitsLength = 6;
	const [digits, setDigits] = useState([]);

  const getDigits = (value) => {
    const digits = `${value}`.split('').map((i) => parseInt(i)).filter((i) => !isNaN(i) && i <= 9 && i >= 0);
    setDigits(digits);
  };

	const onSubmitHandle = (event) => {
		event.preventDefault();
		verifyOTP(type, digits.join(""));
	};

	const { isTooManyAttempts, nextAttempt, error, status, resend: requestOTP } = useGetUserOTP({
		type: type,
		email: email?.address,
		phone: phone?.number
	});

	return (
		<Typography component="div" color={isTooManyAttempts ? "error" : ""} >

			<Typography
				sx={{ ml: "auto", mr: "auto", mb: 8 }}
				variant="subtitle"
				align="center"
				className="page-anchor"
			>
				<span>
					<FontAwesomeIcon icon={solid("user-check")} />
				</span>{" "}
				{t("pages:verification.verification_title")}
			</Typography>
			
			{!isTooManyAttempts && <Typography variant="h6">
				{t("pages:verification.verification_email_title", {
					user_email: hideEmail("phone" === type ? phone?.number : email?.address),
				})}
			</Typography>}
			<form
				onSubmit={onSubmitHandle}
				className="form__verification flex flex--vertical flex--justify-around "
			>
				<fieldset disabled={isTooManyAttempts || status === 'loading'}>
					<DigitsField digitsLength={verifyDigitsLength} onChange={getDigits} />
				</fieldset>
				<LoadingButton
					sx={{ mb: 2 }}
					disabled={digits.length < verifyDigitsLength || isTooManyAttempts}
					type="submit"
					variant="contained"
					loading={status === 'loading'}
					size="small"
				>
					{t("pages:verification.verify_button")}
				</LoadingButton>	
			</form>
			
			{!!error && (
				<MessageBox type="error">{error?.message || t("Unkown error while sending OTP. Please contact developer for more information.")}</MessageBox>
			)}
			{status === 'success' && (
				<MessageBox type="success">{t(`Please check your ${type==='email'? 'e-mail acount' : 'phone'} for Once Time Password we just sent you and enter that code in the boxes`)}</MessageBox>
			)}
			
			{isTooManyAttempts ? (
				<Typography variant="body2" color="error">
					{t("pages:verification.exceeded_attempts")}&nbsp;
					{!!nextAttempt && <CountDown availableTill={nextAttempt} />}
				</Typography>
			) :  
				<Typography component="div" variant="body2">
					{t("pages:verification.not_receive_otp")}&nbsp;
					{nextAttempt && nextAttempt > new Date().getTime() ? (
						<Typography variant="body2" component="span" color="secondary">
							<CountDown
								text={t("pages:verification.resend_label")}
								hideMinute={true}
								hideHour={true}
								availableTill={nextAttempt}
								onSuccess={() => setDigits([])}
							/>
						</Typography>
					) : (
						<Link color="secondary" type="button" onClick={() => requestOTP()}>
							{t("pages:verification.resend_button")}
						</Link>
					)}
				</Typography>
			}
		</Typography>
	)
}

export default connect((state) => {
	const wallet = state?.wallet;
	
	return {
		email: wallet?.private?.email,
		phone: wallet?.private?.phone,
		OTP: wallet?.otp,
		error: wallet?.error,
	};
}, dispatch => {
	return {
		verifyOTP: (type, otp) => {
			dispatch(verifyOTPRequest({ type, otp }));
		}
	}
})(VerificationForm);
