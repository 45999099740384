import { Box, Slider, Stack } from "@mui/material";
import { useImageCompare, useImageCompareDispatch } from "./ImageCompareContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useMarkproofVerificationContext } from "../MarkProofVerificationContext";

const OpacityBar = () => {
	const viewer = useImageCompare();
	const dispatch = useImageCompareDispatch();
	let opacity = typeof viewer?.images?.original?.opacity !== "undefined" ? viewer?.images?.original?.opacity : 100;
	const verificator = useMarkproofVerificationContext();

	return (
		<Box className="image-compare__opacity-bar">
			<Stack
				direction={!!verificator.fullscreen ? "column" : "row"}
				className="image-compare__opacity-bar--wrapper flex flex--justify-center flex--align-center flex--nowrap"
			>
				<Slider
					value={opacity}
					onChange={(event, newValue) => {
						dispatch({
							type: "update_original_opacity",
							data: {
								opacity: newValue,
							},
						});
					}}
					size="small"
					aria-label="original-opacity"
					valueLabelDisplay="off"
					orientation={!!verificator.fullscreen ? "vertical" : "horizontal"}
					color="white"
				/>
				<FontAwesomeIcon icon={solid("droplet")} />
			</Stack>
		</Box>
	);
};

export default OpacityBar;
