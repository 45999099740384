import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { randomSalt } from "@unikbase/react-token-hash";
import { FormField, validFormField } from "../../Form/FormField";
import { useTheme } from "@emotion/react";
import { fileToBase64, normalize, transferUnitFromDisplayToSavedData } from "../../../utils/helpers";
import { TRANSACTION_TYPES, currencies, documentPathTypes, unitTypes } from "../../../configs/constant";
import { connect } from "react-redux";
import { createToken } from "../../../store/actions/tokenActions";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { MessageBox } from "../../Animation";
import "./AddToken.scss";
import { uploadApi, useUploadDocumentsMutation } from "../../../service/api/uploadApi";
import config from "../../../configs/config";
import { useNavigate } from "react-router-dom";
import { clear, removeErrorTokens } from "../../../store/slices/tokenSlice";
import OperatorActionConfirmation from "../../Operator";
import { ShowField } from "../UpdateToken";
import { TokenEditProvider, useTokenEdit, useTokenEditDispatch } from "../EditToken/EditTokenContext";
import { useGetOperatorDetailQuery, useLazyGetTokenByIdQuery, useUpdateTokenByIdMutation } from "../../../service/api/meveoApi";
import { useMintAndPrepareTokenWithSmartContract } from "../../../hooks/smartContractHooks";
import LoadingModal from "../../LoadingModal";
import { toast } from "react-toastify";
import { ShareTokenRetainedFields } from "../ShareToken/ShareToken";
import { usePlatformDispatch } from "../../../layout/Platform/PlatformContext";
import { standPost } from "../../../pages/Standalone/Standalone";

export const convertBase64ToBlob = (base64Image) => {
	// Split into two parts
	const parts = base64Image.split(';base64,');

	// Hold the content type
	const imageType = parts[0].split(':')[1];

	// Decode Base64 string
	const decodedData = window.atob(parts[1]);

	// Create UNIT8ARRAY of size same as row data length
	const uInt8Array = new Uint8Array(decodedData.length);

	// Insert all character code into uInt8Array
	for (let i = 0; i < decodedData.length; ++i) {
		uInt8Array[i] = decodedData.charCodeAt(i);
	}

	// Return BLOB image after conversion
	return new Blob([uInt8Array], { type: imageType });
}

export const convertBase64ToFile = (base64Image) => {
	// Split into two parts
	const parts = base64Image.split(';base64,');

	// Hold the content type
	const imageType = parts[0].split(':')[1];

	// Decode Base64 string
	const decodedData = window.atob(parts[1]);

	// Create UNIT8ARRAY of size same as row data length
	const uInt8Array = new Uint8Array(decodedData.length);

	// Insert all character code into uInt8Array
	for (let i = 0; i < decodedData.length; ++i) {
		uInt8Array[i] = decodedData.charCodeAt(i);
	}

	// Return BLOB image after conversion
	const blob = new Blob([uInt8Array], { type: imageType });

	return new File([blob], `${Date.now()}.${imageType.split('/')[1]}`, { type: imageType });
}

const MintToken = (props) => {
	const { token, onSuccess } = props;
	const [updatePrivacySettings] = useUpdateTokenByIdMutation();
	const [mintAndPrepare, { status: mintAndPrepareStatus }, resetMintAndPrepare] = useMintAndPrepareTokenWithSmartContract({ token });

	const updateHashPrefixes = async () => {
		const fieldsToOmit = Object.keys(token.token).filter((field) => !ShareTokenRetainedFields.includes(field));
		const salts = Array.isArray(token.privacySettings?.token?.hashPrefix)
			? [...token.privacySettings.token.hashPrefix] : [];

		Object.keys(token.token).forEach((name) => {
			if (!salts.find((e) => e.name === name) && !fieldsToOmit.includes(name)) {
				salts.push({ name, prefix: randomSalt() });
			}
		})

		const tokenSettings = { ...token.privacySettings.token };
		tokenSettings.hashPrefix = salts;

		await updatePrivacySettings({
			invalidate: false,
			tokenID: token.token.uuid,
			body: {
				privacySettings: {
					...token.privacySettings,
					token: tokenSettings,
				}
			},
		})
	};

	useEffect(() => {
		if (mintAndPrepareStatus === 'success') {
			resetMintAndPrepare();
			onSuccess();
			toast.success(t("pages:token_list.create_digital_passport_success"));
		}
	}, [mintAndPrepareStatus]);

	useEffect(() => {
		updateHashPrefixes();
		mintAndPrepare();
	}, []);

	return null;
}

const AddToken = (props) => {
	const { user, unit, sx, tokens, addToken, partnerCode, cleanTokens, standalone, onClose, onSuccess } = props;
	const navigate = useNavigate();
	const tokenCreate = useTokenEdit();
	const dispatch = useTokenEditDispatch();

	const [getTokenById] = useLazyGetTokenByIdQuery();
	const [tokenToMint, setTokenToMint] = useState(null);

	const { data: operatorInfo } = useGetOperatorDetailQuery();

	const [errors, setErrors] = useState({});
	const [uploadFiles, uploadResults] = useUploadDocumentsMutation();
	const [processing, setProcessing] = useState(false);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
	const dispatchPlatform = usePlatformDispatch();

	const initData = {
		uuid: {
			id: "uuid",
			label: t("common:uuid"),
			prefix: `${partnerCode}_`,
			errors: [],
			valid: [["[0-9a-zA-Z]{1,}", "Required"]],
			value: "",
		},
		mark_ref: {
			id: "mark_ref",
			label: t("pages:token.mark_reference"),
			errors: [],
			valid: [["^.{1,128}$", "Required (up to 128 characters)"]],
			value: "",
		},
		name: {
			id: "name",
			label: t("common:name"),
			errors: [],
			valid: [["[0-9a-zA-Z]{1,}", "Required"]],
			value: "",
		},
		description: {
			id: "description",
			label: t("pages:token.description"),
			errors: [],
			valid: [],
			value: "",
			type: "multiline",
			maxLength: 500,
		},
		cover_image: {
			id: "cover_image",
			label: t("pages:token.cover_image"),
			type: "file",
			errors: [],
			valid: [
				["required", "Cover image is required"],
				["mimetype|image", "Cover image file is not valid"],
			],
			value: [],
			fullWidth: true,
			multiple: false,
		},
	};
	const [formData, setFormData] = useState(initData);
	const [open, toggleDialog] = useState(false);
	const [confirmDialog, toggleConfirmDialog] = useState(false);

	const uuid = `${partnerCode}_${formData["uuid"]?.value || ""}`;
	const currentTokenState = !!tokens && !!tokens[uuid] ? tokens[uuid] : {};
	// const [isLoading, setIsLoading] = useState(false);

	const handleClose = () => {
		if (standalone && onClose) {
			return onClose();
		}
		setFormData(initData);
		toggleDialog(false);
		toggleConfirmDialog(false);
		dispatch({
			type: 'reset_changed'
		})
		cleanTokens();
	};

	const createNewToken = async () => {
		let validations = {};
		let isValid = true;
		for (let id in formData) {
			let fieldErrors = validFormField(id, formData[id].value, formData);
			validations[id] = fieldErrors;
			isValid = isValid && fieldErrors.length <= 0;
		}
		setErrors({
			...validations,
		});
		if (!isValid) return;

		// setIsLoading(true);
		let newToken = {
			token: {
				uuid: uuid,
				markReference: formData["mark_ref"]?.value || "",
				name: formData["name"]?.value || "",
				description: formData["description"]?.value || "",
				value: tokenCreate.value || 0, // price
				currency: "EUR", // USD, EUR
				lengthInMeter: tokenCreate.lengthInMeter ? transferUnitFromDisplayToSavedData(unit, tokenCreate.lengthInMeter, true) : 0.0,
				widthInMeter: tokenCreate.widthInMeter ? transferUnitFromDisplayToSavedData(unit, tokenCreate.widthInMeter, true) : 0.0,
				depthInMeter: tokenCreate.depthInMeter ? transferUnitFromDisplayToSavedData(unit, tokenCreate.depthInMeter, true) : 0.0,
				weightInKilo: tokenCreate.weightInKilo ? transferUnitFromDisplayToSavedData(unit, tokenCreate.weightInKilo, false) : 0.0,
			},
			// client: {
			// 	email: user.email.address,
			// 	phone: user.phone.number,
			// },
			addedDocuments: [],
		};

		toggleConfirmDialog({
			callback: async () => {
				toggleConfirmDialog(false);
				setProcessing(true);
				if (formData["cover_image"]?.value) {
					const file = formData["cover_image"]?.value[0];
					let requestData = new FormData();
					requestData.append("file", file);

					let response = await uploadFiles({
						data: requestData,
						folder: uuid
					}).unwrap();
					if (response.status === "success" && !!response?.result?.files && response.result.files.length) {
						let uploaded = response.result.files[0].file;
						let uploadedHash = response.result.files[0].hash;
						let url = `${config.IMGPROXY_SERVER_ADDRESS}insecure/raw:1/plain/s3://${config.SCW_BUCKET_NAME}/${uploaded.key}`;
						newToken.addedDocuments.push({
							documentId: uploadedHash, // this is the document hash
							path: documentPathTypes.COVER_IMAGE, // should contain the category of the document (coverImage, invoice, 2d-gallery, 3d-gallery, /doc/certificate/, markProofs, others)
							name: uploaded.name.replace(/\.[^/.]+$/, ""),
							filename: `${uploaded.name}`,
							mimeType: uploaded.mimetype,
							size: uploaded.size, // file size in bytes
							fileUrl: url,
						});
					}
				}
				addToken(newToken)
					.then((response) => {
						if (response?.error?.message) {
							setProcessing(false)
							toast.error(response.payload?.message || response.error?.message)
						}
					})
					.catch((error) => {
						setProcessing(false)
						toast.error(error.message)
					});
			}
		});

	};

	const handleChange = (event, value, id) => {
		let fieldId = !!event ? event?.target?.name : id;
		let fieldValue = !!event ? event?.target?.value : value;
		if (!fieldId) return;

		const isSpecs = !Object.keys(formData).includes(fieldId);
		const data = isSpecs ? formDataField(fieldId) : formData;

		const fieldErrors = validFormField(fieldId, fieldValue, data);

		if (isSpecs) {
			if (isNumberType(fieldId)) {
				fieldValue = fieldValue.replace(/[^\d\.]/g, '');
				fieldValue = !fieldValue ? 0.0 : parseFloat(fieldValue);
			}

			dispatch({
				type: 'update_field',
				data: {
					key: fieldId,
					value: fieldValue
				}
			})
		} else {
			if (formData[fieldId]?.type === "file") {
				if (!formData[fieldId].multiple) {
					fieldValue = [fieldValue[0]];
				}
				if (fieldErrors.length > 0) {
					fieldValue = [];
				}
			}
			setErrors({
				...errors,
				[fieldId]: fieldErrors,
			});

			setFormData({
				...formData,
				[fieldId]: {
					...formData[fieldId],
					value: fieldValue,
				},
			});
		}
	};

	const isLoading = uploadResults.isLoading || (!!currentTokenState && currentTokenState.status === "loading");

	const isNumberType = (fieldId) => ['value', 'lengthInMeter', 'depthInMeter', 'widthInMeter', 'weightInKilo'].includes(fieldId);

	const formDataField = (fieldId) => {
		const fieldValue = tokenCreate[fieldId];

		const formData = {
			[fieldId]: {
				id: fieldId,
				label: '',
				errors: errors[fieldId] || [],
				valid: [],
				value: fieldValue,
				type: isNumberType(fieldId) ? 'number' : 'text',
			}
		};

		return formData;
	}

	const _onSuccess = () => {
		setProcessing(false)
		cleanTokens();
		if (standalone && onSuccess) {
			onSuccess(`${uuid}`);
		} else
			return navigate(`/token/${uuid}`, { replace: true });
	}

	useEffect(() => {
		let token = tokens[uuid];
		if (!!token && token.status === "success") {
			if (operatorInfo?.mintOnTransfer || operatorInfo?.result?.mintOnTransfer) {
				setProcessing(false)
				_onSuccess();
			} else if (!tokenToMint) {
				getTokenById({ id: token.token.uuid, mode: 'default' })
					.unwrap()
					.then((response) => {
						if (response.status === "success") {
							setTokenToMint(response.result)
						} else {
							setProcessing(false)
						}
					})
					.catch((error) => {
						setProcessing(false)
					})
			}
		}
	}, [tokens, uuid, navigate, cleanTokens]);

	const onNavigationIntercept = () => {
		if (standalone && onClose) onClose();
		return true;
	}

	useEffect(() => {
		if (standalone) {
			toggleDialog(true)
			standPost({ action: 'loaded', data: true })

			dispatchPlatform({
				type: 'navigation_intercept',
				data: {
					interceptHandle: onNavigationIntercept
				}
			});
		}
		return () => {
			dispatchPlatform({
				type: 'reset'
			});
		}
	}, [standalone])

	useEffect(() => {
		if (partnerCode && formData?.uuid) {
			formData.uuid.prefix = `${partnerCode}_`;
			setFormData({ ...formData });
		}
	}, [partnerCode])

	return (
		<>
			{!standalone && (
				<Button
					sx={sx}
					variant="contained"
					color="secondary"
					size="medium"
					className="btn-add-token"
					onClick={() => toggleDialog(true)}
				>
					<span className="icon icon--add-token" style={{
						backgroundColor: "#ffffff",
						marginRight: '8px'
					}}></span>
					{t("pages:wallet.create")}
					{/* <FontAwesomeIcon style={{ marginLeft: "1em" }} icon={solid("circle-plus")} /> */}
				</Button>
			)}

			<Dialog fullScreen={fullScreen} className="dialog" open={open} onClose={handleClose}>
				<DialogTitle>
					<Box display="flex" justifyContent="space-between">
						<Typography variant="h5" fontWeight="500">{t("pages:token_list.create_digital_passport")}</Typography>
						<IconButton className="confirm-dialog__close" aria-label="close" onClick={handleClose}>
							<span className="icon icon--close"></span>
						</IconButton>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Typography variant="h6" fontWeight="500" mb={1}>{t("pages:token_list.basic_info")}</Typography>
					<fieldset disabled={!!isLoading}>
						<Box className="form__add-new-token">
							{Object.values(formData).map((item, idx) => {
								return (
									<>
										<Typography variant="body2" fontWeight="500">{item.label}</Typography>
										<Box display="flex" alignItems="center">
											{!!item.prefix && <Typography className="form__add-new-token__prefix" variant="body2" fontWeight="400" mb={2} p={1}>{item.prefix}</Typography>}
											<FormField
												fieldId={item["id"]}
												key={`add-token-${item["id"]}`}
												value={item["value"]}
												onChange={handleChange}
												label={''}
												placeholder={`${t('pages:token_list.insert')} ${item.label}`}
												errors={errors[item.id]}
												fullWidth={true}
												type={item.type || "default"}
												disabled={!!isLoading}
												multiple={item.multiple}
												maxLength={item.maxLength}
											/>
										</Box>
										{item.maxLength && (
											<Box mt={-1} mb={2}>
												<Typography variant="caption" component="span" color="GrayText">
													{item.value?.length}/{item.maxLength}
												</Typography>
											</Box>
										)}
									</>
								);
							})}

							<Box className="form__add-new-token specifications">
								<Typography variant="h6" fontWeight="500" mb={1}>{t("pages:token_list.specifications")}</Typography>
								<Grid container spacing={2}>
									<Grid item xs={6} sm={6} md={6}>
										<Typography component="span" variant="body2">
											{t("pages:token.price")}
										</Typography>
										<Box className="input_price" display="flex" flexDirection="row" alignItems="center" py={`0!important`}>
											<Typography variant="body2" fontWeight="400" p={1}>$</Typography>
											<ShowField numeric formData={formDataField('value')} id="value" handleChange={handleChange} />
										</Box>
									</Grid>
								</Grid>

								<Grid container spacing={2} className="attributes">
									<Grid item xs={6} sm={6} md={6} className="attribute">
										<Typography variant="body">
											{t("pages:token.length")}{'\n'}
										</Typography>
										<Box display="flex" flexDirection="row" alignItems="center" ml={`0 !important`}>
											<ShowField numeric formData={formDataField('lengthInMeter')} id="lengthInMeter" handleChange={handleChange} />
											<Typography variant="body2" fontWeight="400" p={1}>cm</Typography>
										</Box>
									</Grid>
									<Grid item xs={6} sm={6} md={6} className="attribute">
										<Typography variant="body">
											{t("pages:token.weight")}{'\n'}
										</Typography>
										<Box display="flex" flexDirection="row" alignItems="center" ml={`0 !important`}>
											<ShowField numeric formData={formDataField('weightInKilo')} id="weightInKilo" handleChange={handleChange} />
											<Typography variant="body2" fontWeight="400" p={1}>g</Typography>
										</Box>
									</Grid>
									<Grid item xs={6} sm={6} md={6} className="attribute">
										<Typography variant="body">
											{t("pages:token.width")}{'\n'}
										</Typography>
										<Box display="flex" flexDirection="row" alignItems="center" ml={`0 !important`}>
											<ShowField numeric formData={formDataField('widthInMeter')} id="widthInMeter" handleChange={handleChange} />
											<Typography variant="body2" fontWeight="400" p={1}>cm</Typography>
										</Box>
									</Grid>
									<Grid item xs={6} sm={6} md={6} className="attribute">
										<Typography variant="body">
											{t("pages:token.depth")}{'\n'}
										</Typography>
										<Box display="flex" flexDirection="row" alignItems="center" ml={`0 !important`}>
											<ShowField numeric formData={formDataField('depthInMeter')} id="depthInMeter" handleChange={handleChange} />
											<Typography variant="body2" fontWeight="400" p={1}>cm</Typography>
										</Box>
									</Grid>
								</Grid>
							</Box>
						</Box>
					</fieldset>
					{currentTokenState?.status === "error" && (
						<MessageBox type="error">{currentTokenState?.message}</MessageBox>
					)}
				</DialogContent>
				<DialogActions>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Button className="w--100" onClick={handleClose} size="medium" variant="outlined" color="secondary">
								{t("common:cancel")}
							</Button>
						</Grid>
						<Grid item xs={12} sm={6}>
							<LoadingButton
								className="w--100"
								type="submit"
								size="medium"
								variant="contained"
								color="secondary"
								onClick={() => createNewToken()}
								loading={!!isLoading}
							>
								{t("pages:wallet.create_digital_twin")}
							</LoadingButton>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>

			<OperatorActionConfirmation
				action={TRANSACTION_TYPES.CREATE_TOKEN.key}
				open={confirmDialog}
				toggle={toggleConfirmDialog}
				onClose={() => {
					handleClose()
				}}
			/>

			{tokenToMint && tokenToMint.token && (
				<MintToken token={tokenToMint} onSuccess={_onSuccess} />
			)}

			{processing && <LoadingModal
				visible={processing}
				// message={t("pages:token_list.creating_digital_passport")}
				onClose={() => null}
			/>}
		</>
	);
};

const AddTokenContext = (props) => (
	<TokenEditProvider>
		<AddToken {...props} />
	</TokenEditProvider>
)

export default connect(
	(state) => {
		const tokenState = state?.token;
		const wallet = state?.wallet;
		return {
			tokens: tokenState.tokens,
			user: wallet?.private,
			unit: wallet?.public?.unit || unitTypes.METRIC.key,
			operatorPricingPlan: wallet?.operatorPricingPlan
		};
	},
	(dispatch) => {
		return {
			addToken: (token) => {
				return dispatch(createToken(token));
			},
			cleanTokens: () => {
				dispatch(clear());
			},
		};
	}
)(AddTokenContext);
