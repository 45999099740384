import { Box, InputBase } from "@mui/material";
import { useState } from "react";
import './digitsField.scss';

const DigitsField = (props) => {
	const { digitsLength = 7, onChange } = props;
	const [value, setValue] = useState('');

	const onChangeHandle = (event) => {
		const target = event.target;
		let value = target.value;

		if (value.length > digitsLength) {
			value = value.substring(0, digitsLength);
		}

		setValue(value)
		typeof onChange === 'function' && onChange(value);
	};

	return (
		<Box className="digits-row">
			<Box className="digits-row-boxes">
				{[...Array(digitsLength).keys()].map((idx) => (
					<Box
						key={`verification-digit-${idx}`}
						className="digits-row-digit"
					>{value.split('')[idx] || ' '}</Box>
				))}
			</Box>
			<InputBase
				name="verify-digit"
				key={`verification-digits`}
				value={value}
				onChange={onChangeHandle}
				inputProps={{ min: 0, max: 9 }}
			/>
		</Box>
	)
}

export default DigitsField;