import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Avatar,
	Box,
	Card,
	CardContent,
	FormControl,
	IconButton,
	InputBase,
	InputLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	NativeSelect,
	Skeleton,
	Typography,
} from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link as AppLink } from "react-router-dom";
import Header from "../../components/Header";
import { useGetHighlightAuctionsQuery, useSearchTokenQuery } from "../../service/api/crappyApi";
import { useDebounce } from "../../utils/hooks";
import TokenSearchDetail from "./TokenSearchDetail";
import { PlatformLayout as Layout } from "../../layout";

import "./TokenSearch.scss";

const SkeletonItem = () => {
	return (
		<ListItem sx={{ pl: 0, pr: 0, mb: 1 }} alignItems="flex-start">
			<Skeleton sx={{ transform: "none" }} width={70} height={70}></Skeleton>
			<div className="w--100" style={{ paddingLeft: "0.68em" }}>
				<Skeleton width="100%" sx={{ mb: 1, transform: "none" }} height={30}></Skeleton>
				<Skeleton width="100%" sx={{ transform: "none" }} height={12}></Skeleton>
			</div>
		</ListItem>
	);
};

const ResultItem = ({ data, group }) => {
	const { img, title, url, date } = data;
	return (
		<ListItem sx={{ pl: 0, pr: 0, mb: 1 }} alignItems="flex-start">
			<ListItemAvatar>
				<AppLink to={`/token/search/${url}`}>
					<Avatar sx={{ width: 70, height: 70 }} variant="square" alt={title} src={img} />
				</AppLink>
			</ListItemAvatar>
			<ListItemText
				sx={{ ml: 1 }}
				primary={
					<AppLink to={`/token/search/${!!group && group === "highlight" ? "auction/" : ""}${url}`}>
						{title}
					</AppLink>
				}
				secondary={
					<AppLink to={`/token/search/${url}`}>
						<Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
							<strong>{date}</strong>
						</Typography>
					</AppLink>
				}
			/>
		</ListItem>
	);
};

const HighlightAuctions = (props) => {
	const { isFetching, data } = useGetHighlightAuctionsQuery();

	let items = !!data && data.status === "success" && !!data?.result?.data ? data.result.data : false;

	return (
		<>
			{!!isFetching ? (
				<List>
					<SkeletonItem />
					<SkeletonItem />
					<SkeletonItem />
					<SkeletonItem />
				</List>
			) : !!items && items.length > 0 ? (
				<List>
					{items.map((item, idx) => {
						let itemData = {
							...item,
							url: item.url.replace('./detail/', '')
						}
						return <ResultItem group="highlight" data={itemData} key={`token-search-${item.id || idx}`} />;
					})}
				</List>
			) : (
				<Box className="flex flex--vertical flex--grow flex--algin-center flex--justify-center align--center">
					<FontAwesomeIcon style={{ fontSize: "4em", color: "rgba(0,0,0,0.3)" }} icon={regular("keyboard")} />
					<Typography variant="h5">There are no results that match your search.</Typography>
					<Typography variant="body2">Please try adjusting your search keywords or filters.</Typography>
				</Box>
			)}
		</>
	);
};

const SearchResult = ({ query, country = "", type = "search", detail = "" }) => {
	const { data, isFetching, isUninitialized } = useSearchTokenQuery(
		{
			type: type,
			detail: detail,
			query: query,
			country: country,
			paging: 1,
		},
		{
			skip: type === "search" && (!query || query.length <= 2) && !country,
		}
	);

	let items = !!data && data.status === "success" && !!data?.result?.data ? data.result.data : false;

	if (!!isUninitialized) {
		return (
			<>
				<Typography variant="subtitle1">Auction Highlights:</Typography>
				<HighlightAuctions />
			</>
		);
	}
	return (
		<>
			{!!query && query.length > 2 && (
				<Typography variant="subtitle1">
					Tokens for{" "}
					<Typography component="span" variant="subtitle2">
						{query}
					</Typography>
					:
				</Typography>
			)}
			{!!isFetching ? (
				<List>
					<SkeletonItem />
					<SkeletonItem />
					<SkeletonItem />
					<SkeletonItem />
				</List>
			) : !!items && items.length > 0 ? (
				<List>
					{items.map((item, idx) => (
						<ResultItem data={item} key={`token-search-${item.id || idx}`} />
					))}
				</List>
			) : (
				<Box className="flex flex--vertical flex--grow flex--algin-center flex--justify-center align--center">
					<FontAwesomeIcon style={{ fontSize: "4em", color: "rgba(0,0,0,0.3)" }} icon={regular("keyboard")} />
					<Typography variant="h5">There are no results that match your search.</Typography>
					<Typography variant="body2">Please try adjusting your search keywords or filters.</Typography>
				</Box>
			)}
		</>
	);
};

const SearchForm = (props) => {
	const { defaultValue, onSubmit } = props;
	const [query, setQuery] = useState(defaultValue);
	const [country, setCountry] = useState("worldwide");
	const [page, setPage] = useState(1);
	const navigate = useNavigate();
	// const debouncedQuery = useDebounce(query, 500);
	const countries = t("pages:search.countries", { returnObjects: true });

	const searchToken = (event) => {
		event.preventDefault();
		onSubmit({
			query: query,
			country: !country || country === "worldwide" ? "" : country,
			page: page,
			type: "search",
		});
		navigate(`/token/search`, { replace: true })
	};

	const onChangeHandle = (event) => {
		setQuery(event.target.value);
	};

	const handleChangeCountry = (event) => {
		setCountry(event.target.value);
		onSubmit({
			query: query,
			country: event.target.value,
			page: page,
			type: "search",
		});
	};

	return (
		<Card sx={{ mb: 2, pt: 1, pb: 1 }} >
			<CardContent>
				<Box component="form" onSubmit={searchToken} className="flex flex--wrap search__form">
					<FormControl
						className="flex--grow"
						sx={{
							border: "1px solid var(--unikbase-gray)",
							borderRadius: "4px",
							flexDirection: "row",
							mr: 1,
						}}
					>
						<InputBase
							sx={{ ml: 1, flex: 1, p: "4px 8px" }}
							placeholder="Search Tokens"
							inputProps={{ "aria-label": "Seach Tokens" }}
							className="w--100"
							onChange={onChangeHandle}
							id="search-query-field"
							defaultValue={defaultValue}
						/>
						<IconButton
							color="secondary"
							type="button"
							sx={{ p: "10px", fontSize: "0.68em" }}
							aria-label="search"
						>
							<FontAwesomeIcon icon={solid("search")} />
						</IconButton>
					</FormControl>

					<FormControl
						sx={{
							width: "20ch",
						}}
						className="search__field--select"
					>
						<InputLabel id="select-country-label">{t("common:country")}</InputLabel>
						<NativeSelect
							id="select-country"
							value={country}
							label="Country"
							onChange={handleChangeCountry}
							size="small"
							variant="outlined"
							input={<InputBase />}
						>
							{countries.map((item) => (
								<option key={`filter-country-${item.value}`} value={item.value}>
									{item.label}
								</option>
							))}
						</NativeSelect>
					</FormControl>
				</Box>
			</CardContent>
		</Card>
	);
};

const TokenSearch = (props) => {
	const location = useLocation();
	const [query, setSearchQuery] = useState({
		query: "",
		country: "",
		page: 1,
	});

	const searchPath = location.pathname.match(/\/token\/search\/(detail|auction)\/(.+)/);
	const [type, setSearchType] = useState(null);

	const onSearchHandle = (searchQuery) => {
		setSearchQuery(searchQuery);
		setSearchType(null);
	};

	useEffect(() => {
		if ( !searchPath ) {
			setSearchType(null)
		}
		if ( searchPath && searchPath[2] && (!type ||  searchPath[2] !== type[2]) ) {
			setSearchType(searchPath)
		}
	}, [searchPath])

	return (
		<Layout className="site single token-search" pageTitle={`Search`}>
			{type && type[1] === "detail" ? (
				<TokenSearchDetail type={type[1]} detail={type[2]} />
			) : (
				<Box className="section">
					<SearchForm onSubmit={onSearchHandle} default={query.default} />

					<Card sx={{ mb: 2, pt: 1, pb: 1 }}>
						<CardContent sx={{ minHeight: "500px" }} className="flex flex--vertical">
							<SearchResult
								query={query.query}
								page={query.page}
								country={query.country}
								type={type ? type[1] : "search"}
								detail={type ? type[2] : ""}
							/>
						</CardContent>
					</Card>
				</Box>
			)}
		</Layout>
	);
};

export default TokenSearch;
