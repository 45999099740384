import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material';
import { PlatformLayout } from '../../layout';
import { FormField } from '../../components/Form/FormField'
import { toast } from 'react-toastify';


const keys = {
  path: true,
  name: true,
  filename: true,
  mimeType: true,
  fileUrl: true,
}

const Documents2Json = () => {
  const [formData, setData] = useState<any>({
    path: 'coverImage',
    name: '',
    filename: '',
    mimeType: 'image/png',
    fileUrl: '',
  });
  const [json, setJson] = useState<string>('')

  const handleChange = (event: any, value: string, id: string) => {
    setData({ ...formData, [id]: value })
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    setJson(JSON.stringify([formData]))
  }

  return (
    <PlatformLayout
      hideSidebar={true}
      standalone={true}
      pageTitle={'CSV Documents to JSON'}
      className="site single token-single"
    >
      <form onSubmit={handleSubmit}>
        {Object.keys(keys).map((key: any) => (
          <FormField
            fieldId={`${key}`}
            value={formData[key]}
            onChange={(evt: any) => handleChange(evt, evt.target.value, key)}
            label={`Input ${key}`}
            fullWidth={true}
          />
        ))}
        <Button className='w--100' type='submit' variant='contained' color='secondary'>Generate</Button>
        {!!json &&
          <>
            <Typography component="h3" variant='body1' my={2}>Copy and paste the following into documents column in csv file</Typography>

            <Button
              type='button'
              variant='contained'
              color='secondary'
              onClick={() => {
                navigator.clipboard.writeText(json)
                toast.success('Copied to clipboard')
              }}
            >Copy</Button>

            <Box mt={3}>
              <pre style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>{json}</pre>
            </Box>
          </>
        }
      </form>
    </PlatformLayout>
  )
}

export default Documents2Json
