import { useEffect, useState } from "react";
import { connect } from "react-redux"
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import { updateWallet } from "../../store/actions/walletActions";
import i18next, { t } from "i18next";

const LanguageSetting = props => {
  const { language, isUpdating, updateLanguage } = props;
  const [active, setActive] = useState('en');

  const changeLanguage = event => {
    const newLang = event.target.value;
    if ( ['en','fr'].indexOf(newLang) < 0 ) {
      return;
    }
    setActive(newLang);
    i18next.changeLanguage(newLang);
    updateLanguage(newLang);
  }

  useEffect(() => {
    if (language) setActive(language);
  }, [language])
  
  return (
    <FormControl disabled={isUpdating==='loading'}>
      <FormLabel id="language-setting-label">{t("pages:profile.language")}</FormLabel>
      <RadioGroup
        aria-labelledby="language-setting"
        name="language-setting"
        value={active}
        onChange={changeLanguage}
      >
        <FormControlLabel value="en" control={<Radio />} label="English" />
        <FormControlLabel value="fr" control={<Radio />} label="French" />
      </RadioGroup>
    </FormControl>
  )
}

export default connect(state => {
  const walletState = state?.wallet
  return {
    language: walletState?.public?.language,
    isUpdating: walletState?.isUpdating
  }
}, dispatch => {
  return {
    updateLanguage: (lang) => {
      dispatch(updateWallet({
        public: {
          language: lang
        }
      }))
    }
  }
})(LanguageSetting);