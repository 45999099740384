import React, { useEffect, useRef, useState } from 'react'
import cv from "@techstark/opencv-js";
import { DragDropUpload } from '../../../components/Form';
import { Typography } from '@mui/material';
import { standPost } from '../Standalone';
import measureBlur from './measureBlur';

const THRESHOLD = 42;

const OpenCVBlurImageDetector = () => {
  const imageRef = useRef<any>(null);
  const [result, setResult] = useState('');
  const [trigger, setTrigger] = useState(0);
  const [queuedImages, setQueuedImages] = useState<Array<any>>([]);

  useEffect(() => {
    standPost({ action: 'loaded' })
    window.blurImageAnalyze = (data: any) => {
      const base64Images = JSON.parse(data);
      imageRef.current.src = base64Images[0];
      if (base64Images.length > 1) {
        setQueuedImages(base64Images.slice(1))
      }
      setTrigger(Date.now())
    }
  }, []);

  useEffect(() => {
    if (trigger > 0) {
      detectBlur();
    }
  }, [trigger]);

  const onNewFiles = (files: any) => {
    imageRef.current.src = files[0];

    if (FileReader && files && files.length) {
      var fr = new FileReader();
      fr.onload = function () {
        imageRef.current.src = fr.result;
        setTrigger(Date.now())
      }
      fr.readAsDataURL(files[0]);
    }
  }

  const detectBlur = () => {
    let width = 600;
    let height = width * imageRef.current.height / imageRef.current.width;

    let imgData
    const bigCanvas = imageRef.current;

    if (width < bigCanvas.width) {
      var smallCanvas = document.createElement('canvas');
      smallCanvas.width = width;
      smallCanvas.height = height;

      const smallContext = smallCanvas.getContext("2d");
      if (!smallContext) return;

      const scale = width / bigCanvas.width;
      smallContext.scale(scale, scale);
      smallContext.drawImage(bigCanvas, 0, 0);

      imgData = smallContext.getImageData(0, 0, width, height);
    } else {
      let src = cv.imread(imageRef.current);

      imgData = new ImageData(new Uint8ClampedArray(src.data), src.cols, src.rows);
      src.delete();
    }
    const score = measureBlur(imgData)

    standPost({
      action: 'success',
      data: {
        score,
        isBlur: score.avg_edge_width_perc > 0.94
      }
    })

  }

  const detectBlurLaplacian = () => {
    let src = cv.imread(imageRef.current);
    let dst = new cv.Mat();
    let men = new cv.Mat();
    let menO = new cv.Mat();

    cv.cvtColor(src, src, cv.COLOR_RGBA2GRAY, 0);
    cv.Laplacian(src, dst, cv.CV_64F, 1, 1, 0, cv.BORDER_DEFAULT);

    cv.meanStdDev(dst, menO, men)
    console.log(menO.data64F[0], men.data64F[0]);

    if (men.data64F[0] > THRESHOLD) {
      setResult('Image is NOT BLUR');
      if (queuedImages?.length > 0) {
        imageRef.current.src = queuedImages[0];
        setQueuedImages(queuedImages.slice(1))
        setTrigger(Date.now())
      }
    } else {
      setResult('Image is BLUR');
    }

    standPost({
      action: 'success',
      data: {
        score: men.data64F[0],
        isBlur: men.data64F[0] < THRESHOLD
      }
    })

    src.delete();
    dst.delete();
  }

  return (
    <div>
      <DragDropUpload onChange={onNewFiles} fileType="photo" multiple={false} />
      <Typography variant="h6" component="p" gutterBottom>{result}</Typography>
      <img ref={imageRef} src="#" alt="preview" style={{ display: "block", width: "auto" }} ></img>
    </div>
  )
}

export default OpenCVBlurImageDetector
