import React, { useMemo, useState } from 'react'
import { Autocomplete, Box, Button, Menu, MenuItem, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import './DocumentFilter.scss'
import DateRangeFilterItem from '../Filter/FilterMenu/DateRangeFilterItem'
import { documentTypes } from '../../configs/constant'

interface IDocumentFilter {
  anchorEl: any;
  open: boolean;
  onClose: () => void;
  onChange: (filters: any) => void;
}

const DocumentFilter = ({ anchorEl, open, onClose, onChange }: IDocumentFilter) => {
  const [key, setKey] = useState<number>(0);
  const types: any = documentTypes();

  const initialFilter = useMemo(() => ({
    date: [],
    type: null,
  }), []);

  const [filters, setFilters] = useState<any>({ ...initialFilter })

  const handleChange = (key: string, value: any) => {
    filters[key] = value;
    setFilters({ ...filters });
  }

  const onReset = () => {
    setFilters({ ...initialFilter })
    onChange({ ...initialFilter })
    setKey(key + 1);
    onClose();
  }

  const onApply = () => {
    onChange(filters);
    onClose();
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      className={`token-filter-menu__documents`}
    >
      <MenuItem key="heading" onKeyDown={(e) => e.stopPropagation()}>
        <Box
          width={300}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant='body2'>{t('pages:token.filter')}</Typography>
          <Button
            variant='text'
            onClick={onReset}
          >
            <Typography
              variant='body2'
              color="GrayText"
              sx={{ textDecoration: 'underline' }}
              textTransform="capitalize">
              {t('common:reset')}
            </Typography>
          </Button>
        </Box>
      </MenuItem>
      <MenuItem key="date-range" onKeyDown={(e) => e.stopPropagation()}>
        <Box className="w--100">
          <DateRangeFilterItem
            key={`filter-date-${key}`}
            label={t('common:date')}
            value={filters.date?.length > 0 ? filters.date[0] : null}
            onChange={(start: Date, end: Date) => {
              let items;
              if (start && end) {
                items = [{ startDate: start.getTime(), endDate: end.getTime() }]
              }
              handleChange('date', items);
            }}
          />
        </Box>
      </MenuItem>
      <MenuItem key="document-type" onKeyDown={(e) => e.stopPropagation()}>
        <Box className="w--100">
          <Typography variant='body2'>{t('pages:token.document_details.type')}</Typography>
          <Autocomplete
            key={`filter-type-${key}`}
            fullWidth
            limitTags={1}
            options={Object.values(types)}
            getOptionLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, value) => option.path === value.path}
            size="small"
            value={filters.type ? types[filters.type] : null}
            onChange={(event, newValue: any) => {
              handleChange('type', newValue?.path);
            }}
            renderInput={(params) => <TextField
              {...params}
              placeholder={`${t('pages:token.search_or_select_')}`}
              size="small"
            />
            }
          />
        </Box>
      </MenuItem>

      <MenuItem key="actions">
        <Button
          variant='contained'
          color='secondary'
          fullWidth
          onClick={onApply}
        >
          <Typography
            variant='body2'
            textTransform="capitalize">
            {t('common:apply')}
          </Typography>
        </Button>
      </MenuItem>
    </Menu>
  )
}

export default DocumentFilter
