import { connect } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../Loader";
import { removeOnceMnemonic } from "../../store/slices/walletSlice";

const UserVerificationChecker = (props) => {
	const { children, walletLoading, walletPrivateData, seedPhraseStored } = props;
	const location = useLocation();
	const [searchParams] = useSearchParams();

	// With the links start with verification, this check will show the page check the verfication fields
	if (location.pathname.startsWith("/verification")) {
		return children;
	}

	if (walletLoading === "success") {
		if (
			(
				!seedPhraseStored || !seedPhraseStored[walletPrivateData?.email?.address]
			) &&
			!walletPrivateData?.email?.verified &&
			!!walletPrivateData?.email?.address  && 
			!walletPrivateData?.phone?.verified &&
			!walletPrivateData?.phone?.number
		) {
			return <Loader navigate="/verification/seedPhrase" />;
		}

		if (!!walletPrivateData?.email?.address && !walletPrivateData?.email?.verified) {
			return <Loader navigate="/verification/email" />;
		}
		if (!!walletPrivateData?.phone?.number && !walletPrivateData?.phone?.verified) {
			return <Loader navigate="/verification/phone" />;
		}
	}

	if (typeof walletLoading === "undefined" || walletLoading === "idle") {
		return <Loader />;
	}

	return children;
};

export default connect(
	(state) => {
		return {
			seedPhraseStored: state?.generalSettings.seedPhraseStored,
			walletLoading: state?.wallet?.status,
			walletPrivateData: state?.wallet?.private,
		};
	},
	(dispatch) => {
		return {
			removeMnemonic: () => {
				dispatch(removeOnceMnemonic());
			},
		};
	}
)(UserVerificationChecker);
