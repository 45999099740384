import { Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import CountDown from "../../components/Form/CountDown";
import { getOTPStatus, requestOTP } from "../../store/actions/walletActions";
import { useTraceUpdate } from "../../utils/hooks";
import { resetOTP } from "../../store/slices/walletSlice";
import { useGetUserOTP } from "../../hooks/userHooks";

const OTPChecker = (props) => {
	const { type, OTP, statusOTP, walletStatus, checkOTP, requestOTP, resetOTPChecking, requests, user_email, user_phone } = props;
	const { isTooManyAttempts, nextAttempt } = useGetUserOTP({
		email: user_email,
		phone: user_phone
	});
	const limitAttempTime = 2 * 60 * 60 * 1000; // 2 hours
	const limitResendTime = 60 * 1000; // 60 seconds
	// const isTooManyAttempts = (!!OTP.attempts && OTP.attempts >= 5);
	// const nextAttempt = isTooManyAttempts ? parseInt(OTP.lastAttemptDate || OTP.creationDate) + limitAttempTime : 0;

	const [resend, showResend] = useState(requests[type] !== false && requests[type] + limitResendTime < new Date().getTime());

	const requestOTPHandle = (event = false) => {
		!!event && event.preventDefault();
		if ( !!isTooManyAttempts ) return;
		// const current = new Date().getTime();
		// if ( current <= requests[type] + limitResendTime ) return;

		// requestOTP(type);
		// showResend(false);
	};

	// useEffect(() => {
	// 	// When ever wallet update data itself, recheck OTP
	// 	walletStatus === "success" && checkOTP(type)
	// }, [walletStatus, checkOTP, type]);

	// useEffect(() => {
	// 	// If not sending out any requestOTP= false, (!!requests[type] && requests[type] + 60*1000 < current));
	// 	if (!isTooManyAttempts && statusOTP === 'success' && requests[type] === false ) {
	// 		console.log(requests)
	// 		requestOTP(type);
	// 	}
	// }, [requests, statusOTP, requestOTP, type, limitResendTime, isTooManyAttempts])

	// useTraceUpdate({ requests, statusOTP, requestOTP, type, limitResendTime, isTooManyAttempts });


	return (
		<Box component="div">
			{/* {isTooManyAttempts ? (
				<Typography variant="body2" color="error">
					{t("pages:verification.exceeded_attempts")}&nbsp;
					<CountDown availableTill={nextAttempt} />
				</Typography>
			) :  
				requests[type] !== false && requests[type] !== 1 && 
				<Typography component="div" variant="body2">
					{t("pages:verification.not_receive_otp")}&nbsp;
					{!!resend ? (
						<Link color="secondary" type="button" onClick={requestOTPHandle}>
							{t("pages:verification.resend_button")}
						</Link>
					) : (
						<Typography variant="body2" component="span" color="secondary">
							<CountDown
								text={t("pages:verification.resend_label")}
								hideMinute={true}
								hideHour={true}
								availableTill={requests[type] + limitResendTime}
								onSuccess={() => console}
							/>
						</Typography>
					)}
				</Typography>
			} */}
		</Box>
	);
};

export default connect(
	(state) => {
		const walletState = state.wallet;
		return {
			walletStatus: walletState.status,
			user_email: walletState?.private?.email.address,
			user_phone: walletState?.private?.phone.number,
			OTP: walletState.otp,
			statusOTP: walletState.otpChecking,
			requests: walletState.otpRequests
		};
	},
	(dispatch) => {
		return {
			requestOTP: (type) => {
				dispatch(requestOTP({ type }));
			},
			checkOTP: (type) => {
				dispatch(getOTPStatus({ type }));
			},
			resetOTPChecking: (status) => {
				dispatch(resetOTP({ status }))
			}
		};
	}
)(OTPChecker);