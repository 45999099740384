import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumbs, Typography } from '@mui/material'
import { NavigateNext as NavigateNextIcon } from '@mui/icons-material'

interface IBreadcrumbs {
  paths?: Array<{ label: string, to?: string }>
}

const $link = {
  color: '#000',
  textDecoration: 'underline',
}

const BreadcrumbNavi = ({ paths }: IBreadcrumbs) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {paths?.map((path: any, index: number) => (
        path.to
          ? <Link key={`breadcrumb-${index}`} style={$link} color="text.primary" to={path.to}>{path.label}</Link>
          : <Typography key={`breadcrumb-${index}`} color="text.gray">{path.label}</Typography>
      ))
      }
    </Breadcrumbs>
  )
}

export default BreadcrumbNavi
