import React, { useEffect, useState } from "react";
import { Box, Button, CardMedia, IconButton, Typography, useMediaQuery } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import "../ShareToken/ShareToken.scss";
import TokenPrivacyTable from "./TokenPrivacyTable";
import { TokenPrivacyProvider } from "./TokenPrivacyContext";
import TokenPrivacySubmit from "./TokenPrivacySubmit";
import { t } from "i18next";
import { useTheme } from "@emotion/react";
import { connect } from "react-redux";
import { meoveoRestApi } from '../../../service/api/meveoApi';
import { standPost } from "../../../pages/Standalone/Standalone";
import { MobileScrollbar } from "../../CustomScrollbar";
import ShareTokenHeadInfo from '../ShareToken/ShareTokenHeadInfo';

const TokenPrivacy = (props: any) => {
  const { token, currentUserEmail, standalone } = props;
  const [_token, setToken] = useState(token);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpenModal(false);
    if (standalone) {
      standPost({ action: 'cancel' });
    }
  };

  const _onShare = (url: any) => {
    if (standalone) {
      standPost({ action: 'shareUrl', url });
    }
  }

  useEffect(() => {
    if (standalone) {
      setOpenModal(true);
    }
    window.addEventListener("message", messageHandler);
    document.addEventListener("message", messageHandler);
  }, [standalone]);

  const messageHandler = (message: any) => {
    try {
      const data = JSON.parse(message.data);
      if (data.action === 'token.PrivacySettings') {
        setOpenModal(true);
      } else if (data.action === 'token.GenerateCertificate'
        || data.action === 'token.EditToken'
        || data.action === 'token.TransferToken'
        || data.action === 'token.ShareToken') {
        setOpenModal(false);
      }
    } catch (e) {
    }
  }

  const [getTokenById] = meoveoRestApi.endpoints.getTokenById.useLazyQuery();
  const getTokenUpdate = async () => {
    const { data } = await getTokenById({ id: token.token.uuid, mode: 'default', openModal });
    const _token = Object.assign({}, token, { documents: data?.result?.documents || token.documents }, { privacySettings: data?.result?.privacySettings });
    setToken(_token);
  }

  useEffect(() => {
    if (openModal) {
      getTokenUpdate();
    }
  }, [openModal]);


  const isTransferred = !!currentUserEmail && token?.client && !!token?.client?.email && currentUserEmail !== token?.client?.email;
  if (!!isTransferred) {
    return null;
  }
  return (
    <>
      {!standalone && <Button
        className="token-privacy__button"
        color="secondary"
        variant="text"
        onClick={() => setOpenModal(!openModal)}
        aria-label="privacy"
        title="token privacy settings"
      >
        <span className="icon icon__action icon--document">_</span>
        <Typography component="span" variant="body2">
          {t("pages:token.public_info")}
        </Typography>
      </Button>}
      <Dialog className="token__share dialog" open={openModal} onClose={handleClose} fullScreen={fullScreen}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" fontWeight="500">{t("pages:token.privacy_settings.title")}</Typography>
            <IconButton className="confirm-dialog__close" aria-label="close" onClick={handleClose}>
              <span className="icon icon--close"></span>
            </IconButton>
          </Box>
        </DialogTitle>

        <TokenPrivacyProvider>
          <MobileScrollbar>
            <DialogContent className="token__share--main">
              <ShareTokenHeadInfo token={_token} />
              <TokenPrivacyTable token={_token} />
            </DialogContent>
          </MobileScrollbar>
          <DialogActions
            sx={{ m: "0 auto" }}
            className="flex flex--vertical align--center flex--justify-center"
          >
            <TokenPrivacySubmit token={_token} standalone={standalone} onShare={_onShare} onSaved={getTokenUpdate} />
          </DialogActions>
        </TokenPrivacyProvider>
      </Dialog>
    </>
  );
};
export default connect(state => {
  const wallet = state?.wallet;
  return {
    currentUserEmail: wallet?.private?.email?.address,
  }
})(TokenPrivacy);
