import { t } from "i18next";
import PageTabsLayout from "../../layout/Pages/PageTabsLayout"
import TermsConditions from "./TermsConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import FAQs from "./FAQs";
import Help from "./Help";

const Others = () => {
	const configs = [
		{
			title: t('pages:terms_conditions.page_title'),
			element: <TermsConditions />,
			icon: 'terms-conditions',
			path: 'terms-conditions'	
		},
		{
			title: t('pages:privacy_policy.page_title'),
			element: <PrivacyPolicy />,
			icon: 'privacy-policies',
			path: 'privacy-policies'	
		},
		{
			menu_title: t('pages:faqs.menu_title'),
			title: t('pages:faqs.page_title'),
			element: <FAQs />,
			icon: 'faqs',
			path: 'faqs'	
		},
		// {
		// 	menu_title: t('pages:help.menu_title'),
		// 	title: t('pages:help.page_title'),
		// 	element: <Help />,
		// 	icon: 'help',
		// 	path: 'help'	
		// }
	]
	return (
		<PageTabsLayout pages={configs} />
	)
}

export default Others;