import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material"
import { t } from "i18next"
import { formatDate } from "../../../utils/helpers"
import { ShowField } from '../UpdateToken'
import { useTokenEdit, useTokenEditDispatch } from "./EditTokenContext";
import { FormField, validFormField } from "../../Form/FormField";

interface IEditToken {
	token: any,
	unit: any
	publicInfo?: any
}

const EditTokenSpecifications = ({ token, unit, publicInfo }: IEditToken) => {
	const container = useRef();
	const dispatch = useTokenEditDispatch();
	const tokenUpdate: any = useTokenEdit();
	const [errors, setErrors] = useState<any>({});

	useEffect(() => {
		const resizeHandler = () => {
			if (!container.current) return;
			const wrapper = container.current.querySelector('.attributes');
			const attributes = wrapper.querySelectorAll('.attribute');
			if (!attributes) return;

			let enoughWidth = true;
			attributes.forEach((item: any) => {
				let width = item.offsetWidth - 9;
				let avatar = item.querySelector('.avatar-with-icon')
				let content = item.querySelector('.content')
				enoughWidth = enoughWidth && (avatar && content && avatar.offsetWidth + content.offsetWidth < width)
			})
			if (enoughWidth) {
				wrapper.classList.remove('mobile-view')
			} else {
				wrapper.classList.add('mobile-view')
			}
		}
		resizeHandler();
		window.addEventListener('resize', resizeHandler);
		return () => {
			window.removeEventListener('resize', resizeHandler);
		}
	}, [])

	const isNumberType = (fieldId: string) => ['value', 'lengthInMeter', 'depthInMeter', 'widthInMeter', 'weightInKilo'].includes(fieldId);

	const locale = publicInfo?.language || 'en';
	const floatingPoint = locale === 'fr' ? ',' : '.';

	const handleChange = (event: any, value: string, id: string) => {
		let fieldId = !!event ? event?.target?.name : id;
		let fieldValue = !!event ? event?.target?.value : value;

		if (!fieldId) return;

		if (floatingPoint === ',') {
			fieldValue = fieldValue.replace('.', '').replace(',', '.');
		}

		if (isNumberType(fieldId)) {
			fieldValue = fieldValue.replace(/[^\d\.]/g, '');
			fieldValue = !fieldValue ? 0.0 : parseFloat(fieldValue);
		}

		const formData: any = formDataField(fieldId);
		const fieldErrors = validFormField(fieldId, fieldValue, formData);
		setErrors({
			...errors,
			[fieldId]: fieldErrors,
		});

		dispatch({
			type: 'update_field',
			data: {
				key: fieldId,
				value: fieldValue
			}
		})
	}

	const formDataField = (fieldId: string) => {
		const fieldValue = tokenUpdate[fieldId];

		const formData: any = {
			[fieldId]: {
				id: fieldId,
				label: '',
				errors: errors[fieldId] || [],
				valid: [],
				value: fieldValue,
				type: isNumberType(fieldId) ? 'number' : 'text',
			}
		};

		return formData;
	}

	return (
		<Card ref={container} className="specifications" variant="outlined">
			<CardContent sx={{ p: 0, pb: 0 }}>
				<Grid container spacing={2}>
					<Grid item xs={6} sm={6} md={6}>
						<Typography component="span" variant="body2">
							{t("pages:token.token_origin")}
						</Typography>
						<FormField
							fieldId={t("pages:token.token_origin")}
							value={t("pages:token.token_origin_value")}
							label={''}
							fullWidth={true}
							disabled={true}
						/>
					</Grid>
					<Grid item xs={6} sm={6} md={6}>
						<Typography component="span" variant="body2">
							{t("pages:token.mark_reference")}
						</Typography>
						<ShowField formData={formDataField('tokenMarkReference')} id="tokenMarkReference" handleChange={handleChange} />
					</Grid>
					<Grid item xs={6} sm={6} md={6}>
						<Typography component="span" variant="body2">
							{t("pages:token.creation_date")}
						</Typography>
						<FormField
							fieldId={t("pages:token.creation_date")}
							value={formatDate(token.token.creationDate)}
							label={''}
							fullWidth={true}
							disabled={true}
						/>
					</Grid>
					<Grid item xs={6} sm={6} md={6}>
						<Typography component="span" variant="body2">
							{t("pages:token.price")}
						</Typography>
						<Box className="input_price" display="flex" flexDirection="row" alignItems="center" py={`0!important`}>
							<Typography variant="body2" fontWeight="400" p={1}>$</Typography>
							<ShowField numeric formData={formDataField('value')} id="value" handleChange={handleChange} />
						</Box>
					</Grid>
				</Grid>
				<Grid container spacing={2} className="attributes">
					<Grid item xs={6} sm={6} md={6} className="attribute">
						<Typography variant="body">
							{t("pages:token.length")}{'\n'}
						</Typography>
						<Box display="flex" flexDirection="row" alignItems="center" ml={`0 !important`}>
							<ShowField numeric formData={formDataField('lengthInMeter')} id="lengthInMeter" handleChange={handleChange} />
							<Typography className="unit" variant="body2" fontWeight="400" p={1}>cm</Typography>
						</Box>
					</Grid>
					<Grid item xs={6} sm={6} md={6} className="attribute">
						<Typography variant="body">
							{t("pages:token.weight")}{'\n'}
						</Typography>
						<Box display="flex" flexDirection="row" alignItems="center" ml={`0 !important`}>
							<ShowField numeric formData={formDataField('weightInKilo')} id="weightInKilo" handleChange={handleChange} />
							<Typography className="unit" variant="body2" fontWeight="400" p={1}>g</Typography>
						</Box>
					</Grid>
					<Grid item xs={6} sm={6} md={6} className="attribute">
						<Typography variant="body">
							{t("pages:token.width")}{'\n'}
						</Typography>
						<Box display="flex" flexDirection="row" alignItems="center" ml={`0 !important`}>
							<ShowField numeric formData={formDataField('widthInMeter')} id="widthInMeter" handleChange={handleChange} />
							<Typography className="unit" variant="body2" fontWeight="400" p={1}>cm</Typography>
						</Box>
					</Grid>
					<Grid item xs={6} sm={6} md={6} className="attribute">
						<Typography variant="body">
							{t("pages:token.depth")}{'\n'}
						</Typography>
						<Box display="flex" flexDirection="row" alignItems="center" ml={`0 !important`}>
							<ShowField numeric formData={formDataField('depthInMeter')} id="depthInMeter" handleChange={handleChange} />
							<Typography className="unit" variant="body2" fontWeight="400" p={1}>cm</Typography>
						</Box>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}

export default connect((state) => {
	const wallet = state?.wallet;
	return {
		publicInfo: wallet?.public,
	};
})(EditTokenSpecifications);
