import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import config from '../configs/config';
import { documentPathTypes } from '../configs/constant';
import { meoveoRestApi, useGetTokenByIdQuery, useGetTokenTransactionsQuery, useUpdateTokenMutation } from '../service/api/meveoApi';
import { useCreatePdfMutation } from '../service/api/pdfGeneratorApi';
import { useUploadDocumentsMutation } from '../service/api/uploadApi';
import { useNavigate, useParams } from 'react-router-dom';
import { logout } from '../store/slices/authSlice';
import store, { persistor } from '../store/rootReducer';
import { getTokenBlockchainExplorerURL } from './helpers';
import { useAddDocumentHashWithSmartContract } from '../hooks/smartContractHooks';


function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

function useUploadTokenDocuments(token) {
  const [updateToken, { isLoading, isSuccess }] = useUpdateTokenMutation();
	const [addDocumentHash] = useAddDocumentHashWithSmartContract({ token });

  const uploadDocuments = async (uploadedFiles) => {
    let requestData = {
      token: token.token,
      addedDocuments: [],
    };

    uploadedFiles.forEach((item) => {
      const time = dayjs().format('DD-MM-YYYY-HH-mm-ss');
      const filename = `certificate-${time}.pdf`;
      const url = `${config.IMGPROXY_SERVER_ADDRESS}insecure/raw:1/plain/s3://${config.SCW_BUCKET_NAME}/${item.file.key}`;
      const documentData = {};
      documentData.documentId = item.hash;
      documentData.path = documentPathTypes.CERTIFICATE;
      documentData.name = filename;
      documentData.filename = filename;
      documentData.mimeType = item.file.mimetype;
      documentData.size = item.file.size;
      documentData.fileUrl = url;
      requestData.addedDocuments.push(documentData);
    });

    if (requestData.addedDocuments.length > 0) {
			requestData.isUploadDocument = true;

      if (token.token.mintDate) {
        const transactionInfo = await addDocumentHash(token);
        requestData.transactionInfo = transactionInfo;
      }

      return new Promise((resolve) => {
        updateToken(requestData).unwrap()
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            resolve(error);
          })
      })
    }
  };

  return { uploadDocuments, isLoading, isSuccess };
}

function useGeneratePdfCertificate(token) {
	const [getTransactions, {isFetching: gettingTransactions }] = meoveoRestApi.endpoints.getTokenTransactions.useLazyQuery();
  const [getPdfCert, { isLoading: generatingCert }] = useCreatePdfMutation();
	const [getOperatorInfor, { isLoading: gettingOperator }] = meoveoRestApi.endpoints.getOperatorDetail.useLazyQuery();
  const [uploadFiles, { isLoading: uploadingCert }] =
    useUploadDocumentsMutation();
  const {
    uploadDocuments,
    isLoading: updatingToken,
    isSuccess,
  } = useUploadTokenDocuments(token);

  const isLoading = useMemo(() => {
    return uploadingCert || updatingToken || generatingCert || gettingTransactions;
  }, [uploadingCert, updatingToken, generatingCert, gettingTransactions]);

  const generatePdfCertificate = async (extra, selectedProps) => {
    const requestData = {
      ...token,
			token: {
				...token.token,
				blockchainUrl: getTokenBlockchainExplorerURL(token.token.uuid),
				publicUrl: `${window.location.origin}/token/public/${token.token.uuid}?t=${new Date().getTime()}`
			},
      selectedProps,
      ...extra,
      transactions: []
    };

    if(selectedProps.includes('token.history')) {
      const transactionsData = await getTransactions({
        tokenId: token.token.uuid
      });

      if (transactionsData.data.status === 'success' && transactionsData.data.result && transactionsData.data.result.length > 0) {
        requestData.transactions = transactionsData.data.result
      }
    }
    let documents = token.documents || [];
    requestData.documents = documents.filter(doc => doc.path === documentPathTypes.COVER_IMAGE || selectedProps.includes(`documents.${doc.path}.${doc.uuid}`) );
		// Prepare Operator
		const operatorCode = token.token.uuid.split('_')[0];
		if ( operatorCode ) {
			const info = await getOperatorInfor({
				operator_code: operatorCode
			}).unwrap();
			if ( info.status === 'success' && info.result ) {
				requestData.operator = {
					uuid: info.result.uuid,
					name: info.result.name || info.result.uuid,
					logo: info.result.logoUrl || '',
					stamp: info.result.stampUrl || '',
				};
			}
		}
    const pdfResult = await getPdfCert(requestData);

    if (pdfResult.error || !pdfResult.data) {
			return pdfResult?.error || {
				error: "Something wrong when geneate Certificate!",
				status: 'fail'
			}
		}

    const file_name = `certificate_${new Date().getTime()}.pdf`;
    const files = new FormData();
    files.append('file', pdfResult.data, file_name);

    const uploadResult = await uploadFiles({ 
			data: files,
			folder: token.token.uuid,
		});
    if (!uploadResult.error && uploadResult.data?.result?.files) {
      const result = await uploadDocuments(uploadResult.data.result.files);
      if (result.status === 'fail') {
        return result;
      }
    }
    return uploadResult;
  };

  return { generatePdfCertificate, isLoading, isSuccess };
}

function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

function useLogout() {
	const navigate = useNavigate();

	return (path = "/") => {
		store.dispatch(logout());
		persistor.purge();
		navigate(path, { replace : true })
	}
}

function usePollingTokenDetail() {
	const { id, mode = "default" } = useParams();
	const [skip, setSkip] = useState(1000);
	const [token, setToken] = useState(undefined);
	const polling = useRef(0);

	const { data, isFetching, isLoading, isError } = useGetTokenByIdQuery(
		{ id, mode },
		{
			pollingInterval: skip,
		}
	);

	useEffect(() => {
		if ( !isFetching ) {
			
			if ( !!data && data.status === 'success' && !!data.result ) {
				setToken(data.result);
				setSkip(0);
				return;
			} 
			if ( polling.current >= 3 ) {
				if ( !!data && (data.status === 'fail' || !data.result) ) {
					setToken(false);
				}
				setSkip(0);
			}
			polling.current += 1;
		}
	}, [isFetching, data]);

	return { token, isFetching, isLoading, isError };
}

export { 
	useDebounce, 
	useGeneratePdfCertificate, 
	useUploadTokenDocuments, 
	useTraceUpdate, 
	useLogout,
	usePollingTokenDetail
};
