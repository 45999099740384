import { useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardContent, CardHeader, IconButton, Modal, Typography } from "@mui/material";
import { t } from "i18next";
import { Carousel } from "react-responsive-carousel";
import ImageGallery from "react-image-gallery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { proxySrcReplace } from "../Gallery/ImageProxy";
import ZoomImage from "../ZoomImage/ZoomImage";

import "./TokenGallery.scss";

const TokenGallery = (props) => {
	const { coverImage = false, documents = [] } = props;
	const [fullscreen, setFullscreen] = useState(null);
	if (!coverImage && (!documents || documents.length <= 0)) {
		return null;
	}
	let images = [].concat(documents);
	if (!!coverImage && coverImage.fileUrl) {
		images.unshift(coverImage)
	}
	return (!fullscreen ? (
		<Card variant="outlined" className="gallery">
			<CardContent>
				<Carousel>
					{images.map((item) => {
						const proxySrc = proxySrcReplace(item?.fileUrl, 600, 400);
						return (
							<Box onClick={() => setFullscreen(item)} component="span" sx={{ backgroundImage: `url("${proxySrc}")` }} className="gallery__item" key={item.uuid}>
								<img style={{ display: 'none' }} src={proxySrc} alt="Token thumbnail" />
								<ZoomImage src={item?.fileUrl} srcSmall={proxySrc} alt={item.name} />
							</Box>
						);
					})}
				</Carousel>
			</CardContent>
		</Card>
	) : (
		<Modal open={!!fullscreen}>
			<Box className="gallery__viewer">
				<ImageGallery
					startIndex={images.findIndex((item) => item.uuid === fullscreen.uuid) || 0}
					items={images.map(item => ({
						original: item?.fileUrl,
						thumbnail: proxySrcReplace(item?.fileUrl, 600, 400)
					}))}
				/>
				<Box className="gallery__viewer__close">
					<IconButton
						className="gallery__close_button"
						aria-label="close"
						onClick={() => setFullscreen(null)}
					>
						<span className="icon icon--close"></span>
					</IconButton>
				</Box>
			</Box>
		</Modal>
	)
	);
};

export default TokenGallery;
