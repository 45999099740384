import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";

import {
	Alert,
	Box,
	FormControl,
	FormControlLabel,
	FormGroup,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Switch,
	Typography,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

// Import from authReducer
import { getAuthToken } from "../../store/actions/authActions";
import { useTranslation } from "react-i18next";
import { isValidMnemonic } from "../../service/crypto";
import { getWalletBySeedphrase } from "../../store/actions/walletActions";

const LoginBySeedPhrase = (props) => {
	const { t } = useTranslation();
	const { getWallet, status, error, user, getToken } = props;
	const isImporting = status === 'loading';

	const [formData, setFormData] = useState({
		username: "",
		password: "",
	});
	const [errors, setErrors] = useState(false);

	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const formValidate = () => {
		let checking = [];
		const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_\{\}\[\]\=\+])(?=.{8,})/);

		if (!formData.seedphrase || !isValidMnemonic(formData.seedphrase) ) {
			checking.push("seedphrase")
		}
		if (!formData.password || !passwordRegex.test(formData.password)) {
			checking.push("password");
		}
		if( formData.password !== formData.confirmPassword) {
			checking.push("confirmPassword");
		}

		return checking;
	};
	const submitHandle = async (event) => {
		event.preventDefault();
		const validation = formValidate();
		if (validation.length > 0) {
			setErrors(validation);
			return;
		}
		setErrors(false);
		
		getWallet(formData.seedphrase, formData.password);	
	};


	const handleChange = (event) => {
		let value = event.target.value;
		setFormData({
			...formData,
			[event.target.name]: value,
		});
	};

	const handleChangePassword = (event) => {
		let value = event.target.value;
		value = value.replace(/\s/g, "");
		setFormData({
			...formData,
			[event.target.name]: value,
		});
	};

	useEffect(() => {
		if (!!error && error.code) {
			setErrors([error.message]);
		}
	}, [error]);

	useEffect(() => {
		if( !!status && status === 'success' && !!user ) {
			getToken(user.username, user.password);
		}
	}, [status, user, getToken])

	return (
		<Box
			onSubmit={submitHandle}
			component="form"
			noValidate={false}
			autoComplete="off"
			className="flex flex--vertical"
			sx={{ p: 2, width: "100%", maxWidth: "600px" }}
		>
			<Typography
				sx={{ ml: "auto", mr: "auto", mb: 8 }}
				variant="subtitle"
				align="center"
				className="page-anchor"
			>
				<span>
					<FontAwesomeIcon icon={solid("user-check")} />
				</span>{" "}
				{t("pages:login.label_account_login_by_seedphrase")}
			</Typography>

			{!!errors && (
				<Alert severity="error" sx={{ mb: 1 }}>
					<ul>
						{errors.map((i, idx) => (
							<li key={`error-${idx}`}>{i}</li>
						))}
					</ul>
				</Alert>
			)}

			<fieldset className="flex flex--vertical" disabled={isImporting}>
				<Box className="form__fields flex flex--vertical" sx={{ mb: 2 }}>

					<FormControl sx={{ mb: 2 }} size="small" variant="outlined" fullWidth>
						<InputLabel htmlFor="field-password">{t("pages:login.label_seedphrase")}</InputLabel>
						<OutlinedInput
							id="field-seedphrase"
							type={showPassword ? "text" : "password"}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label=""
										onClick={handleClickShowPassword}
										edge="end"
										sx={{ fontSize: "1em" }}
									>
										<FontAwesomeIcon icon={solid("qrcode")} />
									</IconButton>
								</InputAdornment>
							}
							label={t("pages:login.label_seedphrase")}
							inputProps={{
								"aria-label": t("pages:login.label_seedphrase"),
								onChange: handleChange,
								value: formData.seedphrase,
								name: "seedphrase",
							}}
						/>
					</FormControl>
					
					<FormControl sx={{ mb: 2 }} size="small" variant="outlined" fullWidth>
						<InputLabel htmlFor="field-password">{t("pages:login.label_new_password")}</InputLabel>
						<OutlinedInput
							id="field-password"
							type={showPassword ? "text" : "password"}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										edge="end"
										sx={{ fontSize: "1em" }}
									>
										<FontAwesomeIcon icon={showPassword ? solid("eye-slash") : solid("eye")} />
									</IconButton>
								</InputAdornment>
							}
							label={t("pages:login.label_new_password")}
							inputProps={{
								"aria-label": t("pages:login.label_new_password"),
								onChange: handleChangePassword,
								value: formData.password,
								name: "password",
							}}
						/>
					</FormControl>

					<FormControl sx={{ mb: 2 }} size="small" variant="outlined" fullWidth>
						<InputLabel htmlFor="field-password">{t("pages:login.label_confirm_password")}</InputLabel>
						<OutlinedInput
							id="field-confirm-password"
							type={showPassword ? "text" : "password"}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										edge="end"
										sx={{ fontSize: "1em" }}
									>
										<FontAwesomeIcon icon={showPassword ? solid("eye-slash") : solid("eye")} />
									</IconButton>
								</InputAdornment>
							}
							label={t("pages:login.label_confirm_password")}
							inputProps={{
								"aria-label": "Confirm password",
								onChange: handleChangePassword,
								value: formData.confirmPassword,
								name: "confirmPassword",
							}}
						/>
						<Typography variant="body2" component="div" sx={{ p : 1}}>
							{t("pages:login.password_field_valid_message")}
						</Typography>
					</FormControl>

					<FormGroup>
      			<FormControlLabel control={<Switch />} label="Remember me" />
					</FormGroup>
				</Box>

				<LoadingButton loading={isImporting} sx={{ mb: 2, borderRadius: 6 }} variant="contained" type="submit" color="secondary">
					{t("pages:login.label_account_login_by_seedphrase_button")}
				</LoadingButton>
			</fieldset>
		</Box>
	);
};

export default connect(
	(state) => {
		let data = state?.wallet;
		return {
			status: data.importAccount.status,
			error: data.importAccount.error,
			user: data.importAccount.user
		};
	},
	(dispatch) => {
		return {
			getToken: (username, password) => {
				dispatch(
					getAuthToken({
						username,
						password,
					})
				);
			},
			getWallet: (seedphrase, password) => {
				dispatch(
					getWalletBySeedphrase({
						seedphrase,
						password
					})
				)
			}
		};
	}
)(LoginBySeedPhrase);
