import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { documentPathTypes, documentTypes } from "../../../configs/constant";
import { meoveoRestApi, useGetTokenByIdQuery } from "../../../service/api/meveoApi";
import TokenFilesGroup from "./TokenFilesGroup";
import { usePrivacy, usePrivacyDispatch } from "./TokenPrivacyContext";
import { t } from "i18next";

const TokenPrivacyTable = (props) => {
	const { token } = props;
	const dispatch = usePrivacyDispatch();
	const privacySettings: any = usePrivacy();
	const [isSelectedAll, setSelectedAll] = useState(true);

	useEffect(() => {
		if (token.privacySettings && token.documents) {
			dispatch({
				type: 'init_privacy_settings',
				data: {
					settings: {
						documents: token.privacySettings.documents,
						token: Object.assign({}, privacySettings.token, token.privacySettings.token),
					},
					documents: token.documents
				}
			});
		}
	}, [token.privacySettings, token.documents, dispatch])



	const validateFiles = (documents: any, type: any) => {
		const typePrivacy = privacySettings.documents[type.path];
		let validFiles = documents
			.filter((item) => item.path === type.path)
			.map((item) => {
				if (typePrivacy && typePrivacy[item.uuid]) {
					return {
						...item,
						perm: typePrivacy[item.uuid],
					};
				}
				return item;
			});

		// I think this is not neccessary, it must to be done in BackEnd
		if (type.path === documentPathTypes.GALLERY_3D) {
			if (validFiles.length > 0) {
				let files = [];
				for (let idx in validFiles) {
					if (validFiles[idx].filename.endsWith(".obj")) {
						let objFile = {
							...validFiles[idx],
							mtl: false,
							texture: false,
						};
						// find support files
						for (let i in validFiles) {
							let subFile = validFiles[i];
							if (subFile.name.toLowerCase().indexOf(objFile.name.toLowerCase()) === 0) {
								if (subFile.filename.endsWith(".mtl")) {
									objFile.mtl = subFile;
								}
								if (subFile.mimeType.startsWith("image/")) {
									objFile.texture = subFile;
								}
							}
						}

						files.push(objFile);
					}
				}
				let invalidFiles = validFiles
					.filter(
						(item) =>
							files.filter(
								(i) =>
									i.uuid === item.uuid ||
									(!!i.mtl && i.mtl.uuid === item.uuid) ||
									(!!i.texture && i.texture.uuid === item.uuid)
							).length <= 0
					)
					.map((item) => ({
						...item,
						isInvalid: true,
					}));
				return files.concat(invalidFiles);
			}
		}

		return validFiles;
	};

	const prepareData = () => {
		let data = [];
		// Token Property
		data.push({
			name: "",
			items: [
				{
					name: t("pages:token.privacy_settings.description"),
					key: "description",
					perm: "public",
				},
				{
					name: t("pages:token.value"),
					key: "value",
					perm: "public",
				},
				{
					name: t("pages:token.specifications"),
					key: "dimensions",
					childs: ['token.weightInKilo', 'token.widthInMeter', 'token.depthInMeter', 'token.lengthInMeter'],
					perm: "public",
				},
				{
					name: t("pages:token.blockchain_id"),
					key: "blockChainID",
					perm: "private",
				},
				{
					name: t("pages:token.privacy_settings.cover_photo_and_name"),
					key: "tokenPrivacy",
					perm: "public",
				},
				{
					name: t("pages:token.creation_date"),
					key: "creationDate",
					perm: "public",
				},
				{
					name: t("pages:token.privacy_settings.owner_username"),
					key: "ownerUsername",
					perm: "public",
				},
				{
					name: t("pages:token.privacy_settings.owner_email"),
					key: "ownerEmail",
					perm: "public",
				},
				{
					name: t("pages:token.privacy_settings.owner_wallet"),
					key: "ownerWallet",
					perm: "public",
				},
				{
					name: t("pages:token.privacy_settings.history"),
					key: "history",
					perm: "public",
				},
			],
			hideAll: true
		});
		// apply selection from stored privacy settings
		data[0].items.forEach((item) => {
			if (item.childs && item.childs.length > 0) {
				const values = item.childs.map((child) => privacySettings.token[child.replace('token.', '')]);
				const valueSet = new Set(values);
				if (valueSet.size === 1) {
					item.perm = valueSet.values().next().value;
				} else {
					item.perm = 'private';
				}
			} else {
				item.perm = privacySettings.token[item.key];
			}
		});
		// Files
		Object.values(documentTypes()).forEach((type, idx) => {
			let files = validateFiles(token.documents, type);
			data.push({
				name: type.name,
				path: type.path,
				items: files,
			});
		});
		return data;
	};

	const setAll = (perm) => {
		setSelectedAll(perm);
		dispatch({
			type: 'update_all',
			data: {
				perm
			}
		})
	}

	const onSelectAll = () => {
		setAll(isSelectedAll === 'public' ? 'private' : 'public')
	}

	const tableData = prepareData();

	useEffect(() => {
		let checker = true;
		// if (isSelectedAll === true) {
		tableData.forEach(group => {
			if (group.items) {
				group.items.forEach(item => {
					if (item.perm && (item.perm === checker || checker === true)) {
						checker = item.perm
					} else {
						checker = false
						return false;
					}
				})
			}
		})

		setSelectedAll(checker);
		// }

	}, [setSelectedAll, tableData, isSelectedAll])

	return (
		<TableContainer className="token__share-table">
			<Table sx={{ wordBreak: "break-word" }} className="table non-responsive" aria-label="collapsible table">
				<TableHead className="table__head">
					<TableRow>
						<TableCell sx={{ maxWidth: "60%", verticalAlign: "bottom", borderBottom: 'none' }} >{t('pages:token.privacy_settings.please_select_info_public')}</TableCell>
						<TableCell sx={{ verticalAlign: "bottom", borderBottom: 'none' }} className="table__head-cell">
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					<TableRow className="table__row select-all">
						<TableCell sx={{ paddingTop: 0 }}>
							<Box onClick={onSelectAll}>
								{t("pages:token.privacy_settings.select_all")}
							</Box>
						</TableCell>
						<TableCell sx={{ paddingTop: 0 }}>
							<Checkbox
								title="Select All"
								size="small"
								color="secondary"
								checked={isSelectedAll === 'public'}
								onChange={onSelectAll}
							/>
						</TableCell>
					</TableRow>
					{tableData.length > 0 && tableData.map((attr, idx) => attr.items.length > 0 ? <TokenFilesGroup key={`document-row-${idx}`} data={attr} /> : null)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default TokenPrivacyTable;
