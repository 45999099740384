import { Box, Button, Chip, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import './SeedRevealVerify.scss'
import { t } from 'i18next';
import { toast } from 'react-toastify';

interface ISeedPhraseRevealVerifyConfirm {
  open: boolean;
  phrases: Array<string>;
  onDismiss: () => void;
}

const SeedPhraseRevealVerifyConfirm = ({ open, phrases: _phrases, onDismiss }: ISeedPhraseRevealVerifyConfirm) => {
  const [randomPhrases] = useState([..._phrases].sort(() => Math.random() - 0.5))
  const [phrases, setPhrases] = useState<Array<string>>(_phrases.map(() => ' '))

  const _onConfirm = () => {
    if (_phrases.join(' ') === phrases.join(' ')) {
      toast.success(t('pages:profile.seed_phrase_verified_successfully'))
    } else {
      toast.error(t('pages:profile.seed_phrase_not_match'))
    }
  }

  const removePhrase = (idx: number) => {
    phrases[idx] = ' ';
    setPhrases([...phrases])
  }

  return (
    <Dialog open={open} className="seed-phrase-reveal-verify confirm-seed-phrase" onClose={onDismiss}>
      <Box className="seed-phrase-reveal-verify__head">
        <span className='logo'></span>
      </Box>
      <DialogContent>
        <Typography variant="h5" fontWeight="500" sx={{ mb: 1 }}>{t('pages:profile.confirm_seed_phrase')}</Typography>
        <Typography variant="body2" sx={{ mb: 3 }}>{t('pages:profile.select_each_word_in_order')}</Typography>

        <Grid container spacing={2} className='seed-phrase-reveal-verify__phrases'>
          <Grid item xs={6} >
            {phrases.map((phrase, idx) => {
              if (idx >= phrases.length / 2) return null;
              return (
                <Box className="seed-phrase-reveal-verify__phrase" onClick={() => removePhrase(idx)}>
                  <Typography variant="body2" component="span" color="GrayText">#{idx + 1}</Typography>
                  <Typography variant="body2" component="span" sx={{ ml: 1 }}>{phrase}</Typography>
                </Box>
              )
            })}
          </Grid>
          <Grid item xs={6} >
            {phrases.map((phrase, idx) => {
              if (idx < phrases.length / 2) return null;
              return (
                <Box className="seed-phrase-reveal-verify__phrase" onClick={() => removePhrase(idx)}>
                  <Typography variant="body2" component="span" color="GrayText">#{idx + 1}</Typography>
                  <Typography variant="body2" component="span" sx={{ ml: 1 }}>{phrase}</Typography>
                </Box>
              )
            })}
          </Grid>
        </Grid>

        <Box sx={{ mt: 2, width: 480 }}>
          {randomPhrases.map((phrase, idx) => (
            phrases.includes(phrase) ? null :
              <Chip key={idx} label={phrase} onClick={() => {
                const index = phrases.findIndex(e => !e || e === ' ');
                phrases[index] = phrase;
                setPhrases([...phrases])
              }} />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} px={2} mb={2}>
          <Grid item xs={12}>
            <Button className="w--100" onClick={_onConfirm} color="secondary" variant="contained" disabled={!!phrases.find(e => !e || e === ' ')}>
              {t("common:verify")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button className="w--100" onClick={onDismiss} color="secondary" variant="text">
              {t("common:cancel")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog >
  )
}

export default SeedPhraseRevealVerifyConfirm;
