import { createAsyncThunk } from "@reduxjs/toolkit";
import { default as apiConfigs } from '../../configs/api';
import config from '../../configs/config';
import { queryParamsURLEncodedString } from "../../utils/helpers";

export const fetchAuthToken = async (username, password) => {
	try {
		let data = {
			client_id: config.CLIENT_ID,
			client_secret: config.CLIENT_SECRET,
			username,
			password,
			grant_type: 'password',
		}

		let response = await fetch(apiConfigs.user.auth, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: queryParamsURLEncodedString(data) 
		}).then(response => response.json())

		return response;
	} catch (error) {
		return false
	}
}

export const getAuthToken = createAsyncThunk('user/getToken', async (payload, { getState, rejectWithValue} ) => {
  const { username, password } = payload

  try {
    
		const response = await fetchAuthToken(username, password);
    if ( !response || !response.access_token || !response.refresh_token || !response.expires_in ) {
      return rejectWithValue({
        code: response.error || 'login',
        message: response.error_description || "Something went wrong in login. Please contact developer"
      });
    }
    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      expiredAt: new Date().getTime() + response.expires_in*1000
    };
  } catch (error) {
    return rejectWithValue({
      code: 'login',
      message: error.message
    });
  }
})

export const refreshToken = createAsyncThunk('user/refreshToken', async (payload, { getState, rejectWithValue}) => {
  const state = getState()?.auth;

  if ( !state ) {
    return rejectWithValue({
      code: 'expired',
      message: 'Missing refresh token'
    })
  }
  const data = {
    client_id: config.CLIENT_ID,
    client_secret: config.CLIENT_SECRET,
    grant_type: 'refresh_token',
    refresh_token: state.refreshToken,
  };

  try {
    let response = await fetch(apiConfigs.user.auth, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryParamsURLEncodedString(data) 
    }).then(response => response.json())

    if ( !response.access_token ) {
      return rejectWithValue(response);
    }

    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      expiredAt: new Date().getTime() + response.expires_in*1000
    };
  } catch (error) {
    return rejectWithValue({
      code: 'refresh-token',
      message: "Something went wrong in refreshToken. Please contact developer"
    });
  }
})