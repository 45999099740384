import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Link, Skeleton, Typography } from "@mui/material";
import { redirect } from "react-router-dom";
import { useSearchTokenQuery } from "../../service/api/crappyApi";
import { t } from "i18next";

const TokenSearchDetail = ({ type, detail }) => {
  const { data, isFetching, isError } = useSearchTokenQuery({ 
    type: type,
    detail: detail 
  }, {
    skip: !detail
  })

  let artLot = !!data && data.status === 'success' &&  data.result.data.length > 0 ? data.result.data[0] : false;

  return (
    <Box className="section">
      <Box>
        <Card>
            {!!isError || artLot === false ?
              <CardContent>
                <Box sx={{ height: 500 }} className="flex flex--vertical flex--align-center flex--justify-center">
                  <Typography variant="h2">404</Typography>
                  <Typography variant="subtitle">{t("Token is not found")}</Typography>
                </Box>
              </CardContent>
              :
              !!isFetching ?
              <>
                <CardHeader 
                  title={<Skeleton width="100%" height={30} />}
                  subheader={<Skeleton width="100%" height={60} />}
                />
                <CardContent>
                  <Skeleton sx={{ transform: 'none' }} widh="100%" height={300} />  
                </CardContent>
              </>
              :
              <>
                <CardHeader 
                  title={artLot?.title}
                  subheader={artLot.short_desc ? artLot.short_desc.replace(/<[^>]*>?/gm, '') : ''}
                />
                <CardMedia
                  component="div"
                  sx={{
                    width: '100%',
                    height: 300,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat'
                  }}
                  image={artLot.img}
                  alt={artLot?.title}
                />
                <CardContent>
                  <Typography sx={{ mb: 2 }} variant="body2">
                    {artLot.long_desc ? artLot.long_desc.replace(/<[^>]*>?/gm, '') : ''}
                  </Typography>

                  {artLot?.meta && artLot.meta.map((item, idx)=> {
                    let value = item?.value.join(', ')
                    if ( value.indexOf('Login') >= 0 ) return null;
                    return (
                      <Box key={`lot-art-meta-${idx}`}>
                        <Typography variant="subtitle2">{item?.label}</Typography>
                        <Typography variant="body2">{value}</Typography>
                      </Box>
                    )} 
                  )}
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }} className="flex flex--vertical">
                  <Button sx={{ mb: 2}} color="secondary" size="small" variant="contained">Request</Button>
                  {false && <Link href={artLot.url} target="_blank" rel="noreferer" >View more</Link>}
                </CardActions>
              </>
            }
        </Card>
      </Box>
    </Box>
  )
}

export default TokenSearchDetail;