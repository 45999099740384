import React from 'react'
import { Box, CardMedia, Typography } from '@mui/material'
import { documentPathTypes } from "../../../configs/constant";

const ShareTokenHeadInfo = ({ token }) => {
  const coverImage = !!token && !!token.documents ? token.documents.find((document: any) => document.path === documentPathTypes.COVER_IMAGE) : '';
  const description = token?.token?.description?.substr(0, 120) || '';
  const desc = description + (description !== token?.token?.description ? '...' : '');

  return (
    <Box className="token__share__token-info" display="flex" p={2} mt={2}>
      <CardMedia
        className='token__share__cover-image'
        component="div"
        image={coverImage?.fileUrl || ''}
        alt={'Cover Image'}
      />
      <Box ml={2}>
        <Typography variant='body'>{token?.token?.name || ''}</Typography>
        <Typography variant='body2' color='text-gray'>{desc || ''}</Typography>
      </Box>
    </Box>
  )
}

export default ShareTokenHeadInfo
