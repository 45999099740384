import React, { Component } from 'react'

interface Props {
  children: any
  onError?: (error: any, errorInfo: any) => void
}

export default class ErrorBoundary extends Component {

  props: Props;
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
  }

  constructor(props: Props) {
    super(props);
    this.props = props;
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const { onError } = this.props;
    onError && onError(error, errorInfo)
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ color: '#f00' }}>
          {JSON.stringify(this.state.errorInfo)}
        </div>
      );
    }

    return (
      <div style={{ backgroundColor: '#fff', width: '100vw', height: '100vh' }}>
        {this.props.children}
      </div>
    );
  }
}