import React from 'react'
import './Spinner.scss'

interface ISpinner {
  variant: 'circle' | 'arc';
  size?: number;
}

const CircularSpinner = ({ variant, size = 50 }: ISpinner) => {
  return (
    <div className="circular-spinner" style={{ width: size, height: size }}>
      <div className="circle-square">
        <span className={`icon icon--${variant === 'arc' ? 'loader' : 'circular_spinner'}`}></span>
      </div>
    </div>
  )
}

export default CircularSpinner
