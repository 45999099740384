import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@mui/material";
import { t } from "i18next";
import { toast } from "react-toastify";
import { useSelectTokenContext, useSelectTokenDispatch } from "../TokenList/SelectTokenContext";
import ConfirmDialog from '../../ConfirmDialog';
import { useArchiveTokensMutation } from "../../../service/api/meveoApi";
import LoadingModal from "../../LoadingModal";

const ArchiveToken = (props: any) => {
	const state: any = useSelectTokenContext();
	const tokens = state?.tokens || [];
	const selectTokenDispatch: any = useSelectTokenDispatch();

	const [open, toggleDialog] = useState(false);
	const [processing, showProgress] = useState(false);
	const [archiveTokens] = useArchiveTokensMutation();

	const handleArchiveTokens = async () => {
		showProgress(true);
		await archiveTokens({ tokenIds: tokens })
			.unwrap()
			.then((res) => {
				showProgress(false);
				toast.success(t('pages:wallet.archive_token_success'));
				selectTokenDispatch({ type: 'reset' });
			})
			.catch((e) => {
				showProgress(false);
				toast.error(e.data?.message || e.message);
			})
	}


	if (tokens.length <= 0) return null;

	return (
		<>
			<Button
				disabled={tokens.length <= 0}
				onClick={() => toggleDialog(!open)}
				size="medium"
				color="secondary"
				variant="outlined"
				style={{ marginRight: 16 }}
			>
				<span
					style={{
						backgroundColor: `var(--unikbase-primary)`,
					}} className="icon icon--archive"></span>
				{t('pages:wallet.archive_token')} {tokens.length > 0 && `(${tokens.length})`}
			</Button>

			<ConfirmDialog
				visible={open}
				title={t("pages:wallet.archive_token")}
				message={t("pages:wallet.archive_token_confirm")}
				onConfirm={() => {
					toggleDialog(false);
					handleArchiveTokens();
				}}
				onDismiss={() => {
					toggleDialog(false);
				}}
			/>

			{processing && (
				<LoadingModal
					visible={true}
					onClose={() => null}
				/>
			)}
		</>
	)
}

export default ArchiveToken;