import { isRejectedWithValue } from '@reduxjs/toolkit'
import { meoveoRestApi } from '../../service/api/meveoApi'
import { refreshToken } from '../actions/authActions'

export const authenticationChecker = (api) => (next) => (action) => {
  if (isRejectedWithValue(action) && action.type.indexOf(meoveoRestApi.reducerPath) === 0 ) {
    if ( 401 === action.payload.originalStatus || 401 === action.payload.status ) {
      api.dispatch(refreshToken())
    }
  }

  return next(action)
}