import { IconButton, TextField, Autocomplete, Box, ListItem, LinearProgress, Typography, Button } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { DOCUMENT_UPLOAD_FILE_SIZE, documentPathTypes, documentTypes } from "../../../configs/constant";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUploadDocumentsMutation } from "../../../service/api/uploadApi";
import { toast } from "react-toastify";
import './UploadDocumentItem.scss';
import { t } from "i18next";

const NoneManualUploadTypes = [];

const LinearDeterminate = ({ onProgress }) => {
	const [progress, setProgress] = useState(0);
	const progressRef = useRef(progress);
	const seconds = useRef(0);

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				if (oldProgress === 100) {
					return 0;
				}
				const diff = Math.random() * 10;
				progressRef.current = Math.min(oldProgress + diff, 90);
				return progressRef.current;
			});
			seconds.current += 1;
			onProgress && onProgress({ progress: Math.floor(progressRef.current), seconds: seconds.current });
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<Box className="upload-document-progress-bar" my={1}>
			<LinearProgress color="success" variant="determinate" value={progress} sx={{ height: 7 }} />
		</Box>
	);
}

const UploadDocumentItem = (props) => {
	const { file, onRemove, onChange, folder = false, disabled = false } = props;
	const prevFile = useRef(false);
	const [uploadFiles, { isLoading, data: uploadResult }] = useUploadDocumentsMutation();
	const [error, setError] = useState("");
	const [percentage, setPercentage] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const icon = file.type ? documentTypes()[file.type.path].icon : 'others';

	const handleChange = (key, value) => {
		let updateFile = file;
		updateFile[key] = value;
		typeof onChange === "function" && onChange(file.name, updateFile);
	};

	const _onRemove = () => {
		typeof onRemove === "function" && onRemove(file.name)
	}

	const onProgress = ({ progress, seconds }) => {
		setPercentage(progress);
		setSeconds(seconds);
	}

	const doUpload = useCallback(() => {
		if (file.size / (1024 * 1024) >= DOCUMENT_UPLOAD_FILE_SIZE) {
			setError(`File size must be smaller than ${DOCUMENT_UPLOAD_FILE_SIZE}MB`);
			return;
		}
		// Start uploading
		let file_key = `file-${new Date().getTime()}`;
		let files = new FormData();
		files.append(file_key, file.file);
		if ( folder ) {
			files.append('folder', folder);
		}
		uploadFiles({ 
			data: files, 
			delay: true 
		});
	}, [file, folder, uploadFiles]);

	useEffect(() => {
		if (!prevFile.current || (!!prevFile.current && prevFile.current.file.name !== file.file.name)) {
			prevFile.current = file;

			// Do auto upload
			doUpload();
		}
	}, [file, doUpload]);

	// Update file when upload success
	useEffect(() => {
		if (!isLoading && uploadResult && uploadResult.status === 'success' && uploadResult?.result?.files) {
			let updateFile = file;
			updateFile['isReady'] = true;
			if (uploadResult?.result?.files.length > 0) {
				let uploadedFile = uploadResult.result.files[0];
				updateFile['file'] = uploadedFile.file;
				if(!updateFile.uuid) updateFile.uuid = uploadedFile.hash;
				updateFile['hash'] = uploadedFile.hash;
				if (uploadedFile?.file?.storageUrl) {
					updateFile['s3_src'] = `${uploadedFile.file.storageUrl}/${uploadedFile.file.key}`;
				}
			}
			typeof onChange === 'function' && onChange(file.name, updateFile);
		}
	}, [isLoading, uploadResult, file, onChange]);

	const isDisabled = disabled;
	return (
		<ListItem
			className={`flex flex--nowrap flex--align-center upload-document ${isLoading ? "upload-document--uploading" : ""
				}`}
			sx={{
				p: 0,
				flexWrap: 'wrap',
			}}
		>
			<Box width="100%" mt={1} py={1} px={2} sx={{ backgroundColor: '#f5f5f5' }}>
				<Box display="flex" flexDirection="row" flexGrow={1} alignItems="center">
					<span className={`icon icon--${icon}`}></span>
					<Typography className="file-name" variant="body2" sx={{ flexGrow: 1 }}>
						{file.name}
					</Typography>
					<Button
						variant="text"
						onClick={_onRemove}
					>
						<Typography
							variant="body2"
							color="GrayText"
							textTransform="capitalize"
							sx={{ textDecoration: 'underline' }}>
							{t('common:remove')}
						</Typography>
					</Button>
				</Box>
				{!!isLoading
					? <>
						<LinearDeterminate onProgress={onProgress} />
						<Box display="flex" flexDirection="row" flexGrow={1} alignItems="center" justifyContent="space-between">
							<Typography className="file-name" variant="body2" color="GrayText" sx={{ flexGrow: 1 }}>
								{t('common:uploading')} {percentage}%
							</Typography>
							<Typography variant="body2" color="GrayText">
								{seconds} {t('pages:token.document_details.seconds')}
							</Typography>
						</Box>
					</>
					: (
						<Box className="flex flex--nowrap flex--align-center  file-information" mt={1} mb={1}>
							<TextField
								onChange={(event) => {
									handleChange("name", event.target.value);
								}}
								sx={{ flexGrow: 1, mr: 1, backgroundColor: '#fff' }}
								size="small"
								defaultValue={file.name}
								disabled={isDisabled}
							/>
							<Box component="span" sx={{ width: "180px", backgroundColor: '#fff' }}>
								<Autocomplete
									disabled={isDisabled}
									fullWidth
									limitTags={1}
									id="multiple-limit-types"
									options={Object.values(documentTypes()).filter((item) => !NoneManualUploadTypes.includes(item.path))}
									getOptionLabel={(option) => option.name}
									isOptionEqualToValue={(option, value) => option.path === value.path}
									size="small"
									defaultValue={file.type || null}
									onChange={(event, newValue) => {
										handleChange("type", newValue);
									}}
									renderInput={(params) => <TextField {...params} placeholder="Select document type" size="small" />}
								/>
							</Box>
						</Box>
					)
				}
			</Box>
		</ListItem>
	);
};

export default UploadDocumentItem;
