import { Typography } from "@mui/material";
import Loader from "../Loader";
import PlatformNotfound from "../../layout/Platform/PlatformNotfound";
import { t } from "i18next";
import { useParams } from "react-router-dom";

const TokenDetailNotFound = (props) => {
	const { id, mode = "default" } = useParams();
	return (
		<div className="flex flex--vertical flex--align-center flex--justify-center">
			<Typography sx={{ mt: "30px" }} variant="h1">
				404!
			</Typography>
			<Typography sx={{ mt: "30px" }} variant="h3" color="secondary">
				{t(mode === "public" ? "pages:notfound.notshared_message" : "pages:notfound.message")}
			</Typography>
		</div>
	);
};

export default TokenDetailNotFound;
