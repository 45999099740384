import { KeyringController } from '@metamask/eth-keyring-controller';

import { meoveoRestApi } from '../../service/api/meveoApi';
import { _aesDecrypt, decryptOperatorKey, decryptSeedPhrase, encryptOperatorKey } from '../../service/crypto';

import { getAuthToken } from '../actions/authActions';
import { requirePassword, updateKeyringController } from '../slices/cryptoSlice';
import { EXPORT_SEEDPHRASE, getWallet, updateWallet } from '../actions/walletActions';
import { revealPrivateKey } from '../actions/cryptoAction';
import { initWallet, setOperatorUser } from '../slices/walletSlice';
import { useDispatch } from 'react-redux';
import { manualLogin } from '../slices/authSlice';

// From listing action, whenever its call if keyringController is not initial yet, show up requirPassword action
export const cryptoKeyringCheckMidleware = (store) => {
  let blockedActions = [];
  return (next) => async (action) => {
		const filter = {
			[updateWallet.rejected.toString()]: updateWallet,
			[revealPrivateKey.rejected.toString()]: revealPrivateKey
		}

		const actionType = action.type.toString();
		// console.log(actionType)

    if ( Object.keys(filter).indexOf(actionType) >= 0 ) {
      const meta = action?.meta;

      if (!!meta && meta.requestStatus === 'rejected' && !!meta.condition && !meta.rejectWithValue ) {
				blockedActions.push({
					callback: filter[actionType],
					action: action
				});
				
				let keyringController = store.getState()?.crypto.keyringController
				if ( !keyringController ) {
					store.dispatch(requirePassword());
				}
        return;
      }
    }
    if (action.type.toString() === updateKeyringController.type) {
      const result = next(action);
      if (result) {
        const resumeAction = blockedActions.shift();
        if (resumeAction) {
					const { action, callback } = resumeAction;
					
          const payload = action?.meta?.arg || {};
          store.dispatch(callback(payload));
        }
      }
      return result;
    }
    return next(action);
  };
};

let initialKeyringCallback = null;

// Store callback function, then call it later
export function setInitialKeyringCallback(func) {
	if ( typeof func === 'function' ) {
		initialKeyringCallback = func;
	}
}

export const initialKeyringController = (store) => {
	let currentPassword = false;
	let operatorHashString = false;
  return (next) => async (action) => {
		const actionName = action.type.toString();
		const { payload, meta } = action
		let isUpdateOPK = false;

		if ( actionName === getAuthToken.fulfilled.toString() ) {
			if ( payload.accessToken && !!meta?.arg?.password ) {
				// pass this password to next getWallet info action
				currentPassword = meta?.arg?.password
			}
		}

		if ( actionName === manualLogin.toString() ) {
			if ( payload.data?.access_token && !!payload?.password ) {
				// pass this password to next getWallet info action
				currentPassword = payload.password
			}
		}
		
		if( actionName === setOperatorUser.toString() && payload?.partnerCode ) {
			operatorHashString = `${payload?.partnerCode}unikbase${payload?.creationDate}`;
		}
		
    if ( (actionName === getWallet.fulfilled.toString() || actionName === initWallet.toString()) && currentPassword ) {
			const { wallet } = action.payload

			const currentKeyringController = store.getState()?.crypto?.keyringController
			if ( !currentKeyringController ) {
				if( !wallet.isOperatorUser && wallet?.private?.seedPhrase && wallet?.address && currentPassword ) {
					let mnemonic = decryptSeedPhrase(wallet.private.seedPhrase, currentPassword, wallet.address);
					if (mnemonic) {
						const keyringController = new KeyringController({});
						await keyringController.createNewVaultAndRestore(currentPassword, mnemonic);
						store.dispatch(updateKeyringController({
							controller: keyringController
						}));
						

						if ( operatorHashString && wallet.address ) {
							isUpdateOPK = true;
						} 
					}
				}
			}
    }

		if ( action.type.toString() === EXPORT_SEEDPHRASE && typeof initialKeyringCallback === 'function' ) {
			let wallet =  store.getState()?.wallet;
			let mnemonic = false;
			if ( wallet && currentPassword ) {
				mnemonic = decryptSeedPhrase(wallet.private.seedPhrase, currentPassword, wallet.address);
			}

			initialKeyringCallback(mnemonic)	
		}

		const res = next(action);
		
		if ( !!isUpdateOPK ) {
			store.dispatch(updateWallet({ opk: operatorHashString }))
		}
    return res;
  };
};