import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import Logo from "../Logo";

const Loader = ({ navigate, children, state = {}, logo = true}) => {

  return (
    <div className="loader">
      <div style={{ textAlign: 'center' }} className="container flex flex--vertical flex--align-center flex--justify-center">
        {!!logo &&  <Logo className="logo--short" style={{ width: '120px', height: '120px' }} />}
        {!!children && children}
      </div>
      {!!navigate && <Navigate to={navigate} replace={true} state={state} />}
    </div>
  )
}

export default Loader;