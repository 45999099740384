import { createAsyncThunk } from '@reduxjs/toolkit';
import * as XLSX from 'xlsx';
import * as zip from '@zip.js/zip.js';

import { default as apiConfigs } from '../../configs/api';
import { ZIP_MIME_TYPES, UPLOAD_DELAY } from '../../configs/constant';
import config from '../../configs/config';

import { blobToBase64, csvToArray, normalize, readText, readWorkbook, delayedFetch } from '../../utils/helpers';
import { toast } from 'react-toastify';
import uploadApi, { useUploadDocumentsMutation } from '../../service/api/uploadApi';
import { meoveoRestApi, prepareBasicAuthToken } from '../../service/api/meveoApi';

const buildTokensFromCsv = (fileContent, keymap) => {
  const { lines, headers } = csvToArray(fileContent);
  const tokens = [];
  for (let lineNumber = 0; lineNumber < lines.length; lineNumber++) {
    const line = lines[lineNumber];
    if (!line || !line[keymap['uuid']] || line[keymap['uuid']] === '') continue;

    const token = {
      uuid: normalize(line[keymap['uuid']]),
      name: normalize(line[keymap['name']] || ''),
      markReference: normalize(line[keymap['markReference']] || ''),
      description: normalize(line[keymap['description']] || ''),
      value: parseFloat(line[keymap['value']] || 0), // price
      currency: 'EUR', // USD, EUR
      lengthInMeter: parseFloat(line[keymap['lengthInMeter']] || 0.0),
      widthInMeter: parseFloat(line[keymap['widthInMeter']] || 0.0),
      depthInMeter: parseFloat(line[keymap['depthInMeter']] || 0.0),
      weightInKilo: parseFloat(line[keymap['weightInKilo']] || 0.0),
      commercialOfferCode: normalize(line[keymap['offer']] || ''),
      operator: normalize(line[keymap['operator']] || ''),
    };

    const documents = line.documents || [];
    // const client = {
    //     email: normalize(email),
    //     phone: normalize(phone),
    //     firstname: normalize(firstname),
    //     lastname: normalize(lastname)
    // };
    const addedDocuments = [];
    documents.forEach((document) => {
      const { uuid, path, name, fileUrl, mimeType, filename, size } = document;
      addedDocuments.push({
        documentId: normalize(uuid),
        path: normalize(path),
        name: normalize(name),
        filename: normalize(filename),
        mimeType: normalize(mimeType),
        size: parseInt(size),
        fileUrl: normalize(fileUrl),
        external: !fileUrl?.includes(config.IMGPROXY_SERVER_ADDRESS),
      });
    });
    tokens.push({ token, addedDocuments });
  }
  return tokens;
};

const buildTokensFromZip = async (file, keymap) => {
  let tokens = [];
  const zipContents = await new zip.ZipReader(new zip.BlobReader(file)).getEntries();
  if (zipContents && zipContents.length) {
    let csvFile;
    const images = {};
    for (let i = 0; i < zipContents.length; i++) {
      const content = zipContents[i];
      if ('tokens.csv' === content.filename) {
        csvFile = await content.getData(new zip.TextWriter());
      } else {
        const [tokenId, filename] = content.filename.split('/');
        const blob = await content.getData(new zip.BlobWriter());
        const base64Content = await blobToBase64(blob);
        images[tokenId] = { ...(images.tokenId || {}), [filename]: base64Content };
      }
    }
    const tokenDetails = buildTokensFromCsv(csvFile, keymap);
    tokens = tokenDetails.map((tokenDetail) => {
      const { token, addedDocuments } = tokenDetail;
      const { uuid } = token;
      const tokenImages = images[uuid];
      tokenDetail.addedDocuments = addedDocuments.map((document) => ({
        ...document,
        base64content: tokenImages[document.filename],
      }));
      return tokenDetail;
    });
  }
  return tokens;
};

const loadFile = async (file, keymap = false) => {
  if (!!file) {
    let fileContent;
    if ('text/csv' === file.type) {
      fileContent = await readText(file);
      return buildTokensFromCsv(fileContent, keymap);
    } else if (ZIP_MIME_TYPES.includes(file.type)) {
      return await buildTokensFromZip(file, keymap);
    } else {
      const workbook = await readWorkbook(file);
      fileContent = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
      return buildTokensFromCsv(fileContent, keymap);
    }
  }
};

const uploadDocument = (authToken, token, document, dispatch) => {
  return new Promise(async (resolve, reject) => {
    const file_key = `file_${new Date().getTime()}_`;
    const tokenId = token.uuid;
    try {
      const response = await dispatch(
        uploadApi.endpoints.uploadGoogleOrExternalImage.initiate({
          token: authToken,
          tokenId,
          upload_key: file_key,
          url: document.fileUrl,
        })
      ).unwrap();

      if (response.status === 'success' && !!response?.result?.files) {
        const item = response.result.files[0];
        let fileUrl = `${config.IMGPROXY_SERVER_ADDRESS}insecure/raw:1/plain/s3://${config.SCW_BUCKET_NAME}/${item.file.key}`;
        const filename =
          document.filename || (item.file.mimetype.startsWith('image') ? file_key + '.png' : item.file.name);
        resolve({
          documentId: item.hash, // this is the document hash
          path: document.path,
          name: document.name || item.file.name,
          size: item.file.size, // file size in bytes
          filename,
          mimeType: document.mimeType || item.file.mimetype,
          fileUrl: fileUrl, // this should be the imgproxy URL of the file
        });
      }
    } catch (e) {
      console.error('Failed to upload document: ', document, ' cause: ', e);
      reject(new Error(`Failed to upload document: ${document.name}, cause: ${e.error}`));
    }
  });
};
export const processFile = createAsyncThunk(
  'token/processFile',
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const { file, keymap } = payload;

    try {
      const token = await prepareBasicAuthToken();
      if (!token) {
        return rejectWithValue({ file, message: 'Invalid token to upload' });
      }

      const fileTokens = await loadFile(file, keymap);
      for (const tokenDetails of fileTokens) {
        const documents = tokenDetails.addedDocuments.filter((document) => !document.external);
        const uploadedDocuments = [];
        if (tokenDetails.addedDocuments.length > 0) {
          const externalDocumentFiles = tokenDetails.addedDocuments.filter((document) => document.external);
          for (const document of externalDocumentFiles) {
            try {
              const uploadedDocument = await uploadDocument(token, tokenDetails.token, document, dispatch);
              uploadedDocuments.push(uploadedDocument);
            } catch (e) {
              // ignore errors
            }
          }
        }
        tokenDetails.addedDocuments = [...documents, ...uploadedDocuments];
        const res = await dispatch(createToken(tokenDetails));
        if (res.error?.message) {
          toast.error(res.payload?.message);
        }
      }

      const { tokens } = getState()?.token;

      const hasError = Object.keys(tokens).some((key) => {
        return tokens[key].status === 'error';
      });
      if (hasError) {
        return rejectWithValue({ file, message: 'pages:wallet.token_error' });
      } else {
        return { file };
      }
    } catch (error) {
      console.error('Error encountered while processing file: ', file, error);
      return rejectWithValue({ file, message: error });
    }
  }
);

export const createToken = createAsyncThunk('token/create', async (newToken, { getState, rejectWithValue }) => {
  const auth = getState()?.auth;
  const { token } = newToken;

  if (!auth) {
    return rejectWithValue({ token, message: 'Permission denied.' });
  }

  const body = JSON.stringify(newToken);
  try {
    const endpointResponse = await delayedFetch(apiConfigs.wallet.token, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
      delay: UPLOAD_DELAY,
    });
    const response = await endpointResponse.json();

    if (!response?.status || response?.status !== 'success' || !response?.result) {
      return rejectWithValue({ token, message: response?.result });
    }
    return { token };
  } catch ({ message }) {
    return rejectWithValue({ token, message });
  }
});

export const getTokenListAllPages = (dispatch, filters) => {
  const pageSize = 100;
  const tokens = [];

  return new Promise((resolve, reject) => {
    const fetchPage = (offset) => {
      dispatch(meoveoRestApi.endpoints.getTokenList.initiate({ offset, limit: pageSize, filters }))
        .then((response) => {
          if (response.data?.result?.length > 0) {
            tokens.push(...response.data.result);

            if (response.data.result.length === pageSize) {
              fetchPage(offset + pageSize);
            } else {
              // all pages fetched
              resolve(tokens);
            }
          } else {
            // all pages fetched
            resolve(tokens);
          }
        })
        .catch((e) => {
          console.warn(e);
          reject(e);
        });
    };

    fetchPage(0);
  });
};
