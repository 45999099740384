import React from 'react'
import Toolbar from '../../layout/Platform/Toolbar';
import { t } from 'i18next';

const TokenShareHeader = ({ children }: any) => {
  return (
    <div className='tokenshare-header'>
      <Toolbar pageTitle={t("pages:token.token_origin_value")} />
      {children}
    </div>
  )
}

export default TokenShareHeader
