import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import "./SingularityPreview.scss";

const SingularityPreview = (props) => {
	const { config, image, onClick, selected } = props;
	const [imageStyle, setStyle] = useState({});
	const container = useRef();
	const imgRef = useRef();


	useEffect(() => {
		if (container.current) {
			const boundingRect = container.current.getBoundingClientRect();
			const realWidth = (boundingRect.width * 100) / (config.r * 100);

			setStyle({
				width: `${realWidth}px`,
				height: "auto",
			});
		}
	}, [config.r]);

	return (
		<Box onClick={onClick} ref={container} className={`singularity__preview ${selected?'selected': ''}`}>
			<img style={{
				...imageStyle,
				transform: `translate(-${config.x*100 - config.r*100/2}%,-${config.y*100 - config.r*100/2}%)`
			}} ref={imgRef} src={image} alt="Reference Singularity" />
		</Box>
	);
};

export default SingularityPreview;
