import { default as Layout } from "../../layout/Platform/Platform";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import './Transactions.scss';
import ListTransactions from "../../components/Transactions/ListTransactions";
import TransactionFilter from "../../components/Transactions/TransactionFilter";
import { useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import { TRANSACTION_TYPES } from "../../configs/constant";
import { parseJwt } from "../../utils/helpers";
import LoadingModal from "../../components/LoadingModal";

const Transactions = (props) => {
	const { username: currentUsername } = props;
	const [filters, setFilters] = useState({});
	const [transactions, setTransactions] = useState([]);
	const [exporting, setExporting] = useState(false);
	const [isFetching, setFetching] = useState(false);

	const csvData = useMemo(() => {
		const rows = []
		const headers = [
			t('pages:token_list.column_labels.uuid'),
			t('pages:token_list.column_labels.name'),
			t('common:type'),
			t('common:author'),
			t('common:date'),
			t('pages:transactions.hash'),
			'File URL',
			"Property",
			"Payment",
			"Data"
		];

		rows.push(headers);
		transactions.map((item) => {
			const {
				tokenId,
				tokenName,
				type,
				authorUsername,
				transactionDate,
				transactionHash,
				coverImage,
				document,
				property,
				payment,
				nfcTag,
				data
			} = item;

			rows.push([
				tokenId,
				tokenName,
				t(TRANSACTION_TYPES[type]?.label || type),
				authorUsername,
				new Date(transactionDate).toISOString(),
				transactionHash,
				document?.fileUrl || coverImage?.fileUrl,
				property?.propertyName ? `${property?.propertyName}: ${property?.oldValue} -> ${property?.newValue}` : '',
				payment?.type ? `${payment?.type}: ${payment?.amount}${payment?.currency}` : '',
				data?.type ? JSON.stringify(data) : JSON.stringify(nfcTag),
			])
		})

		return rows;
	}, [transactions]);

	const exportCSV = () => {
		setExporting(true);
	}

	useEffect(() => {
		if (!isFetching && exporting) {
			setTimeout(() => {
				document.getElementById('export-csv').click();
				setExporting(false);
			}, 300);
		}
	}, [isFetching, exporting])

	return (
		<Layout className="site site__transactions" pageTitle={t("pages:transactions.title")} actions={
			<>
				<TransactionFilter
					onExport={exportCSV}
					onChange={(update) => {
						setFilters({
							...filters,
							...update
						})
					}} />
			</>
		}>
			<Card>
				<CardContent>
					<ListTransactions filters={filters} onFetching={setFetching} onFiltered={setTransactions} />
					{exporting && <CSVLink data={csvData} filename={`${currentUsername}_transactions_${Date.now()}.csv`} id="export-csv" target="_blank" />}
					{exporting && isFetching && (
						<LoadingModal
							visible={isFetching}
							onClose={() => null}
						/>
					)}
				</CardContent>
			</Card>
		</Layout>
	);
};


export default connect(
	(state) => {
		const wallet = state.wallet;
		const auth = state?.auth;

		let username = wallet?.private?.username;

		if (wallet.isOperatorUser) {
			let jwtDecode = parseJwt(auth.accessToken)
			username = jwtDecode.name;
		}

		return {
			username,
		};
	}
)(Transactions);

