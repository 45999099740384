import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Box,
	Avatar,
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Modal,
	Typography,
	Stack,
	Skeleton,
	Menu,
	MenuItem,
} from "@mui/material";
import * as dateFns from "date-fns";
import { saveAs } from 'file-saver';
import { UploadDocuments } from "./UploadDocuments";
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { 
	documentTypes, 
	documentPathTypes,
	newTokenStatus as NEW_STATUSES,
	tokenStatus as STATUSES
} from "../../configs/constant";

import { useEffect, useMemo, useRef, useState } from "react";
import Token3DDocumentViewer from "./Token3DDocumenViewer";
import { t } from "i18next";
import ImageProxy, { proxySrcReplace } from "../Gallery/ImageProxy";
import { useGetMarkproofsVerificationsQuery, useGetPartnerCodeQuery, useGetTokenByIdQuery, useUpdateTokenMutation } from "../../service/api/meveoApi";
import { object } from "prop-types";
import { formatDate, timeSince } from "../../utils/helpers";
import { connect } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTokenEdit, useTokenEditDispatch } from "./EditToken/EditTokenContext";
import { toast } from "react-toastify";
import { useTraceUpdate } from "../../utils/hooks";
import LazyBase64Image from "../Image/LazyBase64Image";
import { isImage } from "../../pages/Token/TokenVerifiablePresentation";
import { standPost } from "../../pages/Standalone/Standalone";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const isPdf = (doc) => {
	return doc?.mimeType?.includes('pdf') || doc?.name?.includes('.pdf') || doc?.filename?.includes('.pdf')
}

const GView = ({ src, width, height }) => {
	const container = useRef();

	useEffect(() => {
		const pdfEmbed = `https://docs.google.com/gview?url=${src}&chrome=false&embedded=true`;
		const frm = document.createElement("iframe");
		frm.src = pdfEmbed;
		frm.width = "100%";
		frm.height = "100%";
		container.current.appendChild(frm);
	}, [src]);

	return (
		<div className="document__viewer--file " style={{ height: "100%" }} ref={container}>
			<CircularProgress
				sx={{ top: "50%", left: "50%", position: "absolute", transform: "translate(-50%,-50%)", zIndex: "-1" }}
			/>
		</div>
	);
};

const PDFRender = ({ currentPage }) => {
	const [pageHeight, setPageHeight] = useState(window.innerHeight * 0.9);

	const onRenderSuccess = () => {
		const canvas = document.querySelector(".react-pdf__Page__canvas");
		if (canvas) {
			let ratio = canvas.width / canvas.height;
			let height;
			let width;
			if (canvas.height > canvas.width) {
				height = window.innerHeight * 0.9;
				width = height * ratio;
			} else {
				width = window.innerWidth * 0.7;
				height = (width * 1) / ratio;
			}

			let maxWidth = window.innerWidth * 0.95;
			let maxHeight = window.innerHeight * 0.95;
			let newWidth, newHeight;

			// # Check if the width is bigger than the maximum width.
			if (width > maxWidth) {
				// # Calculate the new height, keeping the aspect ratio.
				newHeight = Math.min(maxHeight, maxWidth / ratio);
				newWidth = newHeight * ratio;
			} else {
				// # Calculate the new width, keeping the aspect ratio.
				newWidth = Math.min(maxWidth, height * ratio);
				newHeight = newWidth / ratio;
			}

			setPageHeight(newHeight);
			// setPageHeight(height)
		}
	};
	useEffect(() => {
		const resizeHandle = () => {
			onRenderSuccess();
		};
		window.addEventListener("resize", resizeHandle);
		return () => {
			window.removeEventListener("resize", resizeHandle);
		};
	}, []);
	return <Page height={pageHeight} onRenderSuccess={onRenderSuccess} pageNumber={currentPage}></Page>;
};

const DocumentPreview = ({ open, onClose, file, tokenId }) => {
	const [pageNumber, setPageNumber] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [pdfSize, setPdfSize] = useState([0, 0]);

	if (!file) return null;

	const src =
		file.fileUrl ||
		`https://dev1.unikbase.dev/meveo/files/token-documents/${tokenId}/${file.path.replace(/\/$/, "")}/${
			file.filename
		}`;

	const handleClose = () => {
		onClose();
	};

	const onPDFLoad = (pdf) => {
		setPageNumber(pdf.numPages);
	};

	const renderFile = () => {
		switch (file.mimeType) {
			case "image/jpeg":
			case "image/gif":
			case "image/jpg":
			case "image/png":
			case "image/webp":
				const proxySrc = proxySrcReplace(src, 1024, 768);
				return (
					<div className="image__preview">
						<picture>
							<img src={`${proxySrc}`} alt={file.name} className="image--responsive-horizontal" />
						</picture>
					</div>
				);
			case "application/octet-stream":
			case "model/obj":
			case "text/plain":
				if (/\.obj$/.test(file.filename)) {
					let mtl;
					let texture;
					if (file.mtl && file.mtl.length > 0) {
						const mtlFile = file.mtl[0];

						mtl = mtlFile.fileUrl
							? proxySrcReplace(mtlFile.fileUrl)
							: `https://dev1.unikbase.dev/meveo/files/token-documents/${tokenId}/${mtlFile.path.replace(
									/\/$/,
									""
							  )}/${mtlFile.filename}`;
					}
					if (file.texture && file.texture.length > 0) {
						const textureFile = file.texture[0];
						texture = textureFile.fileUrl
							? proxySrcReplace(textureFile.fileUrl)
							: `https://dev1.unikbase.dev/meveo/files/token-documents/${tokenId}/${textureFile.path.replace(
									/\/$/,
									""
							  )}/${textureFile.filename}`;
					}
					return (
						<div className="document__viewer--3D">
							<Token3DDocumentViewer file={src} mtl={mtl} texture={texture} />
						</div>
					);
				}
				break;
			case "application/pdf":
				return (
					<Box>
						<Document
							file={{ url: src }}
							loading={<CircularProgress className="loading" />}
							onLoadSuccess={onPDFLoad}
						>
							<PDFRender currentPage={currentPage} />
						</Document>
						{pageNumber > 1 && (
							<div className="pdf__navigation">
								<span>
									<FontAwesomeIcon
										icon={solid("chevron-left")}
										onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
									/>
								</span>
								<span>
									<FontAwesomeIcon
										icon={solid("chevron-right")}
										onClick={() =>
											setCurrentPage(currentPage >= pageNumber ? pageNumber : currentPage + 1)
										}
									/>
								</span>
							</div>
						)}
					</Box>
				);
			default:
				return <GView src={src} />;
		}
	};

	return (
		<Modal open={open} onClose={handleClose} className="document__preview">
			<Box className={`document__viewer ${file.mimeType?.startsWith("image/") ? "image" : ""}`}>
				<Button onClick={handleClose} className="document__preview-close">
					<FontAwesomeIcon icon={solid("xmark")} />
				</Button>
				{renderFile()}
			</Box>
		</Modal>
	);
};



export const validateFiles = (documents, type) => {
	let validFiles = documents.filter((doc) => !!doc.path).filter((item) => {
		return item.path === type.path;
	});

	// I think this is not neccessary, it must to be done in BackEnd
	if (type.path === documentPathTypes.GALLERY_3D) {
		if (validFiles.length > 0) {
			let files = [];
			for (let idx in validFiles) {
				if (validFiles[idx].filename.endsWith(".obj")) {
					let objFile = {
						...validFiles[idx],
						mtl: [],
						texture: [],
					};
					// find support files
					let subjectName = objFile.name.split("-")[0].toLowerCase();

					for (let i in validFiles) {
						let subFile = validFiles[i];

						if (subFile.name?.toLowerCase().startsWith(subjectName) === true) {
							if (subFile.filename?.endsWith(".mtl")) {
								objFile.mtl.push(subFile);
							}
							if (subFile.mimeType?.startsWith("image/")) {
								objFile.texture.push(subFile);
							}
						}
					}

					files.push(objFile);
				}
			}
			// let invalidFiles = validFiles
			// 	.filter(
			// 		(item) =>
			// 			files.filter(
			// 				(i) =>
			// 					i.uuid === item.uuid ||
			// 					(!!i.mtl && i.mtl.uuid === item.uuid) ||
			// 					(!!i.texture && i.texture.uuid === item.uuid)
			// 			).length <= 0
			// 	)
			// 	.map((item) => ({
			// 		...item,
			// 		isInvalid: true,
			// 	}));
			return files;
		}
	}
	return validFiles;
};

const DocumentListItem = (props) => {
	const { file, moreId, anchorEl, onToggleMore, onCloseMoreMenu, options, pending } = props;

	let filename = file?.file?.name || file?.filename || file?.name;
	let mimetype = file?.file?.mimetype || file?.mimeType;
	return (
		<ListItem
			key={`token-document-${file.uuid}`}
			className={`${file.isInvalid ? "file-invalid" : ""}`}
			secondaryAction={
				<>
					<IconButton
						aria-label={`document-more-button-${file.uuid}`}
						aria-expanded={file.uuid === moreId && Boolean(anchorEl)}
						aria-haspopup="true"
						onClick={onToggleMore}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						open={file.uuid === moreId && Boolean(anchorEl)}
						onClose={onCloseMoreMenu}
					>
						{options.map((option) => (
							option.hidden || (pending && option.key !== 'delete') ? null :
								<MenuItem key={option.label}
									onClick={() => {
										onCloseMoreMenu()
										option.onClick(file, pending)
									}}>
									<Box display="flex" alignItems="center" justifyContent="center">
										{option.icon}
										<Typography ml={1} >{option.label}</Typography>
									</Box>
								</MenuItem>
						))}
					</Menu>
				</>
			}
		>
			<ListItemText>
				{(mimetype.startsWith("image/") || isImage(filename) || isImage(file.fileUrl)) && (
					<span className="token-document__thumbnail">
						{file.fileUrl ?
							<ImageProxy
								url={file.fileUrl}
								width="100"
								height="100"
							/>
							: file.src ? <LazyBase64Image alt={filename} base64String={file.src} /> : null
						}
						
					</span>
				)}
				{pending && !file.src && (
					<span className="token-document__thumbnail icon icon--others"></span>
				)}
				{file.path === documentPathTypes.GALLERY_3D && file.texture && file.texture.length > 0 && (
					<span className="token-document__thumbnail">
						<ImageProxy
							url={file.texture[0].fileUrl}
							width="100"
							height="100"
						/>
					</span>
				)}
				{(file.mimeType === 'application/pdf' || file.fileUrl?.endsWith('.pdf')) && (
					<span className="token-document__thumbnail icon icon--pdf"></span>
				)}
				{[documentPathTypes.INVOICE, documentPathTypes.OTHERS].includes(file.path) && !isImage(filename) && !isPdf(file) && (
					<span className="token-document__thumbnail icon icon--document"></span>
				)}
				

				<Typography
					component="h3"
					variant="body1"
					style={{
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{file.name || file.filename || file.file?.name}
					<br />
					<Typography variant="caption" component="span">
						{documentTypes()[file.path || file.type?.path]?.name || ""}
						{' '}&bull;
						{file.creationDate
							? ` ${dateFns.format(new Date(Number(file.creationDate)), 'yyyy-MM-dd HH:mm')}`
							: ""}
					</Typography>
				</Typography>
			</ListItemText>

			{file.path === documentPathTypes.GALLERY_3D && (
				<Box className="token-document__sub-files">
				{file.mtl && file.mtl.length > 0 && file.mtl.map(mtl => ( 
						<p key={`sub-files-mtl-${file.uuid}`}>
							<Typography variant="caption" component="span">{`${mtl.name} ( ${mtl.filename} )`}</Typography>
							<br />
						</p>
					))}
					{file.texture && file.texture.length > 0 && file.texture.map(texture => ( 
						<p key={`sub-files-texture-${file.uuid}`}>
							<Typography variant="caption" component="span">{`${texture.name} ( ${texture.filename} )`}</Typography>
							<br />
						</p>
					))}
				</Box>
			)}
			
		</ListItem>
	
	)
}
const TokenDocuments = (props) => {
	const { isLoggedin, isOperatorUser, editing, mode: _mode, token: _token, sort, filter } = props;
	const { id: _tokenId, mode = _mode || 'default' } = useParams();
	const [viewAll, setViewAll] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [moreId, setMoreId] = useState(null);
	const [standalone, setStandalone] = useState(false);

	const tokenId = _tokenId || _token?.token?.uuid;
	const dispatch = useTokenEditDispatch();
	const tokenUpdate = useTokenEdit();

	const documentList = useRef();
	const [selectedFile, setSelectedFile] = useState();
	const [open, setOpen] = useState();

	const [deleteDocuments, setDeleteDocuments] = useState(false);
	const [deleteDocument, { data: deleteResponse, isLoading: deleting }] = useUpdateTokenMutation();
	const markproofVerifications = useGetMarkproofsVerificationsQuery({
		tokenId: tokenId
	});

	const applyFilter = (documents, filter) => {
		if (filter) {
			if (filter.type) {
				documents = documents.filter((document) => document.path === filter.type);
			}
			if (filter.date?.length > 0) {
				const dateRange = filter.date[0];
				documents = documents.filter((document) => document.creationDate >= dateRange.startDate && document.creationDate <= dateRange.endDate);
			}
		}
		return documents;
	}

	const documents = (_token?.documents || []).filter((document) => !tokenUpdate?.deletedDocuments.find((e) => e.uuid === document.uuid));
	const sortedDocs = useMemo(() => {
		let sortedDocs = [...documents];
		if (sort) {
			const types = documentTypes();
			sortedDocs = sortedDocs.sort((a, b) => {
				if (sort === "newest") {
					return new Date(Number(b.creationDate)) - new Date(Number(a.creationDate));
				} else if (sort === "oldest") {
					return new Date(Number(a.creationDate)) - new Date(Number(b.creationDate));
				} else if (sort === "type") {
					return types[a.path].name.localeCompare(types[b.path].name);
				} else if (sort === "name") {
					return a.name.localeCompare(b.name);
				}

				return 0;
			});
		}
		return applyFilter(sortedDocs, filter);
	}, [sort, documents, filter]);

	const token = _token?.token || {};
	const addedDocuments = [...(tokenUpdate?.addedDocuments || [])];

	const defaultViewCount = 10;
	const remainingCount = documents.length + addedDocuments.length - defaultViewCount - 1; // -1 cover image

	const getPartnerCode = useGetPartnerCodeQuery({}, {
		skip: !isLoggedin
	});
	const isPartner = !getPartnerCode.isFetching && getPartnerCode.data?.status === 'success' && !!getPartnerCode?.data?.result;

	const isEditable = isLoggedin && token && (token.status !== STATUSES.IN_TRANSFER || isOperatorUser); 

	const navigate = useNavigate();

	const options = [
		{
			key: 'view',
			icon: <FontAwesomeIcon icon={solid("eye")} />,
			label: t('common:view'),
			onClick: (file) => isPdf(file) ? openPdf(file) : showModal(file),
			hidden: !!editing
		},
		{
			key: 'download',
			icon: <FontAwesomeIcon icon={solid("cloud-arrow-down")} />,
			label: t('common:download'),
			onClick: (file) => downloadItem(file),
			hidden: !!editing
		},
		{
			key: 'delete',
			icon: <FontAwesomeIcon icon={solid("trash-can")} />,
			label: t('common:delete'),
			onClick: (file, pending) => pending ? deletePendingDocument(file) : deleteDocumentConfirm(file.path, file.name),
			hidden: !editing || !(mode === "default" && !!isEditable)
		}
	]

	const downloadItem = (doc) => {
		if (standalone) {
			standPost({ action: 'download', data: { ...doc, url: doc.fileUrl } })
		} else {
			return saveAs(doc.fileUrl, doc.name);
		}
	};

	const openPdf = (doc) => {
		const url = doc.fileUrl;

		if (standalone) {
			standPost({ action: 'view', data: { ...doc, url: url } })
		} else {
			const link = document.createElement("a");
			link.setAttribute("rel", "noreferrer");
			link.setAttribute("target", "_blank");
			link.href = url;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	const deletePendingDocument = (file) => {
		dispatch({
			type: 'delete_document',
			data: file,
		})
	}

	const deleteDocumentConfirm = (path, name) => {
		if ( !token || !documents ) return;
		const deleteTokenData = {
			token: token,
			deletedDocuments: [],
		};
		for (let key in documents) {
			const item = documents[key];

			if (item.path === path && item.name === name) {
				deleteTokenData.deletedDocuments.push(item);
			}
		}
		setDeleteDocuments(deleteTokenData);
	};

	const deleteDocumentHandle = () => {
		if (deleteDocuments === false ) return;
		deleteDocuments.isUploadDocument = true;
		if (editing) { //deferred
			deleteDocuments.deletedDocuments.forEach((item => {
				dispatch({
					type: 'delete_document',
					data: item,
				})
			}));
			setDeleteDocuments(false)
			toast.success(t('pages:token.document_details.deleted_document_save_changes'));
		} else {
			deleteDocuments.deletedDocuments = deleteDocuments.deletedDocuments.map((item) => ({
				path: item.path,
				name: item.name,
			}));
			deleteDocument(deleteDocuments);
		}
	};

	const showModal = (file) => {
		setOpen(true);
		setSelectedFile(file);
	};

	const closeHandle = () => {
		setOpen(false);
	};

	const onToggleMore = (event) => {
		setAnchorEl(event.currentTarget);
		setMoreId(event.currentTarget.getAttribute("aria-label").split("-").pop());
	};
	const onCloseMoreMenu = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (!!deleteResponse && deleteResponse.status === 'success') {
			setDeleteDocuments(false);
		}
	}, [deleteResponse]);

	useEffect(() => {
		const resizeHandle = () => {
			if ( !documentList.current ) return;
			const items = documentList.current.querySelectorAll('.MuiListItem-root')
			if ( !items ) return;
			if ( documentList.current.offsetWidth <= 320 ) {
				items.forEach(item => item.classList.add('mobile-view'))
			} else {
				items.forEach(item => item.classList.remove('mobile-view'))
			}
		}
		resizeHandle();
		window.addEventListener('resize', resizeHandle);
		return () => window.removeEventListener('resize', resizeHandle)
	}, []);

	useEffect(() => {
		if (document.getElementById('standaloneWebApp')) {
			setStandalone(true);
		}
	}, []);

	let counter = 0;
	
	return (
		<>
			<Card variant="outlined" className="documents">
				<CardContent>
					<Box ref={documentList}>
						
						{!!isLoggedin && mode === "default" && editing && (
							<>
								{isEditable && <UploadDocuments token={token} deferred={editing} documents={_token?.documents} />}
							</>
						)}
						{[...sortedDocs, ...addedDocuments].map((doc, idx) => {
							const pending = addedDocuments.includes(doc);
							let files = [doc];

							if (files.length <= 0 || (!viewAll && counter > defaultViewCount)) return null;
							return (
								<Box key={`token-document-type-${doc.path}-${doc.uuid}`}>
									<Box>
										<List >
											{files.map((file) => {
												if (!editing && file.path === documentPathTypes.COVER_IMAGE) return null;
												if (editing && (tokenUpdate.deferredCoverImage ? file.uuid === tokenUpdate.deferredCoverImage : file.path === documentPathTypes.COVER_IMAGE)) return null;
												counter++;
												if (!viewAll && counter > defaultViewCount) return null;
												
												return <DocumentListItem 
													file={file}
													moreId={moreId}
													anchorEl={anchorEl}
													onToggleMore={onToggleMore}
													onCloseMoreMenu={onCloseMoreMenu}
													options={options}
													pending={pending}
													key={`token-document-${file.uuid}`}
												/>;
											})}
										</List>
									</Box>
								</Box>
							);
						})}
					</Box>
					{remainingCount > 0 && (
						<Typography
							component="a"
							variant="body2"
							onClick={() => setViewAll(!viewAll)}
						>
							{viewAll ? t('pages:token.show_less') : `${t('pages:token.show_all')} (+${remainingCount})`}
						</Typography>
					)}
				</CardContent>
			</Card>
			<DocumentPreview open={open} onClose={closeHandle} file={selectedFile} tokenId={tokenId} />
			<Dialog open={!!deleteDocuments} onClose={() => setDeleteDocuments(false)}>
				<DialogContent>
					<Typography variant="h6">{t("pages:token.document_details.confirm_delete_document")}</Typography>
				</DialogContent>
				<DialogActions className="flex flex--align-center flex--justify-center">
					<Button onClick={deleteDocumentHandle} color="secondary" variant="contained" size="small">
						{t("common:confirm")}
					</Button>
					<Button onClick={() => setDeleteDocuments(false)} color="primary" variant="outlined" size="small">
						{t("common:cancel")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default connect((state) => {
	const auth = state?.auth;
	const wallet = state.wallet;

	return {
		isLoggedin:
			!!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime() ? auth.accessToken : false,
		isOperatorUser: wallet.isOperatorUser || wallet.isOperator,
	};
})(TokenDocuments);
