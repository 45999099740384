import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { connect } from "react-redux";
import { TRANSACTION_TYPES, currencies } from "../../configs/constant";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { usePricePlan } from "../../hooks/tokenHooks";

const OperatorActionConfirmation = (props) => {
	const { action, offerCode, open, toggle, onClose, defaultPartnerCode = '' } = props;

	const currency = currencies.filter((item) => item.currency === "EUR")[0].currency;

	const prefix = !!offerCode ? `${offerCode}` : '';
	const pricePlan = usePricePlan(prefix + action, defaultPartnerCode);

	let pricing = pricePlan?.pricePlan?.price || 0;
	const onApprove = () => {
		typeof toggle === 'function' && toggle(false);
		typeof open.callback === "function" && open.callback();
	};

	useEffect(() => {
		if (!pricing && open) {
			// this trigger parent
			typeof open.callback === "function" && open.callback();
		}
	}, [pricing, open]);

	
	if (!pricing) {
		return null;
	}
	
	return (
		<Dialog open={!!open} className="dialog">
			<DialogContent>{t("pages:token.debited_message", { amount: pricing, currency: currency })}</DialogContent>
			<DialogActions>
				<Button variant="contained" color="secondary" onClick={onApprove} size="small">
					{t("common:ok")}
				</Button>
				<Button variant="contained" color="primary" onClick={onClose} size="small">
					{t("common:cancel")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default connect((state) => {
	const wallet = state?.wallet;
	return {
		operatorPricingPlans: wallet?.operatorPricingPlan || [],
	};
})(OperatorActionConfirmation);
