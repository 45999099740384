import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: `https://hook.eu2.make.com/`,
  credentials: 'same-origin',
});

export const contactSupportApi = createApi({
  reducerPath: 'contactSupportApi',
  baseQuery,
  endpoints: (builder) => ({
    submitContactSupport: builder.mutation({
      query: (args) => ({
        url: 'kbwb7gglge9wutcinb158qfqman9to5f',
        method: 'POST',
        body: JSON.stringify(args),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

export const { useSubmitContactSupportMutation } = contactSupportApi;
