import { connect } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { Alert, Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { verifyFormField } from "../Form/PasswordField";
import { updateWallet } from "../../store/actions/walletActions";
import { t } from "i18next";
import { MessageBox } from "../Animation";
import { resetWalletStatus, updateError } from "../../store/slices/walletSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import LoadingModal from "../LoadingModal";
import { FormField } from "../Form/FormField";
import './ChangePassword.scss'
import { validatePassword } from "../../utils/helpers";
import { decryptSeedPhrase } from "../../service/crypto";


const ChangePassword = (props) => {
	const { changePassword, isUpdating, errors, resetUpdatingStatus, clearnError, encryptedSeed, address } = props;
	const initData = [
		{
			id: "current-password",
			label: t("pages:profile.current_password"),
			errors: [],
			valid: [["(?=.{8,})", t("pages:profile.current_password_invalid")]],
			value: "",
		},
		{
			id: "new-password",
			label: t("pages:profile.new_password"),
			errors: [],
			valid: [
				[
					"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
					t("pages:profile.password_invalid"),
				],
			],
			value: "",
		},
		{
			id: "confirmation-password",
			label: t("pages:profile.confirmation_password"),
			errors: [],
			valid: [
				[
					"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
					t("pages:profile.password_invalid"),
				],
				[
					"match",
					"new-password",
					t("pages:profile.confirmation_password_invalid"),
				],
			],
			value: "",
		},
	];
	const [data, setFormData] = useState(initData);
	const [valid, setValid] = useState([false, false, false, false]);
	const [passwordMatched, setPasswordMatched] = useState(false);
	const passwordTimeout = useRef();
	const [invalidPassword, setInvalidPassword] = useState(true);

	const requirements = useMemo(() => ([
		t('pages:profile.at_least_8_characters'),
		t('pages:profile.at_least_1_special_character'),
		t('pages:profile.at_least_1_uppercase'),
		t('pages:profile.at_least_1_number'),
	]), [])

	const handleSubmit = (event) => {
		event.preventDefault();
		let isValid = true;
		let currentFormData = [];
		data.forEach((item) => {
			const validation = verifyFormField(item.value, item.valid, data);
			if (validation && validation.length > 0) {
				isValid = false;
			}
			currentFormData.push({
				...item,
				errors: validation,
			});
		});
		if (!isValid) {
			setFormData(currentFormData);
			return;
		}
		const current = data.filter((item) => item.id === "current-password")[0]
			.value;
		const newPassword = data.filter((item) => item.id === "new-password")[0]
			.value;

		changePassword(current, newPassword);
	};

	const handleChange = (id, value, errors) => {
		let newState = [];
		data.forEach((item) => {
			if (item.id !== id) {
				newState.push(item);
				return;
			}
			if (item.id === id) {
				newState.push({
					...item,
					value: value,
					errors: errors,
				});
			}
		});

		if (id === 'new-password') {
			setValid(validatePassword(value))
			setPasswordMatched(data[2].value === value)
		} else if (id === 'confirmation-password') {
			setPasswordMatched(data[1].value === value)
		} else if (id === 'current-password') {
			if (passwordTimeout.current) {
				clearTimeout(passwordTimeout.current)
			}
			passwordTimeout.current = setTimeout(() => {
				try {
					const decoded = decryptSeedPhrase(encryptedSeed, value, address);
					setInvalidPassword(!decoded);
				} catch (error) {
					setInvalidPassword(true);
				}
			}, 500);
		}

		setFormData(newState);
	};

	useEffect(() => {
		if (isUpdating === "success") {
			// Clear form then show message
			setFormData(initData);
			setTimeout(() => resetUpdatingStatus(), 2000);
		}
	}, [isUpdating]);

	useEffect(() => {
		// clean error whenever exit the screen
		return () => clearnError && clearnError();
	}, [])

	return (
		<fieldset disabled={isUpdating === "loading"}>
			<Box
				className="change-password-form"
				component="form"
				onSubmit={handleSubmit}
			>
				<Typography variant="body2" sx={{ pb: 1 }} fontWeight="bold" textTransform="capitalize">{t('pages:profile.current_password')}</Typography>
				<FormField
					fieldId={'field-current-password'}
					type="password"
					value={data[0].value}
					onChange={(evt) => handleChange('current-password', evt.target.value, data[0].errors)}
					label={''}
					placeholder={t("pages:profile.enter_current_password")}
					errors={data[0].value && invalidPassword ? [t('pages:profile.current_password_invalid')] : data[0].errors}
					fullWidth={true}
				/>
				<Box className="password-requirements" px={2} py={1} mb={3}>
					<Typography sx={{ py: 1 }} variant="caption">{t('pages:profile.password_must_contain')}:</Typography>
					<Grid container my={1}>
						{requirements.map((item, idx) => (
							<Grid item xs={12} sm={6}>
								<Box display="flex">
									<span className={`icon icon--checkbox-circle ${valid[idx] ? 'checked' : ''}`}></span>
									<Typography variant="caption">{item}</Typography>
								</Box>
							</Grid>
						))
						}
					</Grid>
				</Box>
				<Typography variant="body2" sx={{ pb: 1 }} fontWeight="bold" textTransform="capitalize">{t('pages:profile.new_password')}</Typography>
				<FormField
					fieldId={'field-new-password'}
					type="password"
					disabled={invalidPassword}
					value={data[1].value}
					onChange={(evt) => handleChange('new-password', evt.target.value, data[1].errors)}
					label={''}
					placeholder={t("pages:profile.create_new_password")}
					errors={data[1].errors}
					fullWidth={true}
				/>
				<Box display="flex" justifyContent="space-between">
					<Typography variant="body2" sx={{ pb: 1 }} fontWeight="bold" textTransform="capitalize">{t('pages:profile.confirmation_password')}</Typography>
					{!!data[1].value && !!data[2].value && (
						<Typography variant="caption" sx={{ pb: 1, color: passwordMatched ? '#4FB100' : '#f00' }}>{
							passwordMatched ? t('pages:profile.password_match') : t('pages:profile.password_do_not_match')
						}</Typography>
					)}
				</Box>
				<FormField
					fieldId={'field-confirmation-password'}
					type="password"
					disabled={invalidPassword}
					value={data[2].value}
					onChange={(evt) => handleChange('confirmation-password', evt.target.value, data[2].errors)}
					label={''}
					placeholder={t("pages:profile.retype_new_password")}
					errors={data[2].errors}
					fullWidth={true}
				/>
				<Box sx={{ my: 2 }}>
					<LoadingButton
						className="w--100"
						type="submit"
						size="medium"
						variant="contained"
						color="secondary"
						loading={isUpdating === "loading"}
						disabled={invalidPassword || !valid.every(e => e === true) || isUpdating === "loading" || !passwordMatched}
					>
						{t("pages:profile.change_password")}
					</LoadingButton>
				</Box>
				{isUpdating === 'loading' && (
					<LoadingModal
						visible={true}
						onClose={() => null}
					/>
				)}
				{isUpdating === "success" && (
					<MessageBox>
						{t("pages:profile.password_changed")}
					</MessageBox>
				)}

				{errors?.length > 0 && (
					<Alert severity="error" variant="outlined">
						<ul>
							{errors.map((item, idx) => (
								<li key={`error-message-${idx}`}>
									{typeof item === "string"
										? item
										: item.message || ""}
								</li>
							))}
						</ul>
					</Alert>
				)}
			</Box>
		</fieldset >
	);
};

export default connect(
	(state) => {
		const wallet = state?.wallet;
		return {
			isUpdating: wallet?.isUpdating,
			address: wallet?.address,
			encryptedSeed: wallet?.private?.seedPhrase,
			errors: Object.values(wallet?.error || []),
		};
	},
	(dispatch) => {
		return {
			changePassword: (current, newPassword) => {
				dispatch(
					updateWallet({
						password: {
							current: current,
							newPassword: newPassword,
						},
					})
				);
			},
			resetUpdatingStatus: () => {
				dispatch(resetWalletStatus());
			},
			clearnError: () => {
				dispatch(updateError(null));
			}
		};
	}
)(ChangePassword);
