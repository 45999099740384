import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import cv from "@techstark/opencv-js";
import { Tensor, InferenceSession } from "onnxruntime-web";
import LoadingMsg from '../LoadingMsg'
import { alignedVerImageToBlob, detectImage, handleSliderChange, showAlignedCanvas } from "./utils/detect";

import { download } from "./utils/download";
import "./MarkProofVerificationAutoAlign.scss";
import config from "../../configs/config";
import { blobToBase64, getImageAsBlob } from "../../utils/helpers";
import LoadingModal from "../LoadingModal";
import { t } from "i18next";
import { Box } from "@mui/material";

interface IMarkProofVerificationAutoAlign {
  open: boolean;
  images: any;
  singularities: any;
  onClose: Function;
  homography: any;
  onAligned: Function;
}

const MarkProofVerificationAutoAlign = forwardRef(({ open, images, singularities, onClose, homography: _homography, onAligned }: IMarkProofVerificationAutoAlign, ref) => {
  const singlePage = window.location.pathname.includes('poi-verify');
  const [session, setSession] = useState<any>(null);
  const [loading, setLoading] = useState<any>({ text: "Loading OpenCV.js", progress: null });
  const [image, setImage] = useState<any>(null);
  const [verificationImage, setVerificationImage] = useState<any>(null);
  const [canAnalyse, setCanAnalyse] = useState(false);
  const [alpha, setAlpha] = useState(0.5);
  const [processedImages, setProcessedImages] = useState({ image1: null, image2: null, singularity_ref_iiv: null, singularity_ref_original: null, singularity_ver_iiv: null, singularity_ver_original: null });
  const [backendMessage, setBackendMessage] = useState(null);
  const [analyzing, setAnalyzing] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const initializing = useRef(false);
  const [initialized, setInitialized] = useState(false);
  const detectOnLoad = useRef(true);

  const inputImage = useRef<any>(null);
  const verificationInputImageRef = useRef<any>(null);
  const imageRef = useRef<any>(null);
  const canvasRef = useRef<any>(null);
  const verificationImageRef = useRef<any>(null);
  const verificationCanvasRef = useRef<any>(null);


  const modelName = "superpoint_lightglue_end2end_512.onnx";
  const modelInputShape = [1, 1, 512, 512];

  cv["onRuntimeInitialized"] = async () => {
    //   try {
    //     const baseModelURL = `${window.location.origin}/model`;

    //     // create session
    //     const arrBufNet = await download(
    //       `${baseModelURL}/${modelName}`, // url
    //       ["Loading LightGlue Model", setLoading] as any // logger
    //     );
    //     const lightGlue = await InferenceSession.create(arrBufNet);

    //     // warmup main model
    //     setLoading({ text: "Warming up model...", progress: null });
    //     const tensor = new Tensor(
    //       "float32",
    //       new Float32Array(modelInputShape.reduce((a, b) => a * b)),
    //       modelInputShape
    //     );
    //     try {
    //       await lightGlue.run({ image0: tensor, image1: tensor })
    //     } catch (error) {
    //       console.log(`caught error: ${error}`)
    //     }


    //     setSession({ net: lightGlue });
    setLoading(null);
    //   } catch (error) {
    //     alert('Error loading model. Please try again.')
    //   }
  };

  useEffect(() => {
    if (!initialized && !initializing.current) {
      initializing.current = true;
      cv.onRuntimeInitialized();
      setInitialized(true);
    }
  }, [initialized]);

  const prepareImages = async (images: any) => {
    const { referenceImage, verifiedImage } = images;

    setLoading(true)
    setCanAnalyse(false);

    const [img1, img2] = await Promise.all([
      getImageAsBlob(referenceImage),
      getImageAsBlob(verifiedImage),
    ]);

    if (!imageRef.current || !verificationImageRef.current) return;

    let url = URL.createObjectURL(img1);
    imageRef.current.src = url;

    url = URL.createObjectURL(img2);
    verificationImageRef.current.src = url;

    setImage(img1)
    setVerificationImage(img2)
    setCanAnalyse(true);
    setLoading(false);
  }

  useEffect(() => {
    if (open && detectOnLoad.current && !loading && canAnalyse && image && verificationImage) {
      detectOnLoad.current = false;
      setIsProcessing(true);
      setTimeout(() => handleDetection(), 500);
    }
  }, [open, loading, canAnalyse, image, verificationImage, detectOnLoad.current])

  useEffect(() => {
    if (images && images.referenceImage && images.verifiedImage) {
      prepareImages(images);
    }
  }, [images])

  const handleReferenceImageUpload = (e: any) => {
    if (image) {
      URL.revokeObjectURL(image);
      setImage(null);
    }
    const url = URL.createObjectURL(e.target.files[0]);
    imageRef.current.src = url;
    setImage(url);
  };

  const handleVerificationImageUpload = (e: any) => {
    if (verificationImage) {
      URL.revokeObjectURL(verificationImage);
      setVerificationImage(null);
    }
    const url = URL.createObjectURL(e.target.files[0]);
    verificationImageRef.current.src = url;
    setVerificationImage(url);
  };

  const handleDetection = async () => {
    if (image && verificationImage && imageRef.current && verificationImageRef.current) {
      setAnalyzing(true)
      setIsProcessing(true)

      const referenceImage = cv.imread(imageRef.current);
      const verImage = cv.imread(verificationImageRef.current);

      let canvasTemp1 = document.createElement('canvas');
      cv.imshow(canvasTemp1, referenceImage);
      let dataURL1 = canvasTemp1.toDataURL('image/jpeg');
      let base64String1 = dataURL1.split(',')[1];

      let canvasTemp2 = document.createElement('canvas');
      cv.imshow(canvasTemp2, verImage);
      let dataURL2 = canvasTemp2.toDataURL('image/jpeg');
      let base64String2 = dataURL2.split(',')[1];

      setAnalyzing(false)
      const data = {
        image1: base64String1,
        image2: base64String2,
        singularityJSON: {
          metadata: {
            singularities
          }
        }
      };

      if (singularities) {
        // detectSingularitiesTransform(data); disabled as it did not work correctly. pending PR https://github.com/unikbase/tokenpage/pull/482
      }

      if (_homography) {
        transformVerImage(_homography, verImage);

        onClose && onClose();
        return
      }

      try {
        const response = await fetch(`${config.IMAGE_ANALYSIS_SERVER_ADDRESS}/align`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        const result = await response.json();
        console.log(result);
        transformVerImage(result.homography, verImage);

        onClose && onClose();
      } catch (error) {
        console.error("There was an error uploading the images", error);
      } finally {
        setIsProcessing(false);
      }


    }
  };

  const transformVerImage = (_homography: any, verImage: any) => {
    if (_homography) {
      let arr: any = [];
      let homography = cv.matFromArray(3, 3, cv.CV_64F, arr.concat(..._homography));

      let warpedVer = new cv.Mat();
      cv.warpPerspective(verImage, warpedVer, homography, verImage.size());

      const canvas = document.getElementById("superimposedCanvas");
      const [blob, dataURL]: any = alignedVerImageToBlob(canvas, warpedVer);
      const imgs = document.querySelectorAll(".markproof__verificator .image-compare__viewer .image--reference .viewport img");
      if (imgs?.length > 0) {
        imgs.forEach((img: any) => {
          img.src = dataURL;
        })
      }

      onAligned && onAligned(_homography)
      // showAlignedCanvas(referenceImage, warpedVer);
      warpedVer.delete();
    }
  }

  const detectSingularitiesTransform = async (data: any) => {
    try {
      const response = await fetch(`${config.IMAGE_ANALYSIS_SERVER_ADDRESS}/upload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const result = await response.json();
      console.log(result);

      setProcessedImages({ image1: result.image2, image2: result.image1, singularity_ref_iiv: result.singularity_ref_iiv, singularity_ref_original: result.singularity_ref_original, singularity_ver_iiv: result.singularity_ver_iiv, singularity_ver_original: result.singularity_ver_original });
      setBackendMessage(result.message);
    } catch (error) {
      console.error("There was an error uploading the images", error);
    } finally {
      setIsProcessing(false);
    }
  }

  useImperativeHandle(ref, () => ({
    analyzeImages: () => handleDetection()
  }));

  return (
    <div className="mark-proof-verification-matching">
      {(isProcessing || loading) && (
        <Box mt={20}>
          <LoadingMsg
            message={`${t('common:default_loading_message')}`}
          />
        </Box>
      )}

      <div className={`header ${singlePage ? '' : 'hidden'}`}>
        <h1>Light Glue Keypoint Detection App</h1>
      </div>

      <div className={`content ${singlePage ? '' : 'hidden'}`}>
        <div>
          {/* <h2>Reference Image</h2> */}
          <img ref={imageRef} src="#" alt="" style={{ display: image ? "block" : "none" }} />
          <canvas id="referenceCanvas" width={modelInputShape[2]} height={modelInputShape[3]} ref={canvasRef} />
        </div>

        <div>
          {/* <h2>Verification Image</h2> */}
          <img ref={verificationImageRef} src="#" alt="" style={{ display: verificationImage ? "block" : "none" }} />
          <canvas id="verificationCanvas" width={modelInputShape[2]} height={modelInputShape[3]} ref={verificationCanvasRef} />
        </div>
      </div>

      <input type="file" ref={inputImage} accept="image/*" style={{ display: "none" }} onChange={handleReferenceImageUpload} />

      <input type="file" ref={verificationInputImageRef} accept="image/*" style={{ display: "none" }} onChange={handleVerificationImageUpload} />
      {/* Using verificationInputImageRef for the file input */}

      <div className={`btn-container ${singlePage ? '' : 'hidden'}`}>
        <button onClick={() => inputImage.current?.click()}>Upload Reference Image</button>
        <button onClick={() => verificationInputImageRef.current?.click()}>Upload Verification Image</button>
        {/* Using verificationInputImageRef for the file input */}
        <button disabled={!image || !verificationImage} onClick={handleDetection}>Run Detection</button>
      </div>
      <div id="modal" className="hidden">
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={alpha}
          id="alpha-slider"
          onChange={(event) => {
            handleSliderChange();
            setAlpha(parseFloat(event.target.value));
          }}
        />
        <canvas id="superimposedCanvas"></canvas>
        {/* <button onClick={hideModal}>Close</button> */}
      </div>

      {
        processedImages.image1 && processedImages.image2 && (
          <div>
            <h2>Illumination invariant transformed images</h2>
            <div className="processed-images">
              <img src={`data:image/jpeg;base64,${processedImages.image1}`} alt="Result 1" />
              <img src={`data:image/jpeg;base64,${processedImages.image2}`} alt="Result 2" />
            </div>
            <h3> Singularity Region Comparison: Reference Image vs Aligned Verification Image</h3>
            <div className="singularity-image">
              <img src={`data:image/jpeg;base64,${processedImages.singularity_ref_original}`} alt="Singularity" />
              <img src={`data:image/jpeg;base64,${processedImages.singularity_ver_original}`} alt="SingularityOriginal" />
            </div>
            <div className="singularity-image">
              <img src={`data:image/jpeg;base64,${processedImages.singularity_ref_iiv}`} alt="Singularity" />
              <img src={`data:image/jpeg;base64,${processedImages.singularity_ver_iiv}`} alt="SingularityOriginal" />
            </div>
            {backendMessage && <h3>{backendMessage}</h3>}
          </div>
        )
      }


    </div>
  );
});

export default MarkProofVerificationAutoAlign;