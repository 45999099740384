import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	useMediaQuery,
} from "@mui/material";
import { t } from "i18next";
import { Fragment, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import config from "../../../configs/config";
import { FormField } from "../../Form/FormField";
import { useTheme } from "@emotion/react";
import { connect } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import "./TopupBalance.scss";
import { Elements } from "@stripe/react-stripe-js";
import api from "../../../configs/api";
import BigNumber from "bignumber.js";
import PaymentForm from "./PaymentForm";
import { meoveoRestApi, useRequestStripeTopupMutation } from "../../../service/api/meveoApi";
import { currencies } from "../../../configs/constant";

const PaymentFirstStep = ({ onUpdate, onSuccess, onClose, accessToken }) => {
	const [amount, setAmount] = useState(0);
	// const [isLoading, setIsLoading] = useState(false);
	const [requestStripeTopup, { isLoading, data }] = useRequestStripeTopupMutation();

	const onGoToPayment = async () => {
		requestStripeTopup({
			data: {
				amount: new BigNumber(amount).multipliedBy(100).toString(),
				currency: "eur",
			},
		});
	};

	const onChangeAmount = (event, value) => {
		let fieldValue = !!event ? event?.target?.value : value;
		setAmount(fieldValue);
		typeof onUpdate === "function" && onUpdate(fieldValue);
	};

	useEffect(() => {
		if ( !isLoading && data && data.status === 'success' && data.result ) {
			onSuccess(data.result);
		}
	}, [onSuccess, isLoading, data])

	const currency = Object.values(currencies).filter(item => item.currency === 'EUR' )[0];
	return (
		<>
			<DialogContent>
				<fieldset disabled={false}>
					<Box className="form__topup_balance">
						<FormControl fullWidth sx={{ m: 1 }} disabled={isLoading}>
							<InputLabel htmlFor="outlined-adornment-amount">{`${t("common:amount")} (EUR)`}</InputLabel>
							<OutlinedInput
								key={`topup-amount`}
								id="balance"
								startAdornment={<InputAdornment position="start">{currency.symbol}</InputAdornment>}
								label={`${t("common:amount")} (EUR)`}
								onChange={onChangeAmount}
							/>
						</FormControl>
					</Box>
				</fieldset>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => onClose()} size="medium" variant="text" disabled={isLoading}>
					{t("common:cancel")}
				</Button>

				<LoadingButton
					type="submit"
					size="small"
					variant="contained"
					color="secondary"
					onClick={onGoToPayment}
					disabled={isLoading || !+amount || +amount < 0.5}
					loading={isLoading}
				>
					{t("common:continue")}
				</LoadingButton>
			</DialogActions>
		</>
	);
};

const TopupBalance = (props) => {
	const [step, setStep] = useState(0);
	const [amount, setAmount] = useState("0");
	const [clientSecret, setClientSecret] = useState("");
	const [open, toggleDialog] = useState(false);
	const { sx, walletAddress } = props;

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const cleanState = () => {
		setStep(0);
		setAmount(0);
		setClientSecret("");
	};

	const handleClose = () => {
		toggleDialog(false);
	};

	const onGoToPrevStep = () => {
		setStep(0);
	};

	useEffect(() => {
		cleanState();
	}, [open]);

	useEffect(() => {
		// Load stripe
		typeof window.stripePromise === "undefined" && (window.stripePromise = loadStripe(config.PUBLISHABLE_KEY))
	}, []);

	if (!walletAddress) return null;
	return (
		<>
			<Button
				sx={sx}
				variant="text"
				color="secondary"
				size="medium"
				className="topup__button btn-add-token"
				onClick={() => toggleDialog(true)}
			>
				<span
					className="icon icon--wallet2"
					style={{
						marginRight: "1em",
						marginLeft: 0,
					}}
				></span>
			{t("pages:wallet.topup")}
			</Button>

			<Dialog fullScreen={fullScreen} className="dialog" open={open} onClose={handleClose}>
				<DialogTitle>
					{t("pages:wallet.topup")}
					<IconButton aria-label="close" onClick={handleClose}>
						<FontAwesomeIcon icon={regular("circle-xmark")} />
					</IconButton>
				</DialogTitle>
				{!!open && (
					<>
						{(!step || step !== 1) && (
							<PaymentFirstStep
								onUpdate={(no) => setAmount(no)}
								onSuccess={({ clientSecret }) => {
									setClientSecret(clientSecret);
									setStep(1);
								}}
								onClose={handleClose}
							/>
						)}
						{!!step && step === 1 && (
							<Elements
								stripe={window.stripePromise}
								options={{
									clientSecret,
								}}
							>
								<PaymentForm
									onGoToPrevStep={onGoToPrevStep}
									clientSecret={clientSecret}
									accessToken={props.accessToken}
									onClose={handleClose}
									emailAddress={props.emailAddress}
									amount={amount}
								/>
							</Elements>
						)}
					</>
				)}
			</Dialog>
		</>
	);
};

export default connect((state) => {
	const wallet = state?.wallet;
	const auth = state?.auth;
	return {
		walletAddress: wallet?.address,
		accessToken: auth.accessToken,
		emailAddress: wallet?.private?.email?.address,
	};
})(TopupBalance);
