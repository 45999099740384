import { createContext, useContext, useReducer } from "react";

export const PlatformContext = createContext(null);
export const PlatformDispatchContext = createContext(null);

const initialState = {
	navigationIntercept: () => false
};

function PlatformReducer(payload: any, action: any) {

	switch (action.type) {
		case "navigation_intercept":
			payload.navigationIntercept = action.data.interceptHandle; // must be uuid of image document
			return payload;
		case "reset":
			payload.navigationIntercept = () => false;
			return payload;
	}
	return payload;
}

export function PlatformContextProvider({ children }) {
	const [data, dispatch] = useReducer(PlatformReducer, initialState);

	return (
		<PlatformContext.Provider value={data}>
			<PlatformDispatchContext.Provider value={dispatch}>{children}</PlatformDispatchContext.Provider>
		</PlatformContext.Provider>
	);
}

export function usePlatformContext() {
	return useContext(PlatformContext);
}

export function usePlatformDispatch() {
	return useContext(PlatformDispatchContext);
}
