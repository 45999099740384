import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useImageCompare, useImageCompareDispatch } from "./ImageCompareContext";


const Spacer = () => {
	const ref = useRef();
	const settings = useImageCompare();
	const dispatch = useImageCompareDispatch();

	useEffect(() => {
		dispatch({
			type: "init_spacer",
			data: {
				ref: ref.current
			}
		})
	}, [dispatch])

	return (
		<Box
			ref={ref}
			className="spacer image-compare__spacer"
			sx={{ left: `${ settings?.spacer?.left || 0 }%` }} 
		>
		</Box>
	)
}

export default Spacer;