import {
	Alert,
	Box,
	Button,
	Drawer,
	FormControl,
	Grid,
	IconButton,
	OutlinedInput,
	Typography,
} from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { documentPrivacyTypes } from "../../../configs/constant";
import { usePrivacy } from "../ShareTokenPrivacy/TokenPrivacyContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ShareCopy from "../../ShareCopy";
import { toast } from "react-toastify";

interface IViewProps {
	label?: string;
	token: any;
	sharedURL?: string;
	publicShare?: boolean;
	onConfirm?: (options: any) => void;
	onClose?: () => void;
}

const ShareTokenSubmit = (props: IViewProps) => {
	const privacySettings: any = usePrivacy();

	const { label, token, publicShare, sharedURL, onConfirm, onClose } = props;
	const [showShare, toggleShare] = useState<any>(false);
	const container = document.querySelector(".token__share--main");

	const shareLink = showShare === 'public' ? `${window.location.origin}/token/public/${token.token.uuid}` : null;

	const tokenPrivacy = privacySettings.sharedOptions[`token.tokenPrivacy`] || documentPrivacyTypes.PRIVATE;
	const note =
		tokenPrivacy === documentPrivacyTypes.PUBLIC
			? t("pages:token.privacy_settings.public_share_note")
			: tokenPrivacy === documentPrivacyTypes.SHARED
				? t("pages:token.privacy_settings.hashed_share_note")
				: t("pages:token.privacy_settings.warning_share_digital_twin");

	const _onClose = () => {
		toggleShare(false);
		onClose && onClose();
	}

	useEffect(() => {
		if (!!sharedURL) {
			toggleShare(true);
		}
	}, [sharedURL]);

	return (
		<Box className="w--100">
			{documentPrivacyTypes.PRIVATE === tokenPrivacy ? (
				<Alert>{note}</Alert>
			) : (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Button
								className='w--100'
								variant='outlined'
								color='secondary'
								onClick={_onClose}
							>
								{t('common:cancel')}
							</Button>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Button
								className="w--100"
								onClick={() => onConfirm ? onConfirm(privacySettings.sharedOptions) : toggleShare(true)}
								size="medium"
								color="secondary"
								variant="contained"
							>
								{label || t("pages:token.privacy_settings.share_restrict_token")}
							</Button>
						</Grid>
					</Grid>
					<ShareCopy
						visible={!!showShare}
						title={t("pages:token.share")}
						message={t("pages:token.privacy_settings.hashed_share_note")}
						link={shareLink || sharedURL || ''}
						onCopied={() => toast.success(t('pages:token.link_copied_success'))}
						onDismiss={_onClose}
					/>
					{/* <Drawer
						className="token__share-link"
						container={container}
						anchor="bottom"
						open={!!showShare}
						onClose={_onClose}
						sx={{
							textAlign: "center",
						}}
					>
						<Typography variant="h5">{token.token.name}</Typography>
						<Typography variant="body2">
							{t("pages:token.privacy_settings.hashed_share_note")}
						</Typography>

						<FormControl sx={{ mt: 2 }} variant="outlined">
							<OutlinedInput
								className="token__share-link-input"
								value={shareLink || sharedURL}
								endAdornment={
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => navigator.clipboard.writeText(`${shareLink || sharedURL}`)}
										edge="end"
										sx={{ mr: 0.5 }}
									>
										<FontAwesomeIcon style={{
											fontSize: "12px",
										}} icon={solid("copy")} />
									</IconButton>
								}
							/>
						</FormControl>
					</Drawer> */}
				</>
			)}
		</Box>
	);
};

export default ShareTokenSubmit;
