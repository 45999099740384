import { Buffer } from "buffer";
global.Buffer = Buffer;
/**
 * Polyfill for Math.pow work with BigInt of some library
 */
const originPow = Math.pow.bind(null);
Math.pow = (base, exponent) => {
  if ("undefined" === typeof window.BigInt || "bigint" !== typeof base || "bigint" !== typeof exponent)
    return originPow(base, exponent)
     
  // use BigInt arithmetic if either input is a BigInt
  base = window.BigInt(base);
  exponent = window.BigInt(exponent);
  let result = 1n;
  while (exponent > 0n) {
    if (exponent % 2n === 1n) {
      result *= base;
    }
    exponent >>= 1n;
    base *= base;
  }
  return result;
}