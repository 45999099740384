import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Menu, MenuItem, MenuList, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import "./ListOperatorUsers.scss";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FormField } from "../Form/FormField";
import { t } from "i18next";
import { useGetManagedOperatorUsersQuery } from "../../service/api/meveoApi";
import ConfirmDialog from "../ConfirmDialog";
import { toast } from "react-toastify";

type OperatorUserType = {
	uuid: string;
	username: string;
	email: string;
	password?: string;
	status: {
		icon: string;
		label: string;
	};
	onEdit: Function
	onRemove: Function
}

const OperatorUserRow = (props: OperatorUserType) => {
	const { uuid, username, email, password, status, onEdit, onRemove } = props;
	const [menuOpen, toggleMenu] = useState(false);

	const anchorMenu = useRef(null);

	const closeMenuHandle = () => {
		toggleMenu(false);

	}
	return (
		<TableRow className="operator-user">
			<TableCell className="operator-user__name">{username}</TableCell>
			<TableCell className="operator-user__email">{email}</TableCell>
			<TableCell className="operator-user__password">
				<span className="password--placeholder">
					<span></span>
					<span></span>
				</span>
			</TableCell>
			<TableCell className="operator-user__status">
				<span className={`status status--${status.toLowerCase()}`}>{status}</span>
			</TableCell>
			<TableCell className="operator-user__actions">
				<IconButton
					ref={anchorMenu}
					onClick={() => toggleMenu(true)}
				>
					<FontAwesomeIcon icon={solid('ellipsis-vertical')} />
				</IconButton>
				<Menu
					anchorEl={anchorMenu.current}
					open={menuOpen}
					onClose={closeMenuHandle}
				>
					<MenuItem
						onClick={() => {
							closeMenuHandle();
							typeof onEdit === "function" && onEdit(uuid);
						}}
					>
						{t("common:edit")}
					</MenuItem>
					<MenuItem onClick={() => {
						closeMenuHandle();
						typeof onRemove === "function" && onRemove(uuid);
					}}><Typography color="secondary">{t("common:remove")}</Typography></MenuItem>
				</Menu>
			</TableCell>
		</TableRow>
	)
}

const ListOperatorUsers = () => {
	const [editUserModal, toggleEditUserModal] = useState<string | boolean>(false);
	const [removeUserModal, toggleRemoveUserModal] = useState<string | boolean>(false);
	const { data, isFetching, error } = useGetManagedOperatorUsersQuery({});

	const onEdit = (uuid: string) => {
		toggleEditUserModal(uuid);
	}
	const visibleData = !isFetching && data && data.status === 'success' && !!data.result ? data.result : [];
	const isComingsoon = true;

	const onRemove = (uuid: string) => {
		toggleRemoveUserModal(uuid);
	}

	const handleRemoveUser = () => {
		toast.info('Coming soon')
	}

	const saveUserChanges = () => {
		toggleEditUserModal(false)
		toast.info('Coming soon')
		toast.success(t('pages:profile.successfully_edit_user'))
	}

	return (
		<>
			<Table className="table non-responsive table__managed-operator-users">
				<TableHead>
					<TableRow>
						<TableCell>Operator User</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>Password</TableCell>
						<TableCell>Status</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{!!isFetching && (
						<>
							<TableRow>
								<TableCell colSpan={5}>
									<Skeleton variant="rectangular" width="100%" height={40} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={5}>
									<Skeleton variant="rectangular" width="100%" height={40} />
								</TableCell>
							</TableRow>
						</>
					)}
					{visibleData.length <= 0 ?
						<>
							{isFetching ? null :
								<TableRow className="empty-users">
									<TableCell sx={{ textAlign: "center" }} colSpan={5}>
										<Box component="span" className="icon icon--empty-wallet"></Box>
									</TableCell>
								</TableRow>
							}
						</>
						: visibleData.map((item: any, idx: number) => {
							return (
								<OperatorUserRow
									key={`operator-user-${idx}`}
									{...item}
									onEdit={onEdit}
									onRemove={onRemove}
								/>
							)
						})}
				</TableBody>
			</Table>

			<Dialog
				className="dialog edit_operator-user-dialog"
				open={!!editUserModal}
				onClose={() => toggleEditUserModal(false)}
			>
				<DialogTitle>
					{t('pages:profile.edit_operator_user')}
					<IconButton aria-label="close" onClick={() => toggleEditUserModal(false)}>
						<span className="icon icon--close"></span>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					{!!editUserModal && visibleData.filter((item: any) => item.uuid === editUserModal).map((item: any, idx: number) => {
						return (
							<fieldset disabled={isComingsoon}>
								<Box>
									<label htmlFor="username">{(t('common:name'))}</label>
									<FormField
										fieldId="username"
										value={item.username}
										label={null}
										disabled={isComingsoon}
									/>
								</Box>
								<Box>
									<label htmlFor="email">{(t('common:email_address'))}</label>
									<FormField
										fieldId="email"
										value={item.email}
										label={null}
										disabled={isComingsoon}
									/>
								</Box>
								<Box>
									<label htmlFor="password">{t("common:password")}</label>
									<FormField
										fieldId="password"
										value={item.password}
										label={null}
										type="password"
										disabled={isComingsoon}
									/>
								</Box>
							</fieldset>
						)
					})}
				</DialogContent>
				<DialogActions>
					<Grid container columnSpacing={2}>
						<Grid item xs={6} sm={6} md={6} lg={6}>
							<Button
								className="w--100"
								variant="outlined"
								size="medium"
								color="secondary"
								onClick={() => toggleEditUserModal(false)}
							>
								{t("common:cancel")}
							</Button>
						</Grid>
						<Grid item xs={6} sm={6} md={6} lg={6}>
							<Button
								className="w--100"
								variant="contained"
								size="medium"
								color="secondary"
								onClick={saveUserChanges}
							>
								{t("common:save")}
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>

			{!!removeUserModal && <ConfirmDialog
				visible={!!removeUserModal}
				title={t("pages:profile.confirm_remove_operator_user", { name: visibleData.find((item: any) => item.uuid === removeUserModal).username })}
				message={t("pages:profile.remove_operator_message")}
				confirmLabel={t("common:remove")}
				cancelLabel={t("common:cancel")}
				onConfirm={() => {
					handleRemoveUser();
					toggleRemoveUserModal(false);
				}}
				onDismiss={() => {
					toggleRemoveUserModal(false);
				}}
			/>}
		</>
	)
}

export default ListOperatorUsers;