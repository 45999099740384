import React, { useEffect, useState } from 'react'
import GenerateTokenCertificate from '../../../components/Token/GenerateTokenCertificate/GenerateTokenCertificate'
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { meoveoRestApi } from '../../../service/api/meveoApi';
import Loading from '../../../components/Loading';
import Center from '../../../components/Center';
import './CertificateGenerator.scss';
import { standPost } from '../Standalone';

const CertificateGenerator = (props: any) => {
  const { isLoggedIn } = props;
  const [params] = useSearchParams();
  const [token, setToken] = useState<any>(null);
  const [getTokenById] = meoveoRestApi.endpoints.getTokenById.useLazyQuery();

  const getToken = async (token: string) => {
    const { data } = await getTokenById({ id: token, mode: 'default' });
    setToken(data?.result);
  }

  useEffect(() => {
    const token = params.get('token');
    if (isLoggedIn && token) {
      getToken(token);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    window.addEventListener("message", message => {
      if (message.data) {
        try {
          const data = JSON.parse(message.data);
          if (data.action === 'appInitToken') {
            setToken(data.token);
          }
        } catch (e) {
          console.log(e);
        }
      }
    });
    standPost({ action: 'loaded' })
  }, []);

  return (
    token
      ? <GenerateTokenCertificate token={token} standalone={true} />
      : <Center><Loading /></Center>
  )
}

export default
  connect(
    (state: any) => {
      let auth = state.auth;

      return {
        isLoggedIn:
          !!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime()
            ? auth.accessToken
            : false,
      };
    }
  )(CertificateGenerator);
