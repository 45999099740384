import { Box, Typography } from "@mui/material";

const Empty = () => {
	return (
		<Box className="transactions--empty flex flex--vertical flex--justify-center flex--align-center">
			<Box className="icon icon--empty icon--transactions"></Box>
			<Typography>{`Here is where you will find your transaction history.`}</Typography>
			<Typography>{`You have not made any transactions yet.`}</Typography>
		</Box>
	);
};
export default Empty;