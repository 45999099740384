import { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { getBalance } from "../../store/actions/walletActions";

const BalanceLoader = ({ address, getBalance }) => {
	
	useEffect(() => {
		
		const activeDetection = () => {
			!!window.activeBrowser && clearTimeout(window.activeBrowser);
			window.activeBrowser = setTimeout(() => getBalance(address), 600);
		};

		// Bind event checking for balance
		// activeDetection();
		// window.addEventListener("mousemove", activeDetection);
		// // Cleaners
		// return () => {
		// 	!!window.activeBrowser && clearTimeout(window.activeBrowser);
		// 	window.removeEventListener("mousemove", activeDetection);
		// };
		
		getBalance(address);
	}, [address, getBalance])

	return (
		<></>
	)
}

export default connect(
	state => ({
		address: state?.wallet?.address
	}),
	dispatch => ({
		getBalance: (address) => {
			dispatch(getBalance({ address }));
		}
	})
)(BalanceLoader);
