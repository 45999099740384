import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { meoveoRestApi } from '../../../service/api/meveoApi';
import Loading from '../../../components/Loading';
import Center from '../../../components/Center';
import '../ShareTokenSelector/ShareTokenSelector.scss';

import "../../../components/Token/TokenDetail.scss";
import { standPost } from '../Standalone';
import TokenDetailEdit from "../../../components/Token/EditToken";
import { TokenEditProvider } from '../../../components/Token/EditToken/EditTokenContext';
import { PlatformLayout } from '../../../layout';
import { t } from 'i18next';

const EditTokenWebApp = (props: any) => {
  const { isLoggedIn } = props;
  const [params] = useSearchParams();
  const [token, setToken] = useState<any>(null);
  const [getTokenById] = meoveoRestApi.endpoints.getTokenById.useLazyQuery();

  const getToken = async (token: string) => {
    const { data } = await getTokenById({ id: token, mode: 'default' });
    setToken(data?.result);
  }

  useEffect(() => {
    const token = params.get('token');
    if (isLoggedIn && token) {
      getToken(token);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    window.addEventListener("message", message => {
      if (message.data) {
        try {
          const data = JSON.parse(message.data);
          if (data.action === 'appInitToken') {
            setToken(data.token);
          }
        } catch (e) {
          console.log(e);
        }
      }
    });
    standPost({ action: 'loaded' })
  }, []);

  return (
    token
      ? <PlatformLayout
        hideSidebar={true}
        standalone={true}
        pageTitle={t('pages:token.edit_digital_passport')}
        className="site single token-single"
      >
        <TokenEditProvider>
          <TokenDetailEdit
            token={token}
            onCancel={() => {
              standPost({ action: 'cancel' });
            }}
            onUpdate={() => {
              standPost({ action: 'success', data: true });
            }}
            standalone={true}
          />
        </TokenEditProvider>
      </PlatformLayout>
      : <Center><Loading /></Center>
  )
}

export default
  connect(
    (state: any) => {
      let auth = state.auth;

      return {
        isLoggedIn:
          !!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime()
            ? auth.accessToken
            : false,
      };
    }
  )(EditTokenWebApp);
