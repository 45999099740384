import { createContext, useContext, useReducer } from "react";

export const TokenEditContext = createContext(null);
export const TokenEditDispatchContext = createContext(null);

const initialState: any = {
	uuid: '',
	name: '',
	description: '',
	// specs
	tokenMarkReference: '',
	value: '',
	// dimensions
	weightInKilo: '',
	widthInMeter: '',
	depthInMeter: '',
	lengthInMeter: '',
	addedDocuments: [],
	deletedDocuments: [],
	deferredCoverImage: '',
};

function tokenEditReducer(payload: any, action: any) {
	const data = action?.data;
	let newState;
	try {
		newState = JSON.parse(JSON.stringify(payload));
	} catch (error) {
		newState = {};
	}

	switch (action.type) {
		case "init_token":
			try {
				const changes = JSON.parse(JSON.stringify(data));
				for (let key in initialState) {
					newState[key] = changes[key];
				}
				if (!newState.addedDocuments) {
					newState.addedDocuments = [];
				}
				if (!newState.deletedDocuments) {
					newState.deletedDocuments = [];
				}
				newState.isChanged = false;
				return newState;
			} catch (error) {
				console.log(error);
			}
			return payload;
		case "update_field":
			if (!data) return newState;

			const keys = Object.keys(data);
			if (!keys.includes('key') || !keys.includes('value')) return newState;

			newState[data.key] = data.value;
			newState.isChanged = true;
			return newState;
		case "set_cover_image":
			newState.deferredCoverImage = data.documentId; // must be uuid of image document
			newState.isChanged = true;
			return newState;
		case "add_document":
			if (!data) return newState;
			newState.addedDocuments.push(data);
			newState.isChanged = true;
			return newState;
		case "delete_document":
			if (!data) return newState;
			if (newState.addedDocuments.find((e: any) => e.uuid === data.uuid)) {
				newState.addedDocuments = newState.addedDocuments.filter((e: any) => e.uuid !== data.uuid);
			} else {
				newState.deletedDocuments.push(data);
				newState.isChanged = true;
			}
			if (newState.deferredCoverImage === data.uuid) {
				newState.deferredCoverImage = '';
			}
			return newState;
		case "reset_changed":
			for (let key in initialState) {
				newState[key] = initialState[key];
			}
			newState.addedDocuments = []
			newState.deletedDocuments = []
			newState.deferredCoverImage = '';
			newState.isChanged = false;
			return newState;
		default: {
			return newState;
		}
	}
}

export function TokenEditProvider({ children }) {
	const [data, dispatch] = useReducer(tokenEditReducer, initialState);

	return (
		<TokenEditContext.Provider value={data}>
			<TokenEditDispatchContext.Provider value={dispatch}>{children}</TokenEditDispatchContext.Provider>
		</TokenEditContext.Provider>
	);
}

export function useTokenEdit() {
	return useContext(TokenEditContext);
}

export function useTokenEditDispatch() {
	return useContext(TokenEditDispatchContext);
}
