import React from "react";
import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { PlatformLayout as Layout } from "../../layout";
import ContactForm from "../../components/ContactForm";

const Support = (props) => {
	return (
		<Layout
			hideSidebar={false}
			pageTitle={t("pages:help.menu_title")}
			className="site single support"
		>
			<Box className="privacy-support-wrapper" sx={{ pt: 2 }}>
				<ContactForm />
			</Box>
		</Layout>
	);
};

export default Support;
