import React, { useMemo } from 'react'
import { useParams, } from 'react-router-dom';
import { useGetTokenByIdQuery } from '../../service/api/meveoApi';
import TokenShareLayout from './TokenShareLayout';
import TokenShareHeader from './TokenShareHeader';
import { TokenDetail, TokenDetailLoading } from '../../components/Token';

const TokenSharePublic = () => {
  const { id } = useParams();
  const { data, isFetching, isLoading } = useGetTokenByIdQuery({ id, mode: 'public' });

  const token = useMemo(() => {
    return data?.result;
  }, [data?.result]);

  return (
    <TokenShareLayout>
      <TokenShareHeader />
      <div className='tokenshare-body'>
        {!!isFetching || !token ? (
          <TokenDetailLoading />
        ) : (
          <TokenDetail
            token={token}
            isFetching={isFetching}
            isLoading={isLoading}
            mode={'public'}
          />
        )}
      </div>
    </TokenShareLayout>
  )
}

export default TokenSharePublic
