import React from 'react'
import { connect } from 'react-redux';
import { Box } from '@mui/material';

import Wallet from '../../Wallet';
import './TokenListWebApp.scss';

const TokenListWebApp = (props: any) => {

  const handleRefresh = async () => {
    window.location.reload();
  }

  return (
    <Box id="TokenListWebApp">
        <Wallet />
    </Box>
  )
}

export default
  connect(
    (state: any) => {

      return {
        store: state,
      };
    }
  )(TokenListWebApp);