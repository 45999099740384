import React, { useRef, useState } from 'react';
import './ZoomImage.scss';

interface IZoomImage {
  src: string;
  srcSmall: string;
  alt?: string;
  onClick?: () => void;
}

const Zoom = ({ src, srcSmall, alt, onClick }: IZoomImage) => {
  const backgroundImage = `url(${src})`;
  const [backgroundPosition, setBackgroundPosition] = useState('0% 0%');
  const [isZoomed, setIsZoomed] = useState(false);
  const [width, setWidth] = useState(0);
  const [offsetX, setOffsetX] = useState(0);

  const imageRef = useRef<any>();
  const figureRef = useRef<any>();

  const zoomed = isZoomed ? 'zoomed' : '';

  const handleMouseMove = (e: any) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();

    const x = ((e.clientX - left) * 100 / width);
    const y = ((e.clientY - top) * 100 / height);
    setBackgroundPosition(`${x}% ${y}%`);
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed)
    if (onClick) onClick()
  }

  const _onImageLoaded = () => {
    const imgRect = imageRef.current?.getBoundingClientRect();
    const figRect = figureRef.current?.getBoundingClientRect();

    setWidth(imgRect.width)
    setOffsetX(imgRect.left - figRect.left)
  }

  return (
    <div className={`zoom-image ${zoomed}`} onClick={toggleZoom}>
      <img ref={imageRef} src={srcSmall} alt={alt} onLoad={_onImageLoaded} />
      <figure
        ref={figureRef}
        onMouseMove={handleMouseMove}
        style={{ backgroundImage, backgroundPosition, width }}
      >
        <img src={srcSmall} alt={alt} />
      </figure>
    </div>
  );
};

export default Zoom;