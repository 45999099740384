import { useEffect, useState } from "react";
import { connect } from "react-redux"
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import { updateWallet } from "../../store/actions/walletActions";
import i18next, { t } from "i18next";
import { unitTypes } from "../../configs/constant";

const UnitSettings = props => {
  const { unit, isUpdating, updateUnit } = props;
  const [active, setActive] = useState('');

  const changeUnit = event => {
    const newUnit = event.target.value;
		const validKeys = Object.values(unitTypes).map(item => item.key);

    if ( validKeys.indexOf(newUnit) < 0 ) {
      return;
    }
    setActive(newUnit);
    updateUnit(newUnit);
  }

  useEffect(() => {
    if (unit) setActive(unit);
  }, [unit])
  
  return (
    <FormControl disabled={isUpdating==='loading'}>
      <FormLabel id="unit-setting-label">{t("pages:profile.unit")}</FormLabel>
      <RadioGroup
        aria-labelledby="unit-setting"
        name="unit-setting"
        value={active}
        onChange={changeUnit}
      >
				{Object.values(unitTypes).map((type,idx) => <FormControlLabel key={`user-unit-setting-${type.key}`} value={type.key} control={<Radio />} label={type.label} />)}
        
      </RadioGroup>
    </FormControl>
  )
}

export default connect(state => {
  const walletState = state?.wallet
  return {
    unit: walletState?.public?.unit,
    isUpdating: walletState?.isUpdating
  }
}, dispatch => {
  return {
    updateUnit: (unit) => {
      dispatch(updateWallet({
        public: {
          unit: unit
        }
      }))
    }
  }
})(UnitSettings);