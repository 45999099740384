import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, Skeleton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateWallet } from "../../store/actions/walletActions";
import { t } from "i18next";

const WalletDescription = props => {
  const { description, updateDescription, isUpdating, isFetching } = props;
  const [editing, setEdit] = useState(false);
  const [value, setValue] = useState(description || '');

  const toggleEditor = (status) => {
    setValue(description);
    setEdit(status);
  }

  const changeHandle = (event) => {
    setValue(event.target.value);
  }

  const submitHandle = () => {
    updateDescription(value)
  }

  useEffect(() => {
    if ( isUpdating === 'success' ) {
      setEdit(false)
    }
  }, [isUpdating])

  return (
    <div className="description">
      <div className={`description__editor ${editing?'editing':'flex flex--nowrap flex--align-center'}`}>
        {isFetching === 'loading' ?
          // Skeleton for loading
          <Skeleton sx={{ bgcolor: 'var(--unikbase-gray)' }} variant="rounded" width={210} height={60} />
          : (editing ? 
            <TextField
              className="description__editor-field w--100"
              id="wallet-description"
              multiline
              rows={2}
              value={value}
              onChange={changeHandle}
              sx={{
                pb: '4px'
              }}
              disabled={isUpdating==='loading'}
            />
            : <div className="description__content">{description}</div>)
        }
        <div className="editor__actions" >
          {!editing ? 
            <IconButton onClick={() => toggleEditor(true)}><FontAwesomeIcon icon={solid('pen')} /></IconButton>
            :
            <>
              <Button sx={{ border: "none" }} size="small" variant="outlined" onClick={() => toggleEditor(false)}>{t("common:cancel")}</Button>
              <Button sx={{ border: "none" }} color="secondary" size="small" variant="contained" onClick={submitHandle}>{t("common:submit")}</Button>
            </>
          }
        </div>
      </div>
    </div>
  )
}


export default connect(state => {
  const wallet = state.wallet;
  return {
    description: wallet?.public?.description || '',
    isUpdating: wallet?.isUpdating,
    isFetching: wallet?.status
  }
}, dispatch => {
  return { 
    updateDescription: (text) => {
      dispatch(updateWallet({
        public: {
          description: text
        }
      }))
    }
  } 
})(WalletDescription);
