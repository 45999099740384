import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, Slider, Stack } from "@mui/material"
import { useEffect, useState } from "react";
import { useImageCompare, useImageCompareDispatch } from "./ImageCompareContext";

const AttributeBar = (props) => {
	const context = useImageCompare();
	const dispatch = useImageCompareDispatch();
	const [activeBar, setActiveBar] = useState(false);
	const config = context.images[context.editting] || {};
	const [rotation, updateRotation] = useState(config?.rotation || 0);
	const [scale, updateScale] = useState(config?.scale || 1);

	const switchHandle = (type) => {
		if ( !activeBar ) {
			setActiveBar(type);
			return;
		}
		if (type === activeBar) {
			setActiveBar(false);
			return;
		}
		setActiveBar(type);
	}

	const onChangeHandle = (type, value) => {
		type === 'rotation' ? updateRotation(value) : updateScale(value);
		dispatch({
			type: 'zoom',
			data: {
				key: context.editting,
				[type]: value
			}
		});
	}

	if ( !config ) return null;

	return (
		<Box className={`image-compare__attributes flex flex--horizontal flex--nowrap ${!!context.editting ? context.editting : ''}`}>
			<Button className="start-edit" size="small">Edit</Button>
			<Stack className={`action ${activeBar==='rotation' ? 'active' : ''}`} direction="column" onClick={() => switchHandle('rotation')}>
				<Slider 
					max={180}
					min={-180}
					step={0.01}
					value={-1*(config?.rotation || 0)}
					onChange={(event, value) => onChangeHandle('rotation', `${-1*value}`)}
					size="small"
					aria-label="rotation"
					valueLabelDisplay="auto"
					orientation="vertical"
					color="white"
				/>
				<IconButton>
					<FontAwesomeIcon icon={solid("rotate-right")} />
				</IconButton>
			</Stack>
			<Stack className={`action ${activeBar==='scale' ? 'active' : ''}`} direction="column" onClick={() => switchHandle('scale')}>
				<Slider 
					max={3}
					min={0}
					step={0.02}
					value={config?.scale || 1}
					onChange={(event, value) => onChangeHandle('scale', value)}
					size="small"
					aria-label="rotation"
					valueLabelDisplay="auto"
					orientation="vertical"
					color="white"
				/>
				<IconButton>
					<span className="stack" >
						<span>+</span>
						<span>-</span>
					</span>
				</IconButton>
			</Stack>
		</Box>
	)
}

export default AttributeBar;