import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { PlatformLayout as Layout } from "../../layout";
import { default as Privacy } from "../Others/PrivacyPolicy";

const PrivacyPolicy = (props) => {
	const html = t("pages:privacy_policy.html");
	
	return (
		<Layout
			hideSidebar={false}
			pageTitle={t("pages:privacy_policy.page_title")}
			className="site single privacy-policy"
		>
			<Box className="privacy-policy-wrapper" sx={{ pt: 2 }}>
				<div dangerouslySetInnerHTML={{ __html: html }}></div>
			</Box>
		</Layout>
	);
};

export default PrivacyPolicy;
