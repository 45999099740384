import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Button, Skeleton } from "@mui/material";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { fileToBase64 } from "../../utils/helpers";
import { updateWallet } from "../../store/actions/walletActions";
import { useUploadDocumentsMutation } from "../../service/api/uploadApi";
import { proxyS3UrlGeneratorWithKey, proxySrcReplace } from "../../components/Gallery/ImageProxy";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

const UserImageSetting = ({ photoBase64, avatarKey, updatePhoto, updating, isOperatorUser }) => {
	const inputRef = useRef(null);
	const [uploadFiles, { isLoading, data }] = useUploadDocumentsMutation();
	const fileKey = "avatarKey";

	// triggers when file is selected with click
	const handleChange = async function (e) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			let image = e.target.files[0];
			if (!!image.type && image.type.startsWith("image/")) {
				const formData = new FormData();
				formData.append(fileKey, image);

				uploadFiles({ data: formData });
				inputRef.current.value = "";
			}
		}
	};

	const onClick = () => {
		inputRef.current.click();
	};

	useEffect(() => {
		if (!isLoading && !!data) {
			if (data.status === "success" && data?.result?.files.length > 0) {
				let photo = data.result.files[0].file;
				updatePhoto(`${photo["key"]}`);
			}
		}
	}, [isLoading, data, updatePhoto]);

	let userImageSrc = !!avatarKey
		? proxyS3UrlGeneratorWithKey(avatarKey, 250, 250)
		: photoBase64
		? `data:image/png;base64,${photoBase64}`
		: "";
	let disabled = !!isLoading || !!updating;
	return (
		<>
			{ disabled ? (
				<Skeleton variant="circular" width="250" height="250" sx={{ m: "0 auto", width: "250px", height: "250px" }} />
			) : (
				<Avatar src={userImageSrc} sx={{ m: "0 auto", width: "250px", height: "250px", maxWidth: "100%" }} />
			)}

			{!isOperatorUser &&
				<>
					<input onChange={handleChange} ref={inputRef} type="file" accept="image/png, image/jpeg, image/gif" style={{ display: "none" }} />
					<LoadingButton
						onClick={onClick}
						variant="outlined"
						className="flex flex--horizontal flex--justify-center flex--align-center btn-update-photo"
						sx={{ transform: "translate(0, -50%)" }}
						loading={disabled}
						size="small"
					>
						<span className="icon icon--camera"></span>
						<span style={{ fontSize: "12px", lineHeight: "1.618em" }}>{t("common:edit")}</span>
					</LoadingButton>
				</>
			}
		</>
	);
};

export default connect(
	(state) => {
		let data = state?.wallet;
		return {
			photoBase64: data.public?.base64Avatar,
			avatarKey: data.public?.avatarKey,
			updating: data.isUpdating === 'loading',
			isOperatorUser: data.isOperatorUser
		};
	},
	(dispatch) => {
		return {
			updatePhoto: (imageUrl) => {
				dispatch(
					updateWallet({
						public: {
							avatarKey: imageUrl,
							base64Avatar: ''
						},
					})
				);
			},
		};
	}
)(UserImageSetting);
