import { createSlice } from "@reduxjs/toolkit";
import {
	processFile,
	createToken
} from "../actions/tokenActions";

const initialState = {
	fileInfo: { status: "idle", file: null, message: null },
	tokens: {},
	createTokenStatus: "idle",
	transactionError: null,
};

const initialTokenState = {
	status: null,
	token: null,
	message: null,
};

const tokenSlice = createSlice({
	name: "token",
	initialState: initialState,
	reducers: {
		clear: (state) => {
			return { ...state, ...initialState };
		},
		clearTransferStatus: (state, action) => {
			const { tokenId } = action.payload;
			state.tokens = {
				...state.tokens,
				[tokenId]: {
					...(state.tokens[tokenId] || {}),
					mintAndPrepare: { status: 'idle' },
					propose: { status: 'idle' },
					refuse: { status: 'idle' },
					acept: { status: 'idle' },
				}
			}
		},
		setTransactionError: (state, action) => {
			state.transactionError = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(processFile.pending, (state, action) => {
				const { arg: file } = action.meta;
				state.fileInfo = { status: "loading", file };
			})
			.addCase(processFile.fulfilled, (state, action) => {
				const { arg: file } = action.meta;
				state.fileInfo = { status: "success", file };
			})
			.addCase(processFile.rejected, (state, action) => {
				const { file, message } = action.payload;
				state.fileInfo = { status: "error", file, message };
			});

		builder
			.addCase(createToken.pending, (state, action) => {
				const { token } = action.meta.arg;
				state.tokens = { ...state.tokens, [token.uuid]: { ...initialTokenState, token, status: "loading" } };
			})
			.addCase(createToken.fulfilled, (state, action) => {
				const { token } = action.payload;
				state.tokens = { ...state.tokens, [token.uuid]: { ...initialTokenState, token, status: "success" } };
			})
			.addCase(createToken.rejected, (state, action) => {
				const { token, message } = action.payload;
				state.tokens = {
					...state.tokens,
					[token.uuid]: { ...initialTokenState, token, status: "error", message },
				};
			});
	},
});

export const { clear, clearTransferStatus, setTransactionError } = tokenSlice.actions;
export default tokenSlice.reducer;
