import { useEffect, useState } from "react";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import { connect } from "react-redux";
import { t } from "i18next";
import { requirePassword, setVerifiedPassword, updateKeyringController } from "../../store/slices/cryptoSlice";
import OTPVerify from "../../components/OTPVerify";
import SeedPhraseRevealVerify from "./SeedPhraseRevealVerify";


const RevealSeedPhrase = ({ keyringController, address, requirePassword, cleanPassword, toggleRequirePassword, setVerifiedPassword, verifiedPassword }) => {
	const [openOTP, showOTP] = useState(false);
	const [openSeedPhrase, showSeedPhrase] = useState(false);


	const onReveal = () => {
		setVerifiedPassword('');
		toggleRequirePassword(t('pages:profile.enter_password_to_reveal_seed_phrase'))
	}

	const onConfirmOTP = () => {
		showOTP(false)
		showSeedPhrase(true)
	}

	useEffect(() => {
		if (requirePassword === false && verifiedPassword && !!keyringController) {
			showOTP(true)
		}
		if (!requirePassword && !keyringController) {
			showOTP(false)
		}

	}, [requirePassword, verifiedPassword, address, keyringController])

	useEffect(() => {
		onReveal();
	}, [])

	if (!address ) {
		return (
			<Box>
				<Skeleton variant="text" width="12ch" />
				<Skeleton variant="rectangular" width="100%" height="40px" sx={{ transform: "scale(1)"}} />
			</Box>
		)
	}

	return (
		<Box>
			<Typography sx={{ mb: 2 }} variant="subtitle" component="label">
				<Typography variant="h3" className="setting__dialog-title">{t('pages:profile.reveal_seed_phrase')}</Typography>
			</Typography>
			<Button
				variant="outlined"
				size="large"
				color="secondary"
				sx={{ minWidth: 284 }}
				onClick={onReveal}
			>
				{t("common:reveal")}
			</Button>
			{!!verifiedPassword && openOTP && (
				<OTPVerify
					visible={true}
					onDismiss={() => showOTP(false)}
					onConfirm={onConfirmOTP} />
			)}
			{!!verifiedPassword && openSeedPhrase && (
				<SeedPhraseRevealVerify
					open={true}
					needVerify={true}
					password={verifiedPassword}
					onDismiss={() => showSeedPhrase(false)} />
			)}
		</Box>
	)
}

export default connect(state => {
	return {
		verifiedPassword: state?.crypto?.password,
		keyringController: state?.crypto?.keyringController,
		address: state?.wallet?.address,
		requirePassword: state?.crypto?.requirePassword
	}
}, dispatch => {
	return {
		toggleRequirePassword: (passwordHint) => {
			dispatch(requirePassword(passwordHint));
		},
		setVerifiedPassword: (password) => {
			dispatch(setVerifiedPassword(password));
		},
		cleanPassword: () => {
			dispatch(updateKeyringController());
		}
	}
})(RevealSeedPhrase);