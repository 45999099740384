import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";

import {
	Alert,
	Box,
	Button,
	FormControl,
	FormHelperText,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	Link,
	OutlinedInput,
	TextField,
	Typography,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular as icon } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

// Import from authReducer
import { getAuthToken } from "../../store/actions/authActions";
import { useTranslation } from "react-i18next";
import { isEmail } from "../../utils/helpers";
import { meoveoRestApi } from "../../service/api/meveoApi";
import { logout } from "../../store/slices/authSlice";

const Login = (props) => {
	const { getToken, isSubmitting, loginError, resetAuth } = props;
	const { t } = useTranslation();
	const [getUsernameByEmail] = meoveoRestApi.endpoints.getUsernameByEmail.useLazyQuery();

	const [formData, setFormData] = useState({
		username: "",
		password: "",
	});
	const [errors, setErrors] = useState(false);

	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const formValidate = () => {
		let checking = [];
		const usernameRegex = new RegExp(/^[^\s]+$/);
		const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_\{\}\[\]\=\+])(?=.{8,})/);

		if (!formData.username || !usernameRegex.test(formData.username)) {
			checking.push("Invalid username");
		}

		return checking;
	};
	const submitHandle = async (event) => {
		event.preventDefault();
		const validation = formValidate();
		if (validation.length > 0) {
			setErrors(validation);
			return;
		}
		setErrors(false);
		if ( isEmail(formData.username) ) {
			const data = await getUsernameByEmail(formData.username).unwrap();
			if ( data.status === 'success' && data.result ) {
				getToken(data.result, formData.password);
			}
		} else {
			getToken(formData.username, formData.password);
		}
	};

	const handleChange = (event) => {
		let value = event.target.value;
		value = value.replace(/\s/g, "");
		setFormData({
			...formData,
			[event.target.name]: value,
		});
	};

	useEffect(() => {
		if (!!loginError && loginError.code) {
			setErrors([loginError.message]);
		}
	}, [loginError]);

	useEffect(() => {
		resetAuth();
	}, []);

	return (
		<Box
			onSubmit={submitHandle}
			component="form"
			noValidate={false}
			autoComplete="off"
			className="flex flex--vertical"
			sx={{ p: 2, width: "100%", maxWidth: "600px" }}
		>
			<Typography
				sx={{ ml: "auto", mr: "auto", mb: 8 }}
				variant="subtitle"
				align="center"
				className="page-anchor"
			>
				<span>
					<FontAwesomeIcon icon={solid("user-check")} />
				</span>{" "}
				{t("pages:login.label_account_login")}
			</Typography>

			{!!errors && (
				<Alert severity="error" sx={{ mb: 1 }}>
					<ul>
						{errors.map((i, idx) => (
							<li key={`error-${idx}`}>{i}</li>
						))}
					</ul>
				</Alert>
			)}

			<fieldset className="flex flex--vertical" disabled={isSubmitting}>
				<Box className="form__fields flex flex--vertical" sx={{ mb: 2 }}>
					<FormControl sx={{ mb: 2 }} size="small" variant="outlined" fullWidth>
						<InputLabel htmlFor="field-username">{t("pages:login.label_username")}</InputLabel>
						<OutlinedInput
							id="field-username"
							aria-describedby="field-username"
							label={t("pages:login.label_username")}
							inputProps={{
								"aria-label": "username",
								onChange: handleChange,
								value: formData.username,
								name: "username",
							}}
						/>
						{/* <FormHelperText id="field-username">Weight</FormHelperText> */}
					</FormControl>
					<FormControl sx={{ mb: 2 }} size="small" variant="outlined" fullWidth>
						<InputLabel htmlFor="field-password">{t("pages:login.label_password")}</InputLabel>
						<OutlinedInput
							id="field-password"
							type={showPassword ? "text" : "password"}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										edge="end"
										sx={{ fontSize: "1em" }}
									>
										<FontAwesomeIcon icon={showPassword ? solid("eye-slash") : solid("eye")} />
									</IconButton>
								</InputAdornment>
							}
							label={t("pages:login.label_password")}
							inputProps={{
								"aria-label": "password",
								onChange: handleChange,
								value: formData.password,
								name: "password",
							}}
						/>
					</FormControl>
				</Box>

				<LoadingButton loading={isSubmitting} sx={{ mb: 2, borderRadius: 6 }} variant="contained" type="submit">
					{t("pages:login.login_account")}
				</LoadingButton>
			</fieldset>
		</Box>
	);
};

export default connect(
	(state) => {
		let data = state.auth;
		return {
			isSubmitting: data.status === "loading",
			loginError: data.error,
		};
	},
	(dispatch) => {
		return {
			resetAuth: () => {
				dispatch(logout());
			},
			getToken: (username, password) => {
				dispatch(
					getAuthToken({
						username,
						password,
					})
				);
			},
		};
	}
)(Login);
