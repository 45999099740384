import {
	Alert,
	Box,
	Button,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { toast } from "react-toastify";
import { documentPrivacyTypes } from "../../../configs/constant";
import { useUpdateTokenByIdMutation } from "../../../service/api/meveoApi";
import { usePrivacy, usePrivacyDispatch } from "./TokenPrivacyContext";
import ShareCopy from "../../ShareCopy";
import { standPost } from "../../../pages/Standalone/Standalone";

const TokenPrivacySubmit = (props: any) => {
	const privacySettings: any = usePrivacy();
	const dispatch = usePrivacyDispatch();

	const { token, standalone, onSaved, onShare } = props;
	const [update, result] = useUpdateTokenByIdMutation();
	const [showShare, toggleShare] = useState(false);
	const container = document.querySelector(".token__share--main");

	const onSaveConfiguration = (event: any) => {
		event.preventDefault();
		update({
			invalidate: false,
			tokenID: token.token.uuid,
			body: {
				privacySettings: {
					documents: privacySettings.documents,
					token: privacySettings.token,
				},
			},
		}).unwrap().then((data) => {
			onSaved(data);
		}).catch(console.warn);
		dispatch({
			type: "reset_changed",
		});
		if (standalone) {
			standPost({ action: 'success', data: privacySettings })
		}
	};

	const _toggleShare = (type: any) => {
		if (standalone && onShare) {
			onShare(publicShare)
		} else {
			toggleShare(type);
		}
	}

	let sharedHash = token.sharedLink ? token.sharedLink.match(/\?shareHash\=([^\=\?]+)/) : "";
	sharedHash = !!sharedHash && sharedHash.length ? sharedHash[1] : "";
	let time = new Date().getTime();

	const publicShare = `${window.location.origin}/token/public/${token.token.uuid}?t=${time}`;
	const privateShare = `${window.location.origin}/token/share/${sharedHash}?t=${time}`;

	const shareLink = `${window.location.origin}/token/${privacySettings.token.tokenPrivacy === documentPrivacyTypes.PUBLIC
		? `public/${token.token.uuid}`
		: privacySettings.token.tokenPrivacy === documentPrivacyTypes.SHARED
			? `share/${sharedHash}`
			: "Private"
		}`;

	const note =
		privacySettings.token.tokenPrivacy === documentPrivacyTypes.PUBLIC
			? t("pages:token.privacy_settings.public_share_note")
			: privacySettings.token.tokenPrivacy === documentPrivacyTypes.SHARED
				? t("pages:token.privacy_settings.hashed_share_note")
				: t("pages:token.privacy_settings.warning_share_digital_twin");

	return (
		<Box className="w--100">
			<Button
				onClick={onSaveConfiguration}
				className="w--100"
				size="small"
				sx={{ mb: 1 }}
				variant="contained"
				color="primary"
				disabled={privacySettings.isChanged === false}
			>
				{t("pages:token.privacy_settings.save_configuration")}
			</Button>

			{documentPrivacyTypes.PRIVATE === privacySettings.token.tokenPrivacy ? (
				<Alert>{note}</Alert>
			) : (
				<>
					<Button
						className="w--100"
						onClick={() => _toggleShare("public")}
						size="small"
						sx={{ ml: "0!important" }}
						color="secondary"
						variant="contained"
						disabled={privacySettings.isChanged}
					>
						{t("pages:token.privacy_settings.share_public_token")}
					</Button>
					<ShareCopy
						visible={!!showShare}
						title={t("pages:token.share")}
						message={t("pages:token.privacy_settings.public_share_note")}
						link={shareLink || publicShare || ''}
						onCopied={() => toast.success(t('pages:token.link_copied_success'))}
						onDismiss={() => _toggleShare(false)}
					/>
				</>
			)}
		</Box>
	);
};

export default TokenPrivacySubmit;
