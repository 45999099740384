import i18next from "i18next"
import { useEffect, useRef } from "react"
import { connect } from "react-redux"

const Language = ({ language, children }) => {
	const currentLanguage = useRef('en');

  useEffect(() => {
		currentLanguage.current = language;
    i18next.changeLanguage(language)
  }, [language])
  return children
}

export default connect(state => {
  const walletState = state?.wallet
  const currentLanguge = walletState?.public?.language || 'en'
  return {
    language: currentLanguge 
  }
})(Language)