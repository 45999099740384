import React, { useMemo, useRef, useState } from "react";
import { Button, FormControl, IconButton, InputBase, Menu } from "@mui/material";
import { t } from "i18next";
import { DateRange } from "react-date-range";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

type DateFilterItemProps = {
	label: string;
	value?: any;
	onChange?: Function;
	datePickerWidth?: number;

}
const DateRangeFilterItem: React.FC<DateFilterItemProps> = (props) => {
	const { label, value, onChange, datePickerWidth = 320 } = props;

	const [startDate, endDate, dateRange] = useMemo(() => {
		if (!value) return [new Date(), new Date(), ''];

		let start = new Date();
		let end = new Date();

		start.setTime(value.startDate);
		end.setTime(value.endDate);

		let dateString = `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`

		return [start, end, dateString];
	}, [value]);

	const initState = [
		{
			startDate,
			endDate,
			key: 'selection',
			color: "#EA5123"
		}
	];
	const [state, setState] = useState(initState);
	const [dateRangeOpen, setDateRangeOpen] = useState(false);
	const dateInputRef = useRef(null);
	const [dateInput, setDateInput] = useState(dateRange);

	const cancelHandle = () => {
		setState(initState);
		setDateRangeOpen(false);
		setDateInput("");
		typeof onChange === 'function' && onChange(null, null);
	}

	const saveHandle = () => {
		const select = state[0];
		if (select.endDate.getTime() === select.startDate.getTime()) {
			select.endDate.setHours(23);
			select.endDate.setMinutes(59);
			select.endDate.setSeconds(59);
		}
		setDateInput(`${select.startDate.toLocaleDateString()} - ${select.endDate.toLocaleDateString()}`);
		typeof onChange === 'function' && onChange(select.startDate, select.endDate);
		setDateRangeOpen(false);
	}

	return (
		<div className="filter-menu__item filter-menu__item--date">
			{!!label && <label>{label}</label>}
			<FormControl
				component="div"
				className={`flex flex--grow filter filter__by-date-input`}
				ref={dateInputRef}
				onClick={() => setDateRangeOpen(!dateRangeOpen)}
			>
				<InputBase
					sx={{ ml: 1, flex: 1, p: "4px 8px" }}
					placeholder={`${t("common:date")}`}
					id="filter__by-date-input"
					value={dateInput}
				/>
				<IconButton color="secondary" type="button" aria-label="search">
					<i className="icon icon--calendar"></i>
				</IconButton>
			</FormControl>
			<Menu
				open={dateRangeOpen}
				anchorEl={dateInputRef.current}
				onClose={() => setDateRangeOpen(false)}
				className="filter-menu__date-range-picker"
			>
				<DateRange
					editableDateInputs={false}
					onChange={(item: any) => {
						setState([item.selection]);
					}}
					moveRangeOnFirstSelection={false}
					ranges={state}
					maxDate={new Date()}
					dateDisplayFormat="dd/MM/yyyy"
					showDateDisplay={false}
					color="#EA5123"
					width={datePickerWidth}
				/>
				<div className="actions flex flex--horizontal flex--wrap">
					<Button color="secondary" onClick={() => cancelHandle()} size="small" variant="outlined">{t("common:cancel")}</Button>
					<Button onClick={() => saveHandle()} size="small" color="secondary" variant="contained">{t("common:save")}</Button>
				</div>
			</Menu>
		</div>

	)
}

export default DateRangeFilterItem;