import { Box } from "@mui/material";

import "./ListVerification.scss";
import { t } from "i18next";
import { PlatformLayout as Layout } from "../../layout";
import { useGetPartnerCodeQuery } from "../../service/api/meveoApi";
import { useParams } from "react-router-dom";
import { ListMarkProofVerification } from "../../components/MarkProof";

const ListVerification = (props) => {
	const params = useParams();

	const { isLoggedIn } = props;
	const { data, isFetching } = useGetPartnerCodeQuery({}, { skip: !isLoggedIn });
	const isPartner = !isFetching && data?.status === 'success' && !!data?.result;

	return (
		<Layout
			className="site mark-proof-verification"
			pageTitle={t("pages:mark_proof.verification.page_title")}
			actions={!!isPartner && (
				<Box className="flex flex--justify-end">
					
				</Box>
			)}
		>
			<Box className="wallet__markproofs">
				<ListMarkProofVerification />	
			</Box>
		</Layout>
	);
};

export default ListVerification;
