import React from 'react'
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import LoaderBar from '../LoaderBar';
import { t } from 'i18next';


interface ILoadingModal {
  visible: boolean;
  message?: string;
  onClose: () => void;
}

const LoadingModal = ({ visible, message, onClose }: ILoadingModal) => {
  return (
    <Dialog className="loading-spinner-modal-dialog" open={visible} onClose={onClose}>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body2" textAlign="center" fontWeight="500">{message || t('common:default_loading_message')}</Typography>
          <LoaderBar size={80} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default LoadingModal
