import { Button, Typography } from '@mui/material'
import { toast } from "react-toastify";
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useMintAndPrepareTokenWithSmartContract } from '../../../hooks/smartContractHooks'
import LoadingModal from '../../LoadingModal';
import { connect } from 'react-redux';
import { standPost } from '../../../pages/Standalone/Standalone';

const MintToken = (props: any) => {
	const { variant, token, onSuccess, isOperatorUser } = props
	const [standalone, setStandalone] = useState(false);
	const [mintTokenTrigger, setMintTokenTrigger] = useState(0);

	const [mintAndPrepare, { status: mintAndPrepareStatus }, resetMintAndPrepare]: any = useMintAndPrepareTokenWithSmartContract({ token });

	const processing = mintAndPrepareStatus === 'loading';

	const registerMintTokenAction = () => {
		window.mintTokenAction = (data: any) => {
			setMintTokenTrigger(Date.now())
		}
	}

	useEffect(() => {
		if (mintTokenTrigger > 0) {
			mintAndPrepare()
		}
	}, [mintTokenTrigger]);

	useEffect(() => {
		if (document.getElementById('standaloneWebApp')) {
			setStandalone(true);
		}
		registerMintTokenAction();
	}, []);

	useEffect(() => {
		if (mintAndPrepareStatus === 'success') {
			resetMintAndPrepare();
			setMintTokenTrigger(0);
			onSuccess && onSuccess();
			toast.success(t("pages:token.mint_digital_passport_success"));
			if (standalone) {
				standPost({ action: 'success', data: { type: 'MintToken', token: token.token } })
			}
		}
	}, [mintAndPrepareStatus]);

	return !isOperatorUser || !token || token.token?.mintDate ? null : (
		<>
			<Button
				color="secondary"
				variant={variant || "text"}
				onClick={mintAndPrepare}
				aria-label="mint"
				title="mint token"
				disabled={processing}
			>
				<span className="icon icon__action icon--mint">_</span>
				<Typography component="span" variant="body2">
					{t("pages:token.mint")}
				</Typography>
			</Button>

			{processing && (
				<LoadingModal
					visible={true}
					onClose={() => null}
				/>
			)}
		</>
	)
}
export default connect((state: any) => {
	const wallet = state.wallet;

	return {
		isOperatorUser: wallet.isOperatorUser || wallet.isOperator,
	};
})(MintToken);