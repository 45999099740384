import { createContext, useContext, useReducer } from "react";

export const ImageCompareContext = createContext(null);
export const ImageCompareContextDispatchContext = createContext(null);

const initialConfigs = {
	images: {
		original: {
			opacity: 50,
			clipPath: true,
			rotation: 0,
			scale: 1	
		},
		reference: {
			opacity: 0,
			rotation: 0,
			scale: 1	
		},
	},
	spacer: {
		left: 50
	},
	fullscreen: false
};

const reducer = (settings, action) => {
	const data = action?.data;
	let newState;
	
	try {
		newState = JSON.parse(JSON.stringify(settings));
	} catch (error) {
		newState = {};
	}

	switch (action.type) {
		case "start_edit":
			return {
				...newState,
				editting: data.editting
			};
		case "move":
			return {
				...newState,
				images: {
					...newState.images,
					[data.key]: {
						...(newState.images[data.key] || {}),
						translate: data.translate || {
							x: 50,
							y: 50
						}
					}
				}
			}
		case "zoom":
			let current = newState.images[data.key] || {}
			
			return {
				...newState,
				images: {
					...newState.images,
					[data.key]: {
						...current,
						scale: data.scale || current.scale  || 1,
						rotation: typeof data.rotation !== 'undefined' ? data.rotation : current.rotation || 0
					}
				}
			}
		case "init_spacer":
			let _spacer = {
				...newState.spacer
			}
			return {
				...newState,
				spacer: _spacer
			};
		case "update_spacer": //Token origin data
			return {
				...newState,
				...data
			};
		case "update_original_opacity":
			return {
				...newState,
				images: {
					...newState.images,
					original: {
						...(newState?.images?.original || {}),
						opacity: data.opacity
					}
				}
			};
		case "reset":
			// newState.spacer.left = 50;
			// if ( Object.values(newState.images).length > 0 ) {
			// 	for(let type in newState.images ) {
			// 		newState.images[type] = {
			// 			...newState.images[type],
			// 			opacity: 100,
			// 			rotation: 0,
			// 			scale: 1	
			// 		}
			// 	}
			// }
			return initialConfigs;
		default: 
			return newState;
	}
}

export function ImageCompareProvider({ children }) {
	const [settings, dispatch] = useReducer(reducer, initialConfigs);

	return (
		<ImageCompareContext.Provider value={settings}>
			<ImageCompareContextDispatchContext.Provider value={dispatch}>{children}</ImageCompareContextDispatchContext.Provider>
		</ImageCompareContext.Provider>
	);
}

export function useImageCompare() {
	return useContext(ImageCompareContext);
}

export function useImageCompareDispatch() {
	return useContext(ImageCompareContextDispatchContext);
}
