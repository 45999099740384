import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const render = (Component) => {
	root.render(
		<React.StrictMode>
			<Component />
		</React.StrictMode>
	);
};

render(App);

// Enable Hot Module Replacement
if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
