import { Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { documentPathTypes, documentTypes } from "../../../configs/constant";
import ShareOptionGroup from "./ShareOptionGroup";
import { usePrivacy, usePrivacyDispatch } from "../ShareTokenPrivacy/TokenPrivacyContext";
import { t } from "i18next";
import { connect } from "react-redux";

const HiddenTypes: any = [
	documentPathTypes.CERTIFICATE,
];

const ShareOptionTable = (props: any) => {
	const { token, certificateGen, onChange } = props;
	const privacySettings: any = usePrivacy();
	const dispatch = usePrivacyDispatch();

	useEffect(() => {
		onChange && onChange();
	}, [privacySettings.sharedOptions]);

	useEffect(() => {
		if (token.privacySettings.token && token.privacySettings.documents) {
			dispatch({
				type: 'init_privacy_settings',
				data: {
					settings: {
						documents: token.privacySettings.documents,
						token: Object.assign({}, privacySettings.token, token.privacySettings.token),
						sharedOptions: privacySettings.sharedOptions,
					},
					documents: token.documents,
				}
			});
			applyPreset('public');
			dispatch({
				type: 'update_share_option',
				data: {
					key: 'token.tokenPrivacy',
					selected: true,
				}
			})
		}
	}, [token, dispatch])

	const validateFiles = (documents: any, type: any) => {
		const typePrivacy = privacySettings.documents[type.path];
		let validFiles = documents
			.filter((item:any) => item.path === type.path)
			.map((item:any) => {
				if (typePrivacy && typePrivacy[item.uuid]) {
					return {
						...item,
						perm: typePrivacy[item.uuid],
					};
				}
				return item;
			});

		// I think this is not neccessary, it must to be done in BackEnd
		if (type.path === documentPathTypes.GALLERY_3D) {
			if (validFiles.length > 0) {
				let files:any = [];
				for (let idx in validFiles) {
					if (validFiles[idx].filename.endsWith(".obj")) {
						let objFile = {
							...validFiles[idx],
							mtl: false,
							texture: false,
						};
						// find support files
						for (let i in validFiles) {
							let subFile = validFiles[i];
							if (subFile.name.toLowerCase().indexOf(objFile.name.toLowerCase()) === 0) {
								if (subFile.filename.endsWith(".mtl")) {
									objFile.mtl = subFile;
								}
								if (subFile.mimeType.startsWith("image/")) {
									objFile.texture = subFile;
								}
							}
						}

						files.push(objFile);
					}
				}
				let invalidFiles = validFiles
					.filter(
						(item:any) =>
							files.filter(
								(i:any) =>
									i.uuid === item.uuid ||
									(!!i.mtl && i.mtl.uuid === item.uuid) ||
									(!!i.texture && i.texture.uuid === item.uuid)
							).length <= 0
					)
					.map((item:any) => ({
						...item,
						isInvalid: true,
					}));
				return files.concat(invalidFiles);
			}
		}

		return validFiles;
	};

	const prepareData = () => {
		let data = [];
		// Token Property
		data.push({
			name: "",
			items: [
				{
					name: t("pages:token.privacy_settings.cert_issue_date"),
					key: "certIssueDate",
					perm: "public",
				},
				{
					name: t("pages:token.privacy_settings.description"),
					key: "description",
					perm: "public",
				},
				{
					name: t("pages:token.value"),
					key: "value",
					perm: "public",
				},
				{
					name: t("pages:token.specifications"),
					key: "dimensions",
					childs: ['token.weightInKilo', 'token.widthInMeter', 'token.depthInMeter', 'token.lengthInMeter'],
					perm: "public",
				},
				{
					name: t("pages:token.blockchain_id"),
					key: "blockChainID",
					perm: "private",
				},
				{
					name: t("pages:token.privacy_settings.cover_photo_and_name"),
					key: "tokenPrivacy",
					perm: "public",
				},
				{
					name: t("pages:token.creation_date"),
					key: "creationDate",
					perm: "public",
				},
				{
					name: t("pages:token.privacy_settings.owner_username"),
					key: "ownerUsername",
					perm: "public",
				},
				{
					name: t("pages:token.privacy_settings.owner_email"),
					key: "ownerEmail",
					perm: "public",
				},
				{
					name: t("pages:token.privacy_settings.owner_wallet"),
					key: "ownerWallet",
					perm: "public",
				},
				{
					name: t("pages:token.privacy_settings.history"),
					key: "history",
					perm: "public",
				},
			],
			hideAll: true
		});
		if (!certificateGen) {
			data[0].items = data[0].items.filter((item: any) => item.key !== 'certIssueDate');
		}
		// Files
		Object.values(documentTypes()).forEach((type, idx) => {
			let files = validateFiles(token.documents, type);
			if (certificateGen && HiddenTypes.includes(type.path)) {
				return true;
			}
			data.push({
				name: type.name,
				path: type.path,
				items: files,
			});
		});
		return data;
	};

	const tableData = prepareData();
	const isAllSelected = tableData.every((group: any) => (
		group.items.every((item: any) => (
			item.childs?.length > 0
				? item.childs.every((child: any) => (privacySettings.sharedOptions[child] === true))
				: item.path === documentPathTypes.COVER_IMAGE
				? privacySettings.sharedOptions[`token.tokenPrivacy`] === true
				: item.fileUrl
				? privacySettings.sharedOptions[`documents.${item.path}.${item.uuid}`] === true
				: privacySettings.sharedOptions[`token.${item.key}`] === true
		))
	));

	const applyPreset = (preset: string) => {
		Object.keys(token.privacySettings.token).forEach((key: string) => {
			dispatch({
				type: 'update_share_option',
				data: {
					key: `token.${key}`,
					selected: token.privacySettings.token[key] === preset
				}
			})
		});

		Object.keys(token.privacySettings.documents).forEach((path: string) => {
			Object.keys(token.privacySettings.documents[path]).forEach((uuid: string) => {
				const key = ['documents', path, uuid].join('.');
				dispatch({
					type: 'update_share_option',
					data: {
						key,
						selected: token.privacySettings.documents[path][uuid] === preset
					}
				})
			});
		});
	}

	const onSelectAll = () => {
		const toggled = !isAllSelected;

		tableData.forEach((group: any) => {
			if (group.path) {
				// documents
				group.items.forEach((item: any) => {
					dispatch({
						type: 'update_share_option',
						data: {
							key: `documents.${item.path}.${item.uuid}`,
							selected: toggled
						}
					});
				});
				return;
			}
			// token props
			group.items.forEach((item: any) => {
				dispatch({
					type: 'update_share_option',
					data: {
						key: `token.${item.key}`,
						selected: toggled
					}
				});

				if (item.childs?.length > 0) {
					item.childs.forEach((child: any) => {
						dispatch({
							type: 'update_share_option',
							data: {
								key: child,
								selected: toggled
							}
						})
					})
				}
			});
		});
	};

	return (
		<TableContainer className="token__share-table">
			<Table sx={{ wordBreak: "break-word" }} className="table non-responsive" aria-label="collapsible table">
				<TableHead className="table__head">
					<TableRow>
						<TableCell sx={{ maxWidth: "60%", verticalAlign: "bottom", borderBottom: 'none' }} >{
							certificateGen
								? t('pages:token.privacy_settings.please_select_items_for_certificate')
								: t('pages:token.privacy_settings.select_information_to_share')
						}</TableCell>
						<TableCell sx={{ verticalAlign: "bottom", borderBottom: 'none' }} className="table__head-cell">
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					<TableRow className="table__row select-all">
						<TableCell sx={{ paddingTop: 0 }}>
							<Box onClick={onSelectAll}>
								{t("pages:token.privacy_settings.select_all")}
							</Box>
						</TableCell>
						<TableCell sx={{ paddingTop: 0 }}>
							<Checkbox
								title="Select All"
								size="small"
								color="secondary"
								checked={isAllSelected}
								onChange={onSelectAll}
							/>
						</TableCell>
					</TableRow>
					{tableData.length > 0 && tableData.map((attr: any, idx: number) => attr.items.length > 0 ? <ShareOptionGroup key={`document-row-${idx}`} data={attr} /> : null)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default connect((state:any) => {
  const wallet = state?.wallet;
  return {
    publicInformation: wallet.public,// deprecated
  }
})(ShareOptionTable);
