import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";
import { FormField, validFormField } from "../../Form/FormField";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { formatDate, transferToUnitDisplay, transferUnitFromDisplayToSavedData } from "../../../utils/helpers";
import { useTheme } from "@emotion/react";
import { useUpdateTokenMutation } from "../../../service/api/meveoApi";
import LoadingButton from "@mui/lab/LoadingButton";
import { MessageBox } from "../../Animation";
import OperatorActionConfirmation from "../../Operator";
import { TRANSACTION_TYPES, unitTypes } from "../../../configs/constant";
import { connect } from "react-redux";

export const ShowField = ({ formData, id, numeric=false, handleChange }) => {
	const item = formData[id];
	if (!item) return null;

	return (
		<FormField
			fieldId={id}
			value={item.value}
			onChange={handleChange}
			label={item.label}
			errors={item.errors}
			fullWidth={true}
			numeric={numeric}
		/>
	);
};

const DialogForm = connect(state => {
	const wallet = state?.wallet;

	return {
		unit: wallet?.public?.unit || unitTypes.METRIC.key
	}
})((props) => {
	const { open = false, onClose, token, unit } = props;
	const [updateToken, { isLoading, isSuccess, data, reset }] = useUpdateTokenMutation();

	const [confirmDialog, toggleConfirmDialog] = useState(false);
	const [errors, setErrors] = useState({});
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const initData = useMemo(() => ({
		name: {
			id: "name",
			label: t("common:name"),
			errors: [],
			valid: [],
			value: token.name,
		},
		markReference: {
			id: "markReference",
			label: t("pages:token.mark_reference"),
			errors: [],
			valid: [["^.{0,128}$", "Cannot exceed 128 characters"]],
			value: token?.tokenMarkReference || '',
		},
		description: {
			id: "description",
			label: t("pages:token.description"),
			errors: [],
			valid: [],
			value: token.description,
			type: "multiline",
		},
		lengthInMeter: {
			id: "lengthInMeter",
			label: `${t("pages:token.length")} (${unitTypes[unit].lengthUnit})`,
			errors: [],
			valid: [],
			value: transferToUnitDisplay(unit, token.lengthInMeter, true, false) || 0.0,
			fullWidth: true,
			type: 'number'
		},
		depthInMeter: {
			id: "depthInMeter",
			label: `${t("pages:token.depth")} (${unitTypes[unit].lengthUnit})`,
			errors: [],
			valid: [],
			value: transferToUnitDisplay(unit, token.depthInMeter, true, false) || 0.0,
			fullWidth: true,
			type: 'number'
		},
		widthInMeter: {
			id: "widthInMeter",
			label: `${t("pages:token.width")} (${unitTypes[unit].lengthUnit})`,
			errors: [],
			valid: [],
			value: transferToUnitDisplay(unit, token.widthInMeter, true, false) || 0.0,
			fullWidth: true,
			type: 'number'
		},
		weightInKilo: {
			id: "weightInKilo",
			label: `${t("pages:token.weight")} (${unitTypes[unit].weightUnit})`,
			errors: [],
			valid: [],
			value: transferToUnitDisplay(unit, token.weightInKilo, false, false) || 0.0,
			fullWidth: true,
			type: 'number'
		},
		value: {
			id: "value",
			label: t("pages:token.price"),
			errors: [],
			valid: [],
			value: token.value || 0.0,
			fullWidth: true,
			type: 'number'
		},
		currency: {
			id: "currency",
			label: t("pages:token.currency"),
			errors: [],
			valid: [],
			value: token.currency || 'EUR',
			fullWidth: true,
		},
	}), [token, unit])
	
	const [formData, setFormData] = useState(initData);
	const handleChange = (event, value, id) => {
		let fieldId = !!event ? event?.target?.name : id;
		let fieldValue = !!event ? event?.target?.value : value;

		if (!fieldId) return;
		
		if ( formData[fieldId].type === 'number' ) {
			fieldValue = fieldValue.replace(/[^\d\.]/g,'');
			fieldValue = !fieldValue ? 0.0 : parseFloat(fieldValue);
		}

		const fieldErrors = validFormField(fieldId, fieldValue, formData);
		setErrors({
			...errors,
			[fieldId]: fieldErrors,
		});
	
		setFormData({
			...formData,
			[fieldId]: {
				...formData[fieldId],
				value: fieldValue,
				errors: fieldErrors
			}
		});
	}

	const handleClose = () => {
		setFormData(initData);
		toggleConfirmDialog(false);
		reset();
		!!onClose && typeof onClose === 'function' && onClose();
	};

	const handleUpdateToken = () => {
		let validations = {};
		let isValid = true;
		for (let id in formData) {
			let fieldErrors = validFormField(id, formData[id].value, formData);
			validations[id] = fieldErrors;
			isValid = isValid && fieldErrors.length <= 0;
		}
		setErrors({
			...validations,
		});
		if (!isValid) return;
		
		const newData = {};
		for(let key in formData ) {
			newData[key] = formData[key].value;
		}

		let updateData = {
			token: {
				...token,
				...newData
			}
		}

		toggleConfirmDialog({
			callback: () => {
				toggleConfirmDialog(false);
				updateData.token.lengthInMeter = transferUnitFromDisplayToSavedData(unit, updateData.token.lengthInMeter, true)
				updateData.token.widthInMeter = transferUnitFromDisplayToSavedData(unit, updateData.token.widthInMeter, true)
				updateData.token.depthInMeter = transferUnitFromDisplayToSavedData(unit, updateData.token.depthInMeter, true)
				updateData.token.weightInKilo = transferUnitFromDisplayToSavedData(unit, updateData.token.weightInKilo, false)
				updateToken(updateData); // deprecated. missing transactionInfo
			}
		});
	}
	
	return (
		<>
			<Dialog fullScreen={fullScreen} className="token__update token__update--dialog dialog" open={open} onClose={handleClose}>
				<DialogTitle>
					{t("common:edit")}
					<IconButton aria-label="close" onClick={handleClose}>
						<FontAwesomeIcon icon={regular("circle-xmark")} />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					{!!isSuccess && <MessageBox type={data.status === 'success' ? 'success':'error'}>{data.status==='success'?`The token details have been updated successfully`: (data.result || '')}</MessageBox>}
					<fieldset disabled={!!isLoading}>
						<Box component="form">
							<Typography sx={{ mb: 2 }} variant="h6" component="h3">
								Details
							</Typography>

							<ShowField formData={formData} id="name" handleChange={handleChange} />

							<ShowField formData={formData} id="markReference" handleChange={handleChange} />

							<ShowField formData={formData} id="description" handleChange={handleChange} />

							<Box className="flex flex--horizontal">
								<Box className="w--50">
									<Typography sx={{ mb: 2 }} variant="h6" component="h3">
										Specifications
									</Typography>

									<FormField fieldId="UUID" value={token.uuid} label={"UUID"} fullWidth={true} disabled={true} />
									<FormField
										fieldId={t("pages:token.token_origin")}
										value={t("pages:token.token_origin_value")}
										label={t("pages:token.token_origin")}
										fullWidth={true}
										disabled={true}
									/>
									<FormField
										fieldId={t("pages:token.creation_date")}
										value={formatDate(token.creationDate)}
										label={t("pages:token.creation_date")}
										fullWidth={true}
										disabled={true}
									/>

									<ShowField formData={formData} id="value" handleChange={handleChange} />
								</Box>
								<Box className="w--50">
									<Typography sx={{ mb: 2 }} variant="h6" component="h3">
										{t("common:sizes")}
									</Typography>
									<Box className="w--100 flex flex--horizontal flex--wrap" sx={{ pl: 1, flexWrap: "wrap" }}>
										<Box className="w--50">
											<ShowField formData={formData} id="lengthInMeter" handleChange={handleChange} />
										</Box>
										<Box className="w--50" sx={{ pl: 1 }}>
											<ShowField formData={formData} id="weightInKilo" handleChange={handleChange} />
										</Box>
										<Box className="w--50">
											<ShowField formData={formData} id="widthInMeter" handleChange={handleChange} />
										</Box>
										<Box className="w--50"  sx={{ pl: 1 }}>
											<ShowField formData={formData} id="depthInMeter" handleChange={handleChange} />
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</fieldset>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} variant="text" size="medium">
						{t("common:cancel")}
					</Button>
					<LoadingButton
						onClick={handleUpdateToken}
						size="medium"
						variant="contained"
						color="secondary"
						loading={!!isLoading}
					>
						{t("common:save")}
					</LoadingButton>
				</DialogActions>
			</Dialog>

			<OperatorActionConfirmation
				offerCode={token?.billingOfferCode}
				defaultPartnerCode={token?.billingOfferCode ? token?.uuid.split('_')[0] : ''}
				action={TRANSACTION_TYPES.UPDATE_TOKEN.key}
				open={confirmDialog} 
				toggle={toggleConfirmDialog}
				onClose={() => {
					handleClose()
				}}
			/>
		</>
	);
});

const UpdateToken = ({ data, open, onClose }) => {
	const showButton = typeof open === 'undefined';
	const [dialogStatus, toggleModal] = useState();

	const handleClose = () => {
		toggleModal(false);
	};

	return (
		<>
			{showButton && <Button variant="text" onClick={() => toggleModal(true)}>
				Edit
			</Button>}
			<DialogForm token={data?.token} open={!!showButton ? dialogStatus : open} onClose={!!showButton ? handleClose : onClose} />
		</>
	);
};

export default UpdateToken;
