import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-xhr-backend";
import { languages } from './configs/constant';

// Dynamically require all translation files
const translations = require.context('./lang', true, /\.json$/);
const resources = {};

translations.keys().forEach((filename) => {
  const [language, namespace] = getLanguageAndNamespaceFromFilename(filename);
  resources[language] = resources[language] || {};
  setNestedTranslation(resources[language], namespace, translations(filename));
});

function getLanguageAndNamespaceFromFilename(filename) {
  const [, language, ...path] = filename.split('/');
  const namespace = path.join('/').replace('.json', '');
  return [language, namespace];
}

function setNestedTranslation(obj, path, value) {
  const keys = path.split('/');
  const lastKey = keys.pop();

  keys.reduce((nestedObj, key) => {
    nestedObj[key] = nestedObj[key] || {};
    return nestedObj[key];
  }, obj)[lastKey] = value;
}


i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;