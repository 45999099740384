import { Snackbar, Alert as MUIAlert } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

function Alert({ type = 'success', visible = false, onClose }) {
  return (
    <Snackbar
      open={visible}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onClose}
    >
      <MUIAlert
        severity={type === 'success' ? 'success' : 'error'}
        sx={{ width: '100%' }}
        onClose={onClose}
      >
        {t(
          `pages:token.generate_pdf_alert_message.${
            type === 'success' ? 'success' : 'fail'
          }`
        )}
      </MUIAlert>
    </Snackbar>
  );
}

export default Alert;
