import React, { useEffect, useState } from "react";
import { ImageCompareProvider, useImageCompare } from "../ImageCompare/ImageCompareContext";
import Singularity from "./Singularity";
import { Box } from "@mui/material";
import SingularityPreview from "./SingularityPreview";
import "./Singularities.scss";
import SingularityZoom from "./SingulartiyZoom";

const Wrapper = ({ sourceKey, children, settings }) => {
	const scale = settings?.images[sourceKey]?.scale || 1;
	const rotation = settings?.images[sourceKey]?.rotation || 0;
	const translate = settings?.images[sourceKey]?.translate || {
		x: -50,
		y: -50,
	};
	const opacity = 1 - (settings?.images?.original?.opacity || 0) / 100;

	const [imgTarget, setImageTarget] = useState();

	useEffect(() => {
		const updateImageTarget = () => {
			const element = document.querySelector(`.image-compare__image.image--${sourceKey} img.visible`);
			if (element) setImageTarget(element);
		};
		updateImageTarget();
		window.addEventListener("resize", updateImageTarget);
		return () => {
			window.removeEventListener("resize", updateImageTarget);
		};
	}, [sourceKey]);

	if (!imgTarget) return null;

	const style = {
		translate: `${translate.x}% ${translate.y}%`,
		scale: `${scale}`,
		rotate: `${rotation}deg`,
		// opacity: opacity,
	};

	return (
		<div
			style={{
				...style,
				width: imgTarget.offsetWidth,
				height: imgTarget.offsetHeight,
			}}
			className="singularities__wrapper"
		>
			{children}
		</div>
	);
};

const Singularities = (props) => {
	const sourceKey = "original";
	const settings = useImageCompare();

	const { singularities, sourceImage } = props;
	const [selected, setSelected] = useState(false);

	const selectSingularityHandle = (index) => {
		setSelected(selected !== index ? index : false);
	};

	let clippath = `inset(0 ${100 - (settings?.spacer?.left || 50)}% 0 0)`;

	return (
		<Box className="singularities__viewport">
			{selected !== false && singularities[selected] && (
				<SingularityZoom config={singularities[selected]} image={sourceImage} />
			)}
			<Wrapper settings={settings} sourceKey={sourceKey}>
				{singularities &&
					singularities.map((item, idx) => <Singularity key={`singularity__${idx}`} config={item} />)}
			</Wrapper>
			<Box className="singularities__preview">
				{singularities.map((item, idx) => (
					<SingularityPreview
						selected={idx === selected}
						key={`singularity-${idx}`}
						config={item}
						image={sourceImage}
						onClick={() => selectSingularityHandle(idx)}
					/>
				))}
			</Box>
		</Box>
	);
};

export default Singularities;
