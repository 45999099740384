import { Avatar, Skeleton } from "@mui/material"
import { connect } from "react-redux"

const WalletCover = props => {
  const { photo, username, isFetching } = props

  return (
    <div className="avatar">
      {isFetching === 'loading' ? 
        <Skeleton sx={{ bgcolor: 'var(--unikbase-gray)' }} variant="circular" width={100} height={100} />
        : !!photo ? <Avatar className="wallet__image" src={`data:image/png;base64,${photo}`} alt="avatar" /> 
          : <Avatar className="wallet__image" alt="avatar">{username}</Avatar>
      }
    </div>
  )
}

export default connect(state => {
  const wallet = state.wallet
  return {
    photo: wallet?.public?.base64Avatar,
    username: wallet.private?.username || '',
    isFetching: wallet?.status
  }
})(WalletCover);