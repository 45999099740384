import React, { useEffect, useMemo, useRef, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import * as THREE from 'three';

import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';

import { Canvas, useThree } from '@react-three/fiber';
import { useLoader } from '@react-three/fiber';
import { Environment, OrbitControls, useTexture } from '@react-three/drei';
import { Box3, Mesh, TextureLoader, Vector3 } from 'three';

import { Suspense } from 'react';
import { LinearProgress } from '@mui/material';

const Controls = (props) => {
  const { camera } = useThree();
  const { sphere, size } = props;

  let vFoV = camera.getEffectiveFOV();
  let hFoV = camera.fov * camera.aspect;

  let FoV = Math.min(vFoV, hFoV);
  let FoV2 = FoV / 2;

  let th = (FoV2 * Math.PI) / 180.0;
  let sina = Math.sin(th);
  let FL = sphere.radius / sina;

  if (size.length() > window.innerWidth) {
    FL = FL / 1.3;
  }

  let cameraDir = new THREE.Vector3();
  camera.getWorldDirection(cameraDir);

  let cameraOffs = cameraDir.clone();
  cameraOffs.multiplyScalar(-FL);
  const bsWorld = sphere.center.clone();

  let newCameraPos = bsWorld.clone().add(cameraOffs);

  camera.position.copy(newCameraPos);

  return (
    <>
      <OrbitControls autoRotate={true} target={sphere.center} />
    </>
  );
};
const Model = ({ url, mtl, texture, container }) => {
  const [obj, setObj] = useState();
  const [mat, setMat] = useState(false);
  const [textureObj, setTexture] = useState(false);
  const [progress, setProgress] = useState(0);

  useMemo(() => (!!texture ? new TextureLoader().load(texture, setTexture) : setTexture(null)), [texture]);
  useMemo(() => (!!mtl ? new MTLLoader().load(mtl, setMat) : setMat(null)), [mtl]);
  useMemo(() => {
    if (textureObj === false || mat === false) return;
    const objLoader = new OBJLoader();
    objLoader.load(
      url,
      (object) => {
        if (textureObj) {
          object.traverse(function (child) {
            // aka setTexture
            if (child instanceof THREE.Mesh) {
              child.material.map = textureObj;
							child.material.side = THREE.BackSide;
            }
          });
        }
        if (mat) {
          objLoader.setMaterials(mat);
        }
        setObj(object);
      },
      (event) => {
        setProgress((event.loaded / event.total) * 100);
      }
    );
  }, [mat, textureObj]);

  if (obj) {
    const box = new Box3().setFromObject(obj);
    const size = box.getSize(new Vector3());
    const boundingBox = box.getBoundingSphere(new THREE.Sphere());

    return (
      <Canvas camera={{ far: 100000 }}>
        <ambientLight intensity={0.2} />
        <directionalLight />
        <primitive object={obj} />;
        <Controls sphere={boundingBox} size={size} />
      </Canvas>
    );
  }
  return (
    <>
      <CircularProgress className='loading' />
      <LinearProgress
        color='secondary'
        className='progress obj-loading-progress'
        variant='determinate'
        value={progress}
      />
    </>
  );
};

const Token3DDocumentViewer = (props) => {
  const { file, mtl, texture } = props;
  const containerRef = useRef();

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      <Model url={file} mtl={mtl} texture={texture} container={containerRef} />
    </div>
  );
};

export default Token3DDocumentViewer;
