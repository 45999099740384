// http://163.172.169.24:5000/search?q=delaunay&p=2
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'; // React-specific entry point to allow generating React hooks
import api from '../../configs/api';
import config from '../../configs/config';

export const crappyApi = createApi({
  reducerPath: 'crappyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.CRAPPY_SERVER_ADDRESS,
    credentials: "same-origin", 
    prepareHeaders: (headers, { getState }) => {
      
    },
  }),
  endpoints: (builder) => ({
    searchToken: builder.query({
      query: ({ query = false, paging = false, country = 'EU', type = false, detail = false }) => {
        let url = '' 
        
        url += !!detail
          ? type === 'auction' ? `${api.crappy.auction.detail}/${detail}` : `${api.crappy.detail}/${detail}` 
          : `${api.crappy.search}?q=${query}&p=${paging||1}&country=${country}`;
        
        return {
          url: url, 
          method: 'GET'
        }
      }
    }),
    getHighlightAuctions: builder.query({
      query: () => ({
        url: api.crappy.auction.highlight,
        method: 'GET'
      })
    })
  })
})

export const { useSearchTokenQuery, useGetHighlightAuctionsQuery } = crappyApi;
