import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useGetOperatorPricingPlanQuery, useGetPartnerCodeQuery } from "../service/api/meveoApi";

function usePricePlan(action: string, defaultPartnerCode: string = '') {
	const isLoggedIn = useSelector((state: any) => {
		const auth = state?.auth;
		return !!auth.accessToken
			&& !!auth.expiredAt
			&& auth.expiredAt > new Date().getTime()
	});
	const { data: partnerResponse } = useGetPartnerCodeQuery({}, {
		skip: !isLoggedIn || !!defaultPartnerCode
	});

	const partnerCode = useMemo(() => {
        if ( !!defaultPartnerCode ) {
            return defaultPartnerCode;
        }
		if (!!partnerResponse && partnerResponse.status === 'success') {
			return partnerResponse.result;
		}
		return false;
	}, [partnerResponse, defaultPartnerCode])

	const { data: pricingPlanResponse } = useGetOperatorPricingPlanQuery(partnerCode, {
		skip: !isLoggedIn || !partnerCode
	})

	const pricingPlans = useMemo(() => !!pricingPlanResponse && pricingPlanResponse.status === 'success' ? pricingPlanResponse.result : [], [pricingPlanResponse]);

	const pricePlan = useMemo(() => {
        return pricingPlans.find((plan: any) => plan?.type === action) || { price: 0 }
    }, [pricingPlans, action]);

	return {
		pricePlan,
		pricingPlans,
		partnerCode: partnerCode,
	}
}

export {
	usePricePlan,
}