import { Box, Button, Typography } from "@mui/material"
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { verifyOTP } from "../../store/actions/walletActions";
import { hideEmail } from "../../utils/helpers";
import DigitsField from "../Form/DigitsField";

const ForgotPasswordVerifyOTP = (props) => {
	const { verifyOTP, status, verifyStatus, type = 'email', value = '' } = props;

	const verifyDigitsLength = 6;
	const [digits, setDigits] = useState([]);
	const form = useRef();

	const getDigits = (value) => {
    const digits = `${value}`.split('').map((i) => parseInt(i)).filter((i) => !isNaN(i) && i <= 9 && i >= 0);
    setDigits(digits);

	}
	const onSubmitHandle = (event) => {
		event.preventDefault();
		if ( digits.length > 0 ) {
			const email = type === 'email' && value;
			const phone = type === 'phone' && value;
			verifyOTP(type, digits.join(''), email, phone );
		}

	}

	useEffect(() => {
		if ( status === 'success' && (!!verifyStatus[type] && verifyStatus[type].verified === true ) ) {
			console.log('Change password')
		}
	}, [status, verifyStatus, type])
	const isTooManyAttempts = false;

	return (
		<Box className="flex flex--justify-center">
			<Box
				ref={form}
				component="form"
				onSubmit={onSubmitHandle}
				className="form__verification flex flex--vertical flex--justify-around align--center"
				sx={{
					maxWidth: '450px'
				}}
			>
				{!isTooManyAttempts && <Typography variant="h6">
					{t("pages:verification.verification_email_title", {
						user_email: hideEmail(value),
					})}
				</Typography>}
				<fieldset disabled={isTooManyAttempts}>
					<DigitsField digitsLength={verifyDigitsLength} onChange={getDigits} />
				</fieldset>
				<Button
					sx={{ mb: 2 }}
					disabled={digits.length < verifyDigitsLength || isTooManyAttempts}
					type="submit"
					variant="contained"
				>
					{t("pages:verification.verify_button")}
				</Button>
			</Box>
		</Box>
	)
}

export default connect(state => {
	const wallet = state.wallet;
	return {
		status: wallet.status,
		verifyStatus: wallet?.private
	}
}, dispatch => {
	return {
		verifyOTP: (type, otp, email = '', phone = '') => {
			dispatch(verifyOTP({ type, otp, email, phone }));
		}
	}
})(ForgotPasswordVerifyOTP);