import { Navigate, useSearchParams } from "react-router-dom";
import TPKSignUp from "./TPKSignUp";
import PestelSignup from "./PestelSignup";
import { useEffect, useRef, useState } from "react";

const Viewer = (props) => {
	return props.children
}

const OnboardingSignup = () => {
	const [searchParams] = useSearchParams();
	const [operatorCode, setOperatorCode] = useState(null);
	
	useEffect(() => {
		if (operatorCode !== null) {
			return;
		}
		let operator = searchParams.get('operator-code') || 'TPK';
		setOperatorCode(operator);

	}, [operatorCode, searchParams])

	if ( operatorCode === null ) {
		return null;
	}

	return (
		<div>
			{operatorCode  === 'TPK' ? <TPKSignUp /> : <PestelSignup />}
		</div>
	);

}

export default OnboardingSignup;