import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

const VerifiedChecker = (props) => {
	const { status, email, phone, type, children } = props;
	const navigate = useNavigate();
	const [isVerified, setVerified] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (status === "success" && email && phone) {
			// Check if process is done move to next screen
			// if ( searchParams.get('rambu') ) {
				// return;
			// }
			const verified = type === "email" ? email.verified : phone.verified;

			if (verified) {
				setVerified(true);
				navigate("/", { replace: true });
			} else {
				setVerified(false);
			}
		}
	}, [status, email, phone, type, navigate, searchParams]);

	return isVerified ? null : children;
};

export default connect(state => {
	const wallet = state.wallet;
	
	return {
		status: wallet.status,
		email: wallet?.private?.email,
		phone: wallet?.private?.phone
	}
})(VerifiedChecker);