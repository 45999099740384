import React from 'react'
import { Box, Typography } from "@mui/material";
import LoaderBar from '../LoaderBar';
import { t } from 'i18next';


interface ILoadingMsg {
  message?: string;
}

const LoadingMsg = ({ message }: ILoadingMsg) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="body2" textAlign="center" fontWeight="500">{message || t('common:default_loading_message')}</Typography>
      <LoaderBar size={80} />
    </Box>
  )
}

export default LoadingMsg
