import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Collapse, List, ListItemButton, ListItemText, Typography } from "@mui/material";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './FAQs.scss';

const Item = (props) => {
  const { title, text, index } = props;
  const [open, setOpen] = useState(index===0);
  const handleClick = () => setOpen(!open);
  return (
    <>
      <ListItemButton 
        sx={{ borderTop: '1px solid var(--unikbase-gray)', pl: 0 }} 
        className="" onClick={handleClick}>
					<span className="number">{`${index<10&&'0'}${index+1}`}</span>
          <ListItemText primary={title} style={{ textTransform: 'uppercase' }} />
          <FontAwesomeIcon icon={open ? solid('arrow-up'): solid('arrow-down')} />

				<Collapse in={open} timeout="auto" unmountOnExit>
					<hr />
					<div dangerouslySetInnerHTML={{__html: text}}></div>
				</Collapse>
      </ListItemButton>
    </>
  )
}

const FAQs = (props) => {
  const { t } = useTranslation(); 
  const texts = t('pages:faqs:items', { returnObjects: true});

	return (
		<div className="faqs">
			{!!texts && typeof texts !== "undefined" && texts.length > 0 && (
				<List component="div">
					{texts.map((el, idx) => (
						<Item key={`faq-item-${idx}`} index={idx} title={el.question} text={el.answer} />
					))}
				</List>
			)}
		</div>
	);
};

export default FAQs;
