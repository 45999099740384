import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import PlatformNotfound from '../../layout/Platform/PlatformNotfound';
import { t } from 'i18next';

const PageNotFound = props => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const parameters = new URLSearchParams(search);

  const page = parameters.get('page');
  const shared = parameters.get('sharedHash');
  const tokenId =  parameters.get('tokenId');

  useEffect(() => {
    if ( !!page && page === 'shared' && !!shared ) {
      return navigate(`/token/share/${shared}`);
    }
    if ( !!page && page === 'token' && !!tokenId ) {
      return navigate(`/token/public/${tokenId}`);
    }
  }, [page])

  const goBack = () => () => {
    return navigate(-1)
  }
  return (
		<PlatformNotfound>
			<Loader logo={false}>
				<div className="flex flex--vertical flex--align-center flex--justify-center">
					<Typography sx={{ mt: "30px" }} variant="h1">
						404!
					</Typography>
					<Typography sx={{ mt: "30px", whiteSpace: "nowrap" }} variant="h3" color="secondary">
						{t("pages:notfound.message")}
					</Typography>
				</div>
			</Loader>
		</PlatformNotfound>
  )
}

export default PageNotFound;