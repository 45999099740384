//Write FilterMenu component here
import React, { Component, useEffect, useMemo, useRef } from "react";
import "./FilterMenu.scss";
import { Badge, Button, Menu, MenuItem, MenuList, buttonClasses } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CheckBoxFilterItem from "./CheckBoxFilterItem";
import DateRangeFilterItem from "./DateRangeFilterItem";

type ToggleComponentType = {
	key: string;
	element: {
		component: any;
		props: any;
	};
}

type FilterMenuItemProps = {
	type: string;
	key: string;
	label: string;
	items?: Array<Object>;
	onChange?: Function;
	toggle?: ToggleComponentType;
}

//Create FilterMenu Type
type FilterMenuProps = {
	data: Array<FilterMenuItemProps>;
	filter?: any;
	label?: string;
	icon?: string;
	className?: string;
	buttonClasses?: string;
	onChange?: Function;
};

const ToggleComponent = (props: any) => {
	const { toggle } = props;
	const componentProps = toggle?.element?.props || {};
	const CustomComponent = toggle['element']['component'];

	return (
		<div className="filter-menu__item--toggle">
			<CustomComponent {...componentProps} />
		</div>
	)
}

const FilterMenu: React.FC<FilterMenuProps> = (props) => {
	const { open: _open, label = "", icon = "filter", className = "", buttonClasses = "", data, filter, searchInput, onChange } = props;
	const defaultData = useRef<any>(data);
	const [items, setItems] = React.useState<Array<FilterMenuItemProps>>(data);

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLButtonElement>(null);

	const counter = useMemo(() => items.reduce((prev: number, current) => {
		let count = 0;
		if (current.type === 'checkbox') {
			count = current.items?.filter((item: any) => item.checked).length || 0;
			if (current.key === 'operator_status') {
				count += !!filter?.status?.operator_status?.user ? 1 : 0;
			}
		} else if (current.type === 'date') {
			count = filter?.dateRange ? 1 : 0;
		}
		return prev + count;
	}, 0)
		, [items, filter]);

	const closeHandle = () => {
		setOpen(false);
	};

	const itemClickHandle = (key: string, newItems: Array<FilterMenuItemProps>) => {
		// setOpen(false);
		let updateItems = items.map((item: FilterMenuItemProps) => {
			if (item.key === key) {
				item.items = newItems;
			}
			return item;
		});
		setItems(updateItems);
		typeof onChange === 'function' && onChange(key, JSON.parse(JSON.stringify(newItems)));
	};

	useEffect(() => {
		if ( data?.length !== defaultData.current?.length ) { // new filter added as operator filter update
			defaultData.current = JSON.parse(JSON.stringify(data));
			setItems(data);
		}
		if(JSON.stringify(data) !== JSON.stringify(defaultData.current)) { // data of operator user list changed
			defaultData.current = JSON.parse(JSON.stringify(data));
		}
	}, [data]);

	useEffect(() => {
		if (_open) {
			setOpen(!open);
		}
	}, [_open])

	return (
		<>
			<Button
				variant="text"
				size="small"
				aria-controls={open ? "demo-positioned-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={() => setOpen(!open)}
				ref={anchorRef}
				className={`filter-menu__button ${open ? "filter-menu__button--open" : ""} ${buttonClasses}`}
			>
				<Badge badgeContent={counter} color="secondary" className="filter-menu__badge" style={{marginRight: 5}}/>
				<span className={`icon icon--${icon}`}></span>
				{label}
				<FontAwesomeIcon icon={open ? solid("chevron-up") : solid("chevron-down")} />
			</Button>
			<Menu
				open={open}
				anchorEl={anchorRef.current}
				onClose={closeHandle}
				className={`filter-menu ${open ? "filter-menu--open" : ""} ${className}`}
			>
				{searchInput && (
					<MenuItem key="filter-uuid-name" onKeyDown={(e) => e.stopPropagation()}>
						{searchInput}
					</MenuItem>
				)}
				{items.map(({type, items, label: itemLabel, key, toggle }, index) => {
					return (
						<MenuItem key={`filter-menu__item-${index}`} onKeyDown={(e) => e.stopPropagation()}>
							{type === "checkbox" 
								? 
									<CheckBoxFilterItem 
										key={`filter-menu__item--checkbox-${index}`} 
										label={itemLabel} 
										items={items || []} 
										onChange={(newItems: Array<FilterMenuItemProps>) => itemClickHandle(key, newItems)} 
									/> 
								: type === "date" ?
									<DateRangeFilterItem 
										key={`filter-menu__item--date-${index}`} 
										label={itemLabel}
										onChange={(start: Date, end: Date) => {
											let items;
											if ( start && end ) {
												items = [{startDate: start.getTime(), endDate: end.getTime()}]
											}
											itemClickHandle(key, [
												{
													type: type,
													label: label,
													key: key, 
													items
												}
											]);
										}}
									/>
									: null
							}
							{data[index]?.toggle && !!items && items.filter(i => i.key === data[index]?.toggle?.key && !!i.checked).length > 0 && (<ToggleComponent toggle={data[index].toggle} />)}
						</MenuItem>
					)
				})}	
			</Menu>
		</>
	);
};

export default FilterMenu;