import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useGetTokenByIdQuery } from '../../../service/api/meveoApi';
import Loading from '../../../components/Loading';
import Center from '../../../components/Center';
import '../ShareTokenSelector/ShareTokenSelector.scss';

import "./TokenDetailsWebApp.scss";
import "../../../components/Token/TokenDetail.scss";
import { standPost } from '../Standalone';
import { PlatformLayout } from '../../../layout';
import { t } from 'i18next';
import { TokenDetail, TokenDetailLoading } from '../../../components/Token';

const TokenDetailsWebApp = (props: any) => {
  const [params] = useSearchParams();
  const tokenId = params.get('token');

  const [_token, setToken] = useState<any>(null);
  const { data, isFetching, isLoading, isError, refetch } = useGetTokenByIdQuery({ id: tokenId, mode: 'default' });

  const token = useMemo(() => {
    return data?.result || _token;
  }, [data?.result, _token])
  const loaded = useMemo(() => !!token?.token, [token])

  const _onBack = () => {
    standPost({ action: 'cancel' })
  }

  useEffect(() => {
    window.addEventListener("message", message => {
      if (message.data) {
        try {
          const data = JSON.parse(message.data);
          if (data.action === 'appInitToken') {
            setToken(data.token);
          }
        } catch (e) {
          console.log(e);
        }
      }
    });
    standPost({ action: 'loaded' })
  }, []);

  return (
    token
      ? <PlatformLayout
        hideSidebar={true}
        standalone={true}
        onNavigate={_onBack}
        pageTitle={t('common:digital_item')}
        className="site single token-single"
      >
        {!loaded && (!!isFetching || !!isError) ? (
          <TokenDetailLoading />
        ) : (
          <TokenDetail
            token={token}
            isFetching={!loaded && isFetching}
            isLoading={!loaded && isLoading}
            refetchFunc={refetch}
            mode={"default"}
          />)
        }
      </PlatformLayout>
      : <Center><Loading /></Center>
  )
}

export default
  connect(
    (state: any) => {
      let auth = state.auth;

      return {
        isLoggedIn:
          !!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime()
            ? auth.accessToken
            : false,
      };
    }
  )(TokenDetailsWebApp);
