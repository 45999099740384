import { connect } from 'react-redux';
import { initWallet, setOperatorUser, updateStatus } from '../../store/slices/walletSlice';
import { persistor } from '../../store/rootReducer';
import { logout } from '../../store/slices/authSlice';
import { useEffect } from 'react';
import { useGetUserWallet } from '../../hooks/userHooks';
import { Box } from '@mui/material';
import Loader from '../Loader';

const WalletLoader = (props) => {
  const { children, storeWallet, setOperatorUser, requireOperator, getOperatorInfo } = props;
  const { isFetching, operatorFetching, data } = useGetUserWallet();
  useEffect(() => {
    try {
      if (!isFetching && !!data?.wallet) {
        storeWallet({ wallet: JSON.parse(data.wallet) });
      }
    } catch (e) {
      console.log(e);
    }
  }, [isFetching, data.partnerCode, data.wallet, storeWallet]);

  useEffect(() => {
    try {
      if (!operatorFetching && !!data?.wallet) {
        let walletData = JSON.parse(data.wallet);
        setOperatorUser({
          isOperatorUser: walletData?.isOperatorUser,
          isOperator: walletData?.isOperator,
          partnerCode: data?.partnerCode,
          creationDate: walletData?.creationDate,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [operatorFetching, data.wallet, data?.partnerCode, setOperatorUser]);

  if (!!isFetching) {
    return (
      <Box className='flex flex--vertical flex--justify-center' sx={{ height: '100vh', backgroundColor: '#041c25' }}>
        <Loader />
      </Box>
    );
  }

  if (!!requireOperator && !data.partnerCode) {
    return (
      <Box className='flex flex--vertical flex--justify-center' sx={{ height: '100vh', backgroundColor: '#041c25' }}>
        <Loader navigate={'/wallet'} />
      </Box>
    );
  }
  return children;
};

export default connect(
  (state) => {
    let auth = state.auth;
    return {
      isLoggedIn: !!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime(),
      partnerCode: state?.wallet.partnerCode,
    };
  },
  (dispatch) => ({
    storeWallet: (data) => {
      dispatch(initWallet(data));
    },
    updateStatus: (status) => {
      dispatch(updateStatus(status));
    },
    logout: () => {
      persistor.purge();
      logout();
    },
    setOperatorUser: (args) => {
      dispatch(setOperatorUser(args));
    },
  })
)(WalletLoader);
