import { t } from "i18next";
import { Box, Typography } from "@mui/material";

import './TermsConditions.scss';

const TermsConditions = (props) => {
	const html = t("pages:terms_conditions.html");

	return (
		<>
			<Box className="terms-conditions-wrapper">
				<div dangerouslySetInnerHTML={{ __html: html }}></div>
			</Box>
			{/* {!!html &&
				Array.isArray(html) &&
				html.map((item, pIdx) => (
					<Box className="html-content-wrapper" key={`html-paragraph-${pIdx}`} sx={{ mb: 2 }}>
						{!!item.title && (
							<Typography sx={{ textTransform: "uppercase", mb: 1 }} variant="h5">
								{item.title}
							</Typography>
						)}
						{(!item.type || "default" === item.type) && (
							<div dangerouslySetInnerHTML={{ __html: item.content }}></div>
						)}
						{!!item.type && item.type === "list" && (
							<ul className="list list--number">
								{item.content &&
									item.content.map((listItem, idx) => (
										<li key={`list-item-${idx}`}>
											{!!listItem.title && <h3>{listItem.title}</h3>}
											<div dangerouslySetInnerHTML={{ __html: listItem.content || "" }}></div>
										</li>
									))}
							</ul>
						)}
					</Box>
				))} */}
		</>
	);
};

export default TermsConditions;
