import { Box, Card, CardContent, Grid, Typography } from "@mui/material"
import { t } from "i18next"
import { formatDate, transferToUnitDisplay } from "../../utils/helpers"
import { centimeterToMeter, gramToKilogram, inchToMeter, poundToKilogram, unitTypes } from "../../configs/constant";
import BigNumber from "bignumber.js";
import { useEffect, useMemo, useRef } from "react";
import { useGetOperatorDetailQuery } from "../../service/api/meveoApi";

const TokenSpecifications = ({ token, unit }) => {
	const container = useRef();
	const { data } = useGetOperatorDetailQuery({
		operator_code: token?.token?.operator || token?.token?.uuid?.split('_')[0] || ''
	});
	const operator = useMemo(() => data?.result || {}, [data]);

	useEffect(() => {
		const resizeHandler = () => {
			if (!container.current) return;
			const wrapper = container.current.querySelector('.attributes');
			const attributes = wrapper.querySelectorAll('.attribute');
			if (!attributes) return;

			let enoughWidth = true;
			attributes.forEach(item => {
				let width = item.offsetWidth - 9;
				let avatar = item.querySelector('.avatar-with-icon')
				let content = item.querySelector('.content')
				enoughWidth = enoughWidth && (avatar && content && avatar.offsetWidth + content.offsetWidth < width)
			})
			if (enoughWidth) {
				wrapper.classList.remove('mobile-view')
			} else {
				wrapper.classList.add('mobile-view')
			}
		}
		resizeHandler();
		window.addEventListener('resize', resizeHandler);
		return () => {
			window.removeEventListener('resize', resizeHandler);
		}
	}, [])
	return (
		<Card ref={container} className="specifications" variant="outlined">
			<CardContent sx={{ p: 0, pb: 0 }}>
				<Grid container spacing={2}>
					<Grid item xs={6} sm={3} md={3} lg={6} xl={3}>
						<Typography component="span" variant="body2">
							{t("pages:token.token_origin")}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{operator?.name || t("pages:token.token_origin_value")}
						</Typography>
					</Grid>
					<Grid item xs={6} sm={3} md={3} lg={6} xl={3}>
						<Typography component="span" variant="body2">
							{t("pages:token.creation_date")}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{token?.token?.creationDate
								? formatDate(token.token.creationDate)
								: t("common:unspecified")}
						</Typography>
					</Grid>
					<Grid item xs={6} sm={3} md={3} lg={6} xl={3}>
						<Typography component="span" variant="body2">
							{t("pages:token.mark_reference")}
						</Typography>
						<Typography className="ellipsis_text" variant="body2" color="text.secondary">
							<span>
								{token?.token?.tokenMarkReference
									? token.token.tokenMarkReference
									: t("common:unspecified")}
							</span>
						</Typography>
					</Grid>
					<Grid item xs={6} sm={3} md={3} lg={6} xl={3}>
						<Typography component="span" variant="body2">
							{t("pages:token.price")}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{token?.token?.value
								? `${token?.token?.value} ${token?.token.currency || ''}`
								: t("common:unspecified")}
						</Typography>
					</Grid>
					<Grid item xs={6} sm={3} md={3} lg={6} xl={3}>
						<Typography component="span" variant="body2">
							{t("pages:token.commercial_offer_code")}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{token?.token?.commercialOfferCode
								? (token.token.commercialOfferCode === 'BASIC' ? 'FREE' : token.token.commercialOfferCode)
								: t("common:unspecified")}
						</Typography>
					</Grid>
					<Grid item xs={6} sm={3} md={3} lg={6} xl={3}>
						<Typography component="span" variant="body2">
							{t("pages:token.owner")}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{token?.token?.ownerEmail
								? token.token.ownerEmail
								: t("common:unspecified")}
						</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={2} className="attributes">
					<Grid item xs={6} sm={3} md={3} lg={6} xl={3} className="attribute">
						<Box display='flex'>
							<i className="icon icon--length"></i>
							<Typography variant="body">
								{t("pages:token.length")}{'\n'}
								<Typography variant="body2" className="content">
									{token?.token?.lengthInMeter
										? `${transferToUnitDisplay(unit, token.token.lengthInMeter)}`
										: t("common:unspecified")}
								</Typography>
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={6} sm={3} md={3} lg={6} xl={3} className="attribute">
						<Box display='flex'>
							<span className="icon icon--width"></span>
							<Typography variant="body">
								{t("pages:token.width")}{'\n'}
								<Typography variant="body2" className="content">
									{token?.token?.widthInMeter
										? transferToUnitDisplay(unit, token.token.widthInMeter)
										: t("common:unspecified")}
								</Typography>
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={6} sm={3} md={3} lg={6} xl={3} className="attribute">
						<Box display='flex'>
							<span className="icon icon--depth"></span>
							<Typography variant="body">
								{t("pages:token.depth")}{'\n'}
								<Typography variant="body2" className="content">
									{token?.token?.depthInMeter
										? transferToUnitDisplay(unit, token.token.depthInMeter)
										: t("common:unspecified")}
								</Typography>
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={6} sm={3} md={3} lg={6} xl={3} className="attribute">
						<Box display='flex'>
							<span className="icon icon--weight" style={{ maskSize: "80%" }}></span>
							<Typography variant="body">
								{t("pages:token.weight")}{'\n'}
								<Typography variant="body2" className="content">
									{token?.token?.weightInKilo
										? transferToUnitDisplay(unit, token.token.weightInKilo, false) : t("common:unspecified")}
								</Typography>
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	)
}

export default TokenSpecifications;