import { Button, Typography } from '@mui/material';
import React from 'react';
import { t } from 'i18next';

function ButtonReport({ variant, onClick }) {
  return (
    <Button
      color='secondary'
      variant={variant || 'text'}
      onClick={onClick}
      aria-label='share'
      title='share token'
    >
      <span className={`icon icon__action icon--note ${variant || ''}`}>_</span>
      <Typography component="span" variant="body2">
        {t('pages:token.certificate')}
      </Typography>
    </Button>
  );
}

export default ButtonReport;
