import { Box } from "@mui/material";
import { useImageCompare } from "../ImageCompare/ImageCompareContext";

const Singularity = props => {
	const viewer = useImageCompare();
	const rotation = viewer?.images['original']?.rotation || 0;
	const { config } = props;
	let reverseDeg = rotation*-1
	return (
		<Box sx={{
			left: `${config.x*100 - config.r*100/2}%`,
			top: `calc(${config.y*100 - config.r*100/2}% - 40px)`,
			width: `${config.r*100}%`,
			transform: `rotate(${reverseDeg}deg)`,
			transformOrigin: 'center'
		}} className="singularity" >
			<span></span>
		</Box>
	)
}

export default Singularity;