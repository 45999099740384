import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Button,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableRow,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { t } from "i18next";
import { TRANSACTION_TYPES } from "../../configs/constant";
import config from "../../configs/config";
import { useEffect, useState } from "react";
import { timeSince } from "../../utils/helpers";
import { ImageProxy } from "../Gallery";
import { meoveoRestApi, useDeleteTokenTransactionByIdQuery } from "../../service/api/meveoApi";
import { MessageBox } from "../Animation";

const RowItem = ({ label, value }) => {
	return (
		<>
			{!!value && (
				<TableRow>
					<TableCell>{value ? label : ""}</TableCell>
					<TableCell colSpan={5}>{value ? value : ""}</TableCell>
				</TableRow>
			)}
		</>
	);
};

const Transaction = ({ transaction, userWalletAddress, index, onDelete, isOpen, showRemove = true, headers }) => {
	const [open, toggleOpen] = useState(false);

	let transactionType = "";
	if (transaction.type === "transferFrom") {
		transactionType = t("pages:transactions.type.smart_constract");
	} else {
		transactionType = t(TRANSACTION_TYPES[transaction.type]?.label || transaction.type);
	}

	let txn = {
		uuid: transaction.id,
		tokenId: transaction.tokenId,
		tokenName: transaction.tokenName,
		time: transaction?.transactionDate ? timeSince(transaction.transactionDate, true) : '',
		type: transactionType,
		from: transaction?.data?.parameters?.from,
		to: transaction?.data?.parameters?.to,
		author: transaction?.authorUsername
	};

	return (
		<>
			<TableRow onClick={() => toggleOpen(!open)} className={`transaction ${index % 2 === 0 ? "odd" : "even"}`}>
				<TableCell 
					sx={{ 
						'&::before': { content: `"${headers[0]?.label || ''}"` }
					}}>{txn.tokenName}</TableCell>
				<TableCell 
					sx={{ 
						'&::before': { content: `"${headers[1]?.label || ''}"` }
					}}>{txn.tokenId}</TableCell>
				<TableCell 
					sx={{ 
						'&::before': { content: `"${headers[2]?.label || ''}"` }
					}}>{t("pages:token.token_origin_value")}</TableCell>
				<TableCell 
					sx={{ 
						'&::before': { content: `"${headers[3]?.label || ''}"` }
					}}>{txn.time}</TableCell>
				<TableCell 
					sx={{ 
						textTransform: "uppercase",
						'&::before': { content: `"${headers[4]?.label || ''}"` }
					}}>{txn.type}</TableCell>
				<TableCell className="collapse--button">
					<FontAwesomeIcon icon={open ? solid("chevron-up") : solid("chevron-down")} />
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell className={`transaction__detail ${index % 2 === 0 ? "odd" : "even"}`} colSpan={6}>
					<Collapse in={open}>
						<Table>
							<TableBody>
								<RowItem label={t("pages:transactions.digital_twin_name")} value={txn.tokenName} />
								<RowItem label={t("common:type")} value={txn.type} />
								<RowItem label={t("common:status")} value={txn?.status} />
								<RowItem label={t("common:date")} value={txn?.time} />
								<RowItem label={t("common:from")} value={txn?.from} />
								<RowItem label={t("common:to")} value={txn?.to} />

								{TRANSACTION_TYPES.CREATE_DOCUMENT.key === transaction.type &&
									!!transaction.document && (
										<>
											{transaction.document.mimeType.startsWith("image") && (
												<RowItem
													label={""}
													value={
														<ImageProxy
															url={transaction.document.fileUrl}
															width={40}
															height={40}
															style={{ marginRight: "2em" }}
														/>
													}
												/>
											)}
											<RowItem
												label={"File name"}
												value={
													transaction.document.name ||
													transaction.document.filename ||
													"file's name is not set"
												}
											/>
											<RowItem label={"Hash"} value={transaction.document.uuid} />
											<RowItem label={"Size"} value={transaction.document.size} />
										</>
									)}
								{TRANSACTION_TYPES.DELETE_DOCUMENT.key === transaction.type &&
									!!transaction.document && (
										<>
											<RowItem
												label={"File name"}
												value={
													transaction.document.name ||
													transaction.document.filename ||
													"file's name is not set"
												}
											/>
											<RowItem label={"Hash"} value={transaction.document.uuid} />
										</>
									)}
								{TRANSACTION_TYPES.UPDATE_PROPERTY.key === transaction.type &&
									!!transaction.property && (
										<>
											<RowItem
												label={"Property Name"}
												value={transaction.property.propertyName}
											/>
											<RowItem label={"New Value"} value={transaction.property.newValue} />
											<RowItem label={"Old Value"} value={transaction.property.oldValue} />
										</>
									)}
								{!!txn.author && (
										<RowItem
											label={t('pages:transactions.updated_by')}
											value={txn.author}
										/>
									)}
								{(TRANSACTION_TYPES.TRANSFER_TOKEN_REQUEST.key === transaction.type ||
									TRANSACTION_TYPES.ACCEPT_TOKEN.key === transaction.type ||
									TRANSACTION_TYPES.REFUSE_TRANSFER_TOKEN_REQUEST.key === transaction.type) && (
									<>
										<RowItem label={"Target Username"} value={transaction.targetUsername} />
										<RowItem label={"Old Value"} value={transaction.targetWallet} />
									</>
								)}

								{TRANSACTION_TYPES.PAYMENT.key === transaction.type && !!transaction.payment && (
									<>
										<RowItem label={t("pages:transactions.amount")} value={`${transaction?.payment?.amount} ${transaction?.payment?.currency}`} />
									</>
								)}

								{!!transaction.transactionHash && config.BLOCK_EXPLORE_URL && (
									<>
										<RowItem label={t("pages:transactions.blockchain_explorer")} value={(
											<a rel="noreferrer" target="_blank" href={`${config.BLOCK_EXPLORE_URL}/tx/0x${transaction.transactionHash}/`}>{transaction.transactionHash}</a>
										)} />
									</>
								)}
								{showRemove && (
									<RowItem
										value=" "
										label={
											<Button
												sx={{ mt: 2 }}
												variant="contained"
												color="secondary"
												onClick={() => onDelete(transaction)}
												size="small"
											>
												{t("pages:transactions.delete_token_history")}
											</Button>
										}
									/>
								)}
							</TableBody>
						</Table>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default Transaction;
