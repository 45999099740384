const CryptoJS = require('crypto-js');
const bip39 = require('@metamask/bip39');
const ethUtil = require('ethereumjs-util');

export function parseWalletAddressFromSignature(signature, message) {
	signature = signature.startsWith('0x') ? signature : '0x' + signature;
	const messageHash = ethUtil.keccak256(Buffer.from(message));
	const signatureBuffer = ethUtil.toBuffer(signature);
	const v = signatureBuffer[64];
	const r = signatureBuffer.slice(0, 32);
	const s = signatureBuffer.slice(32, 64);

	const publicKey = ethUtil.ecrecover(messageHash, v, r, s);
	const address = ethUtil.publicToAddress(publicKey);

	return ethUtil.bufferToHex(address);
}
// generate signature with private key
export function signMessage(message, privateKey) {
	privateKey = privateKey.startsWith('0x') ? privateKey : '0x' + privateKey;
	const messageHash = ethUtil.keccak256(Buffer.from(message));
	const privateKeyBuffer = ethUtil.toBuffer(privateKey);
	const signature = ethUtil.ecsign(messageHash, privateKeyBuffer);

	const combined = Buffer.concat([
			Buffer.from(signature.r),
			Buffer.from(signature.s),
			Buffer.from([signature.v])
	]);

	const signatureHex = combined.toString('hex');
	return signatureHex;
}

export function createSignature(data, key) {
  const hash = CryptoJS.HmacSHA256(JSON.stringify(data), key);
  return hash.toString(CryptoJS.enc.Hex);
}

function sha256(input) {
	return ethUtil.bufferToHex(ethUtil.sha256(input));
}

function getByteSize(str) {
  let byteSize = 0;
  for (let i = 0; i < str.length; i++) {
    let charCode = str.charCodeAt(i);
    if (charCode < 0x0080) {
      byteSize += 1;
    } else if (charCode < 0x0800) {
      byteSize += 2;
    } else {
      byteSize += 3;
    }
  }
  return byteSize;
}

export const generateSeedphrase = () => {
  return bip39.generateMnemonic().toString();
};
/**
 *
 * @param {string} input
 * @returns {string}
 *
 * seedphrase was convert to entropy then encrypted before it was save.
 * so to use it, convert it back
 */
export const decryptSeedPhrase = (input, password, address) => {
  const entropy = _aesDecrypt(input, password, address);
  if (!entropy) return false;

  let seedPhrase = getSeedPhraseFromEntropy(entropy.replace('0x', ''));

  return seedPhrase;
};

export const encrypeSeedPhrase = (mnemonic, password, address) => {
  let entropy = getEntropyFromSeedPhrase(mnemonic);
  if (!/^0x/.test(entropy)) {
    entropy = '0x' + entropy;
  }
  const encrypted = _aesEncrypt(entropy, password, address);
  return encrypted;
};
/**
 *
 * @param input hex string
 * @returns
 */
export const getSeedPhraseFromEntropy = (input) => {
  let buffer = Buffer.from(input, 'hex');
  let bytes = bip39.entropyToMnemonic(buffer);
  return bytes.toString();
};

export const decryptOperatorKey = async (operatorKey, hash, address) => {
	address = address.replace(/^0x/, '')
	hash = sha256(hash)
	return await _aesDecrypt(operatorKey, hash, address);
}
export const encryptOperatorKey = async (privateKey, opKey, address) => {
	address = address.replace(/^0x/, '')
	opKey = sha256(opKey);
	return await _aesEncrypt(privateKey, opKey, address.toLowerCase());
}

export const getEntropyFromSeedPhrase = (input) => {
  return bip39.mnemonicToEntropy(input);
};

export const _aesEncrypt = (data, key, address) => {
  let iv = address.toLowerCase().replace('0x', '').slice(0, 32);
  let key64 = sha256(key).replace('0x', '');
  // iv = CryptoJS.enc.Hex.parse(Buffer.from(iv, 'hex'));

  const cipherText = data;
  const encryptedData = CryptoJS.AES.encrypt(cipherText, CryptoJS.enc.Hex.parse(key64), {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: CryptoJS.enc.Hex.parse(iv),
  });

  return encryptedData.toString();
};

/**
 *
 * @param encryptedData base64 string
 * @param password string
 * @param address
 * @returns
 */
export const _aesDecrypt = (encryptedData, password, address) => {
  let iv = address.toLowerCase().replace('0x', '').slice(0, 32);
  let key64 = sha256(password).replace('0x', '');

  try {
    const data = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Hex.parse(key64), {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
      iv: CryptoJS.enc.Hex.parse(iv),
    });

    return data.toString(CryptoJS.enc.Utf8);
  } catch (error) {
		console.log(error.message)
    console.error(error);
  }
  return '';
};

const generateValuesArray = (tokenDetail) => {
	const omitKeys = [];
	const sortedKeys = Object.keys(tokenDetail)
		.sort()
		.filter((key) => !omitKeys.includes(key));
	const valuesArray = [];
	for (const key of sortedKeys) {
		if (typeof tokenDetail[key] !== 'undefined') {
			const field = {
				name: key,
				value: tokenDetail[key].toString(),
			};
			valuesArray.push(field);
		}
	}
	return valuesArray;
};

const generateHashedValuesArray = (tokenUUID = '', valuesArray) => {
	const hashedValuesArray = valuesArray.map((field) => ({
		name: field.name,
		valueHash: CryptoJS.MD5(tokenUUID + field.value).toString(),
	}));
	return hashedValuesArray;
};

export const generateJsonHash = (token) => {
	// create a json array value serialize in UTF-8
	const valuesArray = generateValuesArray(token.token);

	// hash the values using MD5
	const hashedValuesArray = generateHashedValuesArray(token.token.uuid, valuesArray);

	// return its sha512
	const hash = CryptoJS.SHA512(JSON.stringify(hashedValuesArray)).toString();
	return hash;
};

export const generateZipHash = (token) => {
	const jsonHash = generateJsonHash(token);
	const documentsHashes = token.documents.map((doc) => {
		return doc.uuid;
	});
	const zip = {
		jsonHash,
		documentsHashes,
	};
	const zipHash = CryptoJS.SHA512(JSON.stringify(zip)).toString();
	return zipHash;
};

export const isValidMnemonic = (string) => {
	return bip39.validateMnemonic(string)
}