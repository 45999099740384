import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { TokenPrivacyProvider } from '../../ShareTokenPrivacy/TokenPrivacyContext';
import ShareOptionTable from '../../ShareToken/ShareOptionTable';
import ShareTokenSubmit from '../../ShareToken/ShareTokenSubmit';
import { MobileScrollbar } from '../../../CustomScrollbar';
import ShareTokenHeadInfo from '../../ShareToken/ShareTokenHeadInfo';

function ModalConfirm({
  loading = false,
  onClose,
  onConfirm,
  token,
  visible = false,
}) {
  const _onConfirm = (options) => {
    onConfirm && onConfirm(options);
  }

  return (
    <Dialog className='token__share certificate dialog' open={visible} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h5" fontWeight="500">{t('pages:token.generate_pdf_certificate')}</Typography>
          <IconButton className="confirm-dialog__close" aria-label="close" onClick={onClose}>
            <span className="icon icon--close"></span>
          </IconButton>
        </Box>
      </DialogTitle>

      <TokenPrivacyProvider>
        <MobileScrollbar>
          <DialogContent className="token__share--main">
            <ShareTokenHeadInfo token={token} />
            <ShareOptionTable token={token} certificateGen={true} />
          </DialogContent>
        </MobileScrollbar>

        <DialogActions
          sx={{ m: "0 auto" }}
          className="flex flex--vertical align--center flex--justify-center"
        >
          {loading
            ? <LoadingButton
              variant='contained'
              color='secondary'
              className='w--100'
              onClick={onConfirm}
              loading={loading}
            >
              {t('pages:token.generate')}
            </LoadingButton>
            : <ShareTokenSubmit
              label={t('pages:token.generate')}
              token={token}
              onConfirm={_onConfirm}
              onClose={onClose}
            />
          }
        </DialogActions>
      </TokenPrivacyProvider>
    </Dialog>
  );
}

export default ModalConfirm;
