import { Box, Typography } from "@mui/material";
import { t } from "i18next";

import './PrivacyPolicy.scss';

const PrivacyPolicy = (props) => {
	const html = t("pages:privacy_policy.html");
	return (
		<>
			<Box className="privacy-policy-wrapper">
				<div dangerouslySetInnerHTML={{ __html: html }}></div>
			</Box>
		</>
	);
};
export default PrivacyPolicy;
