import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
} from "@mui/material";
import { t } from "i18next";
import { convertBase64ToFile } from "../AddToken/AddToken";
import { standPost } from "../../../pages/Standalone/Standalone";


const UploadImage = (props: any) => {
	const { onChange } = props;
	const inputRef = useRef<any>(null);
	const [standalone, setStandalone] = useState(false);

	const registerFilePicker = () => {
		if (standalone) {
			window.pickerFile = (data: any) => {
				const base64Images = JSON.parse(data);
				const files = base64Images.map((base64Image: string) => convertBase64ToFile(base64Image))
				onChange({
					uuid: `${new Date().getTime()}`,
					file: files[0],
					src: base64Images[0]
				});
			}
		}
	}

	const openWebAppPicker = () => {
		registerFilePicker();
		standPost({ action: 'input-file', data: { type: 'photo', multiple: false } })
	}

	const handleChange = function (e: any) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			const selectedFile = e.target.files[0];

			var reader = new FileReader();
			reader.onload = function (event: any) {
				typeof onChange === 'function' && onChange({
					uuid: `${new Date().getTime()}`,
					file: selectedFile,
					src: event.target.result
				});
			};
			reader.readAsDataURL(selectedFile);
		}
	};

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.value = '';
		}
		if (document.getElementById('standaloneWebApp')) {
			setStandalone(true);
		}
	}, []);

	return (
		<Box>
			<Button
				className="token__upload_cover_image"
				variant="outlined"
				color="primary"
				onClick={() => standalone ? openWebAppPicker() : inputRef?.current.click()}
				size="small"
				sx={{
					width: '110px',
					height: '110px',
				}}
			>
				<Box>
					<span className="icon icon--add-token" />
					<span>{t('pages:token.document_details.add_image')}</span>
				</Box>
				<input
					ref={inputRef}
					onChange={handleChange}
					id="input-gallery-image-upload"
					type="file"
					accept="image/png, image/jpeg, image/gif"
					style={{ display: 'none' }}
				/>
			</Button>
		</Box>
	);
};

export default UploadImage;
