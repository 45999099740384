import { Skeleton, TableCell, TableRow } from "@mui/material";


const Placeholder = ({ className = "", sx = {} }) => {
	return (
		<TableRow className={className} sx={sx}>
			<TableCell>
				<Skeleton variant="text" />
			</TableCell>
			<TableCell>
				<Skeleton variant="text" />
			</TableCell>
			<TableCell>
				<Skeleton variant="text" />
			</TableCell>
			<TableCell>
				<Skeleton variant="text" />
			</TableCell>
			<TableCell>
				<Skeleton variant="text" />
			</TableCell>
			<TableCell></TableCell>
		</TableRow>
	);
};

export default Placeholder;