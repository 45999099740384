import { Box, Collapse, List, ListItem, ListItemButton, ListItemText, Skeleton, Typography } from "@mui/material";
import { useGetTokenTransactionsQuery } from "../../../service/api/meveoApi";
import { ImageProxy } from "../../Gallery";
import "./TokenTransactions.scss";
import { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as datefns from 'date-fns';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { formatDate, getTransactionExploreURL, timeSince } from "../../../utils/helpers";
import { TRANSACTION_TYPES } from "../../../configs/constant";
import { t } from "i18next";

const TransactionProperty = (props) => {
	const { label, value, link, type = "column", sx = null, className = "" } = props;
	return (
		<Box
			className={`transaction__detail ${className} flex flex--${
				type === "row" ? "horizontal flex--justify-between" : "vertical"
			} flex--wrap`}
			sx={{
				...sx,
			}}
		>
			<Typography variant="caption">{label}</Typography>
			{link
				? (
					<a href={link} target="_blank" rel="noreferrer">
						<Typography variant="body2" color="blue">{value}</Typography>
					</a>
				)
				: <Typography variant="body2">{value}</Typography>
			}
		</Box>
	);
};

export const TransactionDetail = ({ data, txn, open, coverImage = false }) => {
	return (
		<Collapse className="transaction__details" in={open} timeout="auto" unmountOnExit>
			{/* <Box className="flex flex--justify-start" sx={{ p: "0 0 0.618em 0!important" }}>
				<TransactionProperty
					sx={{ width: "70%", mr: 3 }}
					label={t("pages:transactions.status")}
					value={txn.status}
				/>
				<TransactionProperty label="Date" value={txn.time} />
			</Box> */}
			{txn.from && <TransactionProperty label={t("pages:transactions.From")} value={txn.from} />}
			{txn.to && <TransactionProperty label={t("pages:transactions.To")} value={txn.to} />}

			{TRANSACTION_TYPES.CREATE_TOKEN.key === data.type && (
				<Box className="flex flex--justify-between flex--wrap">
					<TransactionProperty label={t("pages:transactions.token_name")} value={data?.tokenName} className="w--50" />
					{coverImage && (
						<TransactionProperty
							label=""
							value={<ImageProxy url={coverImage.fileUrl} alt={data.tokenName} width="50" height="50" />}
							className="w--50"
						/>
					)}
				</Box>
			)}

			{/* Documents */}
				{TRANSACTION_TYPES.CREATE_DOCUMENT.key === data.type && !!data.document && (
					<>
						<Box className="flex flex--horizontal flex--justify-left flex--wrap">
							{data.document.mimeType.startsWith("image") && (
								<ImageProxy
									url={data.document.fileUrl}
									width="50"
									height={50}
									style={{ marginRight: "2em" }}
								/>
							)}
							<TransactionProperty
								label={t("pages:transactions.file_name")}
								value={data.document.name || data.document.filename || "file's name is not set"}
							/>
							<TransactionProperty sx={{ml: 5}} label="Size" value={data.document.size} />
						</Box>
						{/* <TransactionProperty label={t("pages:transactions.hash")} value={data.document.uuid} link={data.document.fileUrl} /> */}
					</>
				)}
				{TRANSACTION_TYPES.DELETE_DOCUMENT.key === data.type && !!data.document && (
					<>
						<TransactionProperty
							label={t("pages:transactions.file_name")}
							value={data.document.name || data.document.filename || "file's name is not set"}
						/>
						{/* <TransactionProperty label={t("pages:transactions.hash")} value={data.document.uuid} link={data.document.fileUrl} /> */}
					</>
			)}
			{data.transactionHash && (
				<TransactionProperty label={t("pages:transactions.hash")} value={data.transactionHash} link={getTransactionExploreURL(data.transactionHash)} />
			)}

				{TRANSACTION_TYPES.UPDATE_PROPERTY.key === data.type && !!data.property && (
					<>
						<TransactionProperty label={t("pages:transactions.property_name")} value={data.property?.propertyName} className="w--100" />
						<TransactionProperty className="w--50" label={t("pages:transactions.tx_details_new_value")} value={data.property?.newValue} />
						<TransactionProperty className="w--50" label={t("pages:transactions.tx_details_old_value")} value={data.property?.oldValue} />
					</>
				)}

				{!!txn.author && (
					<TransactionProperty className="w--100" label={t("pages:transactions.updated_by")} value={txn.author} />
				)}

			{(TRANSACTION_TYPES.TRANSFER_TOKEN_REQUEST.key === data.type ||
				TRANSACTION_TYPES.ACCEPT_TOKEN.key === data.type ||
				TRANSACTION_TYPES.REFUSE_TRANSFER_TOKEN_REQUEST.key === data.type) && (
				<Box className="flex flex--vertical flex--justify-between flex--wrap">
					<TransactionProperty label={t("pages:transactions.tx_details_target_username")} value={data.targetUsername} />
					<TransactionProperty label={t("pages:transactions.tx_details_target_wallet")} value={data.targetWallet} />
				</Box>
			)}

			{TRANSACTION_TYPES.PAYMENT.key === data.type && !!data.payment && (
				<Box className="flex flex--vertical flex--justify-between flex--wrap">
					<TransactionProperty
						label={t("pages:transactions.amount")}
						value={`${data.payment?.amount} (${data.payment?.currency})`}
						className="w--50"
					/>
				</Box>
			)}

			{!!txn.value && (
				<Box className="amount bg--white" sx={{ pl: 2, pr: 2, borderRadius: "4px" }}>
					<TransactionProperty type="row" label={t("pages:transactions.amount")} value={txn.value} />
					<TransactionProperty type="row" label={t("pages:transactions.transaction_fee")} value={txn.gasPrice} />
					<TransactionProperty
						className="amount__total"
						type="row"
						label={t("pages:transactions.total")}
						value={txn.value - txn.gasPrice}
					/>
				</Box>
			)}
		</Collapse>
	);
};

export const Transaction = (props) => {
	const { transaction, coverImage, collapse = true } = props;
	const [open, toggleItem] = useState(false);
	let transactionType = "";
	let status = "Done";

	if (transaction.type === "transferFrom") {
		transactionType = t("pages:transactions.type.smart_constract");
		status = transaction.blockNumber && transaction.blockNumber !== "null" ? "confirmed" : "pending";
	} else {
		transactionType = t(TRANSACTION_TYPES[transaction.type]?.label || transaction.type);
	}

	let txn = {
		type: transactionType,
		from: transaction?.data?.parameters?.from,
		gas: transaction.gas || "",
		gasPrice: transaction.gasPrice ? parseFloat(transaction.gasPrice, 10) : "",
		nonce: transaction.nonce ? `#${parseInt(transaction.nonce, 10)}` : "",
		to: transaction?.data?.parameters?.to,
		value: parseFloat(transaction.value || 0, 10),
		status: status,
		time: transaction?.transactionDate ? formatDate(transaction.transactionDate, true ) : '',
		author: transaction?.authorUsername
	};

	return (
		<Box className="transaction__wrapper" sx={{ p: '0!important' }}>
			{!!collapse && <ListItemButton className="transaction" onClick={() => toggleItem(!open)}>
				<Box>
					<Typography className="transaction__type" component="span" variant="body2">
						{txn.type}
					</Typography>
					<br />
					<Typography className="transaction__sub" variant="caption">
						<Typography className="transaction__status" component="span" variant="caption" textTransform="uppercase">
							{txn.status}
						</Typography>
						{' '}&bull;{' '}
						<span>{datefns.format(new Date(txn.time), 'yyyy-MM-dd HH:mm')}</span>
						{' '}&bull;{' '}
						<span>{t('pages:transactions.updated_by')}{' '}{txn.author}</span>
					</Typography>
				</Box>
				<FontAwesomeIcon icon={open ? solid("chevron-up") : solid("chevron-down")} />
			</ListItemButton>}
			{!collapse && (
				<Typography className="transaction__type" component="span" variant="caption">
					<span className="label">{t("common:type")}</span>
					{txn.nonce && <span className="transaction__number">{txn.nonce}</span>}
					<span>{txn.type}</span>
				</Typography>
			)}
			<TransactionDetail open={!collapse || open} txn={txn} data={transaction} coverImage={coverImage} />
		</Box>
	);
};

const TokenTransactions = (props) => {
	const { token, sort, coverImage, onDataChange, filter, onFilterData } = props;
	const defaultViewCount = 10;
	const [viewAll, setViewAll] = useState(false);

	const { data: _data, isFetching, isError } = useGetTokenTransactionsQuery({
		tokenId: token.token.uuid,
	});

	const data = useMemo(() => {
		const data = token.transactions ? { result: token.transactions } : _data
		if (data?.result && Array.isArray(data?.result) && onFilterData) onFilterData(data.result);
		return data;
	}, [token.transactions, _data]);

	const applyFilter = (documents, filter) => {
		if (filter) {
			if (filter.type) {
				documents = documents.filter((document) => document.type === filter.type);
			}
			if (filter.date?.length > 0) {
				const dateRange = filter.date[0];
				documents = documents.filter((document) => document.transactionDate >= dateRange.startDate && document.transactionDate <= dateRange.endDate);
			}
			if (filter.author) {
				documents = documents.filter((document) => document.authorUsername === filter.author);
			}
		}
		return documents;
	}

	const sortedData = useMemo(() => {
		if (!data || !data.result || !Array.isArray(data.result)) {
			return [];
		}

		let sortedData = [...data.result];
		if (sort) {
			sortedData = sortedData.sort((a, b) => {
				if (sort === "newest") {
					return new Date(b.transactionDate) - new Date(a.transactionDate);
				} else if (sort === "oldest") {
					return new Date(a.transactionDate) - new Date(b.transactionDate);
				} else if (sort === "type") {
					return t(TRANSACTION_TYPES[a.type].label).localeCompare(t(TRANSACTION_TYPES[b.type].label));
				} else if (sort === "status") {
					// no data
				} else if (sort === "author") {
					return `${a.authorUsername}`.localeCompare(`${b.authorUsername}`);
				}

				return 0;
			});
		}

		return applyFilter(sortedData, filter);
	}, [sort, data, filter]);

	const remainingCount = sortedData.length - defaultViewCount;

	useEffect(() => {
		if (onDataChange && Array.isArray(data?.result)) {
			onDataChange(data.result.filter(item => item.type !== TRANSACTION_TYPES.PAYMENT.key && item.type !== TRANSACTION_TYPES.TOP_UP.key))
		}
	}, [data, onDataChange]);

	return (
		<List className="token__transactions">
			{isFetching ? (
				<>
					<ListItem className="transaction">
						<Skeleton variant="text" sx={{ width: "100%", height: "30px" }} />
					</ListItem>
					<ListItem className="transaction">
						<Skeleton variant="text" sx={{ width: "100%", height: "30px" }} />
					</ListItem>
					<ListItem className="transaction">
						<Skeleton variant="text" sx={{ width: "100%", height: "30px" }} />
					</ListItem>
				</>
			) : (
				sortedData
					.filter(item => item.type !== TRANSACTION_TYPES.PAYMENT.key && item.type !== TRANSACTION_TYPES.TOP_UP.key)
					.slice(0, viewAll ? sortedData.length : defaultViewCount)
					.map((item, idx) => (
					<Transaction
						transaction={item}
						key={`token-transaction-${item?.uuid || idx}`}
						coverImage={coverImage}
					/>
				))
			)}
			{remainingCount > 0 && (
				<Typography
					component="a"
					variant="body2"
					onClick={() => setViewAll(!viewAll)}
				>
					{viewAll ? t('pages:token.show_less') : `${t('pages:token.show_all')} (+${remainingCount})`}
				</Typography>
			)}
		</List>
	);
};

export default TokenTransactions;
