import React, { useEffect, useState } from 'react'
import { useGetVerifiablePresentationQuery, useGetVerifiablePresentationTransactionHistoryMutation, useLazyRetrieveDocumentQuery } from '../../service/api/meveoApi';
import { useSearchParams } from 'react-router-dom';
import TokenShareLayout from './TokenShareLayout';
import TokenShareHeader from './TokenShareHeader';
import { TokenDetail, TokenDetailLoading } from '../../components/Token';
import LoadingModal from '../../components/LoadingModal';
import { t } from 'i18next';

export const isImage = (filename: string) => {
  return ['png', 'jpg', 'jpeg', 'gif'].includes(filename?.split('.').reverse()[0].toLowerCase())
}

const TokenVerifiablePresentation = () => {
  const [searchParams] = useSearchParams();
  const vpUuid = searchParams.get('id');

  const [key, setKey] = useState(0);
  const [token, setToken] = useState<any>(null);
  const [fetchDocument] = useLazyRetrieveDocumentQuery();

  const { data, isFetching, isLoading } = useGetVerifiablePresentationQuery({ vpUuid });

  const [transactions, setTransactions] = useState<any[]>([]);
  const [getTransactionHistory] = useGetVerifiablePresentationTransactionHistoryMutation();

  useEffect(() => {
    if (data?.verifiableCredential && data?.selectiveObjectData) {
      const {
        verifiableCredential: [{
          credentialSubject: {
            digitalTwin
          }
        }],
        selectiveObjectData
      } = data;

      getTransactionHistory({ data }).unwrap()
        .then((res) => {
          if (res.status === 'success') {
            setTransactions(res.result);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      const tokenId = digitalTwin.id.split(':').reverse()[0];
      const coverImage = digitalTwin.coverImage.split('/').pop();

      const details: any = {
        token: {
          uuid: tokenId,
        },
        documents: [{
          uuid: coverImage.split('.')[0],
          fileUrl: digitalTwin.coverImage,
          path: 'coverImage',
          isVP: true,
        }]
      };

      Object.keys(selectiveObjectData).forEach((key) => {
        if (key === 'documents') {
          const documents = selectiveObjectData[key];

          Object.keys(documents).forEach((uuid: any) => {
            const document = documents[uuid];
            if (!document.path) return;

            details.documents.push({
              ...document,
              uuid,
            });
          });
        } else {
          const field = selectiveObjectData[key];
          if (key === 'creationDate') {
            const date = new Date();
            date.setTime(field.value || 0);
            details.token[key] = !field.value ? '' : date.toISOString();
          } else {
            details.token[key] = field.value || '';
          }
        }
      });

      setToken(details);
    }
  }, [data]);

  useEffect(() => {
    if (transactions.length > 0 && token?.token && !token?.transactions) {
      setToken({ ...token, transactions })
    }
  }, [token, transactions]);

  return (
    <TokenShareLayout>
      <TokenShareHeader />
      <div className='tokenshare-body'>
        {!!isFetching || !token ? (
          <>
            <TokenDetailLoading />
            <LoadingModal
              visible={true}
              // message={t('common:loading')}
              onClose={() => null}
            />
          </>
        ) : (
          <TokenDetail
            key={`${token.token.uuid}${key}`}
            token={token}
            isFetching={isFetching}
            isLoading={isLoading}
            mode={'verifiablePresentation'}
          />
        )}
      </div>
    </TokenShareLayout>
  )
}

export default TokenVerifiablePresentation
