import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import "./MarkProofVerification.scss";

import ImageCompare from "./ImageCompare";
import { createRef, useEffect, useMemo, useState } from "react";
import {
	MARKPROOF_VERIFICATION_REJECT_REASONS,
	MARKPROOF_VERIFICATION_STATUS,
	documentPathTypes,
} from "../../configs/constant";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
	meoveoRestApi,
	useGetMarkproofsVerificationsQuery,
	useGetTokenByIdQuery,
	useUpdateMarkproofVerificationMutation,
} from "../../service/api/meveoApi";
import { t } from "i18next";
import ImageProxy, { proxySrcReplace } from "../Gallery/ImageProxy";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Singularities from "./Singularities/Singularities";
import { ImageCompareProvider, useImageCompare, useImageCompareDispatch } from "./ImageCompare/ImageCompareContext";
import { MarkproofVerificationProvider, useMarkproofVerificationContext, useMarkproofVerificationDispatch } from "./MarkProofVerificationContext";
import MarkProofVerificationAutoAlign from "./MarkProofVerificationAutoAlign";
import { dataURIToBlob, urlToDataUrl } from "../../utils/helpers";
import config from "../../configs/config";
import LoadingModal from "../LoadingModal";

const sampleData = require("./sample-api-response.json");

const ConfirmDialog = (props) => {
	const { data, verificationImages, selected, open, onClose } = props;
	const [reason, setReason] = useState("");
	const reasons = t("pages:mark_proof.verification.list_reason", { returnObjects: true });

	const closeHandle = () => {
		onClose();
	};

	const onReject = () => {
		if (typeof open.callback === "function") {
			open.callback(reason);
		}
		onClose();
	};
	const onConfirm = () => {
		if (typeof open.callback === "function") {
			open.callback();
		}
		onClose();
	};
	useEffect(() => {
		return () => setReason("");
	}, []);


	if (!data || !verificationImages || verificationImages.length <= 0) return null;
	return (
		<Dialog className="dialog" open={!!open} onClose={closeHandle}>
			<DialogTitle>{t("pages:mark_proof.verification.confirmation")}</DialogTitle>
			<DialogContent>
				<Typography sx={{ mb: 2 }}>Token ID: {data?.tokenId}</Typography>
				<Box sx={{ mb: 2 }} className="flex flex--justify-around">
					<Box className="flex flex--vertical flex--align-center">
						<ImageProxy url={verificationImages[selected].referenceImage} width="70" height="70" />
						<Typography component="span" variant="caption">
							Origin
						</Typography>
					</Box>
					<Box className="flex flex--vertical flex--align-center">
						<ImageProxy url={verificationImages[selected].verifiedImage} width="70" height="70" />
						<Typography component="span" variant="caption">
							Verified
						</Typography>
					</Box>
				</Box>
				{open.type === "approve" && <Typography>Manual verification is OK</Typography>}
				{open.type === "reject" && (
					<>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">
								{t("pages:mark_proof.verification.reason")}
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={reason}
								label={t("pages:mark_proof.verification.reason")}
								onChange={(event) => setReason(event.target.value)}
							>
								<MenuItem value=""></MenuItem>
								{MARKPROOF_VERIFICATION_REJECT_REASONS.map((item, idx) => (
									<MenuItem key={`reject-verification-reason-${item}`} value={item}>
										{t(`${reasons[item] || item}`)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button size="small" variant="outlined" onClick={onClose}>
					{t("common:cancel")}
				</Button>
				<Button
					disabled={open.type === "reject" && !reason}
					color="secondary"
					size="small"
					variant="contained"
					onClick={() => (open.type === "reject" ? onReject() : onConfirm())}
				>
					{t("common:ok")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const FinishDialog = ({ data }) => {
	const navigate = useNavigate();
	const fails = data.filter((item) => item.status === MARKPROOF_VERIFICATION_STATUS.MANUAL_KO);
	const reasons = t("pages:mark_proof.verification.list_reason", { returnObjects: true });

	return (
		<Dialog className="dialog" open={true} onClose={() => false}>
			<DialogTitle>{t("pages:mark_proof.verification.page_title")}</DialogTitle>
			<DialogContent>
				{fails.length > 0
					? t("pages:mark_proof.verification.fail_message", { reason: reasons[fails[0].reason] })
					: t("pages:mark_proof.verification.done_message")}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => navigate("/mark-proof/verification", { replace: true })}
					variant="contained"
					color="primary"
					size="small"
				>
					{t("pages:mark_proof.verification.go_token_list_button")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const MarkProofVerificationBody = () => {
	const params = useParams();
	const [searchParams] = useSearchParams();
	const viewer = useMarkproofVerificationContext();
	const dispatch = useMarkproofVerificationDispatch();

	const [open, toggleConfirmDialog] = useState(false);
	const [update, updateAction] = useUpdateMarkproofVerificationMutation();
	const [verifications, updateVerifications] = useState([]);
	const [autoAlign, setAutoAlign] = useState(false);
	const [isDone, markDone] = useState(false);
	const selectedVerification = params?.uuid || 0;
	const isFullscreen = !!viewer?.fullscreen;
	const analysisRef = createRef();
	const [analyzedData, setAnalyzedData] = useState({});
	const [selected, updatePOI] = useState(0);
	const [verIndex, setVerIndex] = useState(selected);
	const [localImages, setLocalImages] = useState({});
	const [analysisTrigger, setAnalysisTrigger] = useState(0);
	const [processing, setIsProcessing] = useState(false);
	const [mseScores, setMSEScores] = useState({});
	const [mseDebugData, setMSEDebugData] = useState({});

	const debug = searchParams.get('debug') === "true";
	const { data, isFetching, error } = useGetMarkproofsVerificationsQuery({
		tokenId: params.token,
		markproofPrefix: params.markproofPrefix,
	});

	const { data: token } = useGetTokenByIdQuery(
		{ id: params?.token },
		{
			skip: !params.token,
		}
	);

	const onApprove = () => {
		toggleConfirmDialog({
			type: "approve",
			callback: async () => {
				// Update current status
				const newData = JSON.parse(JSON.stringify(verifications));
				newData[selected].status = MARKPROOF_VERIFICATION_STATUS.MANUAL_OK;

				let response = await update({
					tokenID: params.token,
					body: {
						tokenId: params.token,
						markproofPrefix: params.markproofPrefix,
						verificationImages: newData,
					},
				}).unwrap();

				if (response.status === "fail") {
					toast.error(response.result);
					return;
				}
				moveNext(newData);
			},
		});
	};

	const onReject = () => {
		toggleConfirmDialog({
			type: "reject",
			callback: async (reason) => {
				const newData = JSON.parse(JSON.stringify(verifications));

				newData.map((item) => {
					item.status = MARKPROOF_VERIFICATION_STATUS.MANUAL_KO;
					item.reason = reason;
					return item;
				});

				let response = await update({
					tokenID: params.token,
					body: {
						tokenId: params.token,
						markproofPrefix: params.markproofPrefix,
						verificationImages: newData,
					},
				}).unwrap();

				if (response.status === "fail") {
					toast.error(response.result);
					return;
				}

				finishVerification(newData);
			},
		});
	};

	const moveNext = (newData) => {
		updateVerifications(newData);
		if (newData.length > selected + 1) {
			updatePOI(selected + 1);
		}
	};

	const finishVerification = (newData) => {
		updateVerifications(newData);
		updatePOI(newData.length - 1);
		markDone(true);
	};

	const toggleFullsreenHandle = () => {
		dispatch({
			type: 'toggle_fullscreen',
			data: {
				fullscreen: !isFullscreen
			}
		})
		setTimeout(() => window.dispatchEvent(new Event("resize")), 300);
	}

	const handleFileUpload = (e) => {
		const index = e.target.getAttribute("data-idx");
		if (e.target.files && e.target.files[0]) {
			const selectedFile = e.target.files[0];

			var reader = new FileReader();
			reader.onload = function (event) {
				let files = { ...localImages };
				files[index] = URL.createObjectURL(dataURIToBlob(reader.result));
				setLocalImages(files)

				const idx = parseInt(index.split("_")[1]);
				if (index.includes('ref_')) {
					updatePOI(idx)
				}
				setVerIndex(idx)
				if (index.includes('ver_')) {
					setAnalysisTrigger(Date.now())
				}
			};
			reader.readAsDataURL(selectedFile);
		}
	}

	const blobUrlToBase64 = async (url) => {
		const dataUrl = await new Promise(resolve => urlToDataUrl(url, resolve))
		return dataUrl.split(',')[1];
	}

	const computeMSEDistances = async (ref, ver) => {
		setIsProcessing(true)
		const refImage = `${ref}`.startsWith('blob') ? await blobUrlToBase64(ref) : ref;
		const verImage = `${ver}`.startsWith('blob') ? await blobUrlToBase64(ver) : ver;

		const data = {
			debug,
			refImages: [
				refImage,
				verImage,
			],
		}

		try {
			const response = await fetch(`${config.IMAGE_ANALYSIS_SERVER_ADDRESS}/average-mse`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			});

			const result = await response.json();

			if (debug && result) {
				const { croppedRef, croppedComp, normRef, normComp } = result;

				setMSEDebugData(pre => ({
					...pre,
					[`${ref}${ver}`]: {
						croppedRef: URL.createObjectURL(dataURIToBlob(`data:image/jpeg;base64,${croppedRef}`)),
						croppedVer: URL.createObjectURL(dataURIToBlob(`data:image/jpeg;base64,${croppedComp}`)),
						normRef: URL.createObjectURL(dataURIToBlob(`data:image/jpeg;base64,${normRef}`)),
						normVer: URL.createObjectURL(dataURIToBlob(`data:image/jpeg;base64,${normComp}`)),
					}
				}));
			}

			setMSEScores(pre => ({
				...pre,
				[`${ref}${ver}`]: result?.result
			}));
		} catch (error) {
			console.error(error);
		} finally {
			setIsProcessing(false);
		}
	}

	useEffect(() => {
		if (analysisTrigger > 0) {
			const refImage = localImages[`ref_${selected}`] ? localImages[`ref_${selected}`] : verifications[selected].referenceImage;
			const verImage = localImages[`ver_${verIndex}`] ? localImages[`ver_${verIndex}`] : verifications[verIndex].verifiedImage;

			if (!mseScores[`${refImage}${verImage}`]) {
				computeMSEDistances(refImage, verImage)
			}
		}
	}, [selected, verIndex, analysisTrigger]);

	useEffect(() => {
		if (!!data && !!data.result && data.result.length && data.result[0].verificationImages) {
			let verificationImages = data.result[0]?.verificationImages;
			if (selectedVerification) {
				let filter = data.result.filter((item) => item.uuid === selectedVerification);
				if (filter.length > 0) verificationImages = filter[0].verificationImages;
			}
			updateVerifications(verificationImages);
			for (let idx in verificationImages) {
				let item = verificationImages[idx];
				if (
					item.status !== MARKPROOF_VERIFICATION_STATUS.MANUAL_OK &&
					item.status !== MARKPROOF_VERIFICATION_STATUS.MANUAL_KO
				) {
					updatePOI(parseInt(idx));
					break;
				}
			}
			markDone(
				!verificationImages ||
				verificationImages.length <= 0 ||
				verificationImages.filter(
					(item) =>
						item.status !== MARKPROOF_VERIFICATION_STATUS.MANUAL_OK &&
						item.status !== MARKPROOF_VERIFICATION_STATUS.MANUAL_KO
				).length <= 0
			);
		}
	}, [data, selectedVerification]);

	// Get singularities data from token
	const singularities = useMemo(() => {
		if (token && token.status === "success" && token.result && token.result.documents) {
			let markproofs = token.result.documents.filter(
				(item) => item.path === documentPathTypes.MARK_PROOFS && item.name.startsWith(params?.markproofPrefix)
			);

			if (markproofs.length > 0) {
				for (let i in markproofs) {
					let item = markproofs[i]
					if (item?.metadata?.singularities) {
						return item.metadata.singularities;
					}
				}
			}
		}
		return null;
	}, [token])

	useEffect(() => {
		if (!autoAlign) {
			analysisRef.current = null
		}
	}, [autoAlign, analysisRef])

	useEffect(() => {
		setAutoAlign(false)
	}, [selected])

	const verificationImage = useMemo(() => {
		return verifications && selected >= 0 && selected < verifications.length ? verifications[selected] : null
	}, [selected, verifications])

	const homography = useMemo(() => {
		return verificationImage?.alignment
			? JSON.parse(verificationImage.alignment.includes('homography=')
				? verificationImage.alignment.replace(/(\w+)=/g, '"$1":')
				: verificationImage.alignment).homography
			: null
	}, [verificationImage])

	const alignments = useMemo(() => {
		return Array.isArray(verifications) ?
			verifications.map((item) => (
				item?.alignment
					? JSON.parse(item.alignment.includes('homography=')
						? item.alignment.replace(/(\w+)=/g, '"$1":')
						: item.alignment)
					: null
			))
			: []
	}, [verifications])

	const renderMSEScore = () => {
		if (analysisTrigger > 0) {
			const refImage = localImages[`ref_${selected}`] ? localImages[`ref_${selected}`] : verifications[selected].referenceImage;
			const verImage = localImages[`ver_${verIndex}`] ? localImages[`ver_${verIndex}`] : verifications[verIndex].verifiedImage;

			if (!!mseScores[`${refImage}${verImage}`]) {
				return (
					<Box className="mse-data">
						<Typography variant="h6">Ref-Ver MSE</Typography>
						<Typography variant="body2">
							MSE: {mseScores[`${refImage}${verImage}`]}
						</Typography>
						{!!mseDebugData[`${refImage}${verImage}`] && (
							<>
								<Typography variant="h6">Cropped aligned Ref-Ver</Typography>
								<div className="cropped-image">
									<ImageCompareProvider>
										<ImageCompare data={{
											referenceImage: mseDebugData[`${refImage}${verImage}`].croppedRef,
											verifiedImage: mseDebugData[`${refImage}${verImage}`].croppedVer,
										}} />
									</ImageCompareProvider>
								</div>
								<Typography variant="h6">Normalized cropped Ref-Ver</Typography>
								<div className="norm-image">
									<ImageCompareProvider>
										<ImageCompare data={{
											referenceImage: mseDebugData[`${refImage}${verImage}`].normRef,
											verifiedImage: mseDebugData[`${refImage}${verImage}`].normVer,
										}} />
									</ImageCompareProvider>
								</div>
							</>
						)}
					</Box>
				)
			}
		}
	}

	return (
		<>
			<Box
				className={`markproof__detail prevent-select ${isFullscreen ? "fullscreen" : ""} ${
					updateAction.isLoading ? "loading" : ""
				}`}
			>
				<Box className="markproof__verificator flex flex--horizontal">
					<Box className="markproof__pois flex flex--vertical">
						{!!verifications && verifications.length > 0 &&
							verifications.map((item, idx) => {
								const isDisabled =
									item.status === MARKPROOF_VERIFICATION_STATUS.MANUAL_OK ||
									item.status === MARKPROOF_VERIFICATION_STATUS.MANUAL_KO;
								const localImage = localImages[`ref_${idx}`];

								return (
									<Button
										disabled={isDisabled}
										key={`poi-switch-${idx}`}
										variant="outlined"
										className={`poi poin--status-${item.status.toLowerCase()} ${selected === idx && !isDone ? "selected" : ""
											}`}
										onClick={() => {
											updatePOI(idx)
											setVerIndex(idx)
										}}
										color="secondary"
										title={`${isDisabled ? item.status : ""}`}
										onContextMenu={(e) => {
											e.preventDefault();
											var el = document.getElementById("poi-file-upload");
											el.setAttribute("data-idx", `ref_${idx}`);
											el.click();
										}}
									>
										<img
											src={localImage || proxySrcReplace(
												`${item.referenceImage || "https://placehold.it/70x70"}`,
												"70",
												"70"
											)}
											alt={item.markProofId}
										/>
									</Button>
								);
							})}
					</Box>
					{!!verifications[selected] && (
						<Box className="image-compare__tool">
							<ImageCompareProvider>
								<Box className="image-compare__wrapper">
									<Box className="labels flex flex--horizontal flex--justify-center">
										<Typography component="div" variant="h6">
											{t("pages:mark_proof.verification.reference")}
										</Typography>
										<Typography component="div" variant="h6">
											{t("pages:mark_proof.verification.collector")}
										</Typography>

										<IconButton
											title="Edit in fullscreen mode"
											onClick={toggleFullsreenHandle}
											className="fullscreen-switcher"
										>
											<FontAwesomeIcon
												icon={isFullscreen ? solid("minimize") : solid("maximize")}
											/>
										</IconButton>
									</Box>

									<ImageCompare data={{
										referenceImage: localImages[`ref_${selected}`] ? localImages[`ref_${selected}`] : verifications[selected].referenceImage,
										verifiedImage: localImages[`ver_${verIndex}`] ? localImages[`ver_${verIndex}`] : verifications[verIndex].verifiedImage,
									}} />
									<Typography
										className="tool-guide"
										sx={{ color: "#fff", fontStyle: "italic" }}
										variant="caption"
									>
										{t("pages:mark_proof.verification.tool_guide")}
									</Typography>
								</Box>
								{selected === verifications.length - 1 && !!singularities && (
									<Singularities
										singularities={singularities}
										sourceImage={verifications[selected].referenceImage}
									/>
								)}
							</ImageCompareProvider>

							{autoAlign && (
								<Box sx={{
									position: 'absolute',
									top: 0,
									left: 0,
									width: '100%',
									minHeight: '100vh',
									zIndex: 999,
									backgroundColor: '#fff'
								}}>
									<MarkProofVerificationAutoAlign
										ref={analysisRef}
										open={autoAlign}
										images={{
											referenceImage: localImages[`ref_${selected}`] ? localImages[`ref_${selected}`] : verifications[selected].referenceImage,
											verifiedImage: localImages[`ver_${verIndex}`] ? localImages[`ver_${verIndex}`] : verifications[verIndex].verifiedImage,
										}}
										singularities={selected === verifications.length - 1 ? singularities : null}
										onClose={() => setAutoAlign(false)}
										homography={selected === verIndex && Array.isArray(homography)
											? homography
											: analyzedData[selected]}
										onAligned={(homography) => setAnalyzedData(prev => ({ ...prev, [selected]: homography }))}
									/>
									<IconButton
										title="Exit Auto Alignment View"
										onClick={() => setAutoAlign(false)}
										className="auto-align-switcher"
									>
										<FontAwesomeIcon
											icon={solid("xmark")}
										/>
									</IconButton>
								</Box>
							)}
							<Box className="image-compare__actions flex flex--horizontal">
								<Box>
									<Button
										size={`${isFullscreen ? "large" : "small"}`}
										onClick={() => {
											if (debug) setAnalysisTrigger(Date.now())
											analysisRef.current ? analysisRef.current?.analyzeImages() : setAutoAlign(true)
										}}
										color="info"
										variant={`${isFullscreen ? "contained" : "outlined"}`}
									>
										{t("pages:mark_proof.verification.auto_align")}
									</Button>
								</Box>
								<Box>
									<Button
										size={`${isFullscreen ? "large" : "small"}`}
										onClick={() => onApprove()}
										color="success"
										variant={`${isFullscreen ? "contained" : "outlined"}`}
									>
										{t("pages:mark_proof.verification.approve")}
									</Button>
								</Box>
								<Box>
									<Button
										size={`${isFullscreen ? "large" : "small"}`}
										onClick={() => onReject()}
										color="secondary"
										variant={`${isFullscreen ? "contained" : "outlined"}`}
									>
										{t("pages:mark_proof.verification.reject")}
									</Button>
								</Box>
							</Box>
						</Box>
					)}

					<Box className="markproof__pois markproof__pois--verified flex flex--vertical">
						{!!verifications &&
							verifications.map((item, idx) => {
								const localImage = localImages[`ver_${idx}`];

								return (
									<Button
										disabled={
											item.status === MARKPROOF_VERIFICATION_STATUS.MANUAL_OK ||
											item.status === MARKPROOF_VERIFICATION_STATUS.MANUAL_KO
										}
										key={`poi-switch-${idx}`}
										variant="outlined"
										className={`poi poin--status-${item.status.toLowerCase()} ${verIndex === idx ? "selected" : ""}`}
										onClick={() => setVerIndex(idx)}
										sx={{ opacity: `${item.similarity}%` }}
										color="secondary"
										onContextMenu={(e) => {
											e.preventDefault();
											var el = document.getElementById("poi-file-upload");
											el.setAttribute("data-idx", `ver_${idx}`);
											el.click();
										}}
									>
										<img
											src={localImage || proxySrcReplace(
												`${item.verifiedImage || "https://placehold.it/70x70"}`,
												"70",
												"70"
											)}
											alt={item.markProofId}
										/>
										{item.similarity && <span className="similarity">{item.similarity}%</span>}
									</Button>
								)
							})}
					</Box>
				</Box>
				{alignments[selected] && alignments[selected].mseData && (
					<Box className="mse-data">
						<Typography variant="h6">MSE Data</Typography>
						<Typography variant="body2">
							Ref Average MSE: {alignments[selected].mseData.refMSEAverage}
						</Typography>
						<Typography variant="body2">
							Ver MSE Distances
						</Typography>
						{alignments[selected].mseData.verMSEDistances?.map((item, idx) => (
							<Typography key={`ver-mse-distance-${idx}`} variant="body2">
								{item}
							</Typography>
						))}
					</Box>
				)}
				{renderMSEScore()}
				<input
					id="poi-file-upload"
					onChange={handleFileUpload}
					type="file"
					accept="image/png, image/jpeg"
					multiple={false}
				/>
			</Box>
			{processing && (
				<LoadingModal
					visible={true}
					onClose={() => null}
				/>
			)}
			<ConfirmDialog
				data={!!data && !!data.result && data.result[0]}
				verificationImages={verifications}
				open={open}
				onClose={() => toggleConfirmDialog(false)}
				selected={selected}
			/>
			{isDone && <FinishDialog data={verifications}></FinishDialog>}
		</>
	);
};

const MarkProofVerification = () => {
	return (
		<MarkproofVerificationProvider>
			<MarkProofVerificationBody />
		</MarkproofVerificationProvider>
	)
}

export default MarkProofVerification;
