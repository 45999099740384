import "./utils/global";

import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { connect, Provider as Redux } from "react-redux";
import store, { persistor } from "./store/rootReducer";
import { PersistGate } from "redux-persist/integration/react";

import "./assets/scss/main.scss";
import { routes } from "./configs/routes";
import Language from "./components/Language";
import { RequirePassword } from "./components/Authentication";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useEffect } from "react";
const router = createBrowserRouter(routes);

const theme = createTheme({
	typography: {
		fontFamily: ["Rubik", "sans-serif"].join(","),
	},
	palette: {
		primary: {
			main: "#041c25",
			contrastText: "#fff",
		},
		secondary: {
			main: "#EA5123",
			contrastText: "#fff",
		},
		white: {
			main: "#ffffff",
			contrastText: "#041c25",
		},
		gray: {
			main: "#EFEBE6",
			contrastText: "#041c25",
		},
	},
	shape: {
		borderRadius: 0,
	},
	components: {
		MuiCheckbox: {
			defaultProps: {
				icon: <span className="icon icon--square"></span>,
				checkedIcon: <span className="icon icon--square  icon--square-check"><FontAwesomeIcon icon={solid('check')} /></span>
			}

		}
	}
});

const App = (props) => {
	// Google Tags
	useEffect(() => {
		const script = document.createElement('script');
		script.text = `
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','GTM-ND4TWKV7');
		`;
		document.head.appendChild(script);
	}, []);

	useEffect(() => {
		const script = document.createElement('noscript');
		script.text = `
		<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-ND4TWKV7"
		height="0" width="0" style="display:none;visibility:hidden"></iframe>
		`;
		document.body.insertBefore(script, document.body.firstChild);
	}, []);
	// End Google Tags

	return (
		<Redux store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ThemeProvider theme={theme}>
					<Language>
						<CssBaseline />
						<RouterProvider router={router} />
						<RequirePassword />
					</Language>
				</ThemeProvider>
			</PersistGate>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
		</Redux>
	);
};

export default App;
