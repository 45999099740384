import { Box, Skeleton, Typography } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import { t } from "i18next";

const TokenDetailSkeleton = props => {

  return (
    <div className="token__information">
      <div className="cover-image">
        <Skeleton sx={{ transform: 'none' }} width="100%" height="100%" />
      </div>
      <div className="container flex flex--vertical">
        <Box className="description" >
          <Skeleton width={'25ch'} height={24} />
          <Skeleton width="100%" height={50} />
        </Box>

        <Box className="gallery">
          <Typography className="title" gutterBottom variant="h5" component="div">
            <FontAwesomeIcon icon={solid('image')} />{t("pages:token.gallery")}</Typography>

            <Skeleton sx={{ transform: 'none' }} width="100%" height={250} />
        </Box> 
        
        <Box className="specifications"> 
          <Typography className="title" gutterBottom variant="h5" component="div">
            <FontAwesomeIcon icon={solid('file-invoice-dollar')} /> {t("pages:token.specifications")} 
          </Typography>
          
          <Typography component="div" variant="body2" color="text.secondary" className="flex flex--nowrap">
            <Typography component="span" variant="subtitle2">{t("pages:token.token_origin")}: </Typography>
            <Skeleton sx={{ ml: 2 }} width={'15ch'} height={24} />
          </Typography>
          <Typography component="div" variant="body2" color="text.secondary" className="flex flex--nowrap">
            <Typography component="span" variant="subtitle2">{t("pages:token.creation_date")} : </Typography>
            <Skeleton sx={{ ml: 2 }} width={'15ch'} height={24} />
          </Typography>
          <Typography component="div" variant="body2" color="text.secondary" className="flex flex--nowrap">
            <Typography component="span" variant="subtitle2">{t("pages:token.price")} : </Typography>
            <Skeleton sx={{ ml: 2 }} width={'15ch'} height={24} />
          </Typography>
          
        </Box>

        <Box className="gallery">
          <Typography className="title" gutterBottom variant="h5" component="div">
            <FontAwesomeIcon icon={solid('folder-open')} />{t("pages:token.documents")} </Typography>
            <Skeleton sx={{ mt: 1 }} width={'100%'} height={50} />
            <Skeleton sx={{ mt: 1 }} width={'100%'} height={50} />
            <Skeleton sx={{ mt: 1 }} width={'100%'} height={50} />
            <Skeleton sx={{ mt: 1 }} width={'100%'} height={50} />
            <Skeleton sx={{ mt: 1 }} width={'100%'} height={50} />
            <Skeleton sx={{ mt: 1 }} width={'100%'} height={50} />
        </Box>
      </div>
    </div>
  )
}

export default TokenDetailSkeleton;