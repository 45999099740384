import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { t } from "i18next";
import { connect } from "react-redux";
import { useAcceptTransferTokenMutation, useRefuseTransferTokenMutation } from "../../../service/api/meveoApi";
import { MessageBox } from "../../Animation";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { TRANSACTION_TYPES, currencies } from "../../../configs/constant";
import OperatorActionConfirmation from "../../Operator";

import { 
	newTokenStatus as NEW_STATUSES,
	tokenStatus as STATUSES
} from "../../../configs/constant";
import { useAcceptTransferWithSmartConstract, useRefuseTransferWithSmartContract, useReveiveTokenWithSmartContract } from "../../../hooks/smartContractHooks";
import LoadingModal from "../../LoadingModal";
import { standPost } from "../../../pages/Standalone/Standalone";

const ReceiveToken = (props) => {
	const { token, currentUserEmail } = props;
	const navigate = useNavigate();
	const [confirmation, toggleConfirmDialog] = useState(false);
	const [confirmationAccept, toggleConfirmAceptDialog] = useState(false);


	const tokenType =
		token?.token.status === STATUSES.IN_TRANSFER && token?.client?.email === currentUserEmail 
			? NEW_STATUSES.NEW
			: token?.token.status === STATUSES.IN_TRANSFER && token?.client?.email !== currentUserEmail 
			? NEW_STATUSES.TRANSFERRING
			: NEW_STATUSES.OWNED;
	const isNew = tokenType === NEW_STATUSES.NEW;

	const [acceptTransferedToken, { status: acceptStatus, isLoading : acceptLoading, error: acceptError }, resetAcceptProcess] = useAcceptTransferWithSmartConstract({ token });
	
	const [refuseTransferedToken, { isLoading: refuseLoading, status: refuseStatus }, refuseReset] = useRefuseTransferWithSmartContract({ token })

	const refuseHandle = () => {
		refuseTransferedToken({
			tokenID: token?.token?.uuid
		});
	}

	const acceptTransferHandle = () => {
		acceptTransferedToken({
			tokenID: token?.token?.uuid
		})	
	}

	useEffect(() => {
		if ('success' === acceptStatus && !acceptLoading) {
			document.getElementById('gtm-transfer-accepted-button').click();
			standPost({ action: 'success' });
		}
	}, [acceptStatus, acceptLoading])
	// Listen on meveo refuse request, if it is successful then redirect user to wallet's tokens
	useEffect(() => {
		if ('success' === refuseStatus && !refuseLoading) {
			document.getElementById('gtm-transfer-refused-button').click();
			standPost({ action: 'cancel' });
			navigate('/wallet', { replace: true });
		}
	}, [refuseLoading, refuseStatus, navigate, refuseReset])

	if ( !isNew ) return null;
	return (
		<>
			<Dialog open={true} aria-labelledby="token-transfer-confirmation">
				<DialogTitle id="token-transfer-confirmation">{t("pages:token.transfer_confirmation")}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t("pages:token.transferring_message_by", { email: token.sender.email })}
					</DialogContentText>
				</DialogContent>
				<DialogActions
					className="flex flex--vertical align--center"
					sx={{ ml: "auto", mr: "auto", width: "70%", mb: 2 }}
				>
					<LoadingButton
						id="accept-transfer-button"
						onClick={() => {
							toggleConfirmAceptDialog({
								callback: () => acceptTransferHandle()
							})
						}}
						className="w--100"
						sx={{ mb: 1 }}
						variant="contained"
						color="primary"
						loading={!!acceptLoading}
						disabled={!!acceptLoading || !!refuseLoading}
					>
						{t("pages:token.accept_token")}
					</LoadingButton>
					<LoadingButton
						id="refuse-transfer-button"
						onClick={refuseHandle}
						className="w--100"
						sx={{ ml: "0!important" }}
						variant="contained"
						color="secondary"
						loading={!!refuseLoading}
						disabled={!!acceptLoading || !!refuseLoading}
					>
						{t("pages:token.refuse_token")}
					</LoadingButton>
				</DialogActions>
			</Dialog>
			{(!!acceptLoading || !!refuseLoading) &&
				(<LoadingModal
					visible={true}
					// message={refuseLoading ? t("pages:token.refusing_token") : t("pages:token.accepting_token")}
					onClose={() => null}
				/>)
			}

			<OperatorActionConfirmation 
				offerCode={token?.billingOfferCode}
				defaultPartnerCode={token?.billingOfferCode ? token?.uuid.split('_')[0] : ''}
				action={TRANSACTION_TYPES.TRANSFER_TOKEN_REQUEST.key}
				open={confirmation} 
				toggle={toggleConfirmDialog}
				onClose={() => {
					toggleConfirmDialog(false)
				}}
			/>

			<OperatorActionConfirmation 
				offerCode={token?.billingOfferCode}
				defaultPartnerCode={token?.billingOfferCode ? token?.uuid.split('_')[0] : ''}
				action={TRANSACTION_TYPES.ACCEPT_TOKEN.key}
				open={confirmationAccept} 
				toggle={toggleConfirmAceptDialog}
				onClose={() => {
					toggleConfirmAceptDialog(false)
				}}
			/>
			
			<button id="gtm-transfer-accepted-button" type="button" style={{ display: 'none' }}></button>
			<button id="gtm-transfer-refused-button" type="button" style={{ display: 'none' }}></button>
		</>
	);
};

export default connect(
	(state) => {
		const wallet = state?.wallet
		return {
			currentUserEmail: wallet?.private?.email?.address,
			operatorPricingPlan: wallet?.operatorPricingPlan
		};
	}
)(ReceiveToken);
