import React from "react";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import { usePrivacy, usePrivacyDispatch } from "../ShareTokenPrivacy/TokenPrivacyContext";

const ShareTokenPropertyRow = (props: any) => {
	const { name, permId, immutable, padding, childs, className } = props
	const privacySettings: any = usePrivacy();
	const dispatch = usePrivacyDispatch();
	const groupModifier = '';//childs?.length > 0 ? 'group' : '';

	const selected = childs?.length > 0
		? childs.filter((key: any) => !!privacySettings.sharedOptions[key]).length === childs.length
		: privacySettings.sharedOptions[permId];

	const onChange = (selected: boolean) => {
		dispatch({
			type: 'update_share_option',
			data: {
				key: permId,
				selected
			}
		})
		if (childs?.length > 0) {
			childs.forEach((key: any) => {
				dispatch({
					type: 'update_share_option',
					data: {
						key,
						selected
					}
				})
			})
		}
	};

	return (
		<TableRow className={`table__row table__row-item ${groupModifier} ${className || ''}`} >
			<TableCell sx={{ pl: 3 + 3 * (padding || 0) }} >{name}</TableCell>
			<TableCell align="center" key={`property-${permId}`} padding="checkbox">
				{!immutable &&
					<Checkbox
						size="small"
						color="secondary"
						checked={!!selected}
						onChange={(evt, checked) => onChange(checked)}
					/>
				}
			</TableCell>
		</TableRow>
	)
}

export default ShareTokenPropertyRow;