import cv from "@techstark/opencv-js";
import { Tensor } from "onnxruntime-web";
import { dataURIToBlob } from "../../../utils/helpers";

let imageG, image2G, homography;
let reference, verification;
let warpedVer;

export const detectImage = async (
  image,
  canvas,
  image2,
  canvas2,
  session,
  inputShape
) => {
  imageG = image;
  image2G = image2;
  reference = cv.imread(imageG);
  verification = cv.imread(image2G);
  const [modelWidth, modelHeight] = inputShape.slice(2);

  const [input, xRatio, yRatio] = preprocessing(image, modelWidth, modelHeight);
  const [input2, xRatio2, yRatio2] = preprocessing(image2, modelWidth, modelHeight);


  const tensor = new Tensor("float32", input.data32F, inputShape); // to ort.Tensor
  const tensor2 = new Tensor("float32", input2.data32F, inputShape); // to ort.Tensor


  const start = new Date().getTime();

  const output0 = await session.net.run({ image0: tensor, image1: tensor2 }); // run session and get output layer

  const end1 = new Date().getTime();
  const timeElapsed = end1 - start; // Time taken in milliseconds

  console.log(`Time taken: ${timeElapsed}ms`);
  console.log("Inference Ran successfully");


  let kpts0 = output0.kpts0;
  let kpts1 = output0.kpts1;
  let matches0 = output0.matches0;


  let [m_kpts0, m_kpts1] = postProcess(kpts0, kpts1, matches0);


  let m_kpts0S = m_kpts0.map(pair => [pair[0] * xRatio, pair[1] * yRatio]);
  let m_kpts1S = m_kpts1.map(pair => [pair[0] * xRatio2, pair[1] * yRatio2]);

  drawKeypoints(canvas, m_kpts0, image, inputShape);
  drawKeypoints(canvas2, m_kpts1, image2, inputShape);
  drawConnections(canvas, m_kpts0, canvas2, m_kpts1, 0, image, image2, inputShape);

  let srcPoints = new cv.Mat(m_kpts1S.length, 1, cv.CV_32FC2);
  let destPoints = new cv.Mat(m_kpts0S.length, 1, cv.CV_32FC2);

  for (let i = 0; i < m_kpts1S.length; i++) {
    srcPoints.data32F[i * 2] = m_kpts1S[i][0];
    srcPoints.data32F[i * 2 + 1] = m_kpts1S[i][1];
    destPoints.data32F[i * 2] = m_kpts0S[i][0];
    destPoints.data32F[i * 2 + 1] = m_kpts0S[i][1];
  }

  homography = cv.findHomography(srcPoints, destPoints);

  if (homography.empty())
  {
      alert("homography matrix empty!");
      return;
  }
  warpedVer = new cv.Mat();
  cv.warpPerspective(verification, warpedVer, homography, verification.size());

  handleSliderChange();
  
  // Display the modal
  showModal();
  return [reference, warpedVer];

};


const preprocessing = (source, modelWidth, modelHeight) => {
  const mat = cv.imread(source); 
  const matC1 = new cv.Mat(mat.rows, mat.cols, cv.CV_8UC1);
  cv.cvtColor(mat, matC1, cv.COLOR_RGBA2GRAY); // RGBA to GRAY

  const imgWidth = matC1.cols;
  const imgHeight = matC1.rows;

  const matResized = new cv.Mat();
  cv.resize(matC1, matResized, new cv.Size(modelWidth, modelHeight));

  // Adjusted the scale factor to 1.0
  const input = cv.blobFromImage(
      matResized,  // use the resized matrix
      1/255.0,        // No normalization
      new cv.Size(modelWidth, modelHeight),
      new cv.Scalar(0),
      true, 
      false 
  ); 

  mat.delete();
  matC1.delete();
  matResized.delete();  // don't forget to delete this to free memory

  return [input, imgWidth / modelWidth, imgHeight / modelHeight];
};


function convertToCoordinatePairs(array) {
  let result = [];
  for(let i = 0; i < array.length; i+=2) {
      result.push([Number(array[i]), Number(array[i+1])]);
  }
  return result;
}

function bigInt64ArrayToArray(data) {
  return Array.from(data).map(bigIntVal => Number(bigIntVal));
}

function getValidIndices(matchesArray) {
  const validIndices = [];
  matchesArray.forEach((match, index) => {
      if (match > -1) {
          validIndices.push(index);
      }
  });
  return validIndices;
}


function extractKeyPoints(kptsArray, validIndices) {
  return validIndices.map(index => kptsArray[index]);
}


function postProcess(kpts0, kpts1, matches0) {
  // Convert BigInt64Array data to standard arrays with coordinate pairs
  const kpts0Array = convertToCoordinatePairs(bigInt64ArrayToArray(kpts0.data));
  const kpts1Array = convertToCoordinatePairs(bigInt64ArrayToArray(kpts1.data));
  const matchesArray = bigInt64ArrayToArray(matches0.data);

  // Get valid indices from matches
  const validIndices = getValidIndices(matchesArray);

  // Extract matched key points
  const matchedKpts0 = extractKeyPoints(kpts0Array, validIndices);
  const matchedKpts1Indices = validIndices.map(index => matchesArray[index]);
  const matchedKpts1 = extractKeyPoints(kpts1Array, matchedKpts1Indices);

  return [matchedKpts0, matchedKpts1];
}

const drawKeypoints = (canvas, keypoints, imgRef, modelInputShape) => {
  const ctx = canvas.getContext("2d");
  ctx.fillStyle = "red"; 

  keypoints.forEach(point => {
      const adjustedX = point[0];
      const adjustedY = point[1];
      ctx.beginPath();
      ctx.arc(adjustedX, adjustedY, 2, 0, 2 * Math.PI);
      ctx.fill();
  });
}


// Drawing lines connecting the corresponding keypoints
const drawConnections = (canvas1, keypoints1, canvas2, keypoints2, imgRef1, imgRef2, modelInputShape) => {
  const ctx1 = canvas1.getContext("2d");
  const ctx2 = canvas2.getContext("2d");

  ctx1.strokeStyle = ctx2.strokeStyle = "blue";

  for (let i = 0; i < keypoints1.length; i++) {
      const adjustedX1 = keypoints1[i][0]; 
      const adjustedY1 = keypoints1[i][1];
      const adjustedX2 = keypoints2[i][0];
      const adjustedY2 = keypoints2[i][1];
      const intercept = (adjustedY2*(canvas1.width - adjustedX1) + adjustedX2*adjustedY1)/(canvas1.width - adjustedX1 + adjustedX2)

      // Draw line segment on first canvas
      ctx1.beginPath();
      ctx1.moveTo(adjustedX1, adjustedY1);
      ctx1.lineTo(canvas1.width, intercept);  // line ends at right edge of canvas1
      ctx1.stroke();

      // Draw line segment on second canvas
      ctx2.beginPath();
      ctx2.moveTo(0, intercept);  // line starts at left edge of canvas2
      ctx2.lineTo(adjustedX2, adjustedY2);
      ctx2.stroke();
  }
};

export const alignedVerImageToBlob = (canvas, mat1) => {
  if (!canvas || !mat1) return [];

  cv.imshow(canvas, mat1);

  const dataURL = canvas.toDataURL("image/jpeg");
  const blob = dataURIToBlob(dataURL);
  return [blob, dataURL];
}

export const showAlignedCanvas = (refImage, alignedImage) => {
  reference = refImage;
  warpedVer = alignedImage;

  handleSliderChange();

  // Display the modal
  showModal();
}

function superimposeImages(canvas, mat1, mat2, alpha) {

  // Ensure both images are of the same size for superimposition
  if (mat1.rows !== mat2.rows || mat1.cols !== mat2.cols) {
      console.error("Images are not of the same size!");
      return;
  }

  // Create an output mat to hold the superimposed result
  const dst = new cv.Mat(mat1.rows, mat1.cols, mat1.type());

  // Blend the images
  cv.addWeighted(mat1, 1 - alpha, mat2, alpha, 0, dst);

  // Draw the blended image on the canvas
  cv.imshow(canvas, dst);

  dst.delete();
};


function showModal() {
  const modal = document.getElementById('modal');
  if (modal) {
    modal.classList.remove('hidden');
  }
};

export function hideModal() {
  const modal = document.getElementById('modal');
  if (modal) {
    modal.classList.add('hidden');
  }
};

export function handleSliderChange() {
  const slider = document.getElementById("alpha-slider");
  if (!slider) return;
  const alphaValue = parseFloat(slider.value);

  const superimposedCanvas = document.getElementById("superimposedCanvas");
  
  superimposeImages(superimposedCanvas, reference, warpedVer, alphaValue);
};




