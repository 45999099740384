import React from "react";
import "./Logo.scss";

export const UnikbaseSvg = (props) => {
	const { style = {}, className = "", onClick = false, id = 'unikbase-logo' } = props;

	const textColor = "#ffffff"; // 081d24
	const shapeColor = "#e95224";
	const clickHandle = (event) => {
		(typeof onClick === 'function') && onClick(event)
	}

	return (
		<div onClick={clickHandle} id={id} style={style} className={className}>
		</div>
	);
};

export const Logo = ({ className, style, onClick = false }) => {
	return <UnikbaseSvg onClick={onClick} className={`logo ${className}`} style={style} />;
};
