import { createContext, useContext, useReducer } from "react";

export const SendMessageContext= createContext(null);
export const SendMessageDispatchContext = createContext(null);

const initialConfigs = {
	tokens: []
}

const reducer = (settings, action) => {
	const tokens = action?.tokens;
	let newState;
	
	try {
		newState = JSON.parse(JSON.stringify(settings));
	} catch (error) {
		newState = {};
	}

	switch(action.type) {
		case "update":
			return {
				...newState,
				tokens: tokens
			};
		case "reset":
			return initialConfigs;
		default: 
			return newState;
	}
}


export function SendMessageProvider({ children }) {
	const [settings, dispatch] = useReducer(reducer, initialConfigs);

	return (
		<SendMessageContext.Provider value={settings}>
			<SendMessageDispatchContext.Provider value={dispatch}>
				{children}
			</SendMessageDispatchContext.Provider>
		</SendMessageContext.Provider>
	);
}

export function useSendMessageContext() {
	return useContext(SendMessageContext);
}

export function useSendMessageDispatch() {
	return useContext(SendMessageDispatchContext);
}