import { Box, Button, Checkbox, Collapse, FormControlLabel, FormGroup, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { TRANSACTION_TYPES } from "../../configs/constant";
import { useEffect, useMemo, useState } from "react";
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRange } from "react-date-range";

// import { DateRangePicker } from "rsuite";
import { t } from "i18next";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./TransactionFilter.scss";
import DateRangeFilterItem from "../Filter/FilterMenu/DateRangeFilterItem";

const TransactionFilter = ({ standalone, onExport, onChange }) => {
	const [open, toggleFilter] = useState(false);
	const [state, setState] = useState({});
	const initialDate = [
		{
			startDate: new Date().getTime(),
			endDate: new Date().getTime(),
			key: "selection",
		},
	]
	const [dateState, setDateState] = useState(initialDate);
	const [dateType, setDateType] = useState('');

	const DateTypes = useMemo(() => [
		{ value: 'today', label: t('pages:transactions.today') },
		{ value: 'yesterday', label: t('pages:transactions.yesterday') },
		{ value: 'last-seven-days', label: t('pages:transactions.last_7_days') },
		{ value: 'last-thirty-days', label: t('pages:transactions.month_to_date') },
		{ value: 'custom', label: t('pages:transactions.custom_date') },
		// { value: 'clear', label: t('pages:transactions.clear') },
	], []);

	const handleTypeChange = (event) => {
		const newState = {
			...state,
			[event.target.name]: event.target.checked,
		};

		setState(newState);
		if (typeof onChange === "function")
			onChange({
				type: newState,
			});
	};

	const dateChangeByBlock = (type) => {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		const dayMs = 24 * 60 * 60 * 1000;
		let start;
		let end;
		let ranges = [];

		switch (type) {
			case "last-thirty-days":
				start = new Date(today.getTime() - 30 * dayMs);
				end = new Date(today.getTime() + dayMs - 1);

				ranges = [start, end];
				break;
			case "last-seven-days":
				start = new Date(today.getTime() - 7 * dayMs);
				end = new Date(today.getTime() + dayMs - 1);

				ranges = [start, end];
				break;
			case "yesterday":
				start = new Date(today.getTime() - dayMs);
				end = new Date(start.getTime() + dayMs - 1);

				ranges = [start, end];
				break;
			case "today":
			default:
				start = new Date();
				start.setHours(0, 0, 0, 0);

				end = new Date();
				end.setHours(23, 59, 59, 999);
				ranges = [start, end];
				break;
		}

		setDateState([
			{
				startDate: start.getTime(),
				endDate: end.getTime(),
				key: "selection",
			},
		]);
		handleDateChange(ranges);
	};
	const handleDateChange = (ranges) => {
		if (typeof onChange === "function") {
			ranges = ranges === false ? [false, false] : [ranges[0].toISOString(), ranges[1].toISOString()];
			onChange({
				startDate: ranges[0],
				endDate: ranges[1],
			});
		}
	};

	useEffect(() => {
		if (dateType === 'clear') {
			setDateType('');
			handleDateChange(false);
		} else if (dateType === '') {
			handleDateChange(false);
		} else if (!!dateType && dateType !== 'custom') {
			dateChangeByBlock(dateType);
		}
	}, [dateType]);

	return (
		<Box className={`transactions__filter flex flex--justify-end ${standalone ? 'standalone' : ''}`}>
			<Box className="transactions__filter__header">
				{standalone && (
					<Typography variant='h6' component="span" sx={{ color: '#fff' }}>
						{t('pages:transactions.title')}
					</Typography>
				)}
				<IconButton
					className={"export-csv"}
					variant="text"
					color="secondary"
					size="small"
					onClick={onExport}
					sx={{ mb: 2, mr: 1 }}
				><span className="icon-csv-download"></span></IconButton>
				<IconButton
					className={!!open ? `active` : ""}
					size="small"
					onClick={() => toggleFilter(!open)}
					sx={{ mb: "2m" }}
				>
					<span className="icon icon--slider"></span>
				</IconButton>
			</Box>
			<Collapse className="filter__content" in={open}>
				<Box className="filter__type flex flex--horizontal">
					<Typography className="filter__type--label" variant="h6" component="div">
						{t("pages:transactions.filter")}
					</Typography>
					<Box className="transaction__types" sx={{ mb: 3 }}>
						<FormGroup className="flex flex--horizontal">
							{Object.values(TRANSACTION_TYPES).map((item) => (
								<FormControlLabel
									key={`transaction-type-${item.key}`}
									control={<Checkbox name={item.key} onChange={handleTypeChange} />}
									label={t(item.label)}
								/>
							))}
						</FormGroup>
					</Box>
					<Typography className="filter__type--label" variant="h6" component="div">
						{t("pages:transactions.date_range")}
					</Typography>
					<Box className="transaction__date-range">
						<Box className="flex flex--horizontal flex--align-center flex--justify-start" sx={{ mb: 1 }}>
							<Select
								labelId="transaction-filter-date-type"
								id="transaction-filter-date-type"
								value={dateType}
								onChange={(event) => setDateType(event.target.value)}
								placeholder={t('pages:transactions.select_date')}
								sx={{ minWidth: 160 }}
								displayEmpty={true}
							>
								<MenuItem value="" sx={{ color: '#a8a8a8' }}>
									{t('pages:transactions.select_date')}
								</MenuItem>
								{
									DateTypes.map(({ value, label }, index) => (
										<MenuItem value={value}>{label}</MenuItem>
									))
								}
							</Select>
							{dateType === 'custom' && (
								<Box display="flex" width={220} height={40} alignItems="center">
									<DateRangeFilterItem
										key={`filter-date-${dateType}`}
										value={dateState?.length ? dateState[0] : initialDate[0]}
										onChange={(start, end) => {
											let items;
											if (start && end) {
												items = [{ startDate: start.getTime(), endDate: end.getTime() }]
												handleDateChange([start, end]);
											} else {
												handleDateChange(false);
											}
											setDateState(items);
										}}
									/>
								</Box>
							)}
							{/*
							<DateRange
								editableDateInputs={false}
								onChange={(item) => {
									setDateState([item.selection]);
									handleDateChange([item.selection.startDate, item.selection.endDate]);
								}}
								moveRangeOnFirstSelection={false}
								ranges={dateState}
								months={2}
								direction="horizontal"
								maxDate={new Date()}
								dateDisplayFormat="dd/MM/yyyy"
							/>
							 */}
							{/* <DateRangePicker
								onOk={(ranges) => handleDateChange(ranges)}
								onClean={() => handleDateChange(false)}
								format="yyyy-MM-dd HH:mm:ss"
								character=" and "
								block={true}
								ranges={[]}
							/> */}
						</Box>
						{/*
						<Box className="transaction__date-range--block">
							<Button onClick={() => dateChangeByBlock("today")} size="small" variant="text">
								Today
							</Button>
							<Button onClick={() => dateChangeByBlock("yesterday")} size="small" variant="text">
								Yesterday
							</Button>
							<Button onClick={() => dateChangeByBlock("last-seven-days")} size="small" variant="text">
								Last 7 Complete Days
							</Button>
							<Button onClick={() => dateChangeByBlock("last-thirty-days")} size="small" variant="text">
								Month To Date
							</Button>

							<Button onClick={() => {
								setDateState(initialDate);
								handleDateChange(false);
							}} size="small" variant="text">
								Clear
							</Button>
							{/* <Button onClick={() => dateChangeByBlock('current-month')}  size="small" variant="text" >Last Calendar Month</Button> */}
						{/* </Box> */}
					</Box>
				</Box>
			</Collapse>
		</Box>
	);
};

export default TransactionFilter;
