import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Hidden,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { documentPathTypes, newTokenStatus } from '../../../configs/constant';
import { proxySrcReplace } from '../../Gallery/ImageProxy';
import { t } from 'i18next';
import { timeSince } from '../../../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTokenStatus } from './TokenList';
import { useEffect, useRef, useState } from 'react';
import { useSendMessageDispatch } from '../SendMessage/SendMessageContext';
import { useSelectTokenContext, useSelectTokenDispatch } from './SelectTokenContext';
import { standPost } from '../../../pages/Standalone/Standalone';

const Placeholder = () => {
  return (
    <TableRow className='placeholder token'>
      <TableCell>
        <Box className='flex flex--horizontal flex--align-center flex--justify-center flex--nowrap'>
          <Skeleton className='image' variant='rectangular' style={{ width: '50px', height: '50px' }} />
        </Box>
      </TableCell>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
      <TableCell>
        <Skeleton variant='text' />
      </TableCell>
    </TableRow>
  );
};

const Item = ({
  data,
  currentUserEmail,
  headers,
  onCheck = false,
  unCheck = false,
  isItemSelected = false,
  isOperatorUser = false,
  isOperator = false,
  operator,
  standalone,
}) => {
  const navigate = useNavigate();

  const token = data.token;
  const coverImage = data.documents.find((document) => document.path === documentPathTypes.COVER_IMAGE);

  const tokenStatus = getTokenStatus(token.status, currentUserEmail, data.client, token.ownership, token);
  let description = token.description?.substring(0, 100) || '';
  if (description.length !== token.description?.length) {
    description += '...';
  }

  const ownership =
    token.ownership === newTokenStatus.OWNED.toLowerCase() ? newTokenStatus.OWNED : newTokenStatus.MANAGED;
  const _tokenStatus =
    !tokenStatus || tokenStatus.toLowerCase() === newTokenStatus.OWNED.toLowerCase()
      ? '_'
      : t(`pages:token_list.statuses.${tokenStatus.toLowerCase()}`);

  const checkbox = useRef();

  const showItem = (event) => {
    if (checkbox.current && (event.target === checkbox.current || checkbox.current.contains(event.target))) {
      if (event.target.checked) {
        typeof onCheck === 'function' && onCheck(token.uuid);
      } else {
        typeof unCheck === 'function' && unCheck(token.uuid);
      }
      return null;
    }
    if (standalone) {
      standPost({ action: 'viewToken', data: { tokenId: token.uuid } });
    } else
    return navigate(`/token/${token.uuid}`);
  };

  return (
    <TableRow className='token' onClick={showItem}>
      {(!!isOperatorUser || !!isOperator) && (
        <TableCell padding='checkbox'>
          <Checkbox
            ref={checkbox}
            color='secondary'
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': token.uuid,
            }}
          />
        </TableCell>
      )}
      <TableCell
        className='token__name'
        sx={{
          '&::before': {
            content: `"${headers[0].label}"`,
          },
        }}
      >
        <Box className='flex flex--horizontal flex--align-center flex--justiry-center flex--nowrap w--100'>
          <span
            className='token__thumbnail'
            style={
              !!coverImage
                ? {
                    backgroundImage: `url(${proxySrcReplace(coverImage.fileUrl, 250, 250)})`,
                  }
                : {}
            }
          >
            {false && (
              <IconButton className='token__favorite'>
                <FontAwesomeIcon icon={solid('star')} />
              </IconButton>
            )}
          </span>
          {token.name}
        </Box>
      </TableCell>
      <TableCell
        className='token__uuid'
        sx={{
          '&::before': {
            content: `"${headers[1].label}"`,
          },
        }}
      >
        {token.uuid}
      </TableCell>
      <TableCell
        className='token__description'
        sx={{
          '&::before': {
            content: `"description"`,
          },
        }}
      >
        {description}
      </TableCell>
      <TableCell
        className='token__tagRef'
        sx={{
          '&::before': {
            content: `"${headers[2].label}"`,
          },
        }}
      >
        {token.tokenMarkReference || t('common:unspecified')}
      </TableCell>
      <TableCell
        className='token__offer'
        sx={{
          '&::before': {
            content: `"${headers[3].label}"`,
          },
        }}
      >
        {token.commercialOfferCode === 'BASIC' ? 'FREE' : token.commercialOfferCode || <Box mx={2} />}
      </TableCell>
      <TableCell
        className='token__origin'
        sx={{
          '&::before': {
            content: `"${headers[4].label}"`,
          },
        }}
      >
        {isOperator || isOperatorUser ? token.ownerEmail : t('pages:token.token_origin_value')}
      </TableCell>
      <TableCell
        className='token__lastUpdate'
        sx={{
          '&::before': {
            content: `"${headers[5].label}"`,
          },
          whiteSpace: 'nowrap',
        }}
      >
        {timeSince(token.lastUpdate)}
      </TableCell>
      <TableCell
        className='token__statuses'
        sx={{
          '&::before': {
            content: `"${headers[6].label}"`,
          },
        }}
      >
        <Box className='token__statuses_row'>
          <Box
            className={`token__status token__status--${tokenStatus.toLowerCase()} ${
              !tokenStatus || tokenStatus.toLowerCase() === newTokenStatus.OWNED.toLowerCase()
                ? 'token__status--empty'
                : ''
            }`}
          >
            {_tokenStatus}
          </Box>
          <Box className='token__badges'>
            <Hidden smUp>
              {!!ownership && (
                <Chip
                  className={`token__operator-status--${ownership.toLowerCase()}`}
                  title={token.name || ''}
                  size='small'
                  color='secondary'
                  label={t(`pages:token_list.statuses.${ownership.toLowerCase()}`)}
                />
              )}
            </Hidden>
          </Box>
        </Box>
      </TableCell>

      <TableCell
        className='token__operatorStatus'
        sx={{
          '&::before': {
            content: `"${headers[7].label}"`,
          },
        }}
      >
        <Box className='token__badges'>
          {!!ownership && (
            <Chip
              className={`token__operator-status--${ownership.toLowerCase()}`}
              title={token.name || ''}
              size='small'
              color='secondary'
              label={t(`pages:token_list.statuses.${ownership.toLowerCase()}`)}
            />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

const ListHeader = ({
  columns,
  sortHandle,
  selected,
  onSelectAll,
  rowCount = 0,
  isOperatorUser = false,
  isOperator = false,
  operator,
}) => {
  const [sortDirection, toggleSortDirection] = useState({});
  const [sortProperty, setSortProperty] = useState('');

  const createSortHandler = (property) => (event) => {
    let dir = !!sortDirection[property] && sortDirection[property] === 'asc' ? 'desc' : 'asc';
    setSortProperty(property);
    toggleSortDirection({
      ...sortDirection,
      [property]: dir,
    });
    !!sortHandle && typeof sortHandle === 'function' && sortHandle(property, dir);
  };

  const onSelectAllClick = () => {
    typeof onSelectAll === 'function' && onSelectAll();
  };

  return (
    <TableHead>
      <TableRow>
        {(!!isOperatorUser || !!isOperator) && (
          <TableCell width={70} padding='checkbox'>
            <Checkbox color='secondary' onChange={onSelectAllClick} />
          </TableCell>
        )}
        {columns.map((item, idx) => (
          <TableCell key={`token-lists-header-${idx}`} sx={{ p: 0 }}>
            {!!item.order && (
              <TableSortLabel
                active={sortProperty === item.order}
                onClick={createSortHandler(item.order)}
                direction={!!sortDirection[item.order] && sortDirection[item.order] === 'asc' ? 'desc' : 'asc'}
              >
                {item.label}
              </TableSortLabel>
            )}
            {!item.order && item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const ListView = ({
  fetching = true,
  tokens = false,
  currentUserEmail,
  sortHandle,
  partnerCode,
  isOperatorUser,
  isOperator,
  operator,
  standalone,
}) => {
  const columns = [
    {
      label: t('pages:token_list.column_labels.name'),
      order: 'name',
    },
    {
      label: t('pages:token_list.column_labels.uuid'),
      order: 'uuid',
    },
    {
      label: t('pages:token_list.column_labels.tag_ref'),
      order: 'tokenMarkReference',
    },
    {
      label: t('pages:token_list.column_labels.offer'),
    },
    {
      label:
        isOperator || isOperatorUser
          ? t('pages:token_list.column_labels.owner')
          : t('pages:token_list.column_labels.operator'),
    },
    {
      label: t('pages:token_list.column_labels.date'),
      order: 'creationDate',
    },
    {
      label: t('pages:token_list.column_labels.status'),
      order: 'status',
    },
    {
      label: t('pages:token_list.column_labels.operator_status'),
      order: 'operatorStatus',
    },
  ];
  const [viewData, updateData] = useState(tokens || []);
  const [selectedTokens, updateSelectedTokens] = useState([]);
  const sendMessageDispatch = useSendMessageDispatch();
  const selectTokenDispatch = useSelectTokenDispatch();

  const selectTokenContext = useSelectTokenContext();
  useEffect(() => {
    if (selectTokenContext.lastUpdate > 0) {
      updateSelectedTokens([]);
    }
  }, [selectTokenContext.lastUpdate]);

  useEffect(() => {
    updateData(tokens);
  }, [tokens]);

  useEffect(() => {
    const sendMessageTokens = viewData
      .filter((i) => selectedTokens.indexOf(i.token.uuid) >= 0 && i?.token?.ownership === 'managed')
      .map((i) => i?.token?.uuid);

    sendMessageDispatch({
      type: 'update',
      tokens: sendMessageTokens,
    });
    selectTokenDispatch({
      type: 'update',
      tokens: selectedTokens,
    });
  }, [selectedTokens]);

  if (!fetching && tokens.length <= 0) return null;

  const toggleSelectAll = () => {
    if (selectedTokens.length !== viewData.length) {
      updateSelectedTokens(viewData.map((item) => item.token.uuid));
    } else {
      updateSelectedTokens([]);
    }
  };

  return (
    <Table className='tokens--list-view'>
      <Hidden smUp>
        <Box className='tokens--header-toggle-all' py={2}>
          <Typography
            variant='body2'
            component='span'
            color='GrayText'
            dangerouslySetInnerHTML={{
              __html: t('pages:wallet.you_have_count_digital_passports', { count: viewData.length }),
            }}
          ></Typography>
          <FormControlLabel
            key={`token-toggle-select-all`}
            control={
              <Checkbox
                checked={selectedTokens.length === viewData.length}
                name={'token_select_all'}
                onChange={toggleSelectAll}
              />
            }
            label={t('common:select_all')}
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
          />
        </Box>
      </Hidden>
      <ListHeader
        selected={selectedTokens}
        columns={columns}
        sortHandle={sortHandle}
        rowCount={viewData.length}
        isOperatorUser={isOperatorUser}
        isOperator={isOperator}
        operator={operator}
        onSelectAll={toggleSelectAll}
      />
      <TableBody>
        {!!fetching && (
          <>
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </>
        )}
        {!fetching && viewData && viewData.length > 0 && (
          <>
            {viewData.map((token, idx) => (
              <Item
                key={`token-list-view-${idx}`}
                standalone={standalone}
                data={token}
                currentUserEmail={currentUserEmail}
                isOperatorUser={isOperatorUser}
                isOperator={isOperator}
                operator={operator}
                headers={columns}
                isItemSelected={
                  selectedTokens.length === viewData.length || selectedTokens.indexOf(token.token.uuid) >= 0
                }
                onCheck={(tokenId) => {
                  if (selectedTokens.indexOf(tokenId) < 0) {
                    updateSelectedTokens([...selectedTokens, tokenId]);
                  }
                }}
                unCheck={(tokenId) => {
                  let index = selectedTokens.indexOf(tokenId);
                  if (index >= 0) {
                    updateSelectedTokens(selectedTokens.filter((item) => item !== tokenId));
                  }
                }}
              />
            ))}
          </>
        )}
      </TableBody>
    </Table>
  );
};

export default connect((state) => {
  const wallet = state.wallet;

  return {
    currentUserEmail: wallet?.private?.email?.address,
    partnerCode: wallet?.partnerCode,
    isOperatorUser: wallet?.isOperatorUser,
    isOperator: wallet?.isOperator,
    lang: wallet?.public?.language,
    operator: wallet?.operator,
  };
})(ListView);
