import React from 'react'
import { connect } from 'react-redux';
import { parseJwt } from '../../utils/helpers';
import { Avatar, Typography } from '@mui/material';
import { proxyS3UrlGeneratorWithKey } from '../../components/Gallery/ImageProxy';
import BigNumber from 'bignumber.js';
import { fromWei } from 'web3-utils';

const UserAvatarBalance = ({ className, isLogged, isFetching, username, displayName, avatarKey, photoBase64, balance, onClick }: any) => {

  let userImageSrc = !!avatarKey
    ? proxyS3UrlGeneratorWithKey(avatarKey, '250', '250')
    : photoBase64
      ? `data:image/png;base64,${photoBase64}`
      : 'https://placehold.it/100x100';

  return (!isLogged || isFetching ? null :
    <div className={`flex flex--horizontal flex--align-center flex--justify-start ${className}`} onClick={onClick}>
      <Avatar
        sx={{ width: 40, height: 40, mr: 1 }}
        src={userImageSrc}
        alt={username}
      />
      <hgroup className='user__informations flex flex--vertical flex--align-start flex--justify-start'>
        <Typography variant='body1' color="whitesmoke" noWrap component='span' textTransform="capitalize">
          {displayName}
        </Typography>
        <Typography className='wallet__balance' variant='body2' sx={{ color: '#8A9FA7' }} component='span' textAlign="left">
          {`${new BigNumber(fromWei(balance, 'ether')).toFixed(2)} EUR`}
        </Typography>
      </hgroup>
    </div>
  )
}
export default connect(
  (state: any) => {
    let data = state?.auth;
    const wallet = state.wallet;
    let username = wallet?.private?.username;
    let displayName = wallet?.public?.firstName
      ? `${wallet?.public?.firstName} ${wallet?.public?.lastName || ''}`
      : wallet?.private?.username;

    if (wallet.isOperatorUser) {
      let jwtDecode = parseJwt(data.accessToken)
      username = jwtDecode.name;
      displayName = jwtDecode.given_name;
    }
    return {
      isLogged: !!data.accessToken,
      photoBase64: wallet?.public?.base64Avatar,
      avatarKey: wallet?.public?.avatarKey,
      username: username,
      displayName: displayName,
      isFetching: wallet?.status === 'loading',
      balance: wallet?.balance,
    };
  }
)(UserAvatarBalance);
