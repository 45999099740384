import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Box, Button, Chip, InputAdornment, InputBase, OutlinedInput, Skeleton, Typography } from "@mui/material";
import { t } from "i18next";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { markStoredSeedphrase } from "../../store/slices/generalSettingsSlice";
import { removeOnceMnemonic } from "../../store/slices/walletSlice";
import { EXPORT_SEEDPHRASE } from "../../store/actions/walletActions";
import { setInitialKeyringCallback } from "../../store/midleware/cryptoMidleware";
import { useGetWalletQuery } from "../../service/api/meveoApi";
import { logout } from "../../store/slices/authSlice";
import { persistor } from "../../store/rootReducer";
import { useLogout } from "../../utils/hooks";
import { getRandomIndices, shuffleArray } from "../../utils/helpers";
import MessageBox from "../../components/Animation/MessageBox";

const SeedPhraseReveal = (props) => {
	const location = useLocation();
	const { seedPhrase, email, address, keyringController, markStoredSeedphrase, exportSeedphrase, isLoggedIn } = props;
	const logout = useLogout();
	const navigate = useNavigate();
	const [mnemonic, setMnemonic] = useState(false);
	const randomMnemonic = useMemo(() => {
		if( !mnemonic ) return false;
		return shuffleArray(mnemonic.split(" "));
	}, [mnemonic]);

	const { data, isFetching, isError, isUninitialized } = useGetWalletQuery({
	}, {
		skip: !isLoggedIn,
	});
	const [confirmMnemonic, setConfirmMnemonic] = useState(false);

	const indicesToKeep = useMemo(() => getRandomIndices(11, 0), []);

	const [step, setStep] = useState(0);
	const [error, setError] = useState(false);

	const finishHandle = () => {
		markStoredSeedphrase(email);
		navigate("/verification/email");
	}

	const revealSeedphraseHandle = () => {
		exportSeedphrase();
		setStep(1);
	}

	const getConfirmMnemonic = useCallback(() => {
		if ( !mnemonic ) {
			return [];
		}
		// Initial confirm mnemonic
		const defaultWords = mnemonic.split(' ');
		let newObj = {};
		defaultWords.forEach((v, i) => {
			newObj[i] = indicesToKeep.indexOf(i) >= 0 ? defaultWords[i] : ''; 
		});
		return Object.values(newObj);

	}, [mnemonic, indicesToKeep]);

	const verifySeedphraseHandle = () => {
		setConfirmMnemonic(getConfirmMnemonic());
		setStep(2);
	}


	const handleDataFromMiddleware = useCallback((currentMnemonic) => {
		if ( currentMnemonic === false ) {
			logout(location.pathname + location.search);
		}
    // Handle the data received from middleware
		if ( typeof currentMnemonic === 'string' ) {
			setMnemonic(currentMnemonic);
		}
  }, [location, logout]);


	const chooseTextHandle = (word) => {
		let current = Object.assign([], confirmMnemonic);
		const next = current.indexOf('');
		if ( next >= 0 ) {
			current[next] = word;
			setConfirmMnemonic(current);
		}
	}

	const removeTextHandle = (index) => {
		let current = Object.assign([], confirmMnemonic);
		if (current[index]) {
			current[index] = '';
			setConfirmMnemonic(current);
		}
	}
	const confirmMnemonicSubmit = () => {
		let valid = true;
		let mnemonicDefault = mnemonic.split(' ');
		for(let i in confirmMnemonic) {
			if ( confirmMnemonic[i] !== mnemonicDefault[i] ) {
				valid = false;
				break;
			}
		}
		if ( !valid ) {
			setConfirmMnemonic(getConfirmMnemonic());
			setError('Seedphrase doesn\'t match');
			return;
		}
		finishHandle();
	}

  // Send the callback function into the middleware
  useEffect(() => {
    setInitialKeyringCallback(handleDataFromMiddleware);
  }, [handleDataFromMiddleware]);

	const renderSteps = () => {
		switch (step) {
			case 2:
				return (
					<Box>
						<Box sx={{
							p: 2,
							backgroundColor: "var(--rs-gray-300)",
							mb: 1
						}}>
							<Box sx={{ width: "70%", margin: '0 auto' }} className="seedphrase flex">
								{!!confirmMnemonic && confirmMnemonic.map((word, idx) => (
									<Box key={`seedphrase-word-${idx}`} sx={{
										flexBasis: '50%',
										mb: 2,
										order: idx === 0 ? 0 : idx < 6 ? idx + 1 : idx - 5
									}}>
										<Chip 
											sx={{ 
												width: '90%',
												border: '1px solid var(--unikbase-black)',
												'.MuiChip-label': {
													width: '100%',
													display: 'flex'
												}
											}} 
											onClick={() => removeTextHandle(idx)}
											label={(
											<>
												<span style={{
													flexBasis:"20%"
												}}>#{idx+1}</span>
												<span style={{
													flexGrow: 1,
													
												}}>{word}</span>
											</>
										)} />
									</Box>
								))}
							</Box>
						</Box>
						<Box sx={{
							p: 2,
							mb: 2
						}}>
							<Box sx={{ width: "70%", margin: '0 auto' }} className="seedphrase seedphrase--random flex">
								{!!randomMnemonic && randomMnemonic.map((word, idx) => {
									const idxArr = Object.values(randomMnemonic).map((w, i) => w === word ? i : null).filter(i => i!=null);
									const picked = confirmMnemonic.filter(item => item ===word).length;

									const isPicked = picked > 0 && picked <= idxArr.length && idxArr[picked-1] === idx;
									
									return (
										<Box key={`seedphrase-random-word-${idx}`} sx={{
										flexBasis: '50%',
										mb: 2,
										order: idx === 0 ? 0 : idx < 6 ? idx + 1 : idx - 5
									}}>
										<Chip 
											className={`${!!isPicked ? 'selected' : ''}`}
											sx={{ 
												width: '90%',
												border: '1px solid var(--unikbase-white)',
												color: 'var(--unikbase-black)',
												'.MuiChip-label': {
													width: '100%',
													display: 'flex'
												}
											}} 
											label={(
												<Button 
													sx={{ p: 0, width: '100%', textTransform: 'lowercase', fontWeight: 'normal' }} 
													variant="text"
													onClick={() => chooseTextHandle(word)}
												>{`${!!isPicked ? '' : word}`}</Button>
											)} 
										/>
									</Box>
									)
								})}
							</Box>
						</Box>
						{!!error && <MessageBox type="error">{error}</MessageBox>}
						<Button onClick={confirmMnemonicSubmit} color="secondary" size="medium" variant="contained" sx={{ width: '100%' }}>Complete backup</Button>
					</Box>
				)
			case 1:
				return (
					<Box>
						<Box sx={{
							p: 2,
							backgroundColor: "var(--rs-gray-300)",
							mb: 2
						}}>
							<Box sx={{ width: "70%", margin: '0 auto' }} className="seedphrase flex">
								{!!mnemonic && mnemonic.split(" ").map((word, idx) => (
									<Box key={`seedphrase-word-${idx}`} sx={{
										flexBasis: '50%',
										mb: 2,
										order: idx === 0 ? 0 : idx < 6 ? idx + 1 : idx - 5
									}}>
										<Chip sx={{ 
											width: '90%',
											border: '1px solid var(--unikbase-black)',
											'.MuiChip-label': {
												width: '100%',
												display: 'flex'
											}
										}} label={(
											<>
												<span style={{
													flexBasis:"20%"
												}}>#{idx+1}</span>
												<span style={{
													flexGrow: 1,
													
												}}>{word}</span>
											</>
										)} />
									</Box>
								))}
							</Box>
						</Box>
						<Box className="actions">
							<Button onClick={verifySeedphraseHandle} sx={{ width: "50%" }} color="secondary" variant="contained">
								{t("common:continue")}
							</Button>
						</Box>
					</Box>
				)
			case 0:
				default:
					return (
						<Box className="actions">
							<Box className="action" sx={{ mb: 1 }}>
								<Button
									onClick={finishHandle}
									color="gray"
									sx={{ width: "50%" }}
									variant="contained"
								>
									{t("pages:verification.seedphrase.cancel_button")}
								</Button>
								<br />
								<Typography variant="caption">
									({t("pages:verification.seedphrase.cancel_button_sub_title")})
								</Typography>
							</Box>

							<Box className="action">
								<Button onClick={revealSeedphraseHandle} sx={{ width: "50%" }} color="secondary" variant="contained">
									{t("pages:verification.seedphrase.start_button")}
								</Button>
								<br />
								<Typography variant="caption">
									({t("pages:verification.seedphrase.start_button_sub_title")})
								</Typography>
							</Box>
						</Box>
					)
		}
	}

	return (
		<Box>
			<Typography
				sx={{ ml: "auto", mr: "auto", mb: 3 }}
				variant="subtitle"
				align="center"
				className="page-anchor"
			>
				<span>
					<FontAwesomeIcon icon={solid("user-check")} />
				</span>{" "}
				{step === 0
					? t("pages:verification.seedphrase.reveal_title")
					: t("pages:verification.seedphrase.title")}
			</Typography>
			{(isUninitialized || isFetching) && (
				<Skeleton variant="rectangular" height="300px" />
			)}
			{!isUninitialized && !isFetching && (
				<>
					<Typography component="div" sx={{ textAlign: "left", mb: 2 }}>
						<div dangerouslySetInnerHTML={{
							__html: t(step === 2 ? "pages:verification.seedphrase.confirm_seedphrase" : step === 1 ? "pages:verification.seedphrase.reveal_message" : "pages:verification.seedphrase.message")
						}}></div>
					</Typography>

					{renderSteps()}
				</>
			)}

		</Box>
	);
};

export default connect((state) => {
	const wallet = state?.wallet;
	const auth = state?.auth;
	return {
		isLoggedIn: (!!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime()),
		seedPhrase: wallet?.private?.seedPhrase,
		address: wallet?.address,
		email: wallet?.private?.email.address,
		keyringController: state?.crypto?.keyringController
	};
}, dispatch => {
	return {
		markStoredSeedphrase: (email) => {
			dispatch(markStoredSeedphrase({email}))
		},
		removeMnemonic: () => {
			dispatch(removeOnceMnemonic());
		},
		exportSeedphrase: () => {
			dispatch({ type : EXPORT_SEEDPHRASE })
		}
	}
})(SeedPhraseReveal);
