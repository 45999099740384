import { Checkbox } from "@mui/material";
import React from "react";

type CheckBoxFilterItemProps = {
	label: string;
	items: Array<Object>;
	onChange: Function;
} 

const CheckBoxFilterItem: React.FC<CheckBoxFilterItemProps> = (props) => {
	const { label, items, onChange } = props;
	const onChangeHandle = (value: string, checked: boolean) => {
		const newItems = items.map((item: any) => {
			if (item.key === value) {
				item.checked = checked;
			}
			return item;
		});
		onChange(newItems);
	}

	return (
		<div className="filter-menu__item">
			<label>{label}</label>
			<div className="filter-menu__item--checkboxes">
				{items.map((item: any, index: number) => {
					return (
						<label className="checkbox" key={`filter-menu__item-${index}`}>
							<Checkbox
								color="secondary"
								value={item.key}
								onChange={() => onChangeHandle(item.key, !item.checked)}
								checked={item.checked}
							/>
							<span>{item.label}</span>
						</label>
					)
				})}
			</div>
		</div>
	)
}

export default CheckBoxFilterItem;