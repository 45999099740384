import React, { useEffect, useState } from "react";
import { Button, IconButton, Typography, useMediaQuery } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import "./TokenPrivacy4All.scss";
import TokenPrivacyTable from "../../Token/ShareTokenPrivacy/TokenPrivacyTable";
import { TokenPrivacyProvider } from "../../Token/ShareTokenPrivacy/TokenPrivacyContext";
import TokenPrivacy4AllSubmit from "./TokenPrivacy4AllSubmit";
import { t } from "i18next";
import { useTheme } from "@emotion/react";
import { connect } from "react-redux";
import { standPost } from "../../../pages/Standalone/Standalone";
import { MobileScrollbar } from "../../CustomScrollbar";

const TokenPrivacy4All = (props: any) => {
  const { label, publicInformation, standalone } = props;
  const _token = {
    privacySettings: { token: publicInformation?.tokenPrivacySettings || {} },
    token: publicInformation?.token || {},
    documents: [],
  };
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpenModal(false);
    if (standalone) {
      standPost({ action: 'cancel' });
    }
  };

  useEffect(() => {
    if (standalone) {
      setOpenModal(true);
    }
  }, [standalone]);

  return (
    <>
      {!standalone && <Button
        color="secondary"
        variant="contained"
        onClick={() => setOpenModal(!openModal)}
        aria-label="privacy"
        title="token privacy settings"
      >
        <Typography component="span" variant="body1" sx={{ mr: 2 }}>
          {label || t("pages:token.privacy")}
        </Typography>
        <FontAwesomeIcon icon={solid('gear')} />
      </Button>}
      <Dialog className="token__share dialog" open={openModal} onClose={handleClose} fullScreen={fullScreen}>
        <DialogTitle>
          {t("pages:token.privacy_settings.title")}
          <IconButton aria-label="close" onClick={handleClose}>
            <FontAwesomeIcon icon={regular("circle-xmark")} />
          </IconButton>
        </DialogTitle>

        <TokenPrivacyProvider>
          <MobileScrollbar>
            <DialogContent className="token__share--main">
              <TokenPrivacyTable token={_token} />
            </DialogContent>
          </MobileScrollbar>
          <DialogActions
            sx={{ m: "0 auto" }}
            className="flex flex--vertical align--center flex--justify-center"
          >
            <TokenPrivacy4AllSubmit token={_token} />
          </DialogActions>
        </TokenPrivacyProvider>
      </Dialog>
    </>
  );
};
export default connect(state => {
  const wallet = state?.wallet;
  return {
    currentUserEmail: wallet?.private?.email?.address,
    publicInformation: wallet.public,
    isOperatorUser: wallet.isOperatorUser,
  }
})(TokenPrivacy4All);
