import React from 'react';
import { Checkbox, TableCell, TableRow } from "@mui/material";
import { usePrivacy, usePrivacyDispatch } from "./TokenPrivacyContext";

const TokenPropertyRow = (props: any) => {
	const { name, permId, immutable, padding, childs } = props
	const privacySettings = usePrivacy();
	const dispatch = usePrivacyDispatch();
	const groupModifier = '';// childs?.length > 0 ? 'group' : '';

	const keys = !!childs ? childs[0].split('.') : permId.split('.');

	const permSettingValue = (keys: string[]) => {
		return keys.reduce((obj: any, key: string) => {
			if (obj && key in obj) {
				return typeof (obj[key]) == 'string'
					? (obj[key] === 'public' ? 'public' : 'private')
					: obj[key];
			}
			return 'private'; // or return a default value
		}, privacySettings);
	};
	let perm = !!childs
		? (childs.map((e: any) => permSettingValue(e.split('.'))).filter((e: any) => e === 'public').length === childs.length ? 'public' : 'private')
		: permSettingValue(keys);

	const onChange = (selected: boolean) => {
		const perm = selected ? 'public' : 'private';

		let update: any = [];
		if (!!childs) {
			childs.forEach((item: string) => {
				update.push({
					key: item,
					perm
				})
			});
		} else {
			update = [{
				key: permId,
				perm
			}];
		}
		dispatch({
			type: 'update_perm',
			data: update
		})
	};

	return (
		<TableRow className={`table__row table__row-item ${groupModifier}`} >
			<TableCell sx={{ pl: 3 + 3 * (padding || 0) }} >{name}</TableCell>
			<TableCell align="center" key={`property-permision-${permId}`} padding="checkbox">
				{!immutable &&
					<Checkbox
						size="small"
						color="primary"
						checked={perm === 'public'}
						onChange={(evt, selected) => onChange(selected)}
					/>
				}
			</TableCell>
		</TableRow>
	)
}

export default TokenPropertyRow;