import { Alert, Fade } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

const MessageBox = (props) => {
	const { type = "success", children, callback, timeout = 1500 } = props;
	const [show, setShow] = useState(true);
	const container = useRef();
	const timeoutId = useRef();


	const hideMessageHandle = useCallback(() => {
		if ( !!timeoutId.current ) {
			clearTimeout(timeoutId.current);
			timeoutId.current = false;
		}
		if (!show) return;
		timeoutId.current = setTimeout(() => {
			setShow(false);
			typeof callback === 'function' && callback();
		}, timeout)
	}, [timeout, callback, show]);

	useEffect(() => {
		const htmlEl = container.current;

		const observer = new MutationObserver(function () {
			if (!!htmlEl && htmlEl.style.visibility === "hidden") {
				// doSomething
				htmlEl.style.height = "0px";
			} 
		});
		!!htmlEl && observer.observe(htmlEl, { attributes: true, childList: false });

		return () => {
			htmlEl.style.height = "auto";
		}
	}, []);

	hideMessageHandle();
	return (
		<Fade ref={container} appear={false} in={!!show} >
			<Alert sx={{ mb: 2 }} severity={type} variant="outlined">
				{children}
			</Alert>
		</Fade>
	);
};

export default MessageBox;
