import { useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Hidden,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import { documentPathTypes, newTokenStatus } from '../../../configs/constant';
import ImageProxy, { proxySrcReplace } from '../../Gallery/ImageProxy';
import { t } from 'i18next';
import { timeSince } from '../../../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTokenStatus } from './TokenList';

const Placeholder = () => {
  return (
    <Card sx={{ mt: 1, p: '0.618em' }} className='token'>
      <CardHeader
        action={<></>}
        title={
          <Skeleton sx={{ bgcolor: 'var(--unikbase-gray)', maxWidth: '100%' }} variant='text' width='70%' height={30} />
        }
      />
      <CardMedia component='div' sx={{ width: 'auto' }}>
        <Skeleton sx={{ bgcolor: 'var(--unikbase-gray)' }} variant='rounded' width='100%' height={100} />
      </CardMedia>
      <CardContent sx={{ p: '0!important' }}>
        <Skeleton sx={{ bgcolor: 'var(--unikbase-gray)', maxWidth: '100%' }} variant='text' width='100%' height={24} />
        <Skeleton sx={{ bgcolor: 'var(--unikbase-gray)', maxWidth: '100%' }} variant='text' width='100%' height={24} />
        <Skeleton sx={{ bgcolor: 'var(--unikbase-gray)', maxWidth: '100%' }} variant='text' width='100%' height={24} />
      </CardContent>
    </Card>
  );
};

const ItemProperty = ({ value, label = '', className = '' }) => {
  return (
    <Typography component='div' className={`token__property ${className}`}>
      {label && <span className='label'>{label}</span>}
      {value}
    </Typography>
  );
};

const Item = (props) => {
  const navigate = useNavigate();
  const { data, currentUserEmail, isOperatorUser, isOperator, isSelected, onSelectChange } = props;
  const token = data.token;
  const coverImage = data.documents.find((document) => document.path === documentPathTypes.COVER_IMAGE);

  const tokenStatus = getTokenStatus(token.status, currentUserEmail, data.client, token.ownership, token, false);

  const ownership =
    token.ownership === newTokenStatus.OWNED.toLowerCase() ? newTokenStatus.OWNED : newTokenStatus.MANAGED;

  return (
    <Card sx={{ mt: 1, p: '0.618em' }} className='token'>
      <CardMedia className='token__thumbnail' component='div' sx={{ width: 'auto' }}>
        <ImageProxy
          key={`token-thumbnail-${token.uuid}`}
          url={coverImage ? coverImage.fileUrl : ''}
          width='250'
          height='250'
        />
        {false && (
          <IconButton className='token__favorite'>
            <FontAwesomeIcon icon={solid('star')} />
          </IconButton>
        )}
        {(!!isOperatorUser || !!isOperator) && (
          <Checkbox
            color='secondary'
            checked={isSelected}
            inputProps={{
              'aria-labelledby': token.uuid,
            }}
            onChange={(evt) => onSelectChange(evt.target.checked)}
          />
        )}
      </CardMedia>
      <CardContent sx={{ p: '0!important' }}>
        <Box className={`token__operator-status token__operator-status--${ownership.toLowerCase()}`} sx={{ mt: 1 }}>
          {ownership}
        </Box>
        <Typography component='div' className='token__details'>
          <ItemProperty className='token__name' value={token.name} label={t('common:digital_item')} />
          <ItemProperty className='token__uuid' value={token.uuid} label={'UUID'} />
          {!!token?.tokenMarkReference && (
            <ItemProperty
              className='token__tokenMarkReference'
              value={token.tokenMarkReference}
              label={t('pages:token_list.column_labels.tag_ref')}
            />
          )}
          <ItemProperty
            className='token__origin_value'
            value={isOperator || isOperatorUser ? token.ownerEmail : t('pages:token.token_origin_value')}
            label={
              isOperator || isOperatorUser
                ? t('pages:token_list.column_labels.owner')
                : t('pages:token_list.column_labels.operator')
            }
          />
          <ItemProperty
            className='token__lastUpdate'
            value={timeSince(token.lastUpdate)}
            label={t('pages:token_list.column_labels.date')}
          />
          <ItemProperty
            className={''}
            value={
              <>
                <span
                  className={`token__status token__status--${tokenStatus.toLowerCase()} ${
                    !tokenStatus //|| tokenStatus.toLowerCase() === newTokenStatus.OWNED.toLowerCase()
                      ? 'token__status--empty'
                      : ''
                  }`}
                >
                  {!tokenStatus //|| tokenStatus.toLowerCase() === newTokenStatus.OWNED.toLowerCase()
                    ? '_'
                    : t(`pages:token_list.statuses.${tokenStatus.toLowerCase()}`)}
                </span>
              </>
            }
            label={t('pages:token_list.column_labels.status')}
          />
        </Typography>
      </CardContent>

      <CardActions>
        <IconButton className='token__link' onClick={() => navigate(`/token/${token.uuid}`)} variant='text'>
          <Typography variant='body2' flex={1} textAlign='left'>
            {t('common:see_details')}
          </Typography>
          <FontAwesomeIcon icon={solid('chevron-right')} />
        </IconButton>
      </CardActions>
    </Card>
  );
};

const GridView = (props) => {
  const { currentUserEmail, isOperatorUser, isOperator, fetching = true, tokens = [] } = props;
  const [selectedTokens, updateSelectedTokens] = useState([]);

  if (!fetching && tokens.length <= 0) return null;

  const toggleSelectAll = () => {
    if (selectedTokens.length !== tokens?.length) {
      updateSelectedTokens(tokens?.map((item) => item.token.uuid));
    } else {
      updateSelectedTokens([]);
    }
  };

  return (
    <Box className='tokens--grid-view'>
      <Hidden smUp>
        <Box className='tokens--header-toggle-all' py={2}>
          <Typography
            variant='body2'
            component='span'
            color='GrayText'
            dangerouslySetInnerHTML={{
              __html: t('pages:wallet.you_have_count_digital_passports', { count: tokens?.length }),
            }}
          ></Typography>
          <FormControlLabel
            key={`token-toggle-select-all`}
            control={
              <Checkbox
                checked={selectedTokens.length === tokens?.length}
                name={'token_select_all'}
                onChange={toggleSelectAll}
              />
            }
            label={t('common:select_all')}
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
          />
        </Box>
      </Hidden>
      {!!fetching && (
        <>
          <Placeholder />
          <Placeholder />
          <Placeholder />
          <Placeholder />
          <Placeholder />
          <Placeholder />
          <Placeholder />
          <Placeholder />
        </>
      )}
      {!fetching &&
        tokens.length &&
        tokens.map((token) => (
          <Item
            key={`token-${token.token.uuid}`}
            data={token}
            currentUserEmail={currentUserEmail}
            isOperatorUser={isOperatorUser}
            isOperator={isOperator}
            isSelected={selectedTokens.length === tokens?.length || selectedTokens.includes(token.token.uuid)}
            onSelectChange={(checked) => {
              if (checked) {
                if (!selectedTokens.includes(token.token.uuid))
                  // to avoid duplicate
                  updateSelectedTokens([...selectedTokens, token.token.uuid]);
              } else {
                updateSelectedTokens(selectedTokens.filter((item) => item !== token.token.uuid));
              }
            }}
          />
        ))}
    </Box>
  );
};

export default connect((state) => {
  const wallet = state.wallet;

  return {
    currentUserEmail: wallet?.private?.email?.address,
    partnerCode: wallet?.partnerCode,
    isOperatorUser: wallet?.isOperatorUser,
    isOperator: wallet?.isOperator,
    operator: wallet?.operator,
  };
})(GridView);
