import config from "../../configs/config";

export const proxyS3UrlGeneratorWithKey = (fileKey, width = '', height = '') => {
	let key = 'insecure';
	let size = 'raw:1';(!!width || !!height) && (size = `rs:fit:${width}:${height}`);
	
	if ( fileKey.startsWith('http') ) {
		return fileKey
	}
	let s3Url = `plain/s3://${config.SCW_BUCKET_NAME}/${encodeURIComponent(fileKey)}`;
	let url = `${config.IMGPROXY_SERVER_ADDRESS}${key}/${size}/${s3Url}`;
	return url;
}

export const proxySrcReplace = (url, width = '', height = '') => {
	let size = 'raw:1'; //Default
	const match = url.match(/(.*)\/meveo\/+files\/token-documents\/(.+)\/([^\/]+\.[^\/]+)$/);
	if ( !!match && typeof match[2] !== 'undefined' && typeof match[3] !== 'undefined' ) {
		(!!width || !!height) && (size = `rs:fit:${width}${height?`:${height}`:''}`);
		let file = `${match[2]}/${encodeURIComponent(match[3])}`;

		return `${match[1]}/image/insecure/${size}/plain/local:///${encodeURIComponent(file)}`;
	}
	// Catch for 5 group
	// 1 is origin endpoint of image proxy server
	// 2 is the current size setting
	// 3 is bucket name
	// 4 is source path of image
	// 5 is filename

	const regexpS3 = new RegExp(`(.*)\/([^\/]+)\/plain\/s3\:\/\/([^\/]+)\/(.+)\/([^\/]+\.[^\/]+)$`);
	const s3Match = url.match(regexpS3);
	if ( !!s3Match && !!s3Match[1] && !!s3Match[3] && !!s3Match[4] && !!s3Match[5] ) {
		(!!width || !!height) && (size = `rs:fit:${width}${height?`:${height}`:''}`);
		let s3_link = encodeURIComponent(`s3://${s3Match[3]}/${s3Match[4]}/${encodeURIComponent(s3Match[5])}`);
		return `${s3Match[1]}/${size}/plain/${s3_link}`;
	}

	return url;
}

const ImageProxy = ({ url, alt, width = '', height = '', style = {}}) => {
	let classes = ['image']
	if ( !url ) return <></>
	// so basically in the images url : replace `/meveo/files/tocken-documents/` by `/image/insecure/raw:1/plain/local:///` to display full size and `/image/insecure/rs:fit:<width>:<height>/plain/local:///`  
	const proxySrc = proxySrcReplace(url, width, height);

	return (
		<img src={proxySrc} alt={alt} className={classes.join(' ')} width={width} height={height} style={style} />
	)
}

export default ImageProxy;