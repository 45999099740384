import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormField, validFormField } from "../../Form/FormField";
import { useSendTokenMessageMutation } from "../../../service/api/meveoApi";
import { MessageBox } from "../../Animation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { toast } from "react-toastify";
import { useSendMessageContext } from "./SendMessageContext";

const SendMessage = (props) => {
	const state = useSendMessageContext();
	const tokens = state?.tokens || [];

	const [open, toggleDialog] = useState(false);
	const [postMessage, { data: result, isLoading, isError, reset }] = useSendTokenMessageMutation();
	
	const initData = useMemo(() => ({
		subject: {
			id: "subject",
			label: t("common:subject"),
			errors: [],
			valid: [
				["^.{3,}$", "Required"]
			],
			value: "",
			fullWidth: true
		},
		message: {
			id: "message",
			label: t("common:message"),
			type: "multiline",
			errors: [],
			valid: [
				[".{3,}", "Required"]
			],
			value: "",
			fullWidth: true,
		},
	}), []);

	const [data, setFormData] = useState(initData);

	const handleChange = (event, value, id) => {
		let fieldId = !!event ? event?.target?.name : id;
		let fieldValue = !!event ? event?.target?.value : value;

		if (!fieldId) return;

		let updateData = data[fieldId];
		if (!updateData) return;

		const fieldErrors = validFormField(fieldId, fieldValue, data);
		updateData.value = fieldValue;
		updateData.errors = fieldErrors;

		setFormData({
			...data,
			[fieldId]: updateData,
		});
	};

	const sendMessage = () => {
		let isValid = true;
		let updateData = JSON.parse(JSON.stringify(data))
		for (let id in updateData) {
			let fieldErrors = validFormField(id, updateData[id].value, updateData);
			updateData[id].errors = fieldErrors;
			isValid = isValid && fieldErrors.length <= 0;
		}
		setFormData(updateData)
		if ( isValid ) {
			postMessage({
				data: {
					subject: updateData['subject'].value,
					message: updateData['message'].value,
					tokens: tokens
				}
			})
		}
	}

	const handleClose = () => {
		toggleDialog(false)
	}

	useEffect(() => {
		if ( !isLoading && !!result ) {
			if ( result.status === 'success' ) {
				toast.success('Message was sent', {
					autoClose: 1000
				});
			}  else {
				toast.error("Sending message error, please contact developer")
			}
			reset();
		}
		toggleDialog(false);
		setFormData(initData);
	}, [isLoading, result, setFormData, initData, reset]);


	if ( tokens.length <= 0 ) return null;

	return (
		<>
			<Button
				disabled={tokens.length <= 0}
				onClick={() => toggleDialog(!open)}
				size="medium" 
				color="secondary" 
				variant="outlined"
			>
				<span 
					style={{
						backgroundColor: `var(--unikbase-${tokens.length > 0 ? 'primary' : 'disabled'})`,
						marginRight: "8px",
					}} className="icon icon--send-message"></span>
				{t('pages:wallet.send_message')} {tokens.length > 0 && `(${tokens.length})`}
			</Button>

			<Dialog open={!!open} className="dialog" onClose={handleClose}>
				<DialogTitle>
				{t('pages:wallet.send_message')}
					<IconButton aria-label="close" onClick={handleClose}>
						<span className="icon icon--close"></span>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Box sx={{ mb: 2 }}>
						<Typography variant="body">{t('pages:wallet.you_will_send_message', { num: tokens.length })}</Typography>
					</Box>
					
					<fieldset disabled={!!isLoading}>
					{Object.values(data).map((field) => (
						<FormField
							fieldId={field["id"]}
							key={`form-field-${field["id"]}`}
							value={field["value"]}
							onChange={handleChange}
							label={field.label}
							errors={field.errors}
							className={`form__field form__field-${field["id"]} ${
								!field.fullWidth ? "w--50" : ""
							}`}
							fullWidth={field.fullWidth || false}
							type={field.type || "default"}
							options={field.options || false}
						/>
					))}
					</fieldset>
					{!!isError && (
						<MessageBox type="error">{t('pages:wallet.send_message_error')}</MessageBox>
					)}
				</DialogContent>
				<DialogActions>
					<Button 
						disabled={!!isLoading || !data?.message?.value} 
						onClick={sendMessage} 
						variant="contained" color="secondary" size="medium"
						sx={{
							width: "284px"
						}}
					>
						{t('pages:wallet.send_message')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default SendMessage;