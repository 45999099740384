import { useState } from "react";
import { Box, Tabs, Tab, Button, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { PlatformLayout } from "..";
import "./PageTabsLayout.scss";

const PageTabsLayout = ({ pages, children, className = "" }) => {
	const [value, setValue] = useState(0);

	return (
		<PlatformLayout className={`page--tabs ${className} ${typeof pages[value] !== 'undefined' && pages[value] ? `page--tab-${pages[value]?.path}`: ''}`} pageTitle={`More`}>
			<Box className="tabs">
				{pages.map((item, idx) => (
					<Button
						onClick={() => setValue(idx)}
						key={`page-${idx}`}
						className={`tabs__selector ${value === idx && "active"}`}
					>
						{!!item.icon && <span className={`icon icon--${item.icon}`}></span>}
						{item.menu_title || item.title}
					</Button>
				))}
			</Box>

			{!!pages[value] && (
				<Card className="page__content"  sx={{ borderRadius: 0 }}>
					<CardContent>
						<Typography className="tab__title" sx={{ mb: 2 }} component="h3" variant="h6">{pages[value].title}</Typography>

						{pages[value].element}
					</CardContent>
				</Card>
			)}
		</PlatformLayout>
	);
};

export default PageTabsLayout;
