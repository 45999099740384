import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../components/Logo";
import VerificationForm from "./VerificationForm";

import "./Verification.scss";
import VerifiedChecker from "./VerifiedChecker";
import { Card, CardContent, Link, Typography } from "@mui/material";
import OTPChecker from "./OTPChecker";
import { persistor } from "../../store/rootReducer";
import { logout } from "../../store/slices/authSlice";
import { t } from "i18next";
import SeedPhraseReveal from "./SeedPhraseReveal";

const Verification = (props) => {
	const params = useParams();
	const { userLogout } = props;
	const navigate = useNavigate();
	const type = params.type;

	const logoutHandle = () => {
		userLogout();
		return navigate("/");
	};

	const render = () => {
		switch(type) {
			case 'seedPhrase':
				return <SeedPhraseReveal />
			default:
				return (
					<>
						<VerificationForm type={type} />
						{/* <OTPChecker type={type} /> */}
					</>
				)
		}
	}
	return (
		<div className="site single verification flex flex--vertical flex--justify-start flex--grow">
			<div
				style={{ height: "100%", textAlign: "center" }}
				className="container flex flex--vertical flex--align--center flex--grow"
			>
				<Logo className="logo--short" style={{ height: "70px" }} />

				<VerifiedChecker type={type}>
					<main className="container flex flex--vertical flex--justify-center flex--align-center flex--grow">
						<Card
							sx={{
								width: "90vw",
								maxWidth: "600px",
							}}
						>
							<CardContent sx={{ p: "2em" }}>
									{render()}
									<Typography sx={{ mt: 2 }} component="div" variant="subtitle2">
										<Link onClick={() => logoutHandle()}>{t("menu:logout")}</Link>
									</Typography>
							</CardContent>
						</Card>
					</main>
				</VerifiedChecker>
			</div>
		</div>
	);
};


export default connect(
	null,
	(dispatch) => {
		return {
			userLogout: () => {
				persistor.purge();
				dispatch(logout());
			}
		};
	}
)(Verification);
