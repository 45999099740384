
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { toast } from 'react-toastify';

import api from "../../../configs/api";
import { t } from 'i18next';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';


const PaymentForm = ({ onGoToPrevStep, clientSecret, accessToken, onClose, emailAddress, amount }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [isUpdating, setIsUpdating] = useState(false)

	const onPayment = async (event) => {
		event.preventDefault();

		if (elements == null) {
			return;
		}

		const { error: submitError } = await elements.submit();
		if (submitError) {
			toast.error(submitError.message)
			return;
		}

		setIsUpdating(true)
		const { error } = await stripe.confirmPayment({
			elements,
			clientSecret,
			redirect: 'if_required',
			confirmParams: {
				payment_method_data: {
					billing_details: {
						email: emailAddress
					}
				}
			},
		});

		if (error) {
			setIsUpdating(false)
			toast.error(error.message)
		} else {
			onCapture()
		}
	};

	const onCapture = async () => {
		const paymentIntent = clientSecret.match(/pi_[0-9a-zA-Z]+/)[0];
		const res = await fetch(`${api.payment.createStripeTopup}/${paymentIntent}`, {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			}
		});

		const json = await res.json();
		if (json.status === 'success') {
			onClose()
			setIsUpdating(false)
			toast.success(t("pages:wallet.topup_success"))
		} else {
			onClose()
			setIsUpdating(false)
			toast.error(t("pages:wallet.topup_error"))
		}
	}

	return (
		<>
			<DialogContent>
				<p className="form__amount">{`${t("common:amount")}:  ${+amount} EUR`}</p>
				<PaymentElement />
			</DialogContent>
			<DialogActions>
				<Button onClick={onGoToPrevStep} size="medium" variant="text" disabled={isUpdating}>
					{t("common:back")}
				</Button>
				<LoadingButton
					type="submit"
					size="small"
					variant="contained"
					color="secondary"
					onClick={onPayment}
					loading={isUpdating}
					disabled={isUpdating}
				>
					{t("common:topup")}
				</LoadingButton>
			</DialogActions>
		</>
	);
}

export default PaymentForm;