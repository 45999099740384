import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"; // React-specific entry point to allow generating React hooks
import config from "../../configs/config";
import api from "../../configs/api";
import { base64, queryParamsURLEncodedString } from "../../utils/helpers";
import { fetchAuthToken } from "../../store/actions/authActions";

export const prepareBasicAuthToken = async () => {
	// Quick fix for get authentication while login with email
	if ( !config.BASIC_AUTH ) return;
	const [username, password] = config.BASIC_AUTH.split(':');
	const token = await fetchAuthToken(username, password);
	if ( token && token.access_token ) {
		return token.access_token;
	}
	return false;
}

export const meoveoRestApi = createApi({
	reducerPath: "meoveoRestApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${config.SERVER_ADDRESS}/meveo/rest/`,
		prepareHeaders: async (headers, { getState }) => {
			const token = getState().auth.accessToken;
			const current = headers.get('authorization');
			const basicAuth = headers.get('x-basic-auth');
			if ( !current ) {
				if (token && !basicAuth) {
					headers.set("authorization", `Bearer ${token}`);
				} else {
					let newToken = await prepareBasicAuthToken();
					if ( newToken && !basicAuth ) {
						headers.set("authorization", `Bearer ${newToken}`);
					} else {
						headers.set("authorization", `Basic ${base64(config.BASIC_AUTH)}`);
					}
				}
			}
			return headers;
		}
	}),
	tagTypes: ["Token", "Documents", "Transaction"],
	endpoints: (builder) => ({
		getWallet: builder.query({
			query: (args) => ({
				url: "user-wallet-info",
				method: "GET",
			}),
		}),
		getManagedOperatorUsers: builder.query({
			query: (args) => {
				return {
					url: 'operator-user',
					method: "GET",
				}
			},
		}),
		getOperatorDetail: builder.query({
			query: (args) => {
				const url = args?.operator_code ? `operator-info/${args.operator_code}` : 'operator/operator-details';
				return {
					url: url,
					method: "GET",
				}
			},
		}),
		getUsernameByEmail: builder.query({
			query: (email) => ({
				url: `${api.user.usernameByEmail}/${email}`  
			})
		}),
		getWalletAddressByEmail: builder.query({
			query: (email) => ({
				url: `wallet-address/${email}`,
				method: "GET"
			})
		}),
		getTokenList: builder.query({
			query: (args) => {
				let url = "unikbase-token"
				if (args && Object.keys(args).length > 0) {
					url += `?${queryParamsURLEncodedString(args)}`
				}
				return ({
					url,
					method: "GET",
				})
			},
			providesTags: ["Token", "Tokens"],
		}),
		archiveTokens: builder.mutation({
			query: ({ tokenIds }) => {
				let url = api.wallet.archive_token;
				if (tokenIds && tokenIds.length > 0) {
					url += `?${queryParamsURLEncodedString({ tokens: tokenIds })}`
				}
				return ({
					url,
					method: "PUT",
				})
			},
			providesTags: ["Token", "Tokens"],
		}),
		updateToken: builder.mutation({
			query: (args) => {
				let headers = {
					'Content-Type': 'application/json'
				};
				if ( !!args.access_token ) {
					headers.authorization = `Bearer ${args.access_token}`;
				}
				return {
					url: "unikbase-token",
					headers: headers,
					method: "PUT",
					body: JSON.stringify(args)
				}
			},
			invalidatesTags: (result, error, args) => {
				if( !!args?.editToken ) {
					return ["Token", "Documents"]
				}
				if( !!args?.isUploadDocument ) {
					return ["Token", "Documents"]
				}
				return ["Token"]
			}
		}),
		changeTokenCoverImage: builder.mutation({
			query: ({ tokenID, documentId }) => ({
				url: api.token.updateCoverImage(tokenID),
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ documentId })
			}),
			invalidatesTags: ["Token"],
		}),
		// May merge this with tokenList query ?
		getTokenById: builder.query({
			query: (args) => {
				const { id, mode } = args;
				let url = `unikbase-token`;
				let headers = {};

				switch (mode) {
					case "share":
						url += `/shared?shareHash=${id}`;
						break;
					case "public":
						url += `/${id}?mode=public`;
						headers = { 'x-basic-auth': true }
						break;
					case "default":
					default:
						url += `/${id}`;
						break;
				}

				return {
					url: url,
					method: "GET",
					headers
				};
			},
			providesTags: (result, error, args) => {
				if ( !!args?.isGetDocuments ) {
					return ["Documents"]
				}
				return ["Token"];
			},
			refetchOnMountOrArgChange: (oldArgs, newArgs) => {
        // Condition to redirect instead of refetching
        console.log(oldArgs, newArgs)
        return true; // Default behavior: refetch
      },
		}),
		getMarkproofsVerifications: builder.query({
			query: (args) => {
				let params = {
					status: args.status || 'ALL' 
				};

				const url = new URL(`${config.SERVER_ADDRESS}/meveo/rest/${api.methods.token.markProofs}`);

				if ( args.prefix ) {
					params['markproofPrefix'] = args.markproofPrefix;
				}
				if ( args.tokenId ) {
					params['tokenId'] = args.tokenId;
				}
				
				url.search = new URLSearchParams(params);

				return {
					url: url.toString(),
					method: "GET"
				}
			},
			providesTags: ["Markproof"]
		}),
		updateMarkproofVerification: builder.mutation({
			query: (args) => {
				const { tokenID, body } = args;
				const url = `${api.methods.token.markProofs}`
				return {
					url,
					method: "PUT",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify(body)
				}
			},
			invalidatesTags: ["Markproof"]
		}),
		updateTokenById: builder.mutation({
			query: (args) => {
				const { tokenID, body } = args;
				const url = `${api.wallet.token}/${tokenID}/${api.methods.token.privacyUpdate}`;
				return {
					url: url,
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(body),
				};
			},
			invalidatesTags: (result, error, args) => {
				if (args && args.invalidate === false) return [];
				return ["Token"];
			},
		}),
		transferToken: builder.mutation({
			query: ({ tokenID, receiver, delay = 9, token, transactionHash }) => {
				return {
					url: `${api.token.transfer}/${tokenID}`,
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						transferAcceptDelay: parseInt(delay < 1 ? 1 : delay > 9 ? 9 : delay),
						client: receiver,
						transactionHash: transactionHash || null
					}),
				}
			}
		}),
		cancelTransferToken: builder.mutation({
			query: ({ tokenID, transactionHash }) => ({
				url: `${api.token.cancelTransfer}/${tokenID}`,
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ transactionHash })
			}),
			invalidatesTags: ["Token"],
		}),
		refuseTransferToken: builder.mutation({
			query: ({ tokenID, transactionHash = null }) => ({
				url: `${api.token.refuseTransfer}/${tokenID}`,
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ transactionHash }) 
			}),
			invalidatesTags: ["Tokens"]
		}),
		acceptTransferToken: builder.mutation({
			query: ({ tokenID, transactionHash = null }) => ({
				url: `${api.token.acceptTransfer}/${tokenID}`,
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ transactionHash }) 
			}),
			invalidatesTags: ["Token"],
		}),
		getPartnerCode: builder.query({
			query: () => ({
				url: "partner-code",
				method: "GET",
			}),
		}),
		getTokenTransactions: builder.query({
			query: (args) => {
				const { filters = {}, orderBy = 'creationDate', order = 'DESCENDING', tokenId = false } = args;
				const { type = false, startDate = false, endDate = false } = filters;
				const requestData = {
					order,
					orderBy,
				}

				!!tokenId && (requestData['tokenId'] = tokenId);
				let url = `${api.token.history}?${queryParamsURLEncodedString(requestData)}`;

				if ( !!type ) {
					for(let key in type) {
						if ( type[key] === true ) {
							url += `&filter=${encodeURIComponent(key)}`
						}
					}
				}
				!!startDate && !!endDate && (url += `&startDate=${startDate}&endDate=${endDate}`);

				return {
					url: url,
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				};
			},
			providesTags: ["Transaction"],
		}),
		deleteTokenTransactionById: builder.mutation({
			query: (args) => {
				const { id } = args
				let url = `${api.token.history}/${id}`;
				return {
					url,
					method: 'DELETE'
				}
			},
			invalidatesTags: ["Transaction"]
		}),
		getTransactionsByEthScan: builder.query({
			query: (args) => {
				const { walletAddress } = args;
				let params = {
					module : 'account', 
					action : 'balancehistory',
					address: walletAddress,
					tag: 'latest',
					apikey: 'toto'
				}

				const url = `${api.token.ethscan}?${queryParamsURLEncodedString(params)}`;
				return {
					url: url,
					method: "GET",
					headers: {
						"Content-Type": "application/json"
					}
				}
			}
		}),
		requestStripeTopup: builder.mutation({
			query: ({ data }) => ({
				url: api.payment.createStripeTopup,
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data)
			})
		}),
		getOperatorPricingPlan: builder.query({
			query: (operator_code) => ({
				url: `unikbase-priceplan${operator_code ? `/${operator_code}` : ''}`,
				method: "GET"
			})
		}),
		sendTokenMessage: builder.mutation({
			query: ({ data }) => ({
				url: api.token.sendMessage,
				method: 'POST',
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(data)
			})
		}),
		getUserOTPStatus: builder.query({
			query: ({ type = 'email', verifyId }) => ({
				url: `otpStatus/${verifyId}`
			})
		}),
		requestUserOTP: builder.query({
			query: ({ type, verifyId }) => ({
				url: `${type === 'email' ? 'emailOtp' : 'otp'}/${verifyId}`,
				responseHandler: response => {
					const contentType = response.headers.get("content-type");
					if (contentType && contentType.indexOf("application/json") !== -1) {
						return response.json(); 
					}
					return response.text();
				}
			}),
		}),
		verifyOTP: builder.mutation({
			query: ({ otp, verifyId }) => ({
				url: `verifyOtp/${verifyId}`,
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Basic ${base64(config.BASIC_AUTH)}`,
				},
				body: JSON.stringify({ otp }),
				responseHandler: response => {
					const contentType = response.headers.get("content-type");
					if (contentType && contentType.indexOf("application/json") !== -1) {
						return response.json();
					}
					return response.text();
				}
			})
		}),
		updateTPKOpertor: builder.mutation({
			query: ({ data, operator_code, token, is_anonymous = false, is_update = false }) => {
				const method = !!is_update ? 'PUT' : 'POST';
				let headers = {
					"Content-Type": "application/json"
				}
				headers.authorization = false;

				return {
					url: `${api.wallet.tpk_user}/${operator_code}`,
					method,
					headers,
					body: JSON.stringify(data)
				}
			},
			transformResponse: (response, meta, args) => {
				// Customize the payload here

				if ( response?.status === 'success' ) {
					return {
						username: args.username,
						password: args.password,
						...response
					}
				}
				return response
			},
		}),
		updateWallet: builder.mutation({
			query:({ data }) => ( {
				url: api.wallet.update,
				method: 'POST',
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					...api.basicMethod,
					method: api.methods.walletUpdate,
					params: data,
				})
			})
		}),
		createVerifiablePresentation: builder.mutation({
			query: ({data}) => ({
				url: api.token.createVerifiablePresentation,
				method: 'POST',
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(data)
			})
		}),
		deactivateVerifiablePresentation: builder.mutation({
			query: ({vpUuid}) => ({
				url: api.token.deactivateVerifiablePresentation(vpUuid),
				method: 'DELETE',
			})
		}),
		listVerifiablePresentation: builder.query({
			query: ({tokenId, offset, limit}) => ({
				url: api.token.listVerifiablePresentation(tokenId, offset, limit),
				method: 'GET',
			})
		}),
		getVerifiablePresentation: builder.query({
			query: ({vpUuid}) => ({
				url: api.token.getVerifiablePresentation(vpUuid),
				method: 'GET',
				headers: { 'x-basic-auth': true }
			})
		}),
		getVerifiablePresentationTransactionHistory: builder.mutation({
			query: ({ data }) => ({
				url: api.token.getVerifiablePresentationTransactionHistory,
				method: 'POST',
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(data)
			})
		}),
		retrieveDocument: builder.query({
			query: ({ tokenId, documentId }) => ({
				url: api.document.retrieveDocument(tokenId, documentId),
				method: "GET"
			})
		}),
		updateOnboardingSale: builder.mutation({
			query: ({ data, access_token }) => {
				let headers = {
					"Content-Type": "application/json"
				}
				if ( !!access_token ) 	{
					headers.authorization = `Bearer ${access_token}`;
				}
				return {
					url: api.onboarding.sale,
					method: 'POST',
					headers: headers,
					body: JSON.stringify(data)
				}
			}
		}),
		sendEmail: builder.mutation({
			query: ({ email, operatorName }) => {
				let headers = {
					"Content-Type": "application/json"
				}
				const payload = {
					type: 'OPERATOR_USER_INVITATION',
					to: email,
					data: {
						operatorName: 'Unikbase',
						loginLink: `${config.SERVER_ADDRESS}/token-login?email=${email}&operator=${operatorName}`,
					}
				}
				return ({
					url: api.operator.sendEmail,
					method: 'POST',
					headers: headers,
					body: JSON.stringify(payload)
				})
			}
		}),
		deleteAccount: builder.mutation({
			query: (args) => {
				return {
					url: api.user.account,
					method: 'DELETE'
				}
			},
		}),
		getNonce: builder.query({
			query: (args) => ({
				url: api.wallet.getNonce,
				method: 'GET',
			})
		}),
		commitNonce: builder.mutation({
			query: (args) => {
				return {
					url: api.wallet.commitNonce,
					method: 'POST'
				}
			},
		}),
		rollbackNonce: builder.mutation({
			query: (args) => {
				return {
					url: api.wallet.rollBackNonce,
					method: 'POST'
				}
			},
		}),
	}),
});

export const {
	useGetWalletQuery,
	useGetWalletAddressByEmailQuery,
	useGetTokenListQuery,
	useGetTokenByIdQuery,
	useLazyGetTokenByIdQuery,
	useGetPartnerCodeQuery,
	useGetTokenTransactionsQuery,
	useGetMarkproofsVerificationsQuery,
	useUpdateTokenByIdMutation,
	useChangeTokenCoverImageMutation,
	useUpdateMarkproofVerificationMutation,
	useTransferTokenMutation,
	useCancelTransferTokenMutation,
	useRefuseTransferTokenMutation,
	useAcceptTransferTokenMutation,
	useUpdateTokenMutation,
	useGetTransactionsByEthScanQuery,
	useDeleteTokenTransactionByIdMutation,
	useRequestStripeTopupMutation,
	useGetUsernameByEmailQuery,
	useLazyGetUsernameByEmailQuery,
	useGetOperatorDetailQuery,
	useGetOperatorPricingPlanQuery,
	useSendTokenMessageMutation,
	useLazyRequestUserOTPQuery,
	useVerifyOTPMutation,
	useGetUserOTPStatusQuery,
	useUpdateTPKOpertorMutation,
	useUpdateWalletMutation,
	useCreateVerifiablePresentationMutation,
	useGetVerifiablePresentationQuery,
	useGetVerifiablePresentationTransactionHistoryMutation,
	useLazyRetrieveDocumentQuery,
	useUpdateOnboardingSaleMutation,
	useGetManagedOperatorUsersQuery,
	useSendEmailMutation,
	useDeleteAccountMutation,
	useArchiveTokensMutation,
	useLazyGetNonceQuery,
	useCommitNonceMutation,
	useRollbackNonceMutation,
} = meoveoRestApi;
