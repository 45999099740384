import { Box, Card, CardContent, CardHeader, Skeleton, Tabs } from '@mui/material';
import './TokenDetail.scss';

const TokenDetailLoading = () => {
	return (
		<Box className="token__information loading flex flex--horizontal flex--wrap">
			<Skeleton variant="rectangular" style={{ width: '50%', height: '350px' }} />
			
			<Box className="w--50" sx={{ pt: '0!important'}}>
				<Skeleton variant="text" style={{ width: '70%', marginTop: 0, mb: 2 }} />
				<Skeleton variant="text" style={{ width: '100%', height: '120px', transform: 'none' }} />
			</Box>
		</Box>
	)
}

export default TokenDetailLoading;