import React from 'react'
import './Loading.css'

const Loading = () => {
  return (
    <div id="loading">
      <div className="circle-square">
        <div className="red">&nbsp;</div>
        <div className="blue">&nbsp;</div>
        <div className="green">&nbsp;</div>
        <div className="yellow">&nbsp;</div>
      </div>
    </div>
  )
}

export default Loading
