import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const SingularityZoom = props => {
	const { config, image } = props;
	const [style, setStyle] = useState();
	const container = useRef()

	useEffect(() => {
		if (container.current) {
			const boundingRect = container.current.getBoundingClientRect();
			const realWidth = (boundingRect.width * 100) / (config.r * 100);

			setStyle({
				width: `${realWidth}px`,
				height: 'auto'
			});
		}
	}, [config.r]);
	return (
		<Box ref={container} className="singularity__zoom" >
			<img style={{
				...style,
				transform: `translate(-${config.x*100 - config.r*100/2}%,-${config.y*100 - config.r*100/2}%)`
			}} src={image} alt="Reference Singularity" />	
		</Box>
	)
}

export default SingularityZoom;