import React, { ReactElement, useEffect, useState } from 'react';
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { t } from "i18next";
import { toast } from "react-toastify";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { proxySrcReplace } from "../../Gallery/ImageProxy";

import "./EditTokenGallery.scss";
import { useUpdateTokenMutation } from '../../../service/api/meveoApi';
import UploadImage from '../UploadDocuments/UploadImage';
import UploadingImage from '../UploadDocuments/UploadingImage';
import { useTokenEdit, useTokenEditDispatch } from './EditTokenContext';
import { computeFileHash } from '../../../utils/helpers';

const CarouselCustomThumb = (props: any) => {
	const { src, newImage, isCoverImage = false, onToggleMore, isUploaded } = props
	const uuid = props['data-uuid'];
	const dispatch = useTokenEditDispatch();

	const onFileUploadedHandle = (filename: string, file: any) => {
		dispatch({
			type: 'add_document',
			data: file
		})
		toast.success(t('pages:token.document_details.documents_added_save_changes'));
	}

	return (
		<Box className="gallery__control">
			<img src={src} alt="Token thumbnail" />
			{!isCoverImage && (
				<IconButton onClick={onToggleMore} data-uuid={uuid}>
					<FontAwesomeIcon icon={solid('ellipsis-vertical')} size='2xs' />
				</IconButton>
			)}
			{isCoverImage && (
				<Box className="gallery__cover_image">
					{t("pages:token.cover_image")}
				</Box>
			)}
			{newImage && !isUploaded && (
				<UploadingImage key={uuid} file={newImage} onChange={onFileUploadedHandle}/>
			)}
		</Box>
	)
}

const EditTokenGallery = (props: any) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [moreId, setMoreId] = useState<any>(null);
	const [askConfirm, setAskConfirm] = useState(false);
	const [deleteDocument] = useUpdateTokenMutation();
	const [newImages, setNewImages] = useState<any>([]);

	const dispatch = useTokenEditDispatch();
	const tokenUpdate: any = useTokenEdit();

	const { token, coverImage = false, documents = [], onSetCoverImage, onDelete } = props;
	if (!coverImage && (!documents || documents.length <= 0)) {
		return null;
	}
	let images: any = [].concat(documents.filter((document: any) => !tokenUpdate.deletedDocuments.find((e: any) => e.uuid === document.uuid)));

	const options = [
		{
			key: "setAsCoverImage",
			label: t("pages:token.document_details.set_as_cover_image"),
		},
		{
			key: "delete",
			label: t("common:delete"),
		}
	];

	const onToggleMore = (event: any) => {
		setAnchorEl(event.currentTarget);
		setMoreId(event.currentTarget.getAttribute("data-uuid"));
	};

	const onCloseMoreMenu = () => {
		setAnchorEl(null);
	}

	const changeCoverImage = (uuid: string) => {
		if (onSetCoverImage) {
			onSetCoverImage(uuid);
		}
		dispatch({
			type: 'set_cover_image',
			data: { documentId: uuid },
		})
		toast.success(t('pages:token.document_details.cover_image_changed'));
	}

	const handleItemSelect = (option: any) => {
		if (option.key === 'delete') {
			const newImage = newImages.find((e: any) => e.uuid === moreId);
			if (newImage) {
				const images = newImages.filter((e: any) => e.uuid !== moreId);
				setNewImages(images);

				dispatch({
					type: 'delete_document',
					data: newImage,
				})
				toast.success(t('pages:token.document_details.deleted_document_save_changes'));
			} else {
				setAskConfirm(true)
			}
		} else {
			changeCoverImage(moreId);
		}
		onCloseMoreMenu();
	}

	const dismissConfirm = () => {
		setAskConfirm(false)
	}

	const onConfirmDelete = () => {
		dismissConfirm();
		if (onDelete) onDelete(moreId);

		if (!token || !documents || !moreId) return;

		const item = documents.find((doc: any) => doc.uuid === moreId);
		dispatch({
			type: 'delete_document',
			data: coverImage.uuid === moreId ? coverImage : item,
		})
		toast.success(t('pages:token.document_details.deleted_document_save_changes'));
	}

	const onNewFile = (file: any) => {
		computeFileHash(file.file).then((hash: string) => {
			if (coverImage?.uuid === hash
				|| tokenUpdate.addedDocuments.find((e: any) => e.uuid === hash || e.hash === hash || e.documentId === hash)
				|| documents.find((e: any) => e.uuid === hash || e.hash === hash)
				|| newImages.find((e: any) => e.uuid === hash || e.hash === hash)) {
				toast.error(t('pages:token.document_details.file_already_uploaded'));
			} else {
				file.hash = hash;
				setNewImages([file, ...newImages]);
			}
		});
	}

	return (
		<Card variant="outlined" className="gallery gallery__edit">
			<CardContent>
				<Carousel
					renderThumbs={(childrens: []) => childrens.map((thumb : ReactElement) => {
						const uuid = thumb.props.children.props['data-uuid'];
						// Search through addedDocuments to see if this image is uploaded
						const isUploaded = !!tokenUpdate.addedDocuments.find((e: any) => e.uuid === uuid)
						return <CarouselCustomThumb
								key={thumb.key} 
								{...thumb.props.children.props}
								isCoverImage={uuid === (tokenUpdate.deferredCoverImage || coverImage.uuid)}
								newImage={newImages.find((item: any) => item.uuid === uuid)}
								isUploaded={isUploaded}
								onToggleMore={onToggleMore}
							/> 
					})}
				>
					{[coverImage, ...newImages, ...images].filter(e=>!!e).map((item: any) => {
						const { src } = item;
						const proxySrc = src || proxySrcReplace(item?.fileUrl, '600', '400');
						return (
							<Box component="span" sx={{ backgroundImage: `url("${proxySrc}")` }} className="gallery__item" key={item.uuid}>
								<img style={{ display: 'none' }} src={proxySrc} alt="Token thumbnail" data-uuid={item.uuid} />
							</Box>
						);
					})}
				</Carousel>
			</CardContent>

			{!!token && <UploadImage token={token} onChange={onNewFile} />}

			<Menu
				anchorEl={anchorEl}
				open={!!moreId && Boolean(anchorEl)}
				onClose={onCloseMoreMenu}
			>
				{options.map((option) => {
					const isCoverImage = moreId === (tokenUpdate.deferredCoverImage || coverImage.uuid);
					const newImage = newImages.find((e: any) => e.uuid === moreId);

					return (isCoverImage || newImage) && option.key === 'setAsCoverImage' ? null
						: (
							<MenuItem key={option.label}
								onClick={() => handleItemSelect(option)}>
								<Box display="flex" alignItems="center" justifyContent="center">
									<Typography ml={1} color={option.key === 'delete' ? 'secondary' : 'inherit'}>{option.label}</Typography>
								</Box>
							</MenuItem>
						)
				})}
			</Menu>

			<Dialog open={!!askConfirm} onClose={dismissConfirm}>
				<DialogContent>
					<Typography variant="h6">{t("pages:token.document_details.confirm_delete_image")}</Typography>
				</DialogContent>
				<DialogActions className="flex flex--align-center flex--justify-center">
					<Button onClick={onConfirmDelete} color="secondary" variant="contained" size="small">
						{t("common:delete")}
					</Button>
					<Button onClick={dismissConfirm} color="primary" variant="outlined" size="small">
						{t("common:cancel")}
					</Button>
				</DialogActions>
			</Dialog>
		</Card>
	);
};

export default EditTokenGallery;
