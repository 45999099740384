import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Link,
	Tab,
	Tabs,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import ChangePassword from "../../components/ChangePassword";
import Header from "../../components/Header";
import { parseJwt } from "../../utils/helpers";
import LanguageSetting from "./LanguageSetting";
import { languages, unitTypes } from "../../configs/constant";

import { PlatformLayout } from "../../layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTheme } from "@emotion/react";
import UserImageSetting from "./UserImageSetting";
import UnitSettings from "./UnitSettings";
import { decryptSeedPhrase } from "../../service/crypto";
import OperatorKey from "./OperatorKey";
import TokenPrivacy4All from "../../components/Profile/TokenPrivacySettings/TokenPrivacy4All";
import ProfileSettingGroup from "./ProfileSettingGroup";

import "./Profile.scss";
import { updateWallet } from "../../store/actions/walletActions";
import { current } from "@reduxjs/toolkit";
import { ManagedUser } from "../../components/Operator";
import ConfirmDialog from "../../components/ConfirmDialog";
import { useDeleteAccountMutation } from "../../service/api/meveoApi";
import LoadingModal from "../../components/LoadingModal";
import { logout } from "../../store/slices/authSlice";
import { persistor } from "../../store/rootReducer";
import { toast } from "react-toastify";
import RevealSeedPhrase from "./RevealSeedPhrase";

const Settings = (props) => {
	const [tab, setTab] = useState(0);
	const handleChange = (event, newValue) => {
		setTab(newValue);
	};
	return (
		<Box className="flex flex--vertical">
			<LanguageSetting />
			<span style={{ marginBottom: "1em" }}></span>
			<UnitSettings />
		</Box>
	);
};


const Profile = (props) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const tab = searchParams.get('tab') || 'general';
	const { privateInformation, publicInformation, address, isOperatorUser, jwtData, updatePublicInformation, isOperator, userLogout } = props;
	const [settingTab, setActiveTab] = useState('general');
	const editable = !isOperatorUser;
	const [visibleDeleteAccount, toggleDeleteAccount] = useState(false);
	const [deleteAccount, { isLoading: deletingAccount, data: deleteAccountResult }] = useDeleteAccountMutation();

	const tabs = [
		{
			label: t("pages:profile.contact_info"),
			value: "general",
			settings: [
				{
					title: isOperatorUser ? t("pages:profile.operator_info") : t("pages:profile.contact_info"),
					data: [
						{
							label: t("common:first_name"),
							id: "firstName",
							value: publicInformation?.firstName|| ''
						},
						{
							label: t("common:last_name"),
							id: "lastName",
							value: publicInformation?.lastName || '' 
						},
						{
							label: isOperatorUser ? t("common:operator") : t("common:username"),
							value: privateInformation?.username,
							disable: true
						},
						{
							label: t("common:wallet"),
							value: address,
							disable: true
						},
						{
							label: t("common:phone"),
							value: privateInformation?.phone?.number,
							disable: true
						},
						{
							label: t("common:email"),
							value: privateInformation?.email?.address,
							disable: true
						},
					],
					onChange: (newSettings) => {
						// Save public data 
						let update = newSettings.filter(i => ['firstName','lastName'].includes(i.id)).reduce((acc, cur) => { acc[cur.id] = cur.value; return acc; }, {});
						updatePublicInformation(update);
					}
			},]
		},
		{
			label: t("pages:profile.avatar"),
			value: "avatar",	
			settings: [
				{
					title: t("pages:profile.avatar"),
					element: (
						<Box sx={{
							width: "360px",
							textAlign: "center"
						}}>
							<UserImageSetting />
						</Box>
					)
				},
			]
		},
		{
			label: t("common:password"),
			value: "password",
			settings: [
				{
					title: t("common:password"),
					data: [
						{
							label: t("common:password"),
							id: "password",
							value: "********",
						},
					],
					dialog: !!editable && {
						button: t("pages:profile.change_password"),
						modal: {
							title: t('pages:profile.change_password'),
							body: <ChangePassword />
						},
					},
				},
			]
		},
		{
			label: t("pages:profile.settings"),
			value: "settings",
			settings: [
				{
					title: t("pages:profile.settings"),
					data: [
						{
							label: t("pages:profile.language"),
							id: "language",
							value: publicInformation?.language || 'en',
							capital: true,
							options: [
								{
									label: t('pages:profile.english'),
									value: "en",
								},
								{
									label: t('pages:profile.french'),
									value: "fr",
								}
							]
						},
						{
							label: t("pages:profile.unit"),
							value: publicInformation?.unit || "",
							id: "unit",
							options: Object.values(unitTypes).map(type => ({
								label: t('pages:profile.' + type.key),
								value: type.key
							})),
							capital: true,
						},
					],
					onChange: (newSettings) => {
						// Save public data 
						let update = newSettings.filter(i => ['language','unit'].includes(i.id)).reduce((acc, cur) => { acc[cur.id] = cur.value; return acc; }, {});
						updatePublicInformation(update);
					}
				},
				{
					title: t("pages:profile.operator_key"),
					dialog: {
						title: t("pages:profile.operator_key"),
						button: t("common:view"),
						element: <OperatorKey />
					},
					hide: !editable
				},
				{
					title: t("pages:profile.reveal_seed_phrase"),
					dialog: {
						title: t("pages:profile.reveal_seed_phrase"),
						button: t("common:reveal"),
						element: <RevealSeedPhrase />
					},
					hide: !editable
				},
				{
					title: t("pages:profile.account"),
					data: [
						{
							label: t("pages:profile.account"),
							value: t("pages:profile.active"),
						},
					],
					element: (
						<Box className="settings">
							<Typography className={`setting__item setting__acount-status`} component="div" 
								sx={{ mb: 1 }}
							>
								<Typography component="label" variant="body1">
									{t("pages:profile.account")}
								</Typography>
								<Button
									variant="contained"
									color="primary"
									disabled={true}
									size="large"
								>
									<span>{t("pages:profile.active")}</span>
								</Button>

								<Link
									color="primary"
									sx={{
										marginTop: "10px",
										fontSize: "14px",
										color: "#7f7f7f"
									}}
									onClick={() => toggleDeleteAccount(true)}
								>
									{t("pages:profile.delete_account")}
								</Link>
							</Typography>
						</Box>
					)
					// dialog: !!editable && {
					// 	title: t("pages:profile.account"),
					// 	element: <DeleteAccount />,
					// },
				},
			]	
		}
	]

	const handleDeleteAccount = () => {
		deleteAccount();
	}

	useEffect(() => {
		if (!deletingAccount && deleteAccountResult) {
			if (deleteAccountResult?.status === 'success') {
				toast.success(deleteAccountResult.result);
				userLogout();
				navigate('/');
			} else {
				toast.error(t('pages:profile.delete_account_failed'));
			}
		}
	}, [deletingAccount, deleteAccountResult])

	useEffect(() => {
		if (tab) {
			setActiveTab(tab);
		}
	}, [tab]);

	const tabChangeHandle = (event, index) => {
		setActiveTab(index);
	}

	return (
		<PlatformLayout pageTitle={t('common:profile')} className="site site--single profile">
			<div className="profile__settings">
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs className="profile__settings-tabs" value={settingTab} onChange={tabChangeHandle} aria-label="basic tabs">
						{tabs.map(tab => <Tab key={`profile-setting-tab-${tab.value}`} value={tab.value} label={tab.label} />)}

						{editable && isOperator && <Tab value="managed_user" label={t("pages:profile.managed_user")} />}
					</Tabs>
				</Box>
				{tabs.map((tab, idx) => {
					return (
						<div
							role="tabpanel"
							hidden={settingTab !== tab.value}
							id={`profile-setting-tabpanel-${tab.value}`}
							aria-labelledby={`profile-setting-tab-${idx}`}
							key={`profile-setting-tab-${idx}`}
						>
							{settingTab === tab.value && !!tab.settings && (
								<Box sx={{ flexGrow: 1 }}>
									{tab.settings.map((item, idx) => !!item.hide ? null : (
										<ProfileSettingGroup
											key={`profile-setting-group-${idx}`}
											first={idx === 0}
											title={item.title}
											data={item.data}
											button={item.button || false}
											dialog={item.dialog || false}
											element={item.element}
											onSave={item?.onChange}
										/>
									))}
								</Box>
							)}
						</div>
					)
				})}

				{settingTab === 'managed_user' && 
					<div
						role="tabpanel"
						hidden={settingTab !== 'managed_user'}
						id={`profile-setting-tabpanel-managed_user`}
						aria-labelledby={`profile-setting-tab-managed_user}`}
						key={`profile-setting-tab-managed_user`}
					>
						<Box>
							<ManagedUser />
						</Box>
					</div>
				}
			</div>
			{visibleDeleteAccount && (
				<ConfirmDialog
					visible={visibleDeleteAccount}
					title={t("pages:profile.are_you_sure_to_delete_account")}
					message={t("pages:profile.delete_account_warning_message")}
					confirmLabel={t('pages:profile.delete_account')}
					cancelLabel={t('common:cancel')}
					onConfirm={() => {
						handleDeleteAccount();
						toggleDeleteAccount(false);
					}}
					onDismiss={() => {
						toggleDeleteAccount(false);
					}}
				/>
			)}

			{deletingAccount && (
				<LoadingModal
					visible={deletingAccount}
					onClose={() => null}
				/>
			)}
		</PlatformLayout>
	);
};

export default connect((state) => {
	let data = state?.wallet;
	const jwtData = parseJwt(state?.auth?.accessToken);
	const keyCloakUser = {
		username: jwtData.name,
		given_name: jwtData.given_name,
		family_name: jwtData.family_name,
		email: jwtData.email,
	}
	return {
		privateInformation: data.private,
		publicInformation: data.public,
		address: data.address,
		isOperatorUser: data.isOperatorUser,
		isOperator: data.isOperator,
		jwtData: keyCloakUser
	};
}, dispatch => {
	return {
		updatePublicInformation: (data) => {
			dispatch(updateWallet({
				public: data
			}))
		},
		userLogout: () => {
			persistor.purge();
			dispatch(logout());
		},
	}
})(Profile);
