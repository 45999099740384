import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	IconButton,
	InputBase,
	TextField,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { t } from "i18next";
import {
	meoveoRestApi,
	useCancelTransferTokenMutation,
	useGetWalletAddressByEmailQuery,
	useRefuseTransferTokenMutation,
	useTransferTokenMutation,
	useUpdateTokenByIdMutation,
} from "../../../service/api/meveoApi";
import LoadingButton from "@mui/lab/LoadingButton";
import MessageBox from "../../Animation/MessageBox";
import { Trans } from "react-i18next";
import ImageProxy from "../../Gallery/ImageProxy";
import { TRANSACTION_TYPES, documentPathTypes } from "../../../configs/constant";
import "./TransferToken.scss";
import { useTheme } from "@emotion/react";
import { connect } from "react-redux";
import { requirePassword } from "../../../store/slices/cryptoSlice";
import { getTokenIdBignumber, isAddress, isEmail } from "../../../utils/helpers";
import OperatorActionConfirmation from "../../Operator";
import { clearTransferStatus } from "../../../store/slices/tokenSlice";
import { useProposeTokenWithSmartContract, useMintAndPrepareTokenWithSmartContract, useTransferTokenWithSmartContract } from "../../../hooks/smartContractHooks";
import { toast } from "react-toastify";
import { useTraceUpdate } from "../../../utils/hooks";
import LoadingModal from "../../LoadingModal";
import { ShareTokenRetainedFields } from "../ShareToken/ShareToken";
import { randomSalt } from "@unikbase/react-token-hash";
import { standPost } from "../../../pages/Standalone/Standalone";

const ReceiverFormField = (props) => {
	const { onChange, error = false } = props;

	const [receiver, updateReceiver] = useState("");
	const [walletAddress, updateWalletAddress] = useState("");
	const [getWallet, { isLoading, isFetching, data }] = meoveoRestApi.endpoints.getWalletAddressByEmail.useLazyQuery();

	const onChangeHandle = (_newValue) => {
		updateReceiver(_newValue);

		if (_newValue.length > 0) {
			clearTimeout(window.transferEmailTyping);
			window.transferEmailTyping = setTimeout(() => {
				if (isEmail(_newValue)) {
					onChange(_newValue);
					getWallet(_newValue.toLowerCase())
						.unwrap()
						.then((data) => {
							if (data.status === "success" && data.result) {
								const address = data.result.toLowerCase();
								updateWalletAddress(address);
								onChange(address);
							} else {
								updateWalletAddress('');
							}
						})
						.catch((error) => { });
				} else {
					if (isAddress(_newValue)) {
						updateWalletAddress(_newValue);
						onChange(_newValue);
					} else {
						updateWalletAddress('');
						onChange('');
					}
				}
				clearTimeout(window.transferEmailTyping);
			}, 500);
		}
	};

	return (
		<>
			<TextField
				error={!!error}
				className="w--100"
				sx={{ mt: 1, mb: 1 }}
				variant="outlined"
				id=""
				name="receiver"
				onChange={(event) => onChangeHandle(event.target.value)}
				value={receiver}
				placeholder={t("pages:token.transfer_dialog.email_wallet_address")}
				helperText={error || ''}
			/>
			{isEmail(receiver) && (
				<FormHelperText sx={{ fontStyle: "italic" }}>
					{walletAddress ? `${t("pages:token.transfer_dialog.address")}: ${walletAddress}` : ``}
				</FormHelperText>
			)}
		</>
	);
};

const TransferToken = (props) => {
	const { token, refetchFunc, variant, open: _open } = props;
	const [open, toggleOpen] = useState(false);
	const [standalone, setStandalone] = useState(false);
	const [error, setError] = useState(false);
	const [confirmDialog, toggleConfirmDialog] = useState();

	const [email, setEmail] = useState('');
	const [receiver, updateReceiver] = useState("");
	const [cancelTransfer, { isLoading: cancelLoading, data: cancelResult, reset: resetCancelTransfer }] = useCancelTransferTokenMutation();

	const [updatePrivacySettings] = useUpdateTokenByIdMutation();
	const [transferToken, { isLoading: transferLoading, status: transferStatus, error: transferError }, resetTransferData] = useTransferTokenWithSmartContract({ token })

	const tokenID = token?.token?.uuid;

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

	const getRequestData = () => {
		if (!token) return;
		let delayDays = document.querySelector('[name="accept-days"]');
		delayDays = delayDays ? parseInt(delayDays.value) : 2;
		let requestData = {
			tokenID: token?.token?.uuid,
			delays: delayDays,
			receiver: null,
			token: token,
			transactionHash: null,
		};

		if (!receiver || (!isEmail(receiver) && !isAddress(receiver))) {
			setError(true)
			return false;
		}
		setError(false);
		requestData.receiver = isEmail(receiver) ? { email: receiver.toLowerCase() } : { wallet: receiver };
		return requestData;
	}

	const onTransfer = () => {
		let requestData = getRequestData();
		if (!requestData) return false;

		toggleConfirmDialog({
			callback: async () => {
				if (!Array.isArray(token?.privacySettings?.token?.hashPrefix)) {
					await updateHashPrefixes();
				}
				transferToken(requestData)
			}
		});
	};

	const handleClose = () => {
		toggleConfirmDialog(false);
		toggleOpen(false);
	};

	const changeHandle = (receiver) => {
		const _receiver = receiver.toLowerCase();
		updateReceiver(receiver);
		if (isEmail(receiver)) setEmail(_receiver);
		setError(!receiver || (!isEmail(receiver) && !isAddress(receiver))
			? t('pages:token.transfer_dialog.invalid_address')
			: (_receiver === token?.token?.ownerEmail?.toLowerCase() || _receiver.replace('0x', '') === token?.token?.ownerWallet?.toLowerCase())
				? t('pages:token.transfer_dialog.transfer_to_existing_owner')
				: false
		);
	};

	const cancelTransferHandle = () => {
		cancelTransfer({ tokenID });
	};

	useEffect(() => {
		if (_open) {
			toggleOpen(true);
		}
	}, [_open]);

	useEffect(() => {
		window.addEventListener("message", messageHandler);
		document.addEventListener("message", messageHandler);
		return () => toggleOpen(false);
	}, []);

	const messageHandler = message => {
		try {
			const data = JSON.parse(message.data);
			if (data.action === 'token.TransferToken') {
				setStandalone(true);
				toggleOpen(true);
			} else if (data.action === 'token.GenerateCertificate'
				|| data.action === 'token.EditToken'
				|| data.action === 'token.PrivacySettings'
				|| data.action === 'token.ShareToken') {
				toggleOpen(false);
			}
		} catch (e) {
		}
	}

	useEffect(() => {
		if (!open) {
			updateReceiver('');
			setError(false);
		}
	}, [open]);
	useEffect(() => {
		if ('success' === transferStatus && !transferLoading) {
			toast.success(t("pages:token.transfer_dialog.transfer_success_message", { email: email || receiver }));
			resetTransferData();
			typeof refetchFunc === 'function' && refetchFunc();
			if (standalone) {
				standPost({ action: 'success', data: 'Transfer success' });
			}
		}
	}, [transferStatus, resetTransferData, refetchFunc, transferLoading, standalone, email, receiver]);

	const coverImage = token.documents.find((document) => document.path === documentPathTypes.COVER_IMAGE);

	const updateHashPrefixes = async () => {
		const fieldsToOmit = Object.keys(token.token).filter((field) => !ShareTokenRetainedFields.includes(field));
		const salts = Array.isArray(token.privacySettings?.token?.hashPrefix)
			? [...token.privacySettings.token.hashPrefix] : [];

		Object.keys(token.token).forEach((name) => {
			if (!salts.find((e) => e.name === name) && !fieldsToOmit.includes(name)) {
				salts.push({ name, prefix: randomSalt() });
			}
		})

		const tokenSettings = { ...token.privacySettings.token };
		tokenSettings.hashPrefix = salts;

		await updatePrivacySettings({
			invalidate: false,
			tokenID: token.token.uuid,
			body: {
				privacySettings: {
					...token.privacySettings,
					token: tokenSettings,
				}
			},
		})
	};

	if ("IN_TRANSFER" === token.token.status) {
		return null;
	}
	return (
		<>
			<Button
				color="secondary"
				variant={variant || "text"}
				onClick={() => toggleOpen(true)}
				aria-label="share"
				title="share token"
			>
				<span className="icon icon__action icon--export">_</span>
				<Typography component="span" variant="body2">
					{t("pages:token.transfer")}
				</Typography>
			</Button>

			<Dialog
				className="token__transfer dialog"
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullScreen={fullScreen}
			>
				<DialogTitle id="alert-dialog-title">
					{t("pages:token.transfer_dialog.title")}
					<IconButton aria-label="close" onClick={handleClose}>
						<span className="icon icon--close"></span>
					</IconButton>
				</DialogTitle>

				{!!token?.token?.status && token.token.status === "REFUSED" ? (
					<>
						<DialogContent className="token__cancel-share-confirmation">
							<Typography variant="body">{t("pages:token.transfer_dialog.refused_message")}</Typography>
						</DialogContent>
						<DialogActions
							sx={{ width: "50%", m: "0 auto" }}
							className="flex flex--vertical align--center flex--justify-center"
						>
							<LoadingButton
								loading={!!cancelLoading}
								size="medium"
								variant="contained"
								color="primary"
								onClick={cancelTransferHandle}
							>
								{t("common:acknowledge")}
							</LoadingButton>
						</DialogActions>
					</>
				) : (
					<fieldset disabled={transferLoading}>
						<DialogContent className="token__share--main">
							<Box sx={{ mb: 1, p: 2 }} className="bg--gray flex flex--horizontal">
								<ImageProxy url={coverImage ? coverImage.fileUrl : ""} width="100" height="100" />
								<Box sx={{ ml: 2 }}>
									<Typography className="token__name" component="h3" variant="subtitle1">
										{token.token.name}{" "}
									</Typography>
									<Typography className="token__description" component="div" variant="caption">
										{token.token.description}
									</Typography>
								</Box>
							</Box>

							<Box className="receiver-address">
								<Typography component="label" variant="body">
									{t("pages:token.transfer_dialog.enter_email")}
								</Typography>
								<ReceiverFormField error={error} onChange={changeHandle} />
							</Box>

							<Typography sx={{ mb: 2 }} className="expire-aware-message" component="div" variant="body2">
								<Trans
									i18nKey="pages:token.transfer_dialog.disclaimer_2"
									components={[
										<InputBase
											sx={{
												width: "5ch",
												border: "1px solid var(--unikbase-gray);",
												pl: "0.618em",
												pr: "0.618em",
											}}
											type="number"
											id="accept-days"
											name="accept-days"
											defaultValue={2}
											inputProps={{
												min: 1,
												max: 9,
											}}
										/>,
									]}
								/>
							</Typography>

							<Box className="awares">
								<Typography className="aware-message" sx={{ mb: 1 }} component="div" variant="body2">
									{t("pages:token.transfer_dialog.disclaimer")}
								</Typography>

								<Typography className="aware-message" component="div" variant="body2">
									{t("pages:token.transfer_dialog.disclaimer_3")}
								</Typography>
							</Box>

							{!!transferError && (<MessageBox type="error">{transferError.message || "Something wrong"}</MessageBox>)}
						</DialogContent>

						<DialogActions
							sx={{ maxWidth: "600px", m: "0 auto" }}
							className="flex flex--vertical align--center flex--justify-center"
						>
							<Button
								size="medium"
								variant="outlined"
								color="secondary"
								onClick={handleClose}
							>
								{t("common:cancel")}
							</Button>
							<LoadingButton
								loading={transferLoading}
								disabled={!receiver || !!error}
								size="medium"
								variant="contained"
								color="secondary"
								onClick={onTransfer}
							>
								{t("common:send")} {t("common:token")}
							</LoadingButton>
						</DialogActions>
					</fieldset>
				)}
			</Dialog>

			<OperatorActionConfirmation
				offerCode={token?.billingOfferCode}
				defaultPartnerCode={token?.billingOfferCode ? token?.uuid.split('_')[0] : ''}
				action={TRANSACTION_TYPES.TRANSFER_TOKEN_REQUEST.key}
				open={confirmDialog}
				toggle={toggleConfirmDialog}
				onClose={() => {
					handleClose()
				}}
			/>

			{transferLoading && <LoadingModal
				visible={transferLoading}
				// message={t("pages:token.transfer_dialog.transfer_in_progress")}
				onClose={() => null}
			/>}
		</>
	);
};

export default connect(
	(state) => {
		const cryptoState = state.crypto;
		const authState = state.auth;
		return {
			walletReady: authState.seedPhrase && cryptoState.keyringController,
		};
	},
	(dispatch) => {
		return {
			askPassword: () => {
				dispatch(requirePassword());
			}
		};
	}
)(TransferToken);
