import { createAsyncThunk } from "@reduxjs/toolkit";
import { conditionCheck } from "./walletActions";

export const revealPrivateKey = createAsyncThunk('operator/revealPrivateKey', async (payload, { getState, rejectWithValue} ) => {
	const auth = getState()?.auth
	return true 
},{
	condition: (userId, { getState }) => {
		const state = getState()?.crypto
		return !state.requirePassword;
	},
	dispatchConditionRejection: true,
})
