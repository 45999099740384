import { createSlice, isRejectedWithValue } from '@reduxjs/toolkit';
import {
  createWallet,
  getBalance,
  getOTPStatus,
  getWallet,
  getWalletBySeedphrase,
  requestOTP,
  updateWallet,
  verifyOTP,
} from '../actions/walletActions';

const initialState = {
  status: 'idle',
  isUpdating: 'idle',
  error: {},
  public: null,
  partnerCode: null,
  operatorPricingPlan: null,
  private: {
    phone: {
      number: null,
      dialCode: 0,
      verified: false,
    },
    email: {
      address: null,
      verified: false,
    },
    username: null,
  },
  address: '',
  otpChecking: 'idle',
  otp: {},
  otpRequests: {
    email: false,
    phone: false,
  },
  balance: 0,
  importAccount: {
    status: 'idle',
    user: null,
    error: {},
  },
  isOperatorUser: false,
  isOperator: false,
  onceMnemonic: false,
};

const resetError = (error, key) => {
  let updateErrors = { ...error };
  if (!!updateErrors[key]) delete updateErrors[key];

  return updateErrors;
};

const importError = (error, action, key) => {
  if (isRejectedWithValue(action)) {
    const { message } = action.payload;
    return {
      ...error,
      [key]: message,
    };
  }
};

const walletSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    initWallet: (state, action) => {
      const { wallet } = action.payload;
      state.public = wallet?.public;
      state.private = wallet?.private;
      state.address = wallet?.address;
      state.status = 'success';
      state.isUpdating = 'idle';
      state.error = {};
    },
    resetWallet: () => initialState,
    updateStatus: (state, action) => {
      const status = action.payload;
      state.status = status;
    },
    updatePartnerCode: (state, action) => {
      const partnerCode = action.payload;
      state.partnerCode = partnerCode;
    },
    updateOperatorPricingPlan: (state, action) => {
      const plans = action.payload;
      state.operatorPricingPlan = plans;
    },
    updateError: (state, action) => {
      state.error = action?.payload;
    },
    resetWalletStatus: (state) => {
      state.isUpdating = 'idle';
    },
    setOperatorUser: (state, action) => {
      const {
        isOperatorUser = false,
        partnerCode = false,
        creationDate = false,
        isOperator = false,
        operator = {},
      } = action.payload;
      state.isOperatorUser = isOperatorUser;
      state.isOperator = isOperator;
      state.partnerCode = partnerCode;
      state.operatorCreationDate = creationDate;
      state.operator = operator;
    },
    updateOnceMnemonic: (state, action) => {
      const { mnemonic } = action.payload;
      state.onceMnemonic = mnemonic;
    },
    removeOnceMnemonic: (state) => {
      state.onceMnemonic = false;
    },
    resetOTP: (state, action) => {
      const { status } = action.payload;
      state.otpChecking = status || 'idle';
    },
  },
  extraReducers: (builder) => {
    // This reducer will be filled same from both of  getWallet and getOperatorWallet actions, all under wallet/info action key
    builder
      .addCase(getWallet.pending, (state, action) => {
        state.status = 'loading';
        state.error = resetError(state.error, getWallet.rejected);
      })
      .addCase(getWallet.fulfilled, (state, action) => {
        const data = action.payload;
        state.status = 'success';
        const { public: publicInfo, private: privateInfo, address } = data?.wallet;
        state.public = {
          ...state.public,
          ...publicInfo,
        };
        state.private = {
          ...state.private,
          ...privateInfo,
        };

        console.log(address);
        if (!!state.address && state.address !== address) {
          console.log(state.isOperatorUser);
        }
        state.address = address;

        state.error = resetError(state.error, getWallet.rejected);
      })
      .addCase(getWallet.rejected, (state, action) => {
        state.status = 'fail';
        const { code, message } = action.payload;
        if (code !== 'get-operator') {
          state.public = initialState.public;
          state.private = initialState.private;
          state.address = initialState.address;
          state.error = importError(state.error, action, getWallet.rejected);
        }
      });
    // - End

    builder
      .addCase(updateWallet.pending, (state, action) => {
        state.isUpdating = 'loading';
        state.error = resetError(state.error, updateWallet.rejected);
      })
      .addCase(updateWallet.fulfilled, (state, action) => {
        const data = action.payload;
        state.isUpdating = 'success';
        state.public = data.public;
        state.private = data.private;
        state.error = resetError(state.error, updateWallet.rejected);
      })
      .addCase(updateWallet.rejected, (state, action) => {
        state.status = 'fail';
        state.isUpdating = 'fail';
        // Reject from running action
        state.error = importError(state.error, action, updateWallet.rejected);
      });

    builder
      .addCase(createWallet.pending, (state, action) => {
        state.status = 'loading';
        state.error = resetError(state.error, createWallet.rejected);
      })
      .addCase(createWallet.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = resetError(state.error, createWallet.rejected);
      })
      .addCase(createWallet.rejected, (state, action) => {
        state.status = 'fail';
        // Reject from running action
        state.error = importError(state.error, action, createWallet.rejected);
      });

    builder
      .addCase(getOTPStatus.pending, (state, action) => {
        state.otpChecking = 'loading';
        state.error = resetError(state.error, getOTPStatus.rejected);
      })
      .addCase(getOTPStatus.fulfilled, (state, action) => {
        const data = action.payload;
        state.otpChecking = 'success';
        state.error = resetError(state.error, getOTPStatus.rejected);
        state.otp = data;
      })
      .addCase(getOTPStatus.rejected, (state, action) => {
        state.otpChecking = 'fail';
        state.otp = {};
        // Reject from running action
        state.error = importError(state.error, action, getOTPStatus.rejected);
      });

    builder
      .addCase(verifyOTP.pending, (state) => {
        state.status = 'loading';
        state.error = resetError(state.error, verifyOTP.rejected);
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.status = 'success';
        state.error = resetError(state.error, verifyOTP.rejected);
        const { type } = action.payload;

        state.private = {
          ...state.private,
          [type]: {
            ...state.private[type],
            verified: true,
          },
        };
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.status = 'fail';
        state.error = importError(state.error, action, verifyOTP.rejected);
      });

    builder
      .addCase(requestOTP.pending, (state) => {
        state.status = 'loading';
        state.error = resetError(state.error, requestOTP.rejected);
      })
      .addCase(requestOTP.fulfilled, (state, action) => {
        state.status = 'success';
        const { creationTime } = action.payload;

        if (!!creationTime && typeof creationTime === 'object') {
          const request = state.otpRequests || {};
          state.otpRequests = {
            ...request,
            ...creationTime,
          };
        }
        state.error = resetError(state.error, requestOTP.rejected);
      })
      .addCase(requestOTP.rejected, (state, action) => {
        state.status = 'fail';
        state.error = importError(state.error, action, requestOTP.rejected);
      });

    builder
      .addCase(getBalance.pending, (state) => {})
      .addCase(getBalance.fulfilled, (state, action) => {
        const { balance } = action.payload;
        state.balance = balance;
      })
      .addCase(getBalance.rejected, (state, action) => {});

    builder
      .addCase(getWalletBySeedphrase.pending, (state) => {
        state.importAccount.status = 'loading';
        state.importAccount.user = null;
        state.importAccount.error = null;
      })
      .addCase(getWalletBySeedphrase.fulfilled, (state, action) => {
        const { wallet, password } = action.payload;
        state.importAccount.status = 'success';
        state.importAccount.user = {
          username: wallet.name,
          password: password,
          wallet: wallet,
        };
        state.importAccount.error = null;
      })
      .addCase(getWalletBySeedphrase.rejected, (state, action) => {
        state.importAccount.status = 'success';
        state.importAccount.user = null;
        state.importAccount.error = action.payload;
      });
  },
});

export const {
  initWallet,
  resetWallet,
  updateStatus,
  updateError,
  resetWalletStatus,
  updatePartnerCode,
  updateOperatorPricingPlan,
  setOperatorUser,
  updateOnceMnemonic,
  removeOnceMnemonic,
  resetOTP,
} = walletSlice.actions;
export default walletSlice.reducer;
