import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { spacing } from '@mui/system';
import { useEffect, useState } from 'react';

export const verifyFormField = (value, valid, form) => {
  let validates = [];
  if (!valid) return false;
  valid.forEach((checker) => {
    switch (checker[0]) {
      case 'match':
        let matchField;
        if (checker[1] && (matchField = form.filter((item) => item.id === checker[1])[0])) {
          if (matchField.value !== value) {
            validates.push(checker[2]);
          }
        }
        break;
      default:
        try {
          let regex = new window.RegExp(checker[0]);
          if (!regex.test(value)) {
            validates.push(checker[1]);
          }
        } catch (error) {
          console.error('Failed to validate: ' + checker[0]);
        }
        break;
    }
  });

  return validates;
};

const PasswordField = (props) => {
  const { data, formData, onChange = false, autocomplete = "off" } = props;
  const { label, id, valid } = data;
  const [show, toggleShow] = useState(false);
  const [firstChange, setFirstChange] = useState(false);
  const [value, setValue] = useState(data?.value || '');
  const [errors, setErrors] = useState(data?.errors || []);

  const handleClickShowPassword = () => {
    toggleShow(!show);
  };

  const validation = () => {
    let validates = verifyFormField(value, valid, formData);
    setErrors(validates);
    typeof onChange === 'function' && onChange(id, value, validates);
  };

  const handleChange = (event) => {
    !firstChange && setFirstChange(true);
    setValue(event.target.value);
  };

  useEffect(() => {
    if (firstChange) {
      const timeOutId = setTimeout(() => {
        validation();
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [value, firstChange]);

  useEffect(() => {
    setErrors(data.errors);
  }, [data.errors]);

  // Reset field when form data change
  useEffect(() => {
    setFirstChange(false);
    setValue(data.value);
  }, [data.value]);

  return (
    <FormControl variant='standard' error={errors.length > 0}>
      <OutlinedInput
        id={id}
        autoComplete={autocomplete}
        type={show ? 'text' : 'password'}
        onChange={handleChange}
        value={value}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              edge='end'
              sx={{ fontSize: '12px' }}
            >
              <FontAwesomeIcon icon={show ? solid('eye-slash') : solid('eye')} />
            </IconButton>
          </InputAdornment>
        }
        sx={{
          input: { padding: '0 12px', height: 46 }
        }}
      />
      {!!errors && errors.length > 0 && (
        <FormHelperText component='div'>
          <ul>
            {errors.map((mess, idx) => (
              <li key={`field-${id}-error-${idx}`}>{mess}</li>
            ))}
          </ul>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default PasswordField;
