import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { FormField } from "../../components/Form/FormField";

type SettingItem = {
	item: {
		key: string;
		label: string;
		value: string;
		capital?: boolean;
		disable?: boolean;
		options?: any[];
		// set default value to true
	},
	onChange?: Function
}
const ProfileSettingItem = ({ item, onChange }: SettingItem,) => {
	const disabled = !!item?.disable;
	const originValue = !!item?.value
		? typeof item.value === "string" && !!item?.capital
			? `${item.value.slice(0, 1).toUpperCase()}${item.value.slice(1).toLowerCase()}`
			: item.value
		: "";
	const [value, setValue] = useState(originValue);

	
	const onChangeHanlde = (_newValue: any) => {
		if ( !!item.disable ) return;
		setValue(_newValue);
		typeof onChange === 'function' && onChange({
			...item,
			value: _newValue
		});
	}

	return (
		<Typography 
			className={`setting__item ${item.options ? 'setting__item--options' : ''}`} component="div" 
			sx={{ mb: 1 }}
		>
			<Typography component="label" variant="body1">
				{item.label}
			</Typography>
			<Typography component="span" variant="body1">
					{!!item.options && !!item.options.length 
						? item.options.map((option: any, idx: number) => {
							return (
								<Button
									key={`profile-item-option-${idx}`} 
									variant={`${option.value.toLowerCase() === value.toLowerCase() ? 'contained' : 'outlined'}`}
									size="large"
									color="primary"
									onClick={() => onChangeHanlde(option.value)}
								>
										{option.label}
								</Button>
							)
						})
						: (
							<FormField
								fieldId={item.key}
								value={value}
								onChange={(event: any) => onChangeHanlde(event.target.value)}
								label={null}
								disabled={disabled}
							/>
						)}
			</Typography>
		</Typography>
	);
};

export default ProfileSettingItem;