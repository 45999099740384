import { t } from "i18next";

const languages = {
	en: {
		label: "English",
	},
	fr: {
		label: "French",
	},
};

export const TIME_IN_DAYS = 24 * 60 * 60 * 1000;

const newTokenStatus = {
	CHECKING: "CHECKING",
	NEW: "INCOMING",
	OWNED: "OWNED",
	MANAGED: "MANAGED",
	TRANSFERRING: "TRANSFERRING",
	REFUSED: "DECLINED",
	MANAGE: "MANAGE",
};

const tokenStatus = {
	IN_TRANSFER: "IN_TRANSFER",
	ACTIVE: "ACTIVE",
	REFUSED: "REFUSED",
	ARCHIVED: "ARCHIVED",
};

const documentPathTypes = Object.freeze({
	COVER_IMAGE: "coverImage",
	INVOICE: "invoice",
	GALLERY: "2d-gallery",
	GALLERY_3D: "3d-gallery",
	CERTIFICATE: "doc/certificate/",
	OTHERS: "others",
	MARK_PROOFS: "markProofs",
	NFCPath: 'NFCTag',
	INK_FINGERPRINTS: 'inkFingerprints',
});

const documentTypes = () => ({
	[documentPathTypes.COVER_IMAGE]: {
		name: t("pages:token:document_details.cover_image"),
		path: documentPathTypes.COVER_IMAGE,
		icon: 'photo',
	},
	[documentPathTypes.GALLERY]: {
		name: t("pages:token:document_details.gallery"),
		path: documentPathTypes.GALLERY,
		icon: 'photo',
	},
	[documentPathTypes.GALLERY_3D]: {
		name: t("pages:token:document_details.gallery_3d"),
		path: documentPathTypes.GALLERY_3D,
		icon: 'photo',
	},
	[documentPathTypes.CERTIFICATE]: {
		name: t("pages:token:document_details.certificate"),
		path: documentPathTypes.CERTIFICATE,
		icon: 'document',
	},
	[documentPathTypes.INVOICE]: {
		name: t("pages:token:document_details.invoice"),
		path: documentPathTypes.INVOICE,
		icon: 'document',
	},
	[documentPathTypes.MARK_PROOFS]: {
		name: t("pages:token:document_details.digital_fingerprints"),
		path: documentPathTypes.MARK_PROOFS,
		icon: 'document',
	},
	[documentPathTypes.NFCPath]: {
		name: t("pages:token:privacy_settings.nfc_tag"),
		path: documentPathTypes.NFCPath,
		icon: 'document',
	},
	[documentPathTypes.INK_FINGERPRINTS]: {
		name: t("pages:token:document_details.ink_fingerprints"),
		path: documentPathTypes.INK_FINGERPRINTS,
		icon: 'document',
	},
	[documentPathTypes.OTHERS]: {
		name: t("pages:token:document_details.other_documents"),
		path: documentPathTypes.OTHERS,
		icon: 'others',
	},
});

const viewTokenTypes = Object.freeze({
	PUBLIC_TOKEN: "PUBLIC_TOKEN",
	SHARED_TOKEN: "SHARED_TOKEN",
	OWNED_TOKEN: "OWNED_TOKEN",
});

const documentPrivacyTypes = {
	PRIVATE: "private",
	SHARED: "shared",
	PUBLIC: "public",
};

const imageMimeTypes = Object.freeze(["image/png", "image/jpeg", "image/gif"]);

const pdfMimeType = "application/pdf";

const docMimeType = "application/msword";

const textMimeType = "text/plain";

const documentFileTypes = Object.freeze({
	IMAGE: "IMAGE",
	PDF: "PDF",
	DOC: "DOC",
	FILE_3D_OBJ: "3D_OBJ",
});


export const ZIP_MIME_TYPES = ["application/zip", "application/x-zip-compressed", "multipart/x-zip"];
export const inchToMeter = 0.0254;

export const poundToKilogram = 0.45359237;

export const centimeterToMeter = 0.01;

export const gramToKilogram = 0.001;

export const currencies = [
	{
		currency: "EUR",
		symbol: "€",
	},
	{
		currency: "USD",
		symbol: "$",
	},
];

const unitTypes = Object.freeze({
	METRIC: {
		key: "METRIC",
		label: "Metric",
		lengthUnit: "cm",
		weightUnit: "g",
	},
	IMPERIAL: {
		key: "IMPERIAL",
		label: "Imperial",
		lengthUnit: "in",
		weightUnit: "lb",
	},
});

// Transactions Task

export const TOKEN_METHOD_TRANSFER = "transfer";
export const TOKEN_METHOD_APPROVE = "approve";
export const TOKEN_METHOD_TRANSFER_FROM = "transferfrom";
export const CONTRACT_METHOD_DEPLOY = "deploy";
export const CONNEXT_METHOD_DEPOSIT = "connextdeposit";

export const SEND_ETHER_ACTION_KEY = "sentEther";
export const DEPLOY_CONTRACT_ACTION_KEY = "deploy";
export const APPROVE_ACTION_KEY = "approve";
export const SEND_TOKEN_ACTION_KEY = "transfer";
export const TRANSFER_FROM_ACTION_KEY = "transferfrom";
export const UNKNOWN_FUNCTION_KEY = "unknownFunction";
export const SMART_CONTRACT_INTERACTION_ACTION_KEY = "smartContractInteraction";
export const SWAPS_TRANSACTION_ACTION_KEY = "swapsTransaction";

export const TRANSFER_FUNCTION_SIGNATURE = "0xa9059cbb";
export const TRANSFER_FROM_FUNCTION_SIGNATURE = "0x23b872dd";
export const APPROVE_FUNCTION_SIGNATURE = "0x095ea7b3";
export const CONTRACT_CREATION_SIGNATURE = "0x60a060405260046060527f48302e31";

export const TRANSACTION_TYPES = {
	CREATE_WALLET: {
		key: "CREATE_WALLET",
		label: "pages:transactions:type.create_wallet",
	},
	CREATE_TOKEN: {
		key: "CREATE_TOKEN",
		label: "pages:transactions:type.create_token",
	},
	// Documents
	CREATE_DOCUMENT: {
		key: "CREATE_DOCUMENT",
		label: "pages:transactions:type.create_document",
	},
	DELETE_DOCUMENT: {
		key: "DELETE_DOCUMENT",
		label: "pages:transactions:type.delete_document",
	},
	addDocumentHash: {
		key: "addDocumentHash",
		label: "pages:transactions:type.addDocumentHash",
	},
	approveTransfer: {
		key: "approveTransfer",
		label: "pages:transactions:type.approveTransfer",
	},
	proposeTransfer: {
		key: "proposeTransfer",
		label: "pages:transactions:type.proposeTransfer",
	},
	// proposeTransferNoWallet: {
	// 	key: "proposeTransferNoWallet",
	// 	label: "pages:transactions:type.proposeTransfer",
	// },
	cancelTransfer: {
		key: "cancelTransfer",
		label: "pages:transactions:type.cancelTransfer",
	},
	mintAndPrepareTransfer: {
		key: "mintAndPrepareTransfer",
		label: "pages:transactions:type.mintAndPrepareTransfer",
	},
	// Transfer
	// - send
	TRANSFER_TOKEN_REQUEST: {
		key: "TRANSFER_TOKEN_REQUEST",
		label: "pages:transactions:type.transfer_token_request",
	},
	REFUSE_TRANSFER_TOKEN_REQUEST: {
		key: "REFUSE_TRANSFER_TOKEN_REQUEST",
		label: "pages:transactions:type.refuse_transfer_token_request",
	},
	CANCEL_TRANSFER_TOKEN_REQUEST: {
		key: "CANCEL_TRANSFER_TOKEN_REQUEST",
		label: "pages:transactions:type.cancel_transfer_token_request",
	},
	//  - Receive
	ACCEPT_TOKEN: {
		key: "ACCEPT_TOKEN",
		label: "pages:transactions:type.accept_token",
	},
	// Property
	ADD_PROPERTY: {
		key: "ADD_PROPERTY",
		label: "pages:transactions:type.add_property",
	},
	DELETE_PROPERTY: {
		key: "DELETE_PROPERTY",
		label: "pages:transactions:type.delete_property",
	},
	UPDATE_PROPERTY: {
		key: "UPDATE_PROPERTY",
		label: "pages:transactions:type.update_property",
	},
	UPDATE_TOKEN: {
		key: "UPDATE_TOKEN",
		label: "pages:transactions:type.update_token",
	},
	PAYMENT: {
		key: "PAYMENT",
		label: "pages:transactions:type.payment"
	},
	TOP_UP: {
		key: "Stripe topup",
		label: "pages:transactions:type.topup"
	},
	OPERATOR_CHANGE: {
		key: 'OPERATOR_CHANGE',
		label: 'pages:transactions:type.operator_change',
	},
	CREATE_3D_SCAN: {
		key: 'CREATE_3D_SCAN',
		label: 'pages:transactions:type.create_3d_scan',
	},
	BRIDGE_ETHEREUM: {
		key: 'BRIDGE_ETHEREUM',
		label: 'pages:transactions:type.bridge_ethereum',
	},
};

export const DOCUMENT_UPLOAD_FILE_SIZE = 200; // MB

export const UPLOAD_DELAY = 0;
export const BASE64_PREFIX = "base64,";

export const MARKPROOF_VERIFICATION_STATUS_LABEL = {
	ONGOING: t("pages:mark_proof.statuses.ONGOING"),
	MANUAL_OK: t("pages:mark_proof.statuses.MANUAL_OK"),
	MANUAL_KO: t("pages:mark_proof.statuses.MANUAL_KO"),
	AI_OK: t("pages:mark_proof.statuses.AI_OK"),
	AI_KO: t("pages:mark_proof.statuses.AI_KO"),
}

export const MARKPROOF_VERIFICATION_STATUS = {
	ONGOING: "ONGOING",
	MANUAL_OK: "MANUAL_OK",
	MANUAL_KO: "MANUAL_KO",
	AI_OK: "AI_OK",
	AI_KO: "AI_KO",
}

export const MARKPROOF_VERIFICATION_REJECT_REASONS = [
	'NOT_SAME_OBJECT',
	'IMAGE_BLURRY',
	'OBJECT_IS_AN_IMITATION'
]

export {
	languages,
	documentTypes,
	documentPathTypes,
	viewTokenTypes,
	imageMimeTypes,
	pdfMimeType,
	docMimeType,
	textMimeType,
	documentFileTypes,
	documentPrivacyTypes,
	unitTypes,
	tokenStatus,
	newTokenStatus,
};
