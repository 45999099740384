import { connect } from "react-redux";
import { useEffect } from "react";
import { parseJwt } from "../../utils/helpers";

import Loader from "../Loader";
import { refreshToken } from "../../store/actions/authActions";
import { useLocation } from "react-router-dom";
import { persistor } from "../../store/rootReducer";
import { logout } from "../../store/slices/authSlice";
import BalanceLoader from "../User/BalanceLoader";
import WalletLoader from "../User/WalletLoader";
import UserVerificationChecker from "../User/UserVerificationChecker";

const RequireUser = (props) => {
	const {
		isLoggedIn,
		isRefreshingToken,
		refreshToken,
		renewToken, // action
		children,
		requireOperator = false
	} = props;
	const location = useLocation();

	useEffect(() => {
		if (!isLoggedIn && !!refreshToken) {
			const jwt = parseJwt(refreshToken);
			if (jwt && jwt.exp * 1000 > new Date().getTime()) {
				renewToken();
			}
		}
	}, [isLoggedIn, refreshToken]);

	if (!isLoggedIn && !isRefreshingToken) {
		if (!!refreshToken) {
			const jwt = parseJwt(refreshToken);
			if (jwt && jwt.exp * 1000 > new Date().getTime()) {
				// Token is still valid. waiting for the token to be refreshed
			} else {
				return <Loader navigate="/token-login" state={{ from: location.pathname + location.search }} />;
			}
		} else
		return <Loader navigate="/token-login" state={{ from: location.pathname + location.search }} />;
	}

	//  Truly logged-in user
	return (
		<WalletLoader requireOperator={requireOperator}>
			<UserVerificationChecker>
				<BalanceLoader />
				{children}
			</UserVerificationChecker>
		</WalletLoader>
	);
};

export default connect(
	(state) => {
		let auth = state.auth;
		let wallet = state.wallet;
		
		return {
			isLoggedIn:
				!!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime()
					? auth.accessToken
					: false,
			address: wallet?.address,
			expiredAt: auth.expiredAt,
			refreshToken: auth.refreshToken,
			isRefreshingToken: auth.isRefreshingToken,
			userInformation: wallet?.private
		};
	},
	(dispatch) => {
		return {
			renewToken: () => {
				dispatch(refreshToken());
			},
			logout: () => {
				persistor.purge();
				dispatch(logout());
			}
		};
	}
)(RequireUser);
