import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import '../ShareTokenSelector/ShareTokenSelector.scss';

import "../../../components/Token/TokenDetail.scss";
import { standPost } from '../Standalone';
import { PlatformLayout } from '../../../layout';
import { t } from 'i18next';
import AddToken from '../../../components/Token/AddToken/AddToken';
import { parseJwt } from '../../../utils/helpers';

const CreateTokenWebApp = (props: any) => {
  const { store } = props;
  const [params] = useSearchParams();
  const [partnerCode, setPartnerCode] = useState('');

  const _onClose = () => {
    standPost({ action: 'cancel' });
  }

  const _onSuccess = (token: any) => {
    standPost({ action: 'success', data: token });
  }

  useEffect(() => {
    standPost({ action: 'loaded' })

    const auth = store.auth;
    const accessToken = params.get('access_token') || auth?.accessToken;

    if (!accessToken) {
      standPost({ action: 'error', data: 'Invalid token' })
    } else {
      const jwt = parseJwt(accessToken);
      const partnerCode = jwt?.partner_code;

      if (!partnerCode) {
        standPost({ action: 'error', data: 'No operator code' })
      } else {
        setPartnerCode(partnerCode);
      }
    }
  }, []);

  return (
    <PlatformLayout
      hideSidebar={true}
      standalone={true}
      pageTitle={t('pages:token_list.create_digital_passport')}
      className="site single token-single"
    >
      <AddToken
        standalone={true}
        partnerCode={partnerCode}
        onClose={_onClose}
        onSuccess={_onSuccess}
      />
    </PlatformLayout>
  )
}

export default
  connect(
    (state: any) => {
      let auth = state.auth;

      return {
        store: state,
        isLoggedIn:
          !!auth.accessToken && !!auth.expiredAt && auth.expiredAt > new Date().getTime()
            ? auth.accessToken
            : false,
      };
    }
  )(CreateTokenWebApp);