import React, { useEffect, useRef, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import './OTPVerify.scss'
import { connect } from 'react-redux';
import DigitsField from '../Form/DigitsField';
import { useLazyRequestUserOTPQuery, useVerifyOTPMutation } from '../../service/api/meveoApi';
import { secondsToMinutes } from '../../utils/helpers';
import { toast } from 'react-toastify';


interface IOTPVerify {
  visible: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  phoneNumber?: string;
}

const OTPVerify = ({ visible, phoneNumber, onConfirm, onDismiss }: IOTPVerify) => {
  const [countdown, setCountdown] = useState(0);
  const [digits, setDigits] = useState('');
  const [requestOTP, { isLoading: requesting }] = useLazyRequestUserOTPQuery();
  const [verifyOTP, { isLoading: verifying }] = useVerifyOTPMutation();
  const timer = useRef<any>(null);
  const OTP_LENGTH = 6;

  const startTimer = () => {
    clearInterval(timer.current)
    timer.current = setInterval(() => {
      setCountdown(prev => prev - 1)
    }, 1000);
  }

  useEffect(() => {
    if (countdown <= 0) {
      clearInterval(timer.current)
    }
  }, [countdown])

  const onResendOTP = () => {
    setCountdown(100);
    startTimer();
    requestOTP({ type: 'phone', verifyId: `${phoneNumber}` })
  }

  const _onConfirm = () => {
    verifyOTP({ otp: digits, verifyId: phoneNumber }).then((res: any) => {
      if (res.data === 'success') {
        onConfirm && onConfirm();
      } else {
        toast.error(t('pages:profile.otp_code_invalid'));
      }
    })
  }

  const onClose = () => {
    onDismiss && onDismiss();
  }

  useEffect(() => {
    onResendOTP();
  }, []);

  return (
    <Dialog className="verify-otp-dialog" open={visible} onClose={onClose}>
      <DialogContent>
        <Typography variant="h5" fontWeight="500">{t('pages:profile.otp_code_confirmation')}</Typography>
        <Typography variant="body2" sx={{ my: 2, pr: 5 }}>
          {t('pages:profile.enter_otp_sent_to')}
          <Typography variant="body2" component="span" fontWeight="bold">{phoneNumber}</Typography>
        </Typography>

        <DigitsField digitsLength={6} onChange={(code: string) => setDigits(code)} />

        <Typography variant="caption" component="h5" mt={5}>{t('pages:profile.did_not_receive_code')}</Typography>
        <Button
          variant='text'
          onClick={onResendOTP}
          sx={{ p: 0 }}
          disabled={requesting || countdown > 0}
        >
          <Typography variant='caption' component="h5" color={countdown === 0 ? 'secondary' : 'GrayText'} textTransform="capitalize">
            {t('pages:profile.resend_code')} {countdown > 0 ? `(${secondsToMinutes(countdown)})` : ''}
          </Typography>
        </Button>
      </DialogContent>
      <DialogActions className="flex flex--align-center flex--justify-center">
        <Grid container spacing={2} pl={2} pr={2} mb={2}>
          <Grid item xs={OTP_LENGTH}>
            <Button className="w--100" onClick={onClose} color="secondary" variant="outlined">
              {t("common:cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button className="w--100" onClick={_onConfirm} color="secondary" variant="contained" disabled={verifying || digits.length < OTP_LENGTH}>
              {t("common:reveal")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default connect((state: any) => {
  const wallet: any = state.wallet;

  return {
    phoneNumber: wallet?.private?.phone?.number
  }
})(OTPVerify)
