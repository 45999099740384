import {
	Alert,
	Box,
	Button,
} from "@mui/material";
import { t } from "i18next";
import { connect } from "react-redux";
import { documentPrivacyTypes } from "../../../configs/constant";
import { updateWallet } from "../../../store/actions/walletActions";
import { usePrivacy, usePrivacyDispatch } from "../../Token/ShareTokenPrivacy/TokenPrivacyContext";

const TokenPrivacy4AllSubmit = (props) => {
	const privacySettings = usePrivacy();
	const dispatch = usePrivacyDispatch();

	const { updatePrivacySettings } = props;

	const onSaveConfiguration = (event: any) => {
		event.preventDefault();
		updatePrivacySettings(privacySettings.token);
		dispatch({
			type: "reset_changed",
		});
	};

	const note =
		privacySettings.token.tokenPrivacy === documentPrivacyTypes.PUBLIC
			? t("pages:token.privacy_settings.public_share_note")
			: t("pages:token.privacy_settings.warning_share_digital_twin");

	return (
		<Box className="w--100">
			<Button
				onClick={onSaveConfiguration}
				className="w--100"
				size="small"
				sx={{ mb: 1 }}
				variant="contained"
				color="primary"
				disabled={privacySettings.isChanged === false}
			>
				{t("pages:token.privacy_settings.save_configuration")}
			</Button>

			{documentPrivacyTypes.PRIVATE === privacySettings.token.tokenPrivacy && (
				<Alert>{note}</Alert>
			)}
		</Box>
	);
};

export default connect(state => {
	const wallet = state?.wallet;
	return {
		isOperatorUser: wallet.isOperatorUser,
	}
}, dispatch => {
	return {
		updatePrivacySettings: (tokenPrivacySettings: any) => {
			dispatch((updateWallet as any)({
				public: {
					tokenPrivacySettings
				}
			}))
		}
	}
})(TokenPrivacy4AllSubmit);
