import React from 'react'
import './TokenShareLayout.scss'

const TokenShareLayout = ({ children }: any) => {
  return (
    <div className='tokenshare-layout tokenshare-grid'>
      {children}
    </div>
  )
}

export default TokenShareLayout
