import React, { useEffect, useRef, useState } from 'react';

const LazyBase64Image = ({ base64String, alt, className }) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update state when the image comes into the viewport
        if (entry.isIntersecting) {
          setIntersecting(true);
          observer.unobserve(imgRef.current);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1, // Adjust as needed
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    // Cleanup the observer when the component unmounts
    return () => observer.disconnect();
  }, []);

  return (
    <img
      ref={imgRef}
      className={className}
      src={isIntersecting ? `${base64String}` : ''}
      alt={alt}
    />
  );
};

export default LazyBase64Image;