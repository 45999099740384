import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from '@mui/material';
import MuiToolbar from '@mui/material/Toolbar';
import { persistor } from '../../store/rootReducer';
import { logout } from '../../store/slices/authSlice';
import { connect, useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { proxyS3UrlGeneratorWithKey, proxySrcReplace } from '../../components/Gallery/ImageProxy';
import Logo from '../../components/Logo';
import { fromWei } from 'web3-utils';
import BigNumber from 'bignumber.js';
import { parseJwt } from '../../utils/helpers';
import TopupBalance from '../../components/Token/TopupBalance';
import { usePlatformContext } from './PlatformContext';

const Toolbar = (props) => {
  const {
    isLogged,
    username,
    displayName,
    photoBase64,
    avatarKey,
    isFetching,
    userLogout,
    toggleSidebar,
    balance,
    pageTitle,
    standalone,
    onNavigate,
  } = props;
  const anchorEl = document.querySelector('.platform__toolbar ');
  const navigate = useNavigate();
	const isOperatorUser = useSelector(state => state?.wallet?.isOperatorUser);
  const platformContext = usePlatformContext();

  const menus = [
    {
      label: t('common:profile'),
      icon: solid('gear'),
      action: () => {
        navigate('/profile', { replace: true });
      },
    },
    // {
    //   label: t('common:security'),
    //   icon: solid('user-shield'),
    //   action: () => {
    //     navigate('/profile', { replace: true });
    //   },
    // },
    {
      label: t('common:support'),
      icon: solid('headset'),
      action: () => {
        navigate('/support', { replace: true });
      },
    },
    {
      label: t('common:logout'),
      icon: solid('arrow-right-from-bracket'),
      action: () => userLogout(),
    },
  ];

  const [open, toggleMenu] = useState(false);

  const handleClose = () => {
    toggleMenu(false);
  };


  let userImageSrc = !!avatarKey
    ? proxyS3UrlGeneratorWithKey(avatarKey, 250, 250)
    : photoBase64
      ? `data:image/png;base64,${photoBase64}`
      : 'https://placehold.it/100x100';
	const isTokenPage = window.location.pathname === "/" || window.location.pathname.startsWith('/token') || window.location.pathname.startsWith('/wallet');
	
  return (
    <MuiToolbar className='platform__toolbar'>
      {standalone && (
        <IconButton
          sx={{ ml: 2, mr: 1, width: 28, height: 28 }}
          onClick={() => {
            const handled = platformContext?.navigationIntercept && platformContext.navigationIntercept()
            if (!handled) {
              onNavigate && onNavigate();
            }
          }}
        >
          <FontAwesomeIcon icon={solid('chevron-left')} color='black' size='2xs' />
        </IconButton>
      )}
      {!standalone && (
        <div className='logo--mobile'>
          <div className='logo__wrapper' onClick={() => navigate('/', { replace: true })}>
            <Logo className={`platform__logo logo--short`} />
          </div>
        </div>
      )}
      <div className='header__wallet'>
        <Typography variant={standalone ? 'body2' : 'h4'} mr={2}>{pageTitle}</Typography>
        {!isOperatorUser && !standalone && !!isTokenPage && <TopupBalance />}
      </div>
      {!standalone && (
        <div className='logo--mobile'>
          <IconButton
            onClick={() => toggleSidebar(true)}
            className='menu-button toggle-sidebar'
          >
            <FontAwesomeIcon icon={solid('bars')} />
          </IconButton>
        </div>
      )}
      {!!isLogged && (
        <Hidden smDown>
          {!!isFetching && (
            <>
              <Skeleton
                sx={{ 
									mr: 2,
									maxHeight: '100%',
									aspecRatio: 1
								}}
                variant='circular'
                height={70}
              />
              <hgroup className='user__informations flex flex--vertical flex--align-end flex--justify-end'>
                <Skeleton variant='text' width={110} height={28} />
                <Skeleton variant='text' width={50} height={28} />
              </hgroup>
            </>
          )}
          {!isFetching && (
            <>
              <Avatar
                rel={anchorEl}
                sx={{ width: 40, height: 40, mr: 1 }}
                src={userImageSrc}
                alt={username}
              />
              <hgroup className='user__informations flex flex--vertical flex--align-end flex--justify-end'>
                <Typography variant='body' noWrap component='p'>
                  {displayName}
                </Typography>
                <Typography className='wallet__balance' variant='body2' component='h6'>
                  {`${new BigNumber(fromWei(balance, 'ether')).toFixed(2)} EUR`}
                </Typography>
              </hgroup>
            </>
          )}
        </Hidden>
      )}

      {isLogged && (
        <Hidden smDown>
          <IconButton
            onClick={() => toggleMenu(true)}
            className='arrowdown-button'
          >
            <FontAwesomeIcon icon={solid('chevron-down')} />
          </IconButton>
          <Menu
            className='usermenu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{ mt: '20px' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {menus.map((item, idx) => (
              <MenuItem key={`user-menu-${idx}`} onClick={() => platformContext?.navigationIntercept && platformContext.navigationIntercept() ? handleClose() : item.action()}>
								<span>{item.label}</span>
              </MenuItem>
            ))}
          </Menu>
        </Hidden>
      )}
    </MuiToolbar>
  );
};

export default connect(
  (state) => {
    let data = state?.auth;
    const wallet = state.wallet;
		let username = wallet?.private?.username;
		let displayName = wallet?.public?.firstName
        ? `${wallet?.public?.firstName} ${wallet?.public?.lastName || ''}`
        : wallet?.private?.username;

		if ( wallet.isOperatorUser ) {
			let jwtDecode = parseJwt(data.accessToken)
			username = jwtDecode.name;
			displayName = jwtDecode.given_name;
		}
    return {
      isLogged: !!data.accessToken,
      photoBase64: wallet?.public?.base64Avatar,
      avatarKey: wallet?.public?.avatarKey,
      username: username,
      displayName: displayName,
      isFetching: wallet?.status === 'loading',
      balance: wallet?.balance,
    };
  },
  (dispatch) => {
    return {
      userLogout: () => {
        persistor.purge();
        dispatch(logout());
      },
    };
  }
)(Toolbar);
